<template>
  <div class="parent-element"
    :style="`--pos-top: ${positionFromTop + '%'}; --height: ${height + '%'};`">
    #<span>{{ booking.jobNumber }}, {{ booking.startTime.toFormat('HH:mm') }} - {{ booking.endTime.toFormat('HH:mm') }}</span>
  </div>

</template>
<script>
// import { DateTime } from 'luxon'

export default {
  name: 'CalendarWeeklyBookings',
  props: {
    booking: {
      type: Object,
      required: true
    },
    minTime: {
      type: Object,
      required: true
    },
    maxTime: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      // all in percentages
      positionFromTop: 0,
      positionFromLeft: 0,
      height: 0
    }
  },
  watch: {},
  mounted() {
    this.getAbsolutePosition()
  },
  methods: {
    getAbsolutePosition() {
      const day = this.booking.startTime
      const minTime = day.set({ hour: this.minTime.hour, minute: this.minTime.minutes })
      const maxTime = day.set({ hour: this.maxTime.hour, minute: this.maxTime.minute })

      const startTime = this.booking.startTime
      const endTime = this.booking.endTime

      /// calculate the top percentage for absolute position
      const totalMinutes = maxTime.diff(minTime, 'minutes').minutes
      const minutesFromStart = startTime.diff(minTime, 'minutes').minutes
      const percentageFromStart = (minutesFromStart / totalMinutes) * 100
      this.positionFromTop = percentageFromStart

      /// calculate total minutes of the booking to get the height percentage
      const totalBookingMinutes = endTime.diff(startTime, 'minutes').minutes
      const percentageOfTotalMinutes = (totalBookingMinutes / totalMinutes) * 100
      this.height = percentageOfTotalMinutes

      /// check if the bookings overlaps with other bookings and if so, calculate the left position
    }
  }
}
</script>

<style lang="scss" scoped>
.parent-element {
  position: absolute !important;
  /// 50px is the height of the header
  top: calc(50px + var(--pos-top));
  height: calc(var(--height) - 1px);
  min-height: 20px;
  left: 2px;
  padding: 1px;
  // color: #fff;
  border: 1px solid #3291ef;
  border-radius: 5px;
  background: #fff;
}
</style>