/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.DebtorAdjustmentFilter = (function() {

    /**
     * Namespace DebtorAdjustmentFilter.
     * @exports DebtorAdjustmentFilter
     * @namespace
     */
    var DebtorAdjustmentFilter = {};

    DebtorAdjustmentFilter.DebtorAdjustmentFilterModel = (function() {

        /**
         * Properties of a DebtorAdjustmentFilterModel.
         * @memberof DebtorAdjustmentFilter
         * @interface IDebtorAdjustmentFilterModel
         * @property {string|null} [invoiceNo] DebtorAdjustmentFilterModel invoiceNo
         * @property {number|null} [invoiceType] DebtorAdjustmentFilterModel invoiceType
         * @property {string|null} [cusName] DebtorAdjustmentFilterModel cusName
         * @property {string|null} [fromDate] DebtorAdjustmentFilterModel fromDate
         * @property {string|null} [toDate] DebtorAdjustmentFilterModel toDate
         * @property {string|null} [sortColumn] DebtorAdjustmentFilterModel sortColumn
         * @property {string|null} [sortOrder] DebtorAdjustmentFilterModel sortOrder
         * @property {number|null} [pageIndex] DebtorAdjustmentFilterModel pageIndex
         * @property {number|null} [pageSize] DebtorAdjustmentFilterModel pageSize
         * @property {string|null} [quoteNo] DebtorAdjustmentFilterModel quoteNo
         */

        /**
         * Constructs a new DebtorAdjustmentFilterModel.
         * @memberof DebtorAdjustmentFilter
         * @classdesc Represents a DebtorAdjustmentFilterModel.
         * @implements IDebtorAdjustmentFilterModel
         * @constructor
         * @param {DebtorAdjustmentFilter.IDebtorAdjustmentFilterModel=} [p] Properties to set
         */
        function DebtorAdjustmentFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DebtorAdjustmentFilterModel invoiceNo.
         * @member {string|null|undefined} invoiceNo
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        DebtorAdjustmentFilterModel.prototype.invoiceNo = null;

        /**
         * DebtorAdjustmentFilterModel invoiceType.
         * @member {number|null|undefined} invoiceType
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        DebtorAdjustmentFilterModel.prototype.invoiceType = null;

        /**
         * DebtorAdjustmentFilterModel cusName.
         * @member {string|null|undefined} cusName
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        DebtorAdjustmentFilterModel.prototype.cusName = null;

        /**
         * DebtorAdjustmentFilterModel fromDate.
         * @member {string|null|undefined} fromDate
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        DebtorAdjustmentFilterModel.prototype.fromDate = null;

        /**
         * DebtorAdjustmentFilterModel toDate.
         * @member {string|null|undefined} toDate
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        DebtorAdjustmentFilterModel.prototype.toDate = null;

        /**
         * DebtorAdjustmentFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        DebtorAdjustmentFilterModel.prototype.sortColumn = null;

        /**
         * DebtorAdjustmentFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        DebtorAdjustmentFilterModel.prototype.sortOrder = null;

        /**
         * DebtorAdjustmentFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        DebtorAdjustmentFilterModel.prototype.pageIndex = null;

        /**
         * DebtorAdjustmentFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        DebtorAdjustmentFilterModel.prototype.pageSize = null;

        /**
         * DebtorAdjustmentFilterModel quoteNo.
         * @member {string|null|undefined} quoteNo
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        DebtorAdjustmentFilterModel.prototype.quoteNo = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * DebtorAdjustmentFilterModel _invoiceNo.
         * @member {"invoiceNo"|undefined} _invoiceNo
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        Object.defineProperty(DebtorAdjustmentFilterModel.prototype, "_invoiceNo", {
            get: $util.oneOfGetter($oneOfFields = ["invoiceNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DebtorAdjustmentFilterModel _invoiceType.
         * @member {"invoiceType"|undefined} _invoiceType
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        Object.defineProperty(DebtorAdjustmentFilterModel.prototype, "_invoiceType", {
            get: $util.oneOfGetter($oneOfFields = ["invoiceType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DebtorAdjustmentFilterModel _cusName.
         * @member {"cusName"|undefined} _cusName
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        Object.defineProperty(DebtorAdjustmentFilterModel.prototype, "_cusName", {
            get: $util.oneOfGetter($oneOfFields = ["cusName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DebtorAdjustmentFilterModel _fromDate.
         * @member {"fromDate"|undefined} _fromDate
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        Object.defineProperty(DebtorAdjustmentFilterModel.prototype, "_fromDate", {
            get: $util.oneOfGetter($oneOfFields = ["fromDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DebtorAdjustmentFilterModel _toDate.
         * @member {"toDate"|undefined} _toDate
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        Object.defineProperty(DebtorAdjustmentFilterModel.prototype, "_toDate", {
            get: $util.oneOfGetter($oneOfFields = ["toDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DebtorAdjustmentFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        Object.defineProperty(DebtorAdjustmentFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DebtorAdjustmentFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        Object.defineProperty(DebtorAdjustmentFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DebtorAdjustmentFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        Object.defineProperty(DebtorAdjustmentFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DebtorAdjustmentFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        Object.defineProperty(DebtorAdjustmentFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DebtorAdjustmentFilterModel _quoteNo.
         * @member {"quoteNo"|undefined} _quoteNo
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @instance
         */
        Object.defineProperty(DebtorAdjustmentFilterModel.prototype, "_quoteNo", {
            get: $util.oneOfGetter($oneOfFields = ["quoteNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified DebtorAdjustmentFilterModel message. Does not implicitly {@link DebtorAdjustmentFilter.DebtorAdjustmentFilterModel.verify|verify} messages.
         * @function encode
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @static
         * @param {DebtorAdjustmentFilter.IDebtorAdjustmentFilterModel} m DebtorAdjustmentFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DebtorAdjustmentFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.invoiceNo != null && Object.hasOwnProperty.call(m, "invoiceNo"))
                w.uint32(10).string(m.invoiceNo);
            if (m.invoiceType != null && Object.hasOwnProperty.call(m, "invoiceType"))
                w.uint32(16).int32(m.invoiceType);
            if (m.cusName != null && Object.hasOwnProperty.call(m, "cusName"))
                w.uint32(26).string(m.cusName);
            if (m.fromDate != null && Object.hasOwnProperty.call(m, "fromDate"))
                w.uint32(34).string(m.fromDate);
            if (m.toDate != null && Object.hasOwnProperty.call(m, "toDate"))
                w.uint32(42).string(m.toDate);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(50).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(58).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(64).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(72).int32(m.pageSize);
            if (m.quoteNo != null && Object.hasOwnProperty.call(m, "quoteNo"))
                w.uint32(82).string(m.quoteNo);
            return w;
        };

        /**
         * Encodes the specified DebtorAdjustmentFilterModel message, length delimited. Does not implicitly {@link DebtorAdjustmentFilter.DebtorAdjustmentFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @static
         * @param {DebtorAdjustmentFilter.IDebtorAdjustmentFilterModel} message DebtorAdjustmentFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DebtorAdjustmentFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DebtorAdjustmentFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {DebtorAdjustmentFilter.DebtorAdjustmentFilterModel} DebtorAdjustmentFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DebtorAdjustmentFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.DebtorAdjustmentFilter.DebtorAdjustmentFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.invoiceNo = r.string();
                    break;
                case 2:
                    m.invoiceType = r.int32();
                    break;
                case 3:
                    m.cusName = r.string();
                    break;
                case 4:
                    m.fromDate = r.string();
                    break;
                case 5:
                    m.toDate = r.string();
                    break;
                case 6:
                    m.sortColumn = r.string();
                    break;
                case 7:
                    m.sortOrder = r.string();
                    break;
                case 8:
                    m.pageIndex = r.int32();
                    break;
                case 9:
                    m.pageSize = r.int32();
                    break;
                case 10:
                    m.quoteNo = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a DebtorAdjustmentFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {DebtorAdjustmentFilter.DebtorAdjustmentFilterModel} DebtorAdjustmentFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DebtorAdjustmentFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return DebtorAdjustmentFilterModel;
    })();

    return DebtorAdjustmentFilter;
})();

module.exports = $root;
