import { getCurrency, getCurrencySymbol } from '@/components/currency'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  filters: {
    pad(value, length) {
      if (!value) {
        return ''
      }
      const len = !length ? 2 : length
      var n = value + ''
      while (n.length < len) {
        n = '0' + n
      }
      return n
    },
    formatNumber(value, locale, precision = 2) {
      return new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: precision
      }).format(value)
    },
    formatNumberWithRounding(value, locale, precision = 2) {
      return new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: precision
      }).format(roundAwayFromZero(value, precision))
    },
    formatPercentage(value, locale, precision = 2) {
      return new Intl.NumberFormat(locale, {
        style: 'percent',
        minimumFractionDigits: precision
      }).format(value)
    },
    formatCurrency(value, locale, whiteSpace = false, precision = 2) {
      if (whiteSpace) {
        const formatted = new Intl.NumberFormat(locale, {
          style: 'decimal',
          minimumFractionDigits: precision
        }).format(value)
        return `${getCurrencySymbol(locale)} ${formatted}`
      } else {
        return new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: getCurrency(locale),
          minimumFractionDigits: precision
        }).format(value)
      }
    }
  }
}
