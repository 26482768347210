import AgentApi from '@/services/agent-api'

const AgentService = {
  async hello() {
    const result = await AgentApi.get('?class=Agent&method=hello')
    return result.data.data
  },
  Scanner: {
    async getDevices() {
      const result = await AgentApi.get('?class=Scanner&method=getDevices')
      return result.data.data
    },
    async getResolutions(deviceName) {
      const result = await AgentApi.get('?class=Scanner&method=getResolutions&deviceName='+deviceName)
      return result.data.data
    },
    async getPixelTypes(deviceName) {
      const result = await AgentApi.get('?class=Scanner&method=getPixelTypes&deviceName='+deviceName)
      return result.data.data
    },
    async scan(deviceName, resolutionName, pixelTypeName) {
      const result = await AgentApi.get('?class=Scanner&method=scan&deviceName='+deviceName+'&resolutionName='+resolutionName+'&pixelTypeName='+pixelTypeName)
      return result.data.data
    },
    async uploadFile(url, data) {
      const formData = new FormData()
      formData.append('file',data,'Scanned.png')
      const result = await AgentApi.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return result
    }
  }
}

export default AgentService
