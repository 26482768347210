import DebtorListRoutes from './route-types'

// Lazy-load components. Webpack will split each into individual file
const DebtorListListView = r => require.ensure([], () => r(require('./DebtorListListView.vue')), 'debtorlist-list-chunk')

const moduleId = 'A053'

export default [
  {
    path: DebtorListRoutes.DebtorListListView.path,
    name: DebtorListRoutes.DebtorListListView.name,
    component: DebtorListListView,
    meta: {
      id: moduleId,
      route: 'listing',
      title: 'Debtor List',
      fkey: 'DebtorList|filter',
      ekey: 'emailer',
      report: 'AT_DebtorListV2',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  }
]
