/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.QuickInvoiceContactDropdown = (function() {

    /**
     * Namespace QuickInvoiceContactDropdown.
     * @exports QuickInvoiceContactDropdown
     * @namespace
     */
    var QuickInvoiceContactDropdown = {};

    QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel = (function() {

        /**
         * Properties of a QuickInvoiceContactDropdownModel.
         * @memberof QuickInvoiceContactDropdown
         * @interface IQuickInvoiceContactDropdownModel
         * @property {Array.<QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.IContact>|null} [contacts] QuickInvoiceContactDropdownModel contacts
         */

        /**
         * Constructs a new QuickInvoiceContactDropdownModel.
         * @memberof QuickInvoiceContactDropdown
         * @classdesc Represents a QuickInvoiceContactDropdownModel.
         * @implements IQuickInvoiceContactDropdownModel
         * @constructor
         * @param {QuickInvoiceContactDropdown.IQuickInvoiceContactDropdownModel=} [p] Properties to set
         */
        function QuickInvoiceContactDropdownModel(p) {
            this.contacts = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QuickInvoiceContactDropdownModel contacts.
         * @member {Array.<QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.IContact>} contacts
         * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel
         * @instance
         */
        QuickInvoiceContactDropdownModel.prototype.contacts = $util.emptyArray;

        /**
         * Encodes the specified QuickInvoiceContactDropdownModel message. Does not implicitly {@link QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.verify|verify} messages.
         * @function encode
         * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel
         * @static
         * @param {QuickInvoiceContactDropdown.IQuickInvoiceContactDropdownModel} m QuickInvoiceContactDropdownModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuickInvoiceContactDropdownModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.contacts != null && m.contacts.length) {
                for (var i = 0; i < m.contacts.length; ++i)
                    $root.QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact.encode(m.contacts[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Encodes the specified QuickInvoiceContactDropdownModel message, length delimited. Does not implicitly {@link QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel
         * @static
         * @param {QuickInvoiceContactDropdown.IQuickInvoiceContactDropdownModel} message QuickInvoiceContactDropdownModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuickInvoiceContactDropdownModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QuickInvoiceContactDropdownModel message from the specified reader or buffer.
         * @function decode
         * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel} QuickInvoiceContactDropdownModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuickInvoiceContactDropdownModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.contacts && m.contacts.length))
                        m.contacts = [];
                    m.contacts.push($root.QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a QuickInvoiceContactDropdownModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel} QuickInvoiceContactDropdownModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuickInvoiceContactDropdownModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        QuickInvoiceContactDropdownModel.Contact = (function() {

            /**
             * Properties of a Contact.
             * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel
             * @interface IContact
             * @property {string|null} [contactId] Contact contactId
             * @property {string|null} [contactMapId] Contact contactMapId
             * @property {string|null} [fullname] Contact fullname
             * @property {boolean|null} [active] Contact active
             */

            /**
             * Constructs a new Contact.
             * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel
             * @classdesc Represents a Contact.
             * @implements IContact
             * @constructor
             * @param {QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.IContact=} [p] Properties to set
             */
            function Contact(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Contact contactId.
             * @member {string|null|undefined} contactId
             * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact
             * @instance
             */
            Contact.prototype.contactId = null;

            /**
             * Contact contactMapId.
             * @member {string|null|undefined} contactMapId
             * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact
             * @instance
             */
            Contact.prototype.contactMapId = null;

            /**
             * Contact fullname.
             * @member {string|null|undefined} fullname
             * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact
             * @instance
             */
            Contact.prototype.fullname = null;

            /**
             * Contact active.
             * @member {boolean|null|undefined} active
             * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact
             * @instance
             */
            Contact.prototype.active = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Contact _contactId.
             * @member {"contactId"|undefined} _contactId
             * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact
             * @instance
             */
            Object.defineProperty(Contact.prototype, "_contactId", {
                get: $util.oneOfGetter($oneOfFields = ["contactId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Contact _contactMapId.
             * @member {"contactMapId"|undefined} _contactMapId
             * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact
             * @instance
             */
            Object.defineProperty(Contact.prototype, "_contactMapId", {
                get: $util.oneOfGetter($oneOfFields = ["contactMapId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Contact _fullname.
             * @member {"fullname"|undefined} _fullname
             * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact
             * @instance
             */
            Object.defineProperty(Contact.prototype, "_fullname", {
                get: $util.oneOfGetter($oneOfFields = ["fullname"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Contact _active.
             * @member {"active"|undefined} _active
             * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact
             * @instance
             */
            Object.defineProperty(Contact.prototype, "_active", {
                get: $util.oneOfGetter($oneOfFields = ["active"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified Contact message. Does not implicitly {@link QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact.verify|verify} messages.
             * @function encode
             * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact
             * @static
             * @param {QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.IContact} m Contact message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Contact.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.contactId != null && Object.hasOwnProperty.call(m, "contactId"))
                    w.uint32(10).string(m.contactId);
                if (m.contactMapId != null && Object.hasOwnProperty.call(m, "contactMapId"))
                    w.uint32(18).string(m.contactMapId);
                if (m.fullname != null && Object.hasOwnProperty.call(m, "fullname"))
                    w.uint32(26).string(m.fullname);
                if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                    w.uint32(32).bool(m.active);
                return w;
            };

            /**
             * Encodes the specified Contact message, length delimited. Does not implicitly {@link QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact.verify|verify} messages.
             * @function encodeDelimited
             * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact
             * @static
             * @param {QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.IContact} message Contact message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Contact.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Contact message from the specified reader or buffer.
             * @function decode
             * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact} Contact
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Contact.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.contactId = r.string();
                        break;
                    case 2:
                        m.contactMapId = r.string();
                        break;
                    case 3:
                        m.fullname = r.string();
                        break;
                    case 4:
                        m.active = r.bool();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Decodes a Contact message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel.Contact} Contact
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Contact.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            return Contact;
        })();

        return QuickInvoiceContactDropdownModel;
    })();

    return QuickInvoiceContactDropdown;
})();

module.exports = $root;
