<template>
  <div class="tile is-child has-background-dark tile-border ">

    <DashboardPanel>
      <div class="is-flex is-justify-content-center is-align-items-center panel-heading custom-panel-heading">
        <p class="ml-auto has-text-white">QUOTE STATUS</p>
        <button class="button is-small is-info ml-auto"
          @click="addNew">
          <i class="mdi mdi-plus pr-1 is-size-6" />
          New</button>
      </div>

      <DashboardProgressBar :is-loading="isLoading"
        :initial-color="'#4E8FE8'"
        :loading-color="'#0066cc'" />

      <div class="has-background-dark tile-border gap-sm"
        v-if="quotes">
        <div class="panel-block is-flex is-justify-content-space-between custom-panel custom-panel-border pb-3 clickable-panel"
          :class="[{'custom-panel-with-no-chart': isUninvoicedJobsAndChartDisabled}]"
          @click="onClick(JobStageTypes.ToBeQuoted)">
          <p class="is-flex is-align-items-center ">To Be Written</p>
          <DashboardCircularStatus :value="quotes.quoteToBeWritten"
            :color-code="'#3291EF'"
            :width="'50px'"
            :height="'50px'" />
        </div>
        <!--
        <div class="panel-block panel-block is-flex is-justify-content-space-between custom-panel custom-panel-border pb-3 clickable-panel"
          :class="[{'custom-panel-with-no-chart': isUninvoicedJobsAndChartDisabled}]"
          @click="onClick(JobStageTypes.QuoteToBeChecked)">
          <p class="is-flex is-align-items-center">Quotes To Be Checked</p>
          <DashboardCircularStatus :value="quotes.quoteToBeChecked"
            :color-code="'#3291EF'"
            :width="'50px'"
            :height="'50px'" />
        </div> -->

        <div class="panel-block is-flex is-justify-content-space-between custom-panel custom-panel-border pb-3 clickable-panel"
          :class="[{'custom-panel-with-no-chart': isUninvoicedJobsAndChartDisabled}]"
          @click="onClick(JobStageTypes.AuthorityPending)">
          <p class="is-flex is-align-items-center">Pending Authority</p>
          <DashboardCircularStatus :value="quotes.pendingAuthority"
            :color-code="'#3291EF'"
            :width="'50px'"
            :height="'50px'" />
        </div>

        <div class="panel-block is-flex is-justify-content-space-between custom-panel custom-panel-border pb-3 clickable-panel"
          :class="[{'custom-panel-with-no-chart': isUninvoicedJobsAndChartDisabled}]"
          @click="onClick(JobStageTypes.FollowUpRequired)">

          <p class="is-flex is-align-items-center">Follow Up Required</p>
          <DashboardCircularStatus :value="quotes.followUpRequired"
            :color-code="'#3291EF'"
            :width="'50px'"
            :height="'50px'" />
        </div>

        <div class="panel-block is-flex is-justify-content-space-between custom-panel custom-panel-border pb-3 clickable-panel"
          :class="[{'custom-panel-with-no-chart': isUninvoicedJobsAndChartDisabled}]"
          @click="onClick(JobStageTypes.Authorised)">
          <p class="is-flex is-align-items-center">Authorised</p>
          <DashboardCircularStatus :value="quotes.authorised"
            :color-code="'#3291EF'"
            :width="'50px'"
            :height="'50px'" />
        </div>
      </div>
    </DashboardPanel>

  </div>
</template>

<script>
import DashboardCircularStatus from '@/views/dashboard/DashboardCircularStatus.vue'
import DashboardPanel from '@/views/dashboard/DashboardPanel.vue'
import storeMixin from '@/views/dashboard/storeMixin'
import JobStageTypes from '@/enums/JobStageTypes'
import DashboardProgressBar from '@/views/dashboard/DashboardProgressBar.vue'

export default {
  name: 'DashboardQuoteStatus',
  components: { DashboardProgressBar, DashboardPanel, DashboardCircularStatus },
  mixins: [storeMixin],
  props: {
    quotes: Object,
    isChartDisabled: Boolean,
    isLoading: Boolean,
    isUninvoicedJobsAndChartDisabled: Boolean
  },
  computed: {
    JobStageTypes() {
      return JobStageTypes
    }
  },
  methods: {
    async addNew() {
      this.addStoreItem(0)
    },
    onClick(jobStage) {
      this.$emit('handleQuoteRedirect', jobStage)
    }
  }
}
</script>

<style scoped>
.custom-panel {
  color: #fff;
  height: 9.5vh;
}
.custom-panel-with-no-chart {
  color: #fff;
  height: 16vh;
}

.custom-panel-border:not(:last-child) {
  border-bottom: 1px solid black;
}
.tile-border {
  border-radius: 20px !important;
}
.custom-panel-heading {
  border-radius: 20px 20px 0 0 !important;
  background-color: #495057 !important;
}
</style>