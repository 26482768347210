import Guid from '@/components/Guid'

export default class RemarkModel {
  constructor(assetId, remarkTypeId, remark = '',firstName='',lastName='',displayName='') {
    this.assetId = assetId
    this.remarkTypeId = remarkTypeId
    this.remarks = remark
    this.isAlert = false
    this.isDeleted = false
    this.isNew = true
    this.remarkDateTime = new Date()
    this.remarkId = Guid.newGuid()
    this.firstName = firstName
    this.lastName = lastName
    this.displayName = displayName
    this.pinRemarkOrder = null
  }
}
