import CustomerService from './CustomerService'
import CustomerRouteTypes from './route-types'
import _cloneDeep from 'lodash/cloneDeep'
import StoreUtil from '@/store/utils'
import DeepDiff from 'deep-diff'
import { md5 } from '@/components/crypto'

const tag = 'customer'

const state = {
  returnRoute: {},
  baseIndex: 0,
  currentIndex: 0,
  snapshots: []
}

const getters = {
  returnRoute: state => state.returnRoute,
  snapshots: state => state.snapshots,
  baseSnapshot: state => state.snapshots[state.baseIndex],
  currentSnapshot: state => state.snapshots[state.currentIndex],
  snapshotDiff: state => {
    if (state.snapshots[state.baseIndex] && state.snapshots[state.currentIndex]) {
      return DeepDiff.diff(state.snapshots[state.baseIndex], state.snapshots[state.currentIndex])
    } else {
      return null
    }
  }
}

const actions = {
  async getStoreItem({ commit }, id) {
    // const entity = StoreUtil.getStorage(id, tag) || (await CustomerService.getEntity(id))
    // The following for refreshing data from WebApi regardless
    let entity = StoreUtil.getStorage(id, tag)
    if (!entity || (entity && !entity.isNew)) {
      const hash = entity ? md5(JSON.stringify(entity)) : ''
      const data = await CustomerService.getEntity(id, hash)
      if (data) {
        console.log('from WebApi')
        entity = data
      } else {
        console.log('from sessionStorage')
      }
    }
    StoreUtil.setStorage(entity.id, tag, entity)
    commit('saveInitialSnapshots', entity)
  },
  async editStoreItem({ commit }, id) {
    const entity = StoreUtil.getStorage(id, tag) || (await CustomerService.getEntity(id))
    StoreUtil.setStorage(entity.id, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(CustomerRouteTypes.CustomerListView.path, entity.id, false)
  },
  async addStoreItem({ commit }, name) {
    const entity = await CustomerService.getNewEntity()
    StoreUtil.setStorage(entity.id, tag, entity)
    commit('saveInitialSnapshots', entity)
    if (name) {
      const copy = _cloneDeep(entity)
      const nameParts = name.split(',')
      copy.lastname = nameParts[0].trim()
      copy.firstname = nameParts[1] ? nameParts[1].trim() : ''
      commit('saveSnapshot', copy)
    }
    StoreUtil.routeToDetail(CustomerRouteTypes.CustomerListView.path, entity.id, true)
  },
  saveSnapshot({ commit }, entity) {
    commit('saveSnapshot', entity)
  },
  clearSnapshots({ commit }, id) {
    StoreUtil.removeStorage(id, tag)
    commit('clearSnapshots')
  },
  setReturnRoute({ commit }, route) {
    commit('setReturnRoute', route)
  }
}

const mutations = {
  saveInitialSnapshots(state, entity) {
    state.snapshots = []
    state.snapshots.splice(0, 1, entity)
    state.snapshots.splice(1, 1, entity)
    state.currentIndex = state.snapshots.length - 1
  },
  saveSnapshot(state, entity) {
    state.snapshots.splice(state.currentIndex, 1, entity)
  },
  clearSnapshots(state) {
    state.snapshots = []
    state.baseIndex = 0
    state.currentIndex = 0
  },
  setReturnRoute(state, route) {
    state.returnRoute = route
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
