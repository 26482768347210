/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.UnscheduledModelMakeDropdown = (function() {

    /**
     * Namespace UnscheduledModelMakeDropdown.
     * @exports UnscheduledModelMakeDropdown
     * @namespace
     */
    var UnscheduledModelMakeDropdown = {};

    UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel = (function() {

        /**
         * Properties of an UnscheduledModelMakeDropdownModel.
         * @memberof UnscheduledModelMakeDropdown
         * @interface IUnscheduledModelMakeDropdownModel
         * @property {Array.<UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.IAsset>|null} [assets] UnscheduledModelMakeDropdownModel assets
         */

        /**
         * Constructs a new UnscheduledModelMakeDropdownModel.
         * @memberof UnscheduledModelMakeDropdown
         * @classdesc Represents an UnscheduledModelMakeDropdownModel.
         * @implements IUnscheduledModelMakeDropdownModel
         * @constructor
         * @param {UnscheduledModelMakeDropdown.IUnscheduledModelMakeDropdownModel=} [p] Properties to set
         */
        function UnscheduledModelMakeDropdownModel(p) {
            this.assets = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UnscheduledModelMakeDropdownModel assets.
         * @member {Array.<UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.IAsset>} assets
         * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel
         * @instance
         */
        UnscheduledModelMakeDropdownModel.prototype.assets = $util.emptyArray;

        /**
         * Encodes the specified UnscheduledModelMakeDropdownModel message. Does not implicitly {@link UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.verify|verify} messages.
         * @function encode
         * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel
         * @static
         * @param {UnscheduledModelMakeDropdown.IUnscheduledModelMakeDropdownModel} m UnscheduledModelMakeDropdownModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnscheduledModelMakeDropdownModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.assets != null && m.assets.length) {
                for (var i = 0; i < m.assets.length; ++i)
                    $root.UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset.encode(m.assets[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Encodes the specified UnscheduledModelMakeDropdownModel message, length delimited. Does not implicitly {@link UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel
         * @static
         * @param {UnscheduledModelMakeDropdown.IUnscheduledModelMakeDropdownModel} message UnscheduledModelMakeDropdownModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnscheduledModelMakeDropdownModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UnscheduledModelMakeDropdownModel message from the specified reader or buffer.
         * @function decode
         * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel} UnscheduledModelMakeDropdownModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnscheduledModelMakeDropdownModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.assets && m.assets.length))
                        m.assets = [];
                    m.assets.push($root.UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes an UnscheduledModelMakeDropdownModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel} UnscheduledModelMakeDropdownModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnscheduledModelMakeDropdownModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        UnscheduledModelMakeDropdownModel.Asset = (function() {

            /**
             * Properties of an Asset.
             * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel
             * @interface IAsset
             * @property {number|Long|null} [rowNumber] Asset rowNumber
             * @property {string|null} [make] Asset make
             * @property {number|null} [totalRows] Asset totalRows
             */

            /**
             * Constructs a new Asset.
             * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel
             * @classdesc Represents an Asset.
             * @implements IAsset
             * @constructor
             * @param {UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.IAsset=} [p] Properties to set
             */
            function Asset(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Asset rowNumber.
             * @member {number|Long|null|undefined} rowNumber
             * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset
             * @instance
             */
            Asset.prototype.rowNumber = null;

            /**
             * Asset make.
             * @member {string|null|undefined} make
             * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset
             * @instance
             */
            Asset.prototype.make = null;

            /**
             * Asset totalRows.
             * @member {number|null|undefined} totalRows
             * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset
             * @instance
             */
            Asset.prototype.totalRows = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Asset _rowNumber.
             * @member {"rowNumber"|undefined} _rowNumber
             * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset
             * @instance
             */
            Object.defineProperty(Asset.prototype, "_rowNumber", {
                get: $util.oneOfGetter($oneOfFields = ["rowNumber"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Asset _make.
             * @member {"make"|undefined} _make
             * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset
             * @instance
             */
            Object.defineProperty(Asset.prototype, "_make", {
                get: $util.oneOfGetter($oneOfFields = ["make"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Asset _totalRows.
             * @member {"totalRows"|undefined} _totalRows
             * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset
             * @instance
             */
            Object.defineProperty(Asset.prototype, "_totalRows", {
                get: $util.oneOfGetter($oneOfFields = ["totalRows"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified Asset message. Does not implicitly {@link UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset.verify|verify} messages.
             * @function encode
             * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset
             * @static
             * @param {UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.IAsset} m Asset message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Asset.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.rowNumber != null && Object.hasOwnProperty.call(m, "rowNumber"))
                    w.uint32(8).int64(m.rowNumber);
                if (m.make != null && Object.hasOwnProperty.call(m, "make"))
                    w.uint32(18).string(m.make);
                if (m.totalRows != null && Object.hasOwnProperty.call(m, "totalRows"))
                    w.uint32(24).int32(m.totalRows);
                return w;
            };

            /**
             * Encodes the specified Asset message, length delimited. Does not implicitly {@link UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset.verify|verify} messages.
             * @function encodeDelimited
             * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset
             * @static
             * @param {UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.IAsset} message Asset message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Asset.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Asset message from the specified reader or buffer.
             * @function decode
             * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset} Asset
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Asset.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.rowNumber = r.int64();
                        break;
                    case 2:
                        m.make = r.string();
                        break;
                    case 3:
                        m.totalRows = r.int32();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Decodes an Asset message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel.Asset} Asset
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Asset.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            return Asset;
        })();

        return UnscheduledModelMakeDropdownModel;
    })();

    return UnscheduledModelMakeDropdown;
})();

$root.bcl = (function() {

    /**
     * Namespace bcl.
     * @exports bcl
     * @namespace
     */
    var bcl = {};

    bcl.TimeSpan = (function() {

        /**
         * Properties of a TimeSpan.
         * @memberof bcl
         * @interface ITimeSpan
         * @property {number|Long|null} [value] TimeSpan value
         * @property {bcl.TimeSpan.TimeSpanScale|null} [scale] TimeSpan scale
         */

        /**
         * Constructs a new TimeSpan.
         * @memberof bcl
         * @classdesc Represents a TimeSpan.
         * @implements ITimeSpan
         * @constructor
         * @param {bcl.ITimeSpan=} [p] Properties to set
         */
        function TimeSpan(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TimeSpan value.
         * @member {number|Long} value
         * @memberof bcl.TimeSpan
         * @instance
         */
        TimeSpan.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TimeSpan scale.
         * @member {bcl.TimeSpan.TimeSpanScale} scale
         * @memberof bcl.TimeSpan
         * @instance
         */
        TimeSpan.prototype.scale = 0;

        /**
         * Encodes the specified TimeSpan message. Does not implicitly {@link bcl.TimeSpan.verify|verify} messages.
         * @function encode
         * @memberof bcl.TimeSpan
         * @static
         * @param {bcl.ITimeSpan} m TimeSpan message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeSpan.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(8).sint64(m.value);
            if (m.scale != null && Object.hasOwnProperty.call(m, "scale"))
                w.uint32(16).int32(m.scale);
            return w;
        };

        /**
         * Encodes the specified TimeSpan message, length delimited. Does not implicitly {@link bcl.TimeSpan.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.TimeSpan
         * @static
         * @param {bcl.ITimeSpan} message TimeSpan message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeSpan.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TimeSpan message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.TimeSpan
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.TimeSpan} TimeSpan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeSpan.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.TimeSpan();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.value = r.sint64();
                    break;
                case 2:
                    m.scale = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a TimeSpan message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.TimeSpan
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.TimeSpan} TimeSpan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeSpan.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * TimeSpanScale enum.
         * @name bcl.TimeSpan.TimeSpanScale
         * @enum {number}
         * @property {number} DAYS=0 DAYS value
         * @property {number} HOURS=1 HOURS value
         * @property {number} MINUTES=2 MINUTES value
         * @property {number} SECONDS=3 SECONDS value
         * @property {number} MILLISECONDS=4 MILLISECONDS value
         * @property {number} MINMAX=15 MINMAX value
         */
        TimeSpan.TimeSpanScale = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DAYS"] = 0;
            values[valuesById[1] = "HOURS"] = 1;
            values[valuesById[2] = "MINUTES"] = 2;
            values[valuesById[3] = "SECONDS"] = 3;
            values[valuesById[4] = "MILLISECONDS"] = 4;
            values[valuesById[15] = "MINMAX"] = 15;
            return values;
        })();

        return TimeSpan;
    })();

    bcl.DateTime = (function() {

        /**
         * Properties of a DateTime.
         * @memberof bcl
         * @interface IDateTime
         * @property {number|Long|null} [value] DateTime value
         * @property {bcl.DateTime.TimeSpanScale|null} [scale] DateTime scale
         */

        /**
         * Constructs a new DateTime.
         * @memberof bcl
         * @classdesc Represents a DateTime.
         * @implements IDateTime
         * @constructor
         * @param {bcl.IDateTime=} [p] Properties to set
         */
        function DateTime(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DateTime value.
         * @member {number|Long} value
         * @memberof bcl.DateTime
         * @instance
         */
        DateTime.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DateTime scale.
         * @member {bcl.DateTime.TimeSpanScale} scale
         * @memberof bcl.DateTime
         * @instance
         */
        DateTime.prototype.scale = 0;

        /**
         * Encodes the specified DateTime message. Does not implicitly {@link bcl.DateTime.verify|verify} messages.
         * @function encode
         * @memberof bcl.DateTime
         * @static
         * @param {bcl.IDateTime} m DateTime message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateTime.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(8).sint64(m.value);
            if (m.scale != null && Object.hasOwnProperty.call(m, "scale"))
                w.uint32(16).int32(m.scale);
            return w;
        };

        /**
         * Encodes the specified DateTime message, length delimited. Does not implicitly {@link bcl.DateTime.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.DateTime
         * @static
         * @param {bcl.IDateTime} message DateTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateTime.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DateTime message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.DateTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.DateTime} DateTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateTime.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.DateTime();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.value = r.sint64();
                    break;
                case 2:
                    m.scale = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a DateTime message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.DateTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.DateTime} DateTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateTime.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * TimeSpanScale enum.
         * @name bcl.DateTime.TimeSpanScale
         * @enum {number}
         * @property {number} DAYS=0 DAYS value
         * @property {number} HOURS=1 HOURS value
         * @property {number} MINUTES=2 MINUTES value
         * @property {number} SECONDS=3 SECONDS value
         * @property {number} MILLISECONDS=4 MILLISECONDS value
         * @property {number} MINMAX=15 MINMAX value
         */
        DateTime.TimeSpanScale = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DAYS"] = 0;
            values[valuesById[1] = "HOURS"] = 1;
            values[valuesById[2] = "MINUTES"] = 2;
            values[valuesById[3] = "SECONDS"] = 3;
            values[valuesById[4] = "MILLISECONDS"] = 4;
            values[valuesById[15] = "MINMAX"] = 15;
            return values;
        })();

        return DateTime;
    })();

    bcl.Guid = (function() {

        /**
         * Properties of a Guid.
         * @memberof bcl
         * @interface IGuid
         * @property {number|Long|null} [lo] Guid lo
         * @property {number|Long|null} [hi] Guid hi
         */

        /**
         * Constructs a new Guid.
         * @memberof bcl
         * @classdesc Represents a Guid.
         * @implements IGuid
         * @constructor
         * @param {bcl.IGuid=} [p] Properties to set
         */
        function Guid(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Guid lo.
         * @member {number|Long} lo
         * @memberof bcl.Guid
         * @instance
         */
        Guid.prototype.lo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Guid hi.
         * @member {number|Long} hi
         * @memberof bcl.Guid
         * @instance
         */
        Guid.prototype.hi = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Encodes the specified Guid message. Does not implicitly {@link bcl.Guid.verify|verify} messages.
         * @function encode
         * @memberof bcl.Guid
         * @static
         * @param {bcl.IGuid} m Guid message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Guid.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.lo != null && Object.hasOwnProperty.call(m, "lo"))
                w.uint32(9).fixed64(m.lo);
            if (m.hi != null && Object.hasOwnProperty.call(m, "hi"))
                w.uint32(17).fixed64(m.hi);
            return w;
        };

        /**
         * Encodes the specified Guid message, length delimited. Does not implicitly {@link bcl.Guid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.Guid
         * @static
         * @param {bcl.IGuid} message Guid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Guid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Guid message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.Guid
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.Guid} Guid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Guid.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.Guid();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.lo = r.fixed64();
                    break;
                case 2:
                    m.hi = r.fixed64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a Guid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.Guid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.Guid} Guid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Guid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return Guid;
    })();

    bcl.Decimal = (function() {

        /**
         * Properties of a Decimal.
         * @memberof bcl
         * @interface IDecimal
         * @property {number|Long|null} [lo] Decimal lo
         * @property {number|null} [hi] Decimal hi
         * @property {number|null} [signScale] Decimal signScale
         */

        /**
         * Constructs a new Decimal.
         * @memberof bcl
         * @classdesc Represents a Decimal.
         * @implements IDecimal
         * @constructor
         * @param {bcl.IDecimal=} [p] Properties to set
         */
        function Decimal(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Decimal lo.
         * @member {number|Long} lo
         * @memberof bcl.Decimal
         * @instance
         */
        Decimal.prototype.lo = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Decimal hi.
         * @member {number} hi
         * @memberof bcl.Decimal
         * @instance
         */
        Decimal.prototype.hi = 0;

        /**
         * Decimal signScale.
         * @member {number} signScale
         * @memberof bcl.Decimal
         * @instance
         */
        Decimal.prototype.signScale = 0;

        /**
         * Encodes the specified Decimal message. Does not implicitly {@link bcl.Decimal.verify|verify} messages.
         * @function encode
         * @memberof bcl.Decimal
         * @static
         * @param {bcl.IDecimal} m Decimal message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Decimal.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.lo != null && Object.hasOwnProperty.call(m, "lo"))
                w.uint32(8).uint64(m.lo);
            if (m.hi != null && Object.hasOwnProperty.call(m, "hi"))
                w.uint32(16).uint32(m.hi);
            if (m.signScale != null && Object.hasOwnProperty.call(m, "signScale"))
                w.uint32(24).sint32(m.signScale);
            return w;
        };

        /**
         * Encodes the specified Decimal message, length delimited. Does not implicitly {@link bcl.Decimal.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.Decimal
         * @static
         * @param {bcl.IDecimal} message Decimal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Decimal.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Decimal message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.Decimal
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.Decimal} Decimal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Decimal.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.Decimal();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.lo = r.uint64();
                    break;
                case 2:
                    m.hi = r.uint32();
                    break;
                case 3:
                    m.signScale = r.sint32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a Decimal message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.Decimal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.Decimal} Decimal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Decimal.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return Decimal;
    })();

    return bcl;
})();

module.exports = $root;
