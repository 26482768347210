export default class PhoneModel {
  constructor() {
    this.id = null
    this.type = '0'
    this.no = null
    this.isDefault = false
    this.overwriteOnConflict = false
    this.mergeOnConflict = false
    this.isNew = true
  }
}
