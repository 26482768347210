import PermissionRecordListing from './PermissionRecordListing'

export default [
  {
    path: '/permissionrecords',
    component: PermissionRecordListing,
    meta: {
      title: 'Access Routes Setup'
    }
  }
]
