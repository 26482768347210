// directives as mixins
export default {
  directives: {
    FocusInserted: {
      inserted: function(el) {
        el.focus()
      }
    }
  }
}
