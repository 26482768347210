// import Modal from './Modal'
// import BaseModal from './BaseModal'
// import CardModal from './CardModal'
// import ImageModal from './ImageModal'
// import BuefyModal from './BuefyModal'

const Modal = (r) => require.ensure([], () => r(require('./Modal.vue')), 'shared-modal')
const BaseModal = (r) => require.ensure([], () => r(require('./BaseModal.js')), 'shared-modal')
const CardModal = (r) => require.ensure([], () => r(require('./CardModal.vue')), 'shared-modal')
const ImageModal = (r) => require.ensure([], () => r(require('./ImageModal.vue')), 'shared-modal')
const BaseModalEx = (r) => require.ensure([], () => r(require('./BaseModalEx.vue')), 'shared-modal')
const UnsavedModal = (r) => require.ensure([], () => r(require('./UnsavedModal.vue')), 'shared-modal')
const SaveConflictModal = (r) => require.ensure([], () => r(require('./SaveConflictModal.vue')), 'shared-modal')
const ConfirmModal = (r) => require.ensure([], () => r(require('./ConfirmModal.vue')), 'shared-modal')
const RemarkModal = (r) => require.ensure([], () => r(require('./RemarkModal.vue')), 'shared-modal')
const AboutModal = (r) => require.ensure([], () => r(require('./AboutModal.vue')), 'shared-modal')
const PdfModal = (r) => require.ensure([], () => r(require('./PdfModal.vue')), 'shared-modal')
const QuoteItemPresentAsModal = (r) => require.ensure([], () => r(require('./QuoteItemPresentAsModal.vue')), 'shared-modal')
const ReleaseNotesModal = (r) => require.ensure([], () => r(require('./ReleaseNotesModal.vue')), 'shared-modal')

export {
  Modal,
  BaseModal,
  CardModal,
  ImageModal,
  BaseModalEx,
  UnsavedModal,
  SaveConflictModal,
  ConfirmModal,
  RemarkModal,
  AboutModal,
  PdfModal,
  QuoteItemPresentAsModal,
  ReleaseNotesModal
}
