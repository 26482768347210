/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.ProductionInOutFilter = (function() {

    /**
     * Namespace ProductionInOutFilter.
     * @exports ProductionInOutFilter
     * @namespace
     */
    var ProductionInOutFilter = {};

    ProductionInOutFilter.ProductionInOutFilterModel = (function() {

        /**
         * Properties of a ProductionInOutFilterModel.
         * @memberof ProductionInOutFilter
         * @interface IProductionInOutFilterModel
         * @property {string|null} [dateFrom] ProductionInOutFilterModel dateFrom
         * @property {string|null} [dateTo] ProductionInOutFilterModel dateTo
         * @property {number|null} [jobInOut] ProductionInOutFilterModel jobInOut
         * @property {string|null} [sortColumn] ProductionInOutFilterModel sortColumn
         * @property {string|null} [sortOrder] ProductionInOutFilterModel sortOrder
         */

        /**
         * Constructs a new ProductionInOutFilterModel.
         * @memberof ProductionInOutFilter
         * @classdesc Represents a ProductionInOutFilterModel.
         * @implements IProductionInOutFilterModel
         * @constructor
         * @param {ProductionInOutFilter.IProductionInOutFilterModel=} [p] Properties to set
         */
        function ProductionInOutFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ProductionInOutFilterModel dateFrom.
         * @member {string|null|undefined} dateFrom
         * @memberof ProductionInOutFilter.ProductionInOutFilterModel
         * @instance
         */
        ProductionInOutFilterModel.prototype.dateFrom = null;

        /**
         * ProductionInOutFilterModel dateTo.
         * @member {string|null|undefined} dateTo
         * @memberof ProductionInOutFilter.ProductionInOutFilterModel
         * @instance
         */
        ProductionInOutFilterModel.prototype.dateTo = null;

        /**
         * ProductionInOutFilterModel jobInOut.
         * @member {number|null|undefined} jobInOut
         * @memberof ProductionInOutFilter.ProductionInOutFilterModel
         * @instance
         */
        ProductionInOutFilterModel.prototype.jobInOut = null;

        /**
         * ProductionInOutFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof ProductionInOutFilter.ProductionInOutFilterModel
         * @instance
         */
        ProductionInOutFilterModel.prototype.sortColumn = null;

        /**
         * ProductionInOutFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof ProductionInOutFilter.ProductionInOutFilterModel
         * @instance
         */
        ProductionInOutFilterModel.prototype.sortOrder = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ProductionInOutFilterModel _dateFrom.
         * @member {"dateFrom"|undefined} _dateFrom
         * @memberof ProductionInOutFilter.ProductionInOutFilterModel
         * @instance
         */
        Object.defineProperty(ProductionInOutFilterModel.prototype, "_dateFrom", {
            get: $util.oneOfGetter($oneOfFields = ["dateFrom"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProductionInOutFilterModel _dateTo.
         * @member {"dateTo"|undefined} _dateTo
         * @memberof ProductionInOutFilter.ProductionInOutFilterModel
         * @instance
         */
        Object.defineProperty(ProductionInOutFilterModel.prototype, "_dateTo", {
            get: $util.oneOfGetter($oneOfFields = ["dateTo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProductionInOutFilterModel _jobInOut.
         * @member {"jobInOut"|undefined} _jobInOut
         * @memberof ProductionInOutFilter.ProductionInOutFilterModel
         * @instance
         */
        Object.defineProperty(ProductionInOutFilterModel.prototype, "_jobInOut", {
            get: $util.oneOfGetter($oneOfFields = ["jobInOut"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProductionInOutFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof ProductionInOutFilter.ProductionInOutFilterModel
         * @instance
         */
        Object.defineProperty(ProductionInOutFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ProductionInOutFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof ProductionInOutFilter.ProductionInOutFilterModel
         * @instance
         */
        Object.defineProperty(ProductionInOutFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified ProductionInOutFilterModel message. Does not implicitly {@link ProductionInOutFilter.ProductionInOutFilterModel.verify|verify} messages.
         * @function encode
         * @memberof ProductionInOutFilter.ProductionInOutFilterModel
         * @static
         * @param {ProductionInOutFilter.IProductionInOutFilterModel} m ProductionInOutFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProductionInOutFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.dateFrom != null && Object.hasOwnProperty.call(m, "dateFrom"))
                w.uint32(10).string(m.dateFrom);
            if (m.dateTo != null && Object.hasOwnProperty.call(m, "dateTo"))
                w.uint32(18).string(m.dateTo);
            if (m.jobInOut != null && Object.hasOwnProperty.call(m, "jobInOut"))
                w.uint32(24).int32(m.jobInOut);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(34).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(42).string(m.sortOrder);
            return w;
        };

        /**
         * Encodes the specified ProductionInOutFilterModel message, length delimited. Does not implicitly {@link ProductionInOutFilter.ProductionInOutFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ProductionInOutFilter.ProductionInOutFilterModel
         * @static
         * @param {ProductionInOutFilter.IProductionInOutFilterModel} message ProductionInOutFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProductionInOutFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProductionInOutFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof ProductionInOutFilter.ProductionInOutFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ProductionInOutFilter.ProductionInOutFilterModel} ProductionInOutFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProductionInOutFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ProductionInOutFilter.ProductionInOutFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.dateFrom = r.string();
                    break;
                case 2:
                    m.dateTo = r.string();
                    break;
                case 3:
                    m.jobInOut = r.int32();
                    break;
                case 4:
                    m.sortColumn = r.string();
                    break;
                case 5:
                    m.sortOrder = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a ProductionInOutFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ProductionInOutFilter.ProductionInOutFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ProductionInOutFilter.ProductionInOutFilterModel} ProductionInOutFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProductionInOutFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return ProductionInOutFilterModel;
    })();

    return ProductionInOutFilter;
})();

module.exports = $root;
