<template>
  <div class="tile is-parent is-vertical pb-0 pl-0">
    <div class="tile is-child has-background-dark tile-border ">

      <DashboardPanel>
        <div class="panel-heading has-text-centered has-text-white custom-panel-heading">MESSAGE INBOX</div>

        <DashboardProgressBar :is-loading="isLoading"
          :initial-color="'#6C757D'"
          :loading-color="'#1c1c1b'" />

        <div class="tabs">
          <ul class="tab-list tab-override">
            <li @click="filterMessage(dashboardTabs.Today)"
              :class="[{'item-border': activeTab === dashboardTabs.Today}]"><a class="item"
                :class="[{'item-active': activeTab === dashboardTabs.Today}]">Today</a></li>
            <li @click="filterMessage(dashboardTabs.Yesterday)"
              :class="[{'item-border': activeTab === dashboardTabs.Yesterday}]">
              <a class="item"
                :class="[{'item-active': activeTab === dashboardTabs.Yesterday}]">Yesterday</a>
            </li>
            <li @click="filterMessage(dashboardTabs.ThisWeek)"
              :class="[{'item-border': activeTab === dashboardTabs.ThisWeek}]">
              <a class="item"
                :class="[{'item-active': activeTab === dashboardTabs.ThisWeek}]">This Week</a>
            </li>
          </ul>
        </div>

        <div class="message-container"
          :class="{'container-fullscreen':isUninvoicedJobsAndChartDisabled }">
          <div v-if="filteredMessages.length === 0"
            class="notification has-background-black has-text-centered">
            <span>No Messages</span>
          </div>
          <div v-for="(message,index) in filteredMessages"
            :key="index"
            class="container has-background-black has-text-grey m-2 p-2"
            :class="[{'message-border' : !message.isRead}]">
            <div class="is-flex is-justify-content-space-between is-align-items-center mb-2">
              <div class="is-flex is-align-items-center has-text-white">
                <span class="icon mr-2">
                  <i :class="getIconCss(message.notificationType)"
                    aria-hidden="true" />
                </span>
                <a class="route-link"
                  @click="gotoRoute(message.quoteId, message.notificationType)"
                  :key="index">
                  #{{ message.quoteNoRef }}
                </a>
              </div>
              <span class="help is-italic m-0">{{ getSender(message) }}</span>
              <span class="help is-italic m-0">{{ $filters.formatDateTimeLocale(message.createdDate, $userInfo.locale) }}</span>
            </div>
            <div class="message has-background-black has-text-grey">
              <p class="message-line has-text-white"
                :class="[{'has-text-weight-bold message-line':!message.isRead}]">
                {{ getContent(message) }}
              </p>
            </div>
            <div class="is-flex is-justify-content-end">
              <div class="is-flex is-align-items-center">
                <span v-if="message.isRead"
                  class="is-flex help is-italic">Read by {{ message.readBy }}</span>
                <div class="is-flex is-clickable ml-1"
                  @click="setMessageStatus(message, message.notificationType)">
                  <span class="icon">
                    <i v-if="!message.isRead"
                      class="mdi mdi-message has-text-info" />
                    <i v-if="message.isRead"
                      class="mdi mdi-message-check-outline has-text-white" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardPanel>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { dashboardTabs } from '@/views/dashboard/helper'
import DashboardPanel from '@/views/dashboard/DashboardPanel.vue'
import DashboardService from '@/views/dashboard/DashboardService'
import DashboardProgressBar from '@/views/dashboard/DashboardProgressBar.vue'
import { NotificationType } from '@/enums'
import QuoteRoutes from '@/views/quote/route-types'
import { truncate } from '@/utils/StringFunctions'

export default {
  name: 'DashboardIncomingMessages',
  components: { DashboardProgressBar, DashboardPanel },
  mixins: [],
  props: {
    isUninvoicedJobsAndChartDisabled: Boolean
  },
  data() {
    return {
      filteredMessages: [],
      activeTab: 0,
      messages: [],
      isLoading: false,
      timeOutId: 0
    }
  },
  computed: {
    dashboardTabs() {
      return dashboardTabs
    }
  },
  created() {
    this.constantlyUpdateData()
  },
  beforeDestroy() {
    if (this.timeOutId) clearTimeout(this.timeOutId)
  },
  methods: {
    async getData() {
      this.isLoading = true
      this.messages = await DashboardService.getDashboardIncomingMessages()
      this.messages.sort(this.compareDates)
      this.filterMessage(this.activeTab)
      this.isLoading = false
    },

    constantlyUpdateData() {
      this.getData()
      // we want to update the data every 30 seconds
      this.timeOutId = setTimeout(this.constantlyUpdateData, 1000 * 30)
    },

    filterMessage(id) {
      this.activeTab = id
      if (id === dashboardTabs.Today) {
        // get messages for today
        this.getTodayMessage()
      } else if (id === dashboardTabs.Yesterday) {
        this.getYesterdayMessage()
      } else {
        this.filteredMessages = this.messages
      }
    },
    gotoRoute(entityId, type) {
      this.$router.push({
        name: QuoteRoutes.QuoteCommunications.name,
        params: { quoteId: entityId, type: type }
      })
    },
    getTodayMessage() {
      const start = DateTime.local().startOf('day').toMillis()
      this.filteredMessages = this.messages.filter((message) => {
        return Date.parse(message.createdDate) > start
      })
    },

    getYesterdayMessage() {
      // get messages for yesterday
      const start = DateTime.local().minus({ days: 1 }).startOf('day').toMillis()
      const end = DateTime.local().startOf('day').toMillis()

      this.filteredMessages = this.messages.filter((message) => {
        return Date.parse(message.createdDate) > start && Date.parse(message.createdDate) < end
      })
    },

    compareDates(a, b) {
      return Date.parse(b.createdDate) - Date.parse(a.createdDate)
    },

    getDisplayMessage(str) {
      // we only want to display 20 characters,
      if (str.length > 20) return str.substring(0, 20) + '...'
      return str
    },

    async setMessageStatus(message, notificationType) {
      this.isLoading = true
      await DashboardService.setMessageStatus(message.id, notificationType)
      await this.getData()
      this.isLoading = false
    },
    getIconCss(type) {
      return {
        mdi: true,
        'mdi-whatsapp': type == NotificationType.QuoteWhatsAppIncoming,
        'mdi-email': type == NotificationType.QuoteEmailIncoming,
        'mdi-message-reply-text-outline': type == NotificationType.QuoteSmsIncoming,
        'mdi-license': type == NotificationType.CompanyLicense,
        'mdi-20px': true,
        'icon-green': type == NotificationType.QuoteWhatsAppIncoming,
        'icon-blue': type == NotificationType.QuoteSmsIncoming,
        'icon-white': type == NotificationType.QuoteEmailIncoming
      }
    },
    getSender(message) {
      return truncate(message.sender, 30)
    },
    getContent(message) {
      return message.notificationType == NotificationType.QuoteEmailIncoming ? truncate(message.message, 150) : message.message
    }
  }
}
</script>

<style lang="scss" scoped>
// article.message {
//   width: 100%;
//   padding: 5px 10px 5px 0;
//   &.message-reply {
//     justify-content: end;
//     > .message-body {
//       text-align: right;
//       border-width: 0 4px 0 0;
//     }
//   }
// }

// .message-body {
//   width: 100%;
//   margin: 3px;
// }

.container {
  border-radius: 5px;
}

div.message {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 0.5rem;
  .message-line {
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
      overflow-wrap: anywhere;
      overflow: visible;
      white-space: unset;
      height: auto;
    }
  }
}
.route-link {
  // text-decoration: underline;
  &:hover {
    color: darkgray;
  }
}
// .message-line {
//   white-space: nowrap;
//   display: table-cell;
//   position: relative;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   max-width: 20vw;
// }

// .message-line:hover {
//   overflow-wrap: anywhere;
//   overflow: visible;
//   white-space: break-spaces;
//   height: auto;
// }

// .container {
//   max-height: calc(100vh - 37em);
//   overflow-y: auto;
//   scroll-behavior: smooth;
// }

// .container-override {
//   max-height: calc(100vh - 17em);
//   overflow-y: auto;
//   scroll-behavior: smooth;
// }
.message-border {
  border-right: 3px solid #3291ef;
}
.tile-border {
  border-radius: 20px !important;
}

.tab-list :hover {
  color: white;
}
.item {
  color: darkgray;
}

.item-active {
  color: white;
}
.tab-override {
  border-bottom: 0 !important;
}
.item-border {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.custom-panel-heading {
  border-radius: 20px 20px 0 0 !important;
  background-color: #495057 !important;
}

.message-container {
  height: 46vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  max-width: 100%;
  &.container-fullscreen {
    height: 77vh;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

i.icon-blue {
  color: #3291ef;
}
i.icon-green {
  color: #2bb741;
}
i.icon-white {
  color: #a09e9e;
  color: #dddddd;
}

</style>
