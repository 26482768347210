/* Vue */
import Vue from 'vue'
import router from './router'
import store from './store'
/* App sass */
import './assets/style/app.scss'
/* App component */
import App from './App'
/* Auth plugin */
import Auth from './auth'
/* Notification plugin */
import Notification from './notification'
import LzString from './lz-string'
import Guid from './guid'

// Vue.use(VueResource)
Vue.config.productionTip = false

Vue.use(Auth)

Vue.use(Notification)
Vue.use(LzString)
Vue.use(Guid)

Vue.prototype.$screen = Vue.observable({
  width: window.innerWidth,
  height: window.innerHeight
})
// bulma grid' breakpoints
Vue.prototype.$screen.breakpoints = {
  mobile: 768,
  tablet: 769,
  desktop: 1024,
  widescreen: 1216,
  fullHD: 1408
}

window.addEventListener('resize', () => {
  Vue.prototype.$screen.width = window.innerWidth
  Vue.prototype.$screen.height = window.innerHeight
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

