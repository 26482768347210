import FqApi from '@/services/fq-api'
import ProtoBufApi from '@/services/protobuf-api'
// import { GlAccountTypes } from '@/enums'

export default {
  async getEntitySummaries(filter) {
    const url = `sundrycreditors/list/${filter}`
    const result = await FqApi.get(url)
    return result.data
  },

  async getEntity(id) {
    const rs = await FqApi.get(`/sundrycreditors?id=${id}`)

    const data = rs.data

    return data
  },

  async postEntity(obj) {
    return FqApi.post('/sundrycreditors', obj)
  },

  async putEntity(entity, deepdiff) {
    if (deepdiff) {
      entity.deepDiff = JSON.stringify(deepdiff)
    }
    return FqApi.put(`/sundrycreditors?id=${entity.id}`, entity)
  },

  async getNewEntity() {
    var res = await FqApi.get('/sundrycreditors/new')
    return res.data
  },

  async getGlBankAccountListComboProto() {
    const result = await ProtoBufApi('/protobuf/glaccounts/bankaccounts/listcombo')
    const glAccountProto = require('../../assets/proto/listcombo/GlAccount.proto')
    const accountMessage = await glAccountProto.GlAccount.GlAccountListComboModel
    const entity = accountMessage.decode(new Uint8Array(result.data))
    return entity.glAccounts
  },

  async getAttachments(sundryCreditorId) {
    return FqApi.get(`/attachments/list?objectId=${sundryCreditorId}`)
  },

  async assertNoUsed(entityId, vendorId, invoiceNo) {
    return FqApi.get(`/sundrycreditors/assertnoused/${entityId}?vendorId=${vendorId}&no=${invoiceNo}`)
  }
}
