import FqApi from '@/services/fq-api'

export default {
  getBodyShapes() {
    return FqApi.get('/vehicles/bodyshapes')
  },

  getColours() {
    return FqApi.get('/vehicles/colours')
  },

  getPaintGroups() {
    return FqApi.get('/vehicles/paintgroups')
  }
}
