import Vue from 'vue'
import UnsavedModalPlugin from './UnsavedModalPlugin'
import store from '@/store'

const UnsavedModalPluginProgrammatic = {
  open(params) {
    let parent
    const defaultParam = {
      licenceExpired: store.getters['licenceExpired']
      // container: '#app'
    }
    if (params.parent) {
      parent = params.parent
      delete params.parent
    }
    const propsData = Object.assign(defaultParam, params)

    const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
    const UnsavedModalPluginComponent = vm.extend(UnsavedModalPlugin)
    return new UnsavedModalPluginComponent({
      parent,
      el: document.createElement('div'),
      propsData
    })
  }
}

const UnsavedModalPluginComponent = {
  install() {
    Vue.prototype['$unSavedModal'] = UnsavedModalPluginProgrammatic
  }
}

// Vue.use(Plugin)

export default UnsavedModalPluginComponent

export { UnsavedModalPluginProgrammatic as UnsavedModalPlugin }
