export default{

  NotRequested: {
    class: 'mdi mdi-22px mdi-checkbox-marked-outline has-text-grey',
    title: 'Not Requested',
    colour: 'has-text-grey'
  },
  Requested: {
    class: 'mdi mdi-22px mdi-checkbox-marked-outline has-text-warning',
    title: 'Requested',
    colour: 'has-text-warning'
  },
  Priced: {
    class: 'mdi mdi-22px mdi-checkbox-marked-outline has-text-success',
    title: 'Priced',
    colour: 'has-text-success'
  },
  ItemOrdered: {
    class: 'mdi mdi-22px mdi-truck has-text-success',
    title: 'Item Ordered',
    colour: 'has-text-success'
  },

  PartiallyOrdered: {
    class: 'mdi mdi-22px mdi-truck has-text-warning',
    title: 'Partially Ordered',
    colour: 'has-text-warning'
  },

  FullyReceived: {
    class: 'mdi mdi-22px mdi-package-variant-closed-check has-text-success',
    title: 'Fully Received',
    colour: 'has-text-success'
  },
  PartiallyReceived: {
    class: 'mdi mdi-22px mdi-package-variant-closed-check has-text-warning',
    title: 'Partially Received',
    colour: 'has-text-warning'
  },
  AllItemsReturned: {
    class: 'mdi mdi-22px mdi-arrow-left-box has-text-primary',
    title: 'All Items Returned',
    colour: 'has-text-primary'
  },
  PartiallyReturned: {
    class: 'mdi mdi-22px mdi-arrow-left-box has-text-warning',
    title: 'Partially Returned',
    colour: 'has-text-warning'
  },
  Cancelled: {
    class: 'mdi mdi-close-circle has-text-danger',
    title: 'Cancelled',
    colour: 'has-text-danger'
  },
  PendingOrder: {
    class: 'mdi mdi-22px mdi-truck has-text-warning',
    title: 'Pending Order',
    colour: 'has-text-warning'

  }
}