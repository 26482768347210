export default class KeyValuePairModel {
  constructor(key, value) {
    this.key = key
    this.value = value
  }
  static convertToKeyValuePairs(parameters) {
    const keyValuePairs = Object.keys(parameters).map(function(key) {
      return new KeyValuePairModel(key, parameters[key])
    })
    return keyValuePairs
  }
}
