import FqApi from '@/services/fq-api'
import ProtoBufApi from '@/services/protobuf-api'
import ProtoBuf from 'protobufjs'
import { GlAccountTypes } from '@/enums'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialiseMiscQuoteFilter(filter)
    const url = `/miscquotes/list?filter=${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseMiscQuoteFilter(filter) {
    const entityProto = require('../../assets/proto/miscquote/MiscQuoteFilter.proto')
    const filterMessage = entityProto.MiscQuoteFilter.MiscQuoteFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getEntity(id, hash) {
    const url = !hash ? `/miscquotes/${id}` : `/miscquotes/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getNewEntity() {
    const url = '/miscquotes/new/'
    const result = await FqApi.get(url)
    return result.data
  },
  putEntity(entity, deepdiff) {
    if (deepdiff) {
      entity.deepDiff = JSON.stringify(deepdiff)
    }
    return FqApi.put(`/miscquotes/${entity.miscQuoteId}`, entity)
  },
  async postEntity(entity) {
    return FqApi.post('/miscquotes', entity)
  },
  serialiseQuickInvoiceAssetDropdownFilter(filter) {
    const entityProto = require('../../assets/proto/quickinvoice/QuickInvoiceAssetDropdownFilter.proto')
    const filterMessage = entityProto.QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getAssetDropdownProto(filter) {
    const serialised = this.serialiseQuickInvoiceAssetDropdownFilter(filter)
    const result = await ProtoBufApi(`/quickinvoices/protobuf/assets/listcombo/${serialised}`)
    const proto = require('../../assets/proto/quickinvoice/QuickInvoiceAssetDropdown.proto')
    const message = proto.QuickInvoiceAssetDropdown.QuickInvoiceAssetDropdownModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.assets
  },
  async getContactDropdown(assetId) {
    const url = `/quickinvoices/protobuf/contacts/listcombo/${assetId}`
    const result = await ProtoBufApi.get(url)
    const proto = require('../../assets/proto/quickinvoice/QuickInvoiceContactDropdown.proto')
    const message = await proto.QuickInvoiceContactDropdown.QuickInvoiceContactDropdownModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.contacts
  },
  async getGlAccountDropdown() {
    const result = await ProtoBufApi(`/protobuf/glaccounts/listcombo?glTypes=${GlAccountTypes.Income}`)
    const glAccountProto = require('../../assets/proto/listcombo/GlAccount.proto')
    const accountMessage = await glAccountProto.GlAccount.GlAccountListComboModel
    const entity = accountMessage.decode(new Uint8Array(result.data))
    return entity.glAccounts
  },
  async getQuickItems(filter) {
    const serialised = this.serialiseQuickItemFilter(filter)
    const url = `/quickitems/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseQuickItemFilter(filter) {
    const entityProto = require('../../assets/proto/quickitem/QuickItemFilter.proto')
    const filterMessage = entityProto.QuickItemFilter.QuickItemFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getAssetEmail(assetId, assetType) {
    const url = `/quickinvoices/getassetemail/${assetId}/${assetType}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getCustomerEntity(id, hash = '') {
    const url = !hash ? `/customers/${id}` : `/customers/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  }
}