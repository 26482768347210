import FqApi from '@/services/fq-api'

export default {
  getPermissionRecords() {
    return FqApi.get('/accesssetup/modules')
  },
  updateAccess(id, data) {
    return FqApi.put(`/accesssetup/module?id=${id}`, data)
  },
  updateAccessRoute(routeModel) {
    return FqApi.post(`/accesssetup/route?id=${routeModel.id}`, routeModel)
  },
  deleteAccessRoute(routeModel) {
    return FqApi.delete(`/accesssetup/route?id=${routeModel.id}`)
  }
}
