import Vue from 'vue'
import Notification from '@/components/BulmaNotification/Notification'

const NotificationComponent = Vue.extend(Notification)
const openNotification = (
  propsData = {
    title: '',
    message: '',
    type: '',
    direction: '',
    duration: 4500,
    container: '.notifications'
  }
) => {
  return new NotificationComponent({
    el: document.createElement('div'),
    propsData
  })
}

export default {
  install() {
    Vue.prototype.$notification = Vue.notification = this
  },

  success(title, message) {
    openNotification({
      title: title,
      message: message,
      type: 'success'
    })
  },

  error(title, message) {
    openNotification({
      title: title,
      message: message,
      type: 'danger'
    })
  },

  validationError(title, message) {
    let errTitle = 'Validation'

    if (title && title !== '') {
      errTitle = title
    }

    let errMessage = 'Validation errors. Please fix before saving'

    if (message && message !== '') {
      errMessage = message
    }
    openNotification({
      title: errTitle,
      message: errMessage,
      type: 'danger'
    })
  },

  openNotificationWithType(type, title, message, duration) {
    openNotification({
      title: title,
      message: message,
      type: type,
      duration: duration
    })
  },

  openMessageXhrError(title, xhrRequest) {
    // console.log(xhrRequest.response)
    var xhrResponse
    const status = xhrRequest.response.request.status
    var message
    if (xhrRequest.response.request.response) {
      xhrResponse = JSON.parse(xhrRequest.response.request.response)
      message = xhrResponse.message
      // console.log(xhrResponse.modelState[0])
      if (xhrResponse.modelState) {
        // console.log(xhrResponse.modelState[Object.keys(xhrResponse.modelState)[0]])
        // console.log(Object.values(xhrResponse.modelState)[0][0])
        Object.keys(xhrResponse.modelState).forEach(function(key) {
          // console.log(xhrResponse.modelState[key])
          message += ` ${xhrResponse.modelState[key]}`
        })
        // message += ` ${xhrResponse.modelState[Object.keys(xhrResponse.modelState)[0]]}`
      }
    } else {
      message = xhrRequest.response.request.statusText
    }
    // openMessage({
    openNotification({
      title: title,
      message: `Error ${status}: ${message}`,
      type: 'danger',
      duration: 0,
      showCloseButton: true
    })
  }
}
