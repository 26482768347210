import routeTypes from './route-types'
// import store from '@/store'

// Lazy-load components. Webpack will split each into individual file
const ReturnPartsListView = r => require.ensure([], () => r(require('./ReturnPartsListView.vue')), 'returnpartslist-chunk')
const ReturnPartsView = r => require.ensure([], () => r(require('./ReturnPartsView.vue')), 'returnparts-chunk')
const ReturnPartsDetail = r => require.ensure([], () => r(require('./ReturnPartsDetail.vue')), 'returnparts-chunk')
// const ReturnPartsDetailValue = r => require.ensure([], () => r(require('./ReturnPartsDetailValue.vue')), 'returnparts-chunk')
// const ReturnPartsRemarks = r => require.ensure([], () => r(require('./ReturnPartsRemarks.vue')), 'returnparts-chunk')

// Get sessionStorage
// const getStorage = function(query) {
//   const sessionId = store.getters['userInfo/info'].sessionId
//   return sessionStorage.getItem(`${sessionId}|returnparts|${query}`)
// }

export default [
  {
    path: routeTypes.ReturnPartsListView.path,
    name: routeTypes.ReturnPartsListView.name,
    component: ReturnPartsListView,
    meta: {
      id: 'A072',
      route: 'listing',
      title: 'Return Parts',
      fkey: 'returnparts|filter',
      report: 'RPTCREDITORRETURNPARTS',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.ReturnPartsView.path,
    component: ReturnPartsView,
    meta: {
      id: 'A072',
      route: 'detail',
      title: 'Return Parts',
      report: 'AT_CREDITRETURN',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    },
    children: [
      {
        path: '',
        name: routeTypes.ReturnPartsDetail.name,
        component: ReturnPartsDetail,
        meta: {
          route: 'detail',
          title: 'Detail',
          reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
        }
      }
    ]
  }
]
