/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.UserPermissions = (function() {

    /**
     * Namespace UserPermissions.
     * @exports UserPermissions
     * @namespace
     */
    var UserPermissions = {};

    UserPermissions.UserPermissionModels = (function() {

        /**
         * Properties of a UserPermissionModels.
         * @memberof UserPermissions
         * @interface IUserPermissionModels
         * @property {Array.<UserPermissions.UserPermissionModels.IUserPermissionModel>|null} [permissions] UserPermissionModels permissions
         */

        /**
         * Constructs a new UserPermissionModels.
         * @memberof UserPermissions
         * @classdesc Represents a UserPermissionModels.
         * @implements IUserPermissionModels
         * @constructor
         * @param {UserPermissions.IUserPermissionModels=} [p] Properties to set
         */
        function UserPermissionModels(p) {
            this.permissions = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserPermissionModels permissions.
         * @member {Array.<UserPermissions.UserPermissionModels.IUserPermissionModel>} permissions
         * @memberof UserPermissions.UserPermissionModels
         * @instance
         */
        UserPermissionModels.prototype.permissions = $util.emptyArray;

        /**
         * Encodes the specified UserPermissionModels message. Does not implicitly {@link UserPermissions.UserPermissionModels.verify|verify} messages.
         * @function encode
         * @memberof UserPermissions.UserPermissionModels
         * @static
         * @param {UserPermissions.IUserPermissionModels} m UserPermissionModels message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPermissionModels.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.permissions != null && m.permissions.length) {
                for (var i = 0; i < m.permissions.length; ++i)
                    $root.UserPermissions.UserPermissionModels.UserPermissionModel.encode(m.permissions[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Encodes the specified UserPermissionModels message, length delimited. Does not implicitly {@link UserPermissions.UserPermissionModels.verify|verify} messages.
         * @function encodeDelimited
         * @memberof UserPermissions.UserPermissionModels
         * @static
         * @param {UserPermissions.IUserPermissionModels} message UserPermissionModels message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPermissionModels.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserPermissionModels message from the specified reader or buffer.
         * @function decode
         * @memberof UserPermissions.UserPermissionModels
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {UserPermissions.UserPermissionModels} UserPermissionModels
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPermissionModels.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.UserPermissions.UserPermissionModels();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.permissions && m.permissions.length))
                        m.permissions = [];
                    m.permissions.push($root.UserPermissions.UserPermissionModels.UserPermissionModel.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a UserPermissionModels message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof UserPermissions.UserPermissionModels
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {UserPermissions.UserPermissionModels} UserPermissionModels
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPermissionModels.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        UserPermissionModels.UserPermissionModel = (function() {

            /**
             * Properties of a UserPermissionModel.
             * @memberof UserPermissions.UserPermissionModels
             * @interface IUserPermissionModel
             * @property {string|null} [codeName] UserPermissionModel codeName
             * @property {string|null} [name] UserPermissionModel name
             * @property {boolean|null} [isBase] UserPermissionModel isBase
             * @property {boolean|null} [hasAccess] UserPermissionModel hasAccess
             * @property {boolean|null} [hasRead] UserPermissionModel hasRead
             * @property {boolean|null} [hasAdd] UserPermissionModel hasAdd
             * @property {boolean|null} [hasDelete] UserPermissionModel hasDelete
             * @property {boolean|null} [hasEdit] UserPermissionModel hasEdit
             * @property {boolean|null} [hasPrint] UserPermissionModel hasPrint
             */

            /**
             * Constructs a new UserPermissionModel.
             * @memberof UserPermissions.UserPermissionModels
             * @classdesc Represents a UserPermissionModel.
             * @implements IUserPermissionModel
             * @constructor
             * @param {UserPermissions.UserPermissionModels.IUserPermissionModel=} [p] Properties to set
             */
            function UserPermissionModel(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * UserPermissionModel codeName.
             * @member {string|null|undefined} codeName
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            UserPermissionModel.prototype.codeName = null;

            /**
             * UserPermissionModel name.
             * @member {string|null|undefined} name
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            UserPermissionModel.prototype.name = null;

            /**
             * UserPermissionModel isBase.
             * @member {boolean|null|undefined} isBase
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            UserPermissionModel.prototype.isBase = null;

            /**
             * UserPermissionModel hasAccess.
             * @member {boolean|null|undefined} hasAccess
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            UserPermissionModel.prototype.hasAccess = null;

            /**
             * UserPermissionModel hasRead.
             * @member {boolean|null|undefined} hasRead
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            UserPermissionModel.prototype.hasRead = null;

            /**
             * UserPermissionModel hasAdd.
             * @member {boolean|null|undefined} hasAdd
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            UserPermissionModel.prototype.hasAdd = null;

            /**
             * UserPermissionModel hasDelete.
             * @member {boolean|null|undefined} hasDelete
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            UserPermissionModel.prototype.hasDelete = null;

            /**
             * UserPermissionModel hasEdit.
             * @member {boolean|null|undefined} hasEdit
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            UserPermissionModel.prototype.hasEdit = null;

            /**
             * UserPermissionModel hasPrint.
             * @member {boolean|null|undefined} hasPrint
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            UserPermissionModel.prototype.hasPrint = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * UserPermissionModel _codeName.
             * @member {"codeName"|undefined} _codeName
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            Object.defineProperty(UserPermissionModel.prototype, "_codeName", {
                get: $util.oneOfGetter($oneOfFields = ["codeName"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserPermissionModel _name.
             * @member {"name"|undefined} _name
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            Object.defineProperty(UserPermissionModel.prototype, "_name", {
                get: $util.oneOfGetter($oneOfFields = ["name"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserPermissionModel _isBase.
             * @member {"isBase"|undefined} _isBase
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            Object.defineProperty(UserPermissionModel.prototype, "_isBase", {
                get: $util.oneOfGetter($oneOfFields = ["isBase"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserPermissionModel _hasAccess.
             * @member {"hasAccess"|undefined} _hasAccess
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            Object.defineProperty(UserPermissionModel.prototype, "_hasAccess", {
                get: $util.oneOfGetter($oneOfFields = ["hasAccess"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserPermissionModel _hasRead.
             * @member {"hasRead"|undefined} _hasRead
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            Object.defineProperty(UserPermissionModel.prototype, "_hasRead", {
                get: $util.oneOfGetter($oneOfFields = ["hasRead"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserPermissionModel _hasAdd.
             * @member {"hasAdd"|undefined} _hasAdd
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            Object.defineProperty(UserPermissionModel.prototype, "_hasAdd", {
                get: $util.oneOfGetter($oneOfFields = ["hasAdd"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserPermissionModel _hasDelete.
             * @member {"hasDelete"|undefined} _hasDelete
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            Object.defineProperty(UserPermissionModel.prototype, "_hasDelete", {
                get: $util.oneOfGetter($oneOfFields = ["hasDelete"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserPermissionModel _hasEdit.
             * @member {"hasEdit"|undefined} _hasEdit
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            Object.defineProperty(UserPermissionModel.prototype, "_hasEdit", {
                get: $util.oneOfGetter($oneOfFields = ["hasEdit"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * UserPermissionModel _hasPrint.
             * @member {"hasPrint"|undefined} _hasPrint
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @instance
             */
            Object.defineProperty(UserPermissionModel.prototype, "_hasPrint", {
                get: $util.oneOfGetter($oneOfFields = ["hasPrint"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified UserPermissionModel message. Does not implicitly {@link UserPermissions.UserPermissionModels.UserPermissionModel.verify|verify} messages.
             * @function encode
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @static
             * @param {UserPermissions.UserPermissionModels.IUserPermissionModel} m UserPermissionModel message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserPermissionModel.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.codeName != null && Object.hasOwnProperty.call(m, "codeName"))
                    w.uint32(10).string(m.codeName);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(18).string(m.name);
                if (m.isBase != null && Object.hasOwnProperty.call(m, "isBase"))
                    w.uint32(24).bool(m.isBase);
                if (m.hasAccess != null && Object.hasOwnProperty.call(m, "hasAccess"))
                    w.uint32(32).bool(m.hasAccess);
                if (m.hasRead != null && Object.hasOwnProperty.call(m, "hasRead"))
                    w.uint32(40).bool(m.hasRead);
                if (m.hasAdd != null && Object.hasOwnProperty.call(m, "hasAdd"))
                    w.uint32(48).bool(m.hasAdd);
                if (m.hasDelete != null && Object.hasOwnProperty.call(m, "hasDelete"))
                    w.uint32(56).bool(m.hasDelete);
                if (m.hasEdit != null && Object.hasOwnProperty.call(m, "hasEdit"))
                    w.uint32(64).bool(m.hasEdit);
                if (m.hasPrint != null && Object.hasOwnProperty.call(m, "hasPrint"))
                    w.uint32(72).bool(m.hasPrint);
                return w;
            };

            /**
             * Encodes the specified UserPermissionModel message, length delimited. Does not implicitly {@link UserPermissions.UserPermissionModels.UserPermissionModel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @static
             * @param {UserPermissions.UserPermissionModels.IUserPermissionModel} message UserPermissionModel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserPermissionModel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UserPermissionModel message from the specified reader or buffer.
             * @function decode
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {UserPermissions.UserPermissionModels.UserPermissionModel} UserPermissionModel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserPermissionModel.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.UserPermissions.UserPermissionModels.UserPermissionModel();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.codeName = r.string();
                        break;
                    case 2:
                        m.name = r.string();
                        break;
                    case 3:
                        m.isBase = r.bool();
                        break;
                    case 4:
                        m.hasAccess = r.bool();
                        break;
                    case 5:
                        m.hasRead = r.bool();
                        break;
                    case 6:
                        m.hasAdd = r.bool();
                        break;
                    case 7:
                        m.hasDelete = r.bool();
                        break;
                    case 8:
                        m.hasEdit = r.bool();
                        break;
                    case 9:
                        m.hasPrint = r.bool();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Decodes a UserPermissionModel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof UserPermissions.UserPermissionModels.UserPermissionModel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {UserPermissions.UserPermissionModels.UserPermissionModel} UserPermissionModel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserPermissionModel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            return UserPermissionModel;
        })();

        return UserPermissionModels;
    })();

    return UserPermissions;
})();

module.exports = $root;
