export default {
  RR: 'RR',
  REP: 'REP',
  RWA: 'RWA',
  MECH: 'MECH',
  CD: 'CD',
  CRUSH: 'CRUSH',
  FIBER: 'FIBER',
  S1: 'S1',
  S2: 'S2',
  S3: 'S3',
  M1: 'M1',
  M2: 'M2',
  M3: 'M3'
}
