import FqApi from '@/services/fq-api'

export default {
  async getNewEntity() {
    const url = '/remarks/new'
    const result = await FqApi.get(url)
    return result.data
  },
  async getRemarkEntities(quoteId, remarkType) {
    const url = `/remarks/list/${quoteId}/${remarkType}`
    const result = await FqApi.get(url)
    return result.data
  },
  async postEntity(entity) {
    return FqApi.post('/remarks', entity)
  }
}
