import { AccessService } from '@/services'
import UserModel from '@/classes/viewmodels/user/UserModel'

export const STORE_USERINFO = 'UPDATE_USERINFO'

const codemirrorLsKey = 'uniq-codemirror-theme'
const scrollItemLabourLsKey = 'uniq-labour-scrollitem'
const scrollItemPartLsKey = 'uniq-part-scrollitem'

const state = {
  data: null,
  user: new UserModel()
}

const getters = {
  info: state => state.data,
  user: state => state.user
}

const actions = {
  async getUserInfo({ commit, state }) {
    // AccessService.getCachedUserInfo().then(res => {
    //   commit(STORE_USERINFO, res)
    // })
    const result = await AccessService.getCachedUserInfo()
    Object.assign(result, { scrollItemLabour: false })
    Object.assign(result, { scrollItemPart: false })
    commit(STORE_USERINFO, result)
  },
  async getUserPermissions({ commit, state }) {
    const result = await AccessService.getUserPermissions()
    commit('setUserPermissions', result.permissions)
  },
  $setCodemirrorTheme({ commit }, theme) {
    commit('setCodemirrorTheme', theme)
  },
  $setScrollItemLabour({ commit }, event) {
    commit('setScrollItemLabour', event.target.checked)
  },
  $setScrollItemPart({ commit }, event) {
    commit('setScrollItemPart', event.target.checked)
  }
}

const mutations = {
  [STORE_USERINFO](state, data) {
    state.data = data
    state.user.info = data
    if (localStorage.getItem(codemirrorLsKey)) {
      const theme = localStorage.getItem(codemirrorLsKey)
      Object.assign(state.data, { codemirrorTheme: theme })
    }
    if (localStorage.getItem(scrollItemLabourLsKey)) {
      const value = localStorage.getItem(scrollItemLabourLsKey)
      state.data.scrollItemLabour = value === 'true' ? true : false
    }
  },
  setCodemirrorTheme(state, theme) {
    Object.assign(state.data, { codemirrorTheme: theme })
    localStorage.setItem(codemirrorLsKey, state.data.codemirrorTheme)
  },
  setScrollItemLabour(state, value) {
    state.data.scrollItemLabour = value
    localStorage.setItem(scrollItemLabourLsKey, state.data.scrollItemLabour)
  },
  setScrollItemPart(state, value) {
    state.data.scrollItemPart = value
    localStorage.setItem(scrollItemPartLsKey, state.data.scrollItemPart)
  },
  setUserPermissions(state, value) {
    state.user.permissions = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
