import Guid from '@/components/Guid'

export default class QuoteItemModel {
  constructor(quoteId, itemNo = '', itemDesc = '', itemType = '') {
    this.quoteItemId = Guid.newGuid()
    this.quoteId = quoteId
    this.quoteVersion = 0
    this.lineNumber = 0
    this.itemNo = itemNo
    this.itemType = itemType
    this.itemQuantity = 0
    this.value = 0
    this.itemComment = null
    this.markupPercent = 0
    this.markupValue = 0
    this.rate = 0
    this.buyPrice = 0
    this.opgCode = ''
    this.reportOnly = false
    this.allowTick = null
    this.blend = false
    this.rev = '0'
    this.partNo = ''
    this.partSource = null
    this.side = ''
    this.jobCost = 0
    this.isLoadItem = false
    this.linkedItemId = null
    this.deleteOtherItem = false
    this.loadingPercent = 0
    this.partStatus = null
    this.vendorId = null
    this.vendorName = ''
    this.subletManagement = null
    this.subletTypeId = null
    this.subletType = ''
    this.subletAuthorityNumber = null
    this.itemStatus = 'A'
    this.itemStatusSent = ''
    this.deleted = false
    this.isDirty = false
    this.dollarValue = 0
    this.hourValue = 0
    this.createdBy = ''
    this.createdDate = ''
    this.modifiedBy = ''
    this.modifiedDate = ''
    this.mark = ''
    this.make = null
    this.deletedItemId = null
    this.sortNo = 0
    this.itemDesc = itemDesc
    this.subletAuthorityDocument = null
    this.extLineId = null
    this.acquiredOn = null
    this.isNew = true
    this.audaLineNo = null
    this.partsCheckLineNo = null
    this.presentAs = []
    this.partsCheckReferenceNo = 0
  }
}
