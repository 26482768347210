import routerTypes from './route-types'

// Lazy-load components. Webpack will split each into individual file
const PaymentListListView = r => require.ensure([], () => r(require('./PaymentListListView.vue')), 'paymentlist-chunk')

export default [
  {
    path: routerTypes.PaymentListListView.path,
    name: routerTypes.PaymentListListView.name,
    component: PaymentListListView,
    meta: {
      id: 'A050',
      route: 'listing',
      title: 'Payment List',
      fkey: 'paymentlist|filter',
      report: 'AT_PaymentListV2',
      reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
    }
  }
]
