import InsurerService from './InsurerService'
import InsurerRouteTypes from './route-types'
import StoreUtil from '@/store/utils'
import DeepDiff from 'deep-diff'
import { md5 } from '@/components/crypto'

const tag = 'insurer'

const state = {
  returnRoute: {},
  baseIndex: 0,
  currentIndex: 0,
  snapshots: []
}

const getters = {
  returnRoute: state => state.returnRoute,
  snapshots: state => state.snapshots,
  baseSnapshot: state => state.snapshots[state.baseIndex],
  currentSnapshot: state => state.snapshots[state.currentIndex],
  snapshotDiff: state => {
    if (state.snapshots[state.baseIndex] && state.snapshots[state.currentIndex]) {
      return DeepDiff.diff(state.snapshots[state.baseIndex], state.snapshots[state.currentIndex])
    } else {
      return null
    }
  }
}

const actions = {
  async getStoreItem({ commit }, id) {
    // const entity = StoreUtil.getStorage(id, tag) || (await InsurerService.getEntity(id, ''))
    // The following for refreshing data from WebApi regardless
    let entity = StoreUtil.getStorage(id, tag)
    if (!entity || (entity && !entity.isNew)) {
      const hash = entity ? md5(JSON.stringify(entity)) : ''
      const data = await InsurerService.getEntity(id, hash)
      if (data) {
        console.log('from WebApi')
        entity = data
      } else {
        console.log('from sessionStorage')
      }
    }
    StoreUtil.setStorage(entity.insurerId, tag, entity)
    commit('saveInitialSnapshots', entity)
  },
  async editStoreItem({ commit }, id) {
    const entity = StoreUtil.getStorage(id, tag) || (await InsurerService.getEntity(id, ''))
    StoreUtil.setStorage(entity.insurerId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(InsurerRouteTypes.InsurerListView.path, entity.insurerId, false)
  },
  async addStoreItem({ commit }, payload) {
    /**
     * @param {InsurerPayload} payload payload for creating new insurer
     */
    const entity = await InsurerService.getNewEntity()
    entity.name = payload.name
    StoreUtil.setStorage(entity.insurerId, tag, entity)
    commit('saveInitialSnapshots', entity)
    if (payload.gotoRoute) {
      StoreUtil.routeToDetail(InsurerRouteTypes.InsurerListView.path, entity.insurerId, true)
    }
  },
  saveSnapshot({ commit }, entity) {
    commit('saveSnapshot', entity)
  },
  clearSnapshots({ commit }, id) {
    StoreUtil.removeStorage(id, tag)
    commit('clearSnapshots')
  },
  setReturnRoute({ commit }, route) {
    commit('setReturnRoute', route)
  }
}

const mutations = {
  // [types.SetItem](state, item) {
  //   const index = state.items.findIndex(i => i.id === item.id)
  //   if (index === -1) {
  //     state.items.push(item)
  //   } else {
  //     state.items.splice(index, 1, item)
  //   }
  // },
  // [types.RemoveItem](state, id) {
  //   const index = state.items.findIndex(item => item.id === id)
  //   if (index >= 0) {
  //     state.items.splice(index, 1)
  //   }
  // },
  saveInitialSnapshots(state, entity) {
    state.snapshots = []
    state.snapshots.splice(0, 1, entity)
    state.snapshots.splice(1, 1, entity)
    state.currentIndex = state.snapshots.length - 1
  },
  saveSnapshot(state, entity) {
    state.snapshots.splice(state.currentIndex, 1, entity)
  },
  clearSnapshots(state) {
    state.snapshots = []
    state.baseIndex = 0
    state.currentIndex = 0
  },
  setReturnRoute(state, route) {
    state.returnRoute = route
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
