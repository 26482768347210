import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {
  async getPricing(tier) {
    const url = `/vehiclelookup/pricing/${tier}`
    const result = await FqApi.get(url)
    return result.data
  },
  async savePricing(pricing) {
    const url = '/vehiclelookup/pricing'
    const result = await FqApi.post(url, pricing)
    return result
  },
  serialiseSubscriptionFilter(filter) {
    const entityProto = require('../../../assets/proto/vehiclelookup/SubscriptionFilter.proto')
    const filterMessage = entityProto.SubscriptionFilter.SubscriptionFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getSubscriptionSummaries(filter) {
    const serialised = this.serialiseSubscriptionFilter(filter)
    const url = `/vehiclelookup/subscription/summaries?filter=${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  async addSubscription(entity) {
    const url = '/vehiclelookup/subscription/add'
    const result = await FqApi.post(url, entity)
    return result
  },
  async batchAddSubscriptions(list) {
    const url = '/vehiclelookup/subscription/batchadd'
    const result = await FqApi.post(url, list)
    return result
  },
  async updateSubscriptions(list) {
    const url = '/vehiclelookup/subscription/update'
    const result = await FqApi.post(url, list)
    return result
  },
  async addCallLog(callLog) {
    const url = '/vehiclelookup/calllog/add'
    const result = await FqApi.post(url, callLog)
    return result
  },
  async getCompanyNames(name, pageIndex, pageSize) {
    let url = `/companies/names?pageIndex=${pageIndex}&pageSize=${pageSize}`
    if (name) {
      url = `${url}&name=${name}`
    }
    const result = await FqApi.get(url)
    return result.data
  }
}