import FqApi from '@/services/fq-api'
import { LabourTimeTypes } from '@/enums'

const baseUrl = '/itemmaster'

export default {
  async getItemMasterByTimesStyle(itemType, timesStyle, query, scheduleId, bodyId) {
    let url = `${baseUrl}/${itemType}?query=${query}&timesStyle=${timesStyle}`
    if (timesStyle && (timesStyle.toUpperCase() === LabourTimeTypes.NTAR || timesStyle.toUpperCase() === LabourTimeTypes.LTAR)) {
      url = `${url}&scheduleId=${scheduleId}&bodyId=${bodyId}`
    }
    const response = await FqApi.get(url)
    return response.data
  }
}