import routerTypes from './route-types'
//import PrintPreviewRoutes from './route-types'

// Lazy-load components. Webpack will split each into individual file
const CreditorAgingReportView = r => require.ensure([], () => r(require('./CreditorAgingReportView.vue')), 'creditoragingreport-chunk')
//const PrintPreviewExtended = r => require.ensure([], () => r(require('@/components/printpreview/PrintPreviewExtended.vue')), 'printpreviewextended-chunk')

export default [
  {
    path: routerTypes.CreditorAgingReportView.path,
    name: routerTypes.CreditorAgingReportView.name,
    component: CreditorAgingReportView,
    meta: {
      id: 'A058',
      route: 'listing',
      title: 'Creditor Aging Report',
      fkey: 'creditoragingreport|filter',
      report: 'AT_APDetailedAgingReport',
      reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
    }
  }
]
