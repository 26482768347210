/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.Insurer = (function() {

    /**
     * Namespace Insurer.
     * @exports Insurer
     * @namespace
     */
    var Insurer = {};

    Insurer.InsurerModel = (function() {

        /**
         * Properties of an InsurerModel.
         * @memberof Insurer
         * @interface IInsurerModel
         * @property {bcl.IGuid|null} [InsurerID] InsurerModel InsurerID
         * @property {string|null} [Name] InsurerModel Name
         * @property {string|null} [RepairerID] InsurerModel RepairerID
         * @property {bcl.IDecimal|null} [ShopRate] InsurerModel ShopRate
         * @property {string|null} [InsurerGLCode] InsurerModel InsurerGLCode
         * @property {string|null} [ABN] InsurerModel ABN
         * @property {boolean|null} [ORM] InsurerModel ORM
         * @property {boolean|null} [PNET] InsurerModel PNET
         * @property {boolean|null} [Arnie] InsurerModel Arnie
         * @property {boolean|null} [Exported] InsurerModel Exported
         * @property {boolean|null} [ExcessGST] InsurerModel ExcessGST
         * @property {string|null} [LabourType] InsurerModel LabourType
         * @property {bcl.IDecimal|null} [PanelCons] InsurerModel PanelCons
         * @property {bcl.IDecimal|null} [PanelConsMin] InsurerModel PanelConsMin
         * @property {bcl.IDecimal|null} [PaintCons] InsurerModel PaintCons
         * @property {string|null} [InsurerBrand] InsurerModel InsurerBrand
         * @property {string|null} [VeroBrand] InsurerModel VeroBrand
         * @property {number|null} [PDRType] InsurerModel PDRType
         * @property {boolean|null} [AutoPaintLoading] InsurerModel AutoPaintLoading
         * @property {bcl.IDecimal|null} [PaintLoadingPct] InsurerModel PaintLoadingPct
         * @property {bcl.IDecimal|null} [EnvironmentalLevy] InsurerModel EnvironmentalLevy
         * @property {bcl.IDecimal|null} [NewMarkup] InsurerModel NewMarkup
         * @property {bcl.IDecimal|null} [UsedMarkup] InsurerModel UsedMarkup
         * @property {bcl.IDecimal|null} [RecoreMarkup] InsurerModel RecoreMarkup
         * @property {bcl.IDecimal|null} [AfterMarketMarkup] InsurerModel AfterMarketMarkup
         * @property {bcl.IDecimal|null} [ExchangeMarkup] InsurerModel ExchangeMarkup
         * @property {bcl.IDecimal|null} [ReconditionedMarkup] InsurerModel ReconditionedMarkup
         * @property {string|null} [QuotingMethod] InsurerModel QuotingMethod
         * @property {string|null} [BSB] InsurerModel BSB
         * @property {string|null} [AccoutNo] InsurerModel AccoutNo
         * @property {bcl.IGuid|null} [CreatedBy] InsurerModel CreatedBy
         * @property {bcl.IDateTime|null} [CreatedDate] InsurerModel CreatedDate
         * @property {bcl.IGuid|null} [ModifiedBy] InsurerModel ModifiedBy
         * @property {bcl.IDateTime|null} [ModifiedDate] InsurerModel ModifiedDate
         * @property {number|null} [InsurerNo] InsurerModel InsurerNo
         * @property {boolean|null} [Active] InsurerModel Active
         * @property {bcl.IGuid|null} [templateId] InsurerModel templateId
         */

        /**
         * Constructs a new InsurerModel.
         * @memberof Insurer
         * @classdesc Represents an InsurerModel.
         * @implements IInsurerModel
         * @constructor
         * @param {Insurer.IInsurerModel=} [p] Properties to set
         */
        function InsurerModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * InsurerModel InsurerID.
         * @member {bcl.IGuid|null|undefined} InsurerID
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.InsurerID = null;

        /**
         * InsurerModel Name.
         * @member {string|null|undefined} Name
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.Name = null;

        /**
         * InsurerModel RepairerID.
         * @member {string|null|undefined} RepairerID
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.RepairerID = null;

        /**
         * InsurerModel ShopRate.
         * @member {bcl.IDecimal|null|undefined} ShopRate
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.ShopRate = null;

        /**
         * InsurerModel InsurerGLCode.
         * @member {string|null|undefined} InsurerGLCode
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.InsurerGLCode = null;

        /**
         * InsurerModel ABN.
         * @member {string|null|undefined} ABN
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.ABN = null;

        /**
         * InsurerModel ORM.
         * @member {boolean|null|undefined} ORM
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.ORM = null;

        /**
         * InsurerModel PNET.
         * @member {boolean|null|undefined} PNET
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.PNET = null;

        /**
         * InsurerModel Arnie.
         * @member {boolean|null|undefined} Arnie
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.Arnie = null;

        /**
         * InsurerModel Exported.
         * @member {boolean|null|undefined} Exported
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.Exported = null;

        /**
         * InsurerModel ExcessGST.
         * @member {boolean|null|undefined} ExcessGST
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.ExcessGST = null;

        /**
         * InsurerModel LabourType.
         * @member {string|null|undefined} LabourType
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.LabourType = null;

        /**
         * InsurerModel PanelCons.
         * @member {bcl.IDecimal|null|undefined} PanelCons
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.PanelCons = null;

        /**
         * InsurerModel PanelConsMin.
         * @member {bcl.IDecimal|null|undefined} PanelConsMin
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.PanelConsMin = null;

        /**
         * InsurerModel PaintCons.
         * @member {bcl.IDecimal|null|undefined} PaintCons
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.PaintCons = null;

        /**
         * InsurerModel InsurerBrand.
         * @member {string|null|undefined} InsurerBrand
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.InsurerBrand = null;

        /**
         * InsurerModel VeroBrand.
         * @member {string|null|undefined} VeroBrand
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.VeroBrand = null;

        /**
         * InsurerModel PDRType.
         * @member {number|null|undefined} PDRType
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.PDRType = null;

        /**
         * InsurerModel AutoPaintLoading.
         * @member {boolean|null|undefined} AutoPaintLoading
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.AutoPaintLoading = null;

        /**
         * InsurerModel PaintLoadingPct.
         * @member {bcl.IDecimal|null|undefined} PaintLoadingPct
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.PaintLoadingPct = null;

        /**
         * InsurerModel EnvironmentalLevy.
         * @member {bcl.IDecimal|null|undefined} EnvironmentalLevy
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.EnvironmentalLevy = null;

        /**
         * InsurerModel NewMarkup.
         * @member {bcl.IDecimal|null|undefined} NewMarkup
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.NewMarkup = null;

        /**
         * InsurerModel UsedMarkup.
         * @member {bcl.IDecimal|null|undefined} UsedMarkup
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.UsedMarkup = null;

        /**
         * InsurerModel RecoreMarkup.
         * @member {bcl.IDecimal|null|undefined} RecoreMarkup
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.RecoreMarkup = null;

        /**
         * InsurerModel AfterMarketMarkup.
         * @member {bcl.IDecimal|null|undefined} AfterMarketMarkup
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.AfterMarketMarkup = null;

        /**
         * InsurerModel ExchangeMarkup.
         * @member {bcl.IDecimal|null|undefined} ExchangeMarkup
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.ExchangeMarkup = null;

        /**
         * InsurerModel ReconditionedMarkup.
         * @member {bcl.IDecimal|null|undefined} ReconditionedMarkup
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.ReconditionedMarkup = null;

        /**
         * InsurerModel QuotingMethod.
         * @member {string|null|undefined} QuotingMethod
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.QuotingMethod = null;

        /**
         * InsurerModel BSB.
         * @member {string|null|undefined} BSB
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.BSB = null;

        /**
         * InsurerModel AccoutNo.
         * @member {string|null|undefined} AccoutNo
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.AccoutNo = null;

        /**
         * InsurerModel CreatedBy.
         * @member {bcl.IGuid|null|undefined} CreatedBy
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.CreatedBy = null;

        /**
         * InsurerModel CreatedDate.
         * @member {bcl.IDateTime|null|undefined} CreatedDate
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.CreatedDate = null;

        /**
         * InsurerModel ModifiedBy.
         * @member {bcl.IGuid|null|undefined} ModifiedBy
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.ModifiedBy = null;

        /**
         * InsurerModel ModifiedDate.
         * @member {bcl.IDateTime|null|undefined} ModifiedDate
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.ModifiedDate = null;

        /**
         * InsurerModel InsurerNo.
         * @member {number|null|undefined} InsurerNo
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.InsurerNo = null;

        /**
         * InsurerModel Active.
         * @member {boolean|null|undefined} Active
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.Active = null;

        /**
         * InsurerModel templateId.
         * @member {bcl.IGuid|null|undefined} templateId
         * @memberof Insurer.InsurerModel
         * @instance
         */
        InsurerModel.prototype.templateId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * InsurerModel _InsurerID.
         * @member {"InsurerID"|undefined} _InsurerID
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_InsurerID", {
            get: $util.oneOfGetter($oneOfFields = ["InsurerID"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _Name.
         * @member {"Name"|undefined} _Name
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_Name", {
            get: $util.oneOfGetter($oneOfFields = ["Name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _RepairerID.
         * @member {"RepairerID"|undefined} _RepairerID
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_RepairerID", {
            get: $util.oneOfGetter($oneOfFields = ["RepairerID"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _ShopRate.
         * @member {"ShopRate"|undefined} _ShopRate
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_ShopRate", {
            get: $util.oneOfGetter($oneOfFields = ["ShopRate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _InsurerGLCode.
         * @member {"InsurerGLCode"|undefined} _InsurerGLCode
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_InsurerGLCode", {
            get: $util.oneOfGetter($oneOfFields = ["InsurerGLCode"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _ABN.
         * @member {"ABN"|undefined} _ABN
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_ABN", {
            get: $util.oneOfGetter($oneOfFields = ["ABN"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _ORM.
         * @member {"ORM"|undefined} _ORM
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_ORM", {
            get: $util.oneOfGetter($oneOfFields = ["ORM"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _PNET.
         * @member {"PNET"|undefined} _PNET
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_PNET", {
            get: $util.oneOfGetter($oneOfFields = ["PNET"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _Arnie.
         * @member {"Arnie"|undefined} _Arnie
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_Arnie", {
            get: $util.oneOfGetter($oneOfFields = ["Arnie"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _Exported.
         * @member {"Exported"|undefined} _Exported
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_Exported", {
            get: $util.oneOfGetter($oneOfFields = ["Exported"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _ExcessGST.
         * @member {"ExcessGST"|undefined} _ExcessGST
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_ExcessGST", {
            get: $util.oneOfGetter($oneOfFields = ["ExcessGST"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _LabourType.
         * @member {"LabourType"|undefined} _LabourType
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_LabourType", {
            get: $util.oneOfGetter($oneOfFields = ["LabourType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _PanelCons.
         * @member {"PanelCons"|undefined} _PanelCons
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_PanelCons", {
            get: $util.oneOfGetter($oneOfFields = ["PanelCons"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _PanelConsMin.
         * @member {"PanelConsMin"|undefined} _PanelConsMin
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_PanelConsMin", {
            get: $util.oneOfGetter($oneOfFields = ["PanelConsMin"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _PaintCons.
         * @member {"PaintCons"|undefined} _PaintCons
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_PaintCons", {
            get: $util.oneOfGetter($oneOfFields = ["PaintCons"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _InsurerBrand.
         * @member {"InsurerBrand"|undefined} _InsurerBrand
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_InsurerBrand", {
            get: $util.oneOfGetter($oneOfFields = ["InsurerBrand"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _VeroBrand.
         * @member {"VeroBrand"|undefined} _VeroBrand
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_VeroBrand", {
            get: $util.oneOfGetter($oneOfFields = ["VeroBrand"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _PDRType.
         * @member {"PDRType"|undefined} _PDRType
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_PDRType", {
            get: $util.oneOfGetter($oneOfFields = ["PDRType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _AutoPaintLoading.
         * @member {"AutoPaintLoading"|undefined} _AutoPaintLoading
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_AutoPaintLoading", {
            get: $util.oneOfGetter($oneOfFields = ["AutoPaintLoading"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _PaintLoadingPct.
         * @member {"PaintLoadingPct"|undefined} _PaintLoadingPct
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_PaintLoadingPct", {
            get: $util.oneOfGetter($oneOfFields = ["PaintLoadingPct"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _EnvironmentalLevy.
         * @member {"EnvironmentalLevy"|undefined} _EnvironmentalLevy
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_EnvironmentalLevy", {
            get: $util.oneOfGetter($oneOfFields = ["EnvironmentalLevy"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _NewMarkup.
         * @member {"NewMarkup"|undefined} _NewMarkup
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_NewMarkup", {
            get: $util.oneOfGetter($oneOfFields = ["NewMarkup"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _UsedMarkup.
         * @member {"UsedMarkup"|undefined} _UsedMarkup
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_UsedMarkup", {
            get: $util.oneOfGetter($oneOfFields = ["UsedMarkup"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _RecoreMarkup.
         * @member {"RecoreMarkup"|undefined} _RecoreMarkup
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_RecoreMarkup", {
            get: $util.oneOfGetter($oneOfFields = ["RecoreMarkup"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _AfterMarketMarkup.
         * @member {"AfterMarketMarkup"|undefined} _AfterMarketMarkup
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_AfterMarketMarkup", {
            get: $util.oneOfGetter($oneOfFields = ["AfterMarketMarkup"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _ExchangeMarkup.
         * @member {"ExchangeMarkup"|undefined} _ExchangeMarkup
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_ExchangeMarkup", {
            get: $util.oneOfGetter($oneOfFields = ["ExchangeMarkup"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _ReconditionedMarkup.
         * @member {"ReconditionedMarkup"|undefined} _ReconditionedMarkup
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_ReconditionedMarkup", {
            get: $util.oneOfGetter($oneOfFields = ["ReconditionedMarkup"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _QuotingMethod.
         * @member {"QuotingMethod"|undefined} _QuotingMethod
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_QuotingMethod", {
            get: $util.oneOfGetter($oneOfFields = ["QuotingMethod"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _BSB.
         * @member {"BSB"|undefined} _BSB
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_BSB", {
            get: $util.oneOfGetter($oneOfFields = ["BSB"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _AccoutNo.
         * @member {"AccoutNo"|undefined} _AccoutNo
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_AccoutNo", {
            get: $util.oneOfGetter($oneOfFields = ["AccoutNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _CreatedBy.
         * @member {"CreatedBy"|undefined} _CreatedBy
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_CreatedBy", {
            get: $util.oneOfGetter($oneOfFields = ["CreatedBy"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _CreatedDate.
         * @member {"CreatedDate"|undefined} _CreatedDate
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_CreatedDate", {
            get: $util.oneOfGetter($oneOfFields = ["CreatedDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _ModifiedBy.
         * @member {"ModifiedBy"|undefined} _ModifiedBy
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_ModifiedBy", {
            get: $util.oneOfGetter($oneOfFields = ["ModifiedBy"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _ModifiedDate.
         * @member {"ModifiedDate"|undefined} _ModifiedDate
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_ModifiedDate", {
            get: $util.oneOfGetter($oneOfFields = ["ModifiedDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _InsurerNo.
         * @member {"InsurerNo"|undefined} _InsurerNo
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_InsurerNo", {
            get: $util.oneOfGetter($oneOfFields = ["InsurerNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _Active.
         * @member {"Active"|undefined} _Active
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_Active", {
            get: $util.oneOfGetter($oneOfFields = ["Active"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerModel _templateId.
         * @member {"templateId"|undefined} _templateId
         * @memberof Insurer.InsurerModel
         * @instance
         */
        Object.defineProperty(InsurerModel.prototype, "_templateId", {
            get: $util.oneOfGetter($oneOfFields = ["templateId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified InsurerModel message. Does not implicitly {@link Insurer.InsurerModel.verify|verify} messages.
         * @function encode
         * @memberof Insurer.InsurerModel
         * @static
         * @param {Insurer.IInsurerModel} m InsurerModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InsurerModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.InsurerID != null && Object.hasOwnProperty.call(m, "InsurerID"))
                $root.bcl.Guid.encode(m.InsurerID, w.uint32(10).fork()).ldelim();
            if (m.Name != null && Object.hasOwnProperty.call(m, "Name"))
                w.uint32(18).string(m.Name);
            if (m.RepairerID != null && Object.hasOwnProperty.call(m, "RepairerID"))
                w.uint32(26).string(m.RepairerID);
            if (m.ShopRate != null && Object.hasOwnProperty.call(m, "ShopRate"))
                $root.bcl.Decimal.encode(m.ShopRate, w.uint32(34).fork()).ldelim();
            if (m.InsurerGLCode != null && Object.hasOwnProperty.call(m, "InsurerGLCode"))
                w.uint32(42).string(m.InsurerGLCode);
            if (m.ABN != null && Object.hasOwnProperty.call(m, "ABN"))
                w.uint32(50).string(m.ABN);
            if (m.ORM != null && Object.hasOwnProperty.call(m, "ORM"))
                w.uint32(56).bool(m.ORM);
            if (m.PNET != null && Object.hasOwnProperty.call(m, "PNET"))
                w.uint32(64).bool(m.PNET);
            if (m.Arnie != null && Object.hasOwnProperty.call(m, "Arnie"))
                w.uint32(72).bool(m.Arnie);
            if (m.Exported != null && Object.hasOwnProperty.call(m, "Exported"))
                w.uint32(80).bool(m.Exported);
            if (m.ExcessGST != null && Object.hasOwnProperty.call(m, "ExcessGST"))
                w.uint32(88).bool(m.ExcessGST);
            if (m.LabourType != null && Object.hasOwnProperty.call(m, "LabourType"))
                w.uint32(98).string(m.LabourType);
            if (m.PanelCons != null && Object.hasOwnProperty.call(m, "PanelCons"))
                $root.bcl.Decimal.encode(m.PanelCons, w.uint32(106).fork()).ldelim();
            if (m.PanelConsMin != null && Object.hasOwnProperty.call(m, "PanelConsMin"))
                $root.bcl.Decimal.encode(m.PanelConsMin, w.uint32(114).fork()).ldelim();
            if (m.PaintCons != null && Object.hasOwnProperty.call(m, "PaintCons"))
                $root.bcl.Decimal.encode(m.PaintCons, w.uint32(122).fork()).ldelim();
            if (m.InsurerBrand != null && Object.hasOwnProperty.call(m, "InsurerBrand"))
                w.uint32(130).string(m.InsurerBrand);
            if (m.VeroBrand != null && Object.hasOwnProperty.call(m, "VeroBrand"))
                w.uint32(138).string(m.VeroBrand);
            if (m.PDRType != null && Object.hasOwnProperty.call(m, "PDRType"))
                w.uint32(144).int32(m.PDRType);
            if (m.AutoPaintLoading != null && Object.hasOwnProperty.call(m, "AutoPaintLoading"))
                w.uint32(152).bool(m.AutoPaintLoading);
            if (m.PaintLoadingPct != null && Object.hasOwnProperty.call(m, "PaintLoadingPct"))
                $root.bcl.Decimal.encode(m.PaintLoadingPct, w.uint32(162).fork()).ldelim();
            if (m.EnvironmentalLevy != null && Object.hasOwnProperty.call(m, "EnvironmentalLevy"))
                $root.bcl.Decimal.encode(m.EnvironmentalLevy, w.uint32(170).fork()).ldelim();
            if (m.NewMarkup != null && Object.hasOwnProperty.call(m, "NewMarkup"))
                $root.bcl.Decimal.encode(m.NewMarkup, w.uint32(178).fork()).ldelim();
            if (m.UsedMarkup != null && Object.hasOwnProperty.call(m, "UsedMarkup"))
                $root.bcl.Decimal.encode(m.UsedMarkup, w.uint32(186).fork()).ldelim();
            if (m.RecoreMarkup != null && Object.hasOwnProperty.call(m, "RecoreMarkup"))
                $root.bcl.Decimal.encode(m.RecoreMarkup, w.uint32(194).fork()).ldelim();
            if (m.AfterMarketMarkup != null && Object.hasOwnProperty.call(m, "AfterMarketMarkup"))
                $root.bcl.Decimal.encode(m.AfterMarketMarkup, w.uint32(202).fork()).ldelim();
            if (m.ExchangeMarkup != null && Object.hasOwnProperty.call(m, "ExchangeMarkup"))
                $root.bcl.Decimal.encode(m.ExchangeMarkup, w.uint32(210).fork()).ldelim();
            if (m.ReconditionedMarkup != null && Object.hasOwnProperty.call(m, "ReconditionedMarkup"))
                $root.bcl.Decimal.encode(m.ReconditionedMarkup, w.uint32(218).fork()).ldelim();
            if (m.QuotingMethod != null && Object.hasOwnProperty.call(m, "QuotingMethod"))
                w.uint32(226).string(m.QuotingMethod);
            if (m.BSB != null && Object.hasOwnProperty.call(m, "BSB"))
                w.uint32(234).string(m.BSB);
            if (m.AccoutNo != null && Object.hasOwnProperty.call(m, "AccoutNo"))
                w.uint32(242).string(m.AccoutNo);
            if (m.CreatedBy != null && Object.hasOwnProperty.call(m, "CreatedBy"))
                $root.bcl.Guid.encode(m.CreatedBy, w.uint32(250).fork()).ldelim();
            if (m.CreatedDate != null && Object.hasOwnProperty.call(m, "CreatedDate"))
                $root.bcl.DateTime.encode(m.CreatedDate, w.uint32(258).fork()).ldelim();
            if (m.ModifiedBy != null && Object.hasOwnProperty.call(m, "ModifiedBy"))
                $root.bcl.Guid.encode(m.ModifiedBy, w.uint32(266).fork()).ldelim();
            if (m.ModifiedDate != null && Object.hasOwnProperty.call(m, "ModifiedDate"))
                $root.bcl.DateTime.encode(m.ModifiedDate, w.uint32(274).fork()).ldelim();
            if (m.InsurerNo != null && Object.hasOwnProperty.call(m, "InsurerNo"))
                w.uint32(280).int32(m.InsurerNo);
            if (m.Active != null && Object.hasOwnProperty.call(m, "Active"))
                w.uint32(288).bool(m.Active);
            if (m.templateId != null && Object.hasOwnProperty.call(m, "templateId"))
                $root.bcl.Guid.encode(m.templateId, w.uint32(298).fork()).ldelim();
            return w;
        };

        /**
         * Encodes the specified InsurerModel message, length delimited. Does not implicitly {@link Insurer.InsurerModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Insurer.InsurerModel
         * @static
         * @param {Insurer.IInsurerModel} message InsurerModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InsurerModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InsurerModel message from the specified reader or buffer.
         * @function decode
         * @memberof Insurer.InsurerModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Insurer.InsurerModel} InsurerModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InsurerModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Insurer.InsurerModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.InsurerID = $root.bcl.Guid.decode(r, r.uint32());
                    break;
                case 2:
                    m.Name = r.string();
                    break;
                case 3:
                    m.RepairerID = r.string();
                    break;
                case 4:
                    m.ShopRate = $root.bcl.Decimal.decode(r, r.uint32());
                    break;
                case 5:
                    m.InsurerGLCode = r.string();
                    break;
                case 6:
                    m.ABN = r.string();
                    break;
                case 7:
                    m.ORM = r.bool();
                    break;
                case 8:
                    m.PNET = r.bool();
                    break;
                case 9:
                    m.Arnie = r.bool();
                    break;
                case 10:
                    m.Exported = r.bool();
                    break;
                case 11:
                    m.ExcessGST = r.bool();
                    break;
                case 12:
                    m.LabourType = r.string();
                    break;
                case 13:
                    m.PanelCons = $root.bcl.Decimal.decode(r, r.uint32());
                    break;
                case 14:
                    m.PanelConsMin = $root.bcl.Decimal.decode(r, r.uint32());
                    break;
                case 15:
                    m.PaintCons = $root.bcl.Decimal.decode(r, r.uint32());
                    break;
                case 16:
                    m.InsurerBrand = r.string();
                    break;
                case 17:
                    m.VeroBrand = r.string();
                    break;
                case 18:
                    m.PDRType = r.int32();
                    break;
                case 19:
                    m.AutoPaintLoading = r.bool();
                    break;
                case 20:
                    m.PaintLoadingPct = $root.bcl.Decimal.decode(r, r.uint32());
                    break;
                case 21:
                    m.EnvironmentalLevy = $root.bcl.Decimal.decode(r, r.uint32());
                    break;
                case 22:
                    m.NewMarkup = $root.bcl.Decimal.decode(r, r.uint32());
                    break;
                case 23:
                    m.UsedMarkup = $root.bcl.Decimal.decode(r, r.uint32());
                    break;
                case 24:
                    m.RecoreMarkup = $root.bcl.Decimal.decode(r, r.uint32());
                    break;
                case 25:
                    m.AfterMarketMarkup = $root.bcl.Decimal.decode(r, r.uint32());
                    break;
                case 26:
                    m.ExchangeMarkup = $root.bcl.Decimal.decode(r, r.uint32());
                    break;
                case 27:
                    m.ReconditionedMarkup = $root.bcl.Decimal.decode(r, r.uint32());
                    break;
                case 28:
                    m.QuotingMethod = r.string();
                    break;
                case 29:
                    m.BSB = r.string();
                    break;
                case 30:
                    m.AccoutNo = r.string();
                    break;
                case 31:
                    m.CreatedBy = $root.bcl.Guid.decode(r, r.uint32());
                    break;
                case 32:
                    m.CreatedDate = $root.bcl.DateTime.decode(r, r.uint32());
                    break;
                case 33:
                    m.ModifiedBy = $root.bcl.Guid.decode(r, r.uint32());
                    break;
                case 34:
                    m.ModifiedDate = $root.bcl.DateTime.decode(r, r.uint32());
                    break;
                case 35:
                    m.InsurerNo = r.int32();
                    break;
                case 36:
                    m.Active = r.bool();
                    break;
                case 37:
                    m.templateId = $root.bcl.Guid.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes an InsurerModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Insurer.InsurerModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Insurer.InsurerModel} InsurerModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InsurerModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return InsurerModel;
    })();

    return Insurer;
})();

$root.bcl = (function() {

    /**
     * Namespace bcl.
     * @exports bcl
     * @namespace
     */
    var bcl = {};

    bcl.TimeSpan = (function() {

        /**
         * Properties of a TimeSpan.
         * @memberof bcl
         * @interface ITimeSpan
         * @property {number|Long|null} [value] TimeSpan value
         * @property {bcl.TimeSpan.TimeSpanScale|null} [scale] TimeSpan scale
         */

        /**
         * Constructs a new TimeSpan.
         * @memberof bcl
         * @classdesc Represents a TimeSpan.
         * @implements ITimeSpan
         * @constructor
         * @param {bcl.ITimeSpan=} [p] Properties to set
         */
        function TimeSpan(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TimeSpan value.
         * @member {number|Long} value
         * @memberof bcl.TimeSpan
         * @instance
         */
        TimeSpan.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TimeSpan scale.
         * @member {bcl.TimeSpan.TimeSpanScale} scale
         * @memberof bcl.TimeSpan
         * @instance
         */
        TimeSpan.prototype.scale = 0;

        /**
         * Encodes the specified TimeSpan message. Does not implicitly {@link bcl.TimeSpan.verify|verify} messages.
         * @function encode
         * @memberof bcl.TimeSpan
         * @static
         * @param {bcl.ITimeSpan} m TimeSpan message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeSpan.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(8).sint64(m.value);
            if (m.scale != null && Object.hasOwnProperty.call(m, "scale"))
                w.uint32(16).int32(m.scale);
            return w;
        };

        /**
         * Encodes the specified TimeSpan message, length delimited. Does not implicitly {@link bcl.TimeSpan.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.TimeSpan
         * @static
         * @param {bcl.ITimeSpan} message TimeSpan message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeSpan.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TimeSpan message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.TimeSpan
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.TimeSpan} TimeSpan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeSpan.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.TimeSpan();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.value = r.sint64();
                    break;
                case 2:
                    m.scale = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a TimeSpan message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.TimeSpan
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.TimeSpan} TimeSpan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeSpan.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * TimeSpanScale enum.
         * @name bcl.TimeSpan.TimeSpanScale
         * @enum {number}
         * @property {number} DAYS=0 DAYS value
         * @property {number} HOURS=1 HOURS value
         * @property {number} MINUTES=2 MINUTES value
         * @property {number} SECONDS=3 SECONDS value
         * @property {number} MILLISECONDS=4 MILLISECONDS value
         * @property {number} MINMAX=15 MINMAX value
         */
        TimeSpan.TimeSpanScale = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DAYS"] = 0;
            values[valuesById[1] = "HOURS"] = 1;
            values[valuesById[2] = "MINUTES"] = 2;
            values[valuesById[3] = "SECONDS"] = 3;
            values[valuesById[4] = "MILLISECONDS"] = 4;
            values[valuesById[15] = "MINMAX"] = 15;
            return values;
        })();

        return TimeSpan;
    })();

    bcl.DateTime = (function() {

        /**
         * Properties of a DateTime.
         * @memberof bcl
         * @interface IDateTime
         * @property {number|Long|null} [value] DateTime value
         * @property {bcl.DateTime.TimeSpanScale|null} [scale] DateTime scale
         */

        /**
         * Constructs a new DateTime.
         * @memberof bcl
         * @classdesc Represents a DateTime.
         * @implements IDateTime
         * @constructor
         * @param {bcl.IDateTime=} [p] Properties to set
         */
        function DateTime(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DateTime value.
         * @member {number|Long} value
         * @memberof bcl.DateTime
         * @instance
         */
        DateTime.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DateTime scale.
         * @member {bcl.DateTime.TimeSpanScale} scale
         * @memberof bcl.DateTime
         * @instance
         */
        DateTime.prototype.scale = 0;

        /**
         * Encodes the specified DateTime message. Does not implicitly {@link bcl.DateTime.verify|verify} messages.
         * @function encode
         * @memberof bcl.DateTime
         * @static
         * @param {bcl.IDateTime} m DateTime message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateTime.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(8).sint64(m.value);
            if (m.scale != null && Object.hasOwnProperty.call(m, "scale"))
                w.uint32(16).int32(m.scale);
            return w;
        };

        /**
         * Encodes the specified DateTime message, length delimited. Does not implicitly {@link bcl.DateTime.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.DateTime
         * @static
         * @param {bcl.IDateTime} message DateTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateTime.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DateTime message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.DateTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.DateTime} DateTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateTime.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.DateTime();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.value = r.sint64();
                    break;
                case 2:
                    m.scale = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a DateTime message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.DateTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.DateTime} DateTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateTime.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * TimeSpanScale enum.
         * @name bcl.DateTime.TimeSpanScale
         * @enum {number}
         * @property {number} DAYS=0 DAYS value
         * @property {number} HOURS=1 HOURS value
         * @property {number} MINUTES=2 MINUTES value
         * @property {number} SECONDS=3 SECONDS value
         * @property {number} MILLISECONDS=4 MILLISECONDS value
         * @property {number} MINMAX=15 MINMAX value
         */
        DateTime.TimeSpanScale = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DAYS"] = 0;
            values[valuesById[1] = "HOURS"] = 1;
            values[valuesById[2] = "MINUTES"] = 2;
            values[valuesById[3] = "SECONDS"] = 3;
            values[valuesById[4] = "MILLISECONDS"] = 4;
            values[valuesById[15] = "MINMAX"] = 15;
            return values;
        })();

        return DateTime;
    })();

    bcl.Guid = (function() {

        /**
         * Properties of a Guid.
         * @memberof bcl
         * @interface IGuid
         * @property {number|Long|null} [lo] Guid lo
         * @property {number|Long|null} [hi] Guid hi
         */

        /**
         * Constructs a new Guid.
         * @memberof bcl
         * @classdesc Represents a Guid.
         * @implements IGuid
         * @constructor
         * @param {bcl.IGuid=} [p] Properties to set
         */
        function Guid(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Guid lo.
         * @member {number|Long} lo
         * @memberof bcl.Guid
         * @instance
         */
        Guid.prototype.lo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Guid hi.
         * @member {number|Long} hi
         * @memberof bcl.Guid
         * @instance
         */
        Guid.prototype.hi = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Encodes the specified Guid message. Does not implicitly {@link bcl.Guid.verify|verify} messages.
         * @function encode
         * @memberof bcl.Guid
         * @static
         * @param {bcl.IGuid} m Guid message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Guid.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.lo != null && Object.hasOwnProperty.call(m, "lo"))
                w.uint32(9).fixed64(m.lo);
            if (m.hi != null && Object.hasOwnProperty.call(m, "hi"))
                w.uint32(17).fixed64(m.hi);
            return w;
        };

        /**
         * Encodes the specified Guid message, length delimited. Does not implicitly {@link bcl.Guid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.Guid
         * @static
         * @param {bcl.IGuid} message Guid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Guid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Guid message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.Guid
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.Guid} Guid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Guid.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.Guid();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.lo = r.fixed64();
                    break;
                case 2:
                    m.hi = r.fixed64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a Guid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.Guid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.Guid} Guid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Guid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return Guid;
    })();

    bcl.Decimal = (function() {

        /**
         * Properties of a Decimal.
         * @memberof bcl
         * @interface IDecimal
         * @property {number|Long|null} [lo] Decimal lo
         * @property {number|null} [hi] Decimal hi
         * @property {number|null} [signScale] Decimal signScale
         */

        /**
         * Constructs a new Decimal.
         * @memberof bcl
         * @classdesc Represents a Decimal.
         * @implements IDecimal
         * @constructor
         * @param {bcl.IDecimal=} [p] Properties to set
         */
        function Decimal(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Decimal lo.
         * @member {number|Long} lo
         * @memberof bcl.Decimal
         * @instance
         */
        Decimal.prototype.lo = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Decimal hi.
         * @member {number} hi
         * @memberof bcl.Decimal
         * @instance
         */
        Decimal.prototype.hi = 0;

        /**
         * Decimal signScale.
         * @member {number} signScale
         * @memberof bcl.Decimal
         * @instance
         */
        Decimal.prototype.signScale = 0;

        /**
         * Encodes the specified Decimal message. Does not implicitly {@link bcl.Decimal.verify|verify} messages.
         * @function encode
         * @memberof bcl.Decimal
         * @static
         * @param {bcl.IDecimal} m Decimal message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Decimal.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.lo != null && Object.hasOwnProperty.call(m, "lo"))
                w.uint32(8).uint64(m.lo);
            if (m.hi != null && Object.hasOwnProperty.call(m, "hi"))
                w.uint32(16).uint32(m.hi);
            if (m.signScale != null && Object.hasOwnProperty.call(m, "signScale"))
                w.uint32(24).sint32(m.signScale);
            return w;
        };

        /**
         * Encodes the specified Decimal message, length delimited. Does not implicitly {@link bcl.Decimal.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.Decimal
         * @static
         * @param {bcl.IDecimal} message Decimal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Decimal.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Decimal message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.Decimal
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.Decimal} Decimal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Decimal.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.Decimal();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.lo = r.uint64();
                    break;
                case 2:
                    m.hi = r.uint32();
                    break;
                case 3:
                    m.signScale = r.sint32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a Decimal message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.Decimal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.Decimal} Decimal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Decimal.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return Decimal;
    })();

    return bcl;
})();

module.exports = $root;
