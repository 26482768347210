<template>
  <div v-if="$company"
    class="is-flex">
    <div class="is-size-3 is-uppercase has-text-grey has-text-weight-medium pr-1">{{ time }}</div>
    <div class="is-flex is-flex-direction-column is-size-7 is-justify-content-center has-text-weight-semibold">
      <span class="am-pm"
        :class="[ timeAmPm === 'am' ? 'has-text-grey' : 'has-text-grey-lighter' ]">AM</span>
      <span class="am-pm"
        :class="[ timeAmPm === 'pm' ? 'has-text-grey' : 'has-text-grey-lighter' ]">PM</span>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'VueClock',
  components: {},
  mixins: [],
  props: {},
  data: () => {
    return {
      interval: null,
      time: null,
      timeAmPm: null
    }
  },
  computed: {
    timezone() {
      return this.$company && this.$company.info && this.$company.info.timezoneIana
    },
    currentTime() {
      const date = DateTime.fromJSDate(new Date(), { locale: this.$userInfo.locale, zone: this.timezone })
      return date.c
    }
  },
  watch: {},
  created() {
    this.interval = setInterval(() => {
      // this.time = Intl.DateTimeFormat(this.$userInfo.locale, {
      //   hour: 'numeric',
      //   minute: 'numeric',
      //   timeZone: this.timezone
      // }).format()
      const date = DateTime.fromJSDate(new Date(), { locale: this.$userInfo.locale, zone: this.timezone })
      // this.time = date.toLocaleString(DateTime.TIME_SIMPLE)
      this.time = `${date.c.hour % 12 || 12}:${this.$filters.pad(date.c.minute, 2)}`
      this.timeAmPm = date.c.hour >= 12 ? 'pm' : 'am'
    }, 1000)
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {},
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.am-pm {
  line-height: 1.1;
}
</style>