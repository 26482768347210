import QuoteService from './QuoteService'
import { ItemService, CompanyService } from './services'
import { VehicleService, CustomerService, InsurerService } from '@/services'
import QuoteRoutes from './route-types'
import StoreUtil from '@/store/utils'
import DeepDiff from 'deep-diff'
import { md5 } from '@/components/crypto'
import _isEmpty from 'lodash.isempty'
import _cloneDeep from 'lodash.clonedeep'
import _orderBy from 'lodash.orderby'
import { updateMarkups, addInsurerLabourRates, addInsurerAutoItems, recalculateTotals } from './store-functions'
import { ItemCategoryTypes, AudaNetLockTypes } from '@/enums'
import store from '@/store'

const tag = 'quote'

const getUser = function () {
  const user = store.getters['userInfo/user']
  return user
}

const state = {
  returnRoute: {},
  emailers: [],
  baseIndex: 0,
  currentIndex: 0,
  snapshots: [],
  currentVehicleSnapshot: null,
  currentCustomerSnapshot: null,
  currentInsurerSnapshot: null,
  companyItemTypes: [],
  quoteVehicle: null,
  quoteCustomer: null,
  quoteInsurer: null,
  emtaVehicle: {
    makeId: '',
    modelId: '',
    year: null,
    variantId: '',
    nvic: ''
  },
  isUpdateInvoice: false,
  isUpdateExcessInvoice: false,
  invoiceIncludedQuotes: [],
  isFixedGst: false,
  standardLabourItems: null,
  ntarLoadingItems: null,
  ntarOtherLoadingItems: null,
  ntarLoadingValues: null,
  jobStages: null,
  partsCheckSetting: null,
  partsCheckSettingExists: false,
  invoiceExtraCharge: 0,
  readOnlyView: false,
  previousQuoteId: '',
  audanetLock: false
}

const getters = {
  returnRoute: (state) => state.returnRoute,
  snapshots: (state) => state.snapshots,
  baseSnapshot: (state) => state.snapshots[state.baseIndex],
  currentSnapshot: (state) => state.snapshots[state.currentIndex],
  snapshotDiff: (state) => {
    const vehicleDiff = DeepDiff.diff(state.quoteVehicle, state.currentVehicleSnapshot)
    const customerDiff = DeepDiff.diff(state.quoteCustomer, state.currentCustomerSnapshot)
    const insurerDiff = DeepDiff.diff(state.quoteInsurer, state.currentInsurerSnapshot)
    const quoteDiff = DeepDiff.diff(state.snapshots[state.baseIndex], state.snapshots[state.currentIndex])
    const diff = { ...quoteDiff, ...vehicleDiff, ...customerDiff, ...insurerDiff }
    if (state.snapshots[state.baseIndex] && state.snapshots[state.currentIndex] && !_isEmpty(diff)) {
      return diff
    } else {
      return null
    }
  },
  companyItemTypes: (state) => state.companyItemTypes,
  currentVehicleSnapshot: (state) => state.currentVehicleSnapshot,
  currentCustomerSnapshot: (state) => state.currentCustomerSnapshot,
  currentInsurerSnapshot: (state) => state.currentInsurerSnapshot,
  quoteVehicle: (state) => state.quoteVehicle,
  quoteCustomer: (state) => state.quoteCustomer,
  quoteInsurer: (state) => state.quoteInsurer,
  snapshotDiffVehicle: (state) => {
    return DeepDiff.diff(state.quoteVehicle, state.currentVehicleSnapshot)
  },
  snapshotDiffCustomer: (state) => {
    return DeepDiff.diff(state.quoteCustomer, state.currentCustomerSnapshot)
  },
  snapshotDiffInsurer: (state) => {
    return DeepDiff.diff(state.quoteInsurer, state.currentInsurerSnapshot)
  },
  emtaVehicle: (state) => state.emtaVehicle,
  isUpdateInvoice: (state) => state.isUpdateInvoice,
  isUpdateExcessInvoice: (state) => state.isUpdateExcessInvoice,
  invoiceIncludedQuotes: (state) => state.invoiceIncludedQuotes,
  isFixedGst: (state) => state.isFixedGst,
  standardLabourItems: (state) => state.standardLabourItems,
  ntarLoadingItems: (state) => state.ntarLoadingItems,
  ntarOtherLoadingItems: (state) => state.ntarOtherLoadingItems,
  ntarLoadingValues: (state) => state.ntarLoadingValues,
  jobStages: (state) => state.jobStages,
  baseItem: (state) =>
    function (item) {
      if (!state.snapshots[state.baseIndex]) {
        return false
      }
      let baseItem = null
      switch (item.itemType) {
        case ItemCategoryTypes.RR:
        case ItemCategoryTypes.REP:
        case ItemCategoryTypes.RWA:
        case ItemCategoryTypes.PAINT:
          baseItem = state.snapshots[state.baseIndex].labours.find((i) => i.quoteItemId === item.quoteItemId)
          break
        case ItemCategoryTypes.MECH:
        case ItemCategoryTypes.CD:
        case ItemCategoryTypes.CRUSH:
        case ItemCategoryTypes.FIBER:
          baseItem = state.snapshots[state.baseIndex].others.find((i) => i.quoteItemId === item.quoteItemId)
          break
        case ItemCategoryTypes.PART:
          baseItem = state.snapshots[state.baseIndex].parts.find((i) => i.quoteItemId === item.quoteItemId)
          break
        case ItemCategoryTypes.OPG:
          baseItem = state.snapshots[state.baseIndex].opgs.find((i) => i.quoteItemId === item.quoteItemId)
          break
        case ItemCategoryTypes.MISC:
          baseItem = state.snapshots[state.baseIndex].miscs.find((i) => i.quoteItemId === item.quoteItemId)
          break
        case ItemCategoryTypes.SUBL:
          baseItem = state.snapshots[state.baseIndex].sublets.find((i) => i.quoteItemId === item.quoteItemId)
          break
        default:
          baseItem = null
      }
      return baseItem
    },
  baseItemDiff: (state, getters) =>
    function (item, fields = [], isExclude = true) {
      const baseItem = getters.baseItem(item)
      let diff = undefined
      if (baseItem !== undefined) {
        if (fields.length > 0 && isExclude) {
          diff = DeepDiff.diff(baseItem, item, (path, key) => path.length === 0 && ~fields.indexOf(key))
        } else if (fields.length > 0 && isExclude) {
          diff = DeepDiff.diff(baseItem, item, (path, key) => path.length === 0 && fields.indexOf(key))
        } else {
          diff = DeepDiff.diff(baseItem, item)
        }
      }
      // const diff = baseItem !== undefined ? DeepDiff.diff(baseItem, item, (path, key) => path.length === 0 && ~['modifiedDate'].indexOf(key)) : false
      // console.log('diff', state.snapshots[state.baseIndex], diff)
      // console.log(diff, diff ? diff.filter(d => d.kind === 'E' && d.path && d.path[0] !== 'modifiedDate') : null)
      // return diff ? diff.filter(d => d.kind === 'E' && d.path && d.path[0] !== 'modifiedDate') : null
      return diff
    },
  partsCheckSettingExists: (state) => state.partsCheckSettingExists,
  invoiceExtraCharge: (state) => state.invoiceExtraCharge,
  readOnlyView: (state) => state.readOnlyView,
  previousQuoteId: (state) => state.previousQuoteId,
  audanetLock: (state) => state.audanetLock,
  getEmailer: state =>
  function(id) {
    return state.emailers.find(e => e.id === id)
  },
  partsCheckSetting: (state) => state.partsCheckSetting
}

const generateHash = function (entity) {
  if (entity) {
    let clone = _cloneDeep(entity)
    clone.labours = _orderBy(clone.labours, 'lineNumber')
    clone.others = _orderBy(clone.others, 'lineNumber')
    clone.parts = _orderBy(clone.parts, 'lineNumber')
    clone.opgs = _orderBy(clone.opgs, 'lineNumber')
    clone.miscs = _orderBy(clone.miscs, 'lineNumber')
    clone.sublets = _orderBy(clone.sublets, 'lineNumber')
    return entity ? md5(JSON.stringify(clone)) : ''
  } else {
    return ''
  }
}

const actions = {
  async getStoreItem({ commit }, id) {
    // const entity = StoreUtil.getStorage(id, tag) || (await QuoteService.getEntity(id, ''))
    // The following for refreshing data from WebApi regardless
    let entity = StoreUtil.getStorage(id, tag)
    if (!entity || (entity && !entity.isNew && !entity.isCopyQuote)) {
      const hash = generateHash(entity)
      const data = await QuoteService.getEntity(id, hash)
      const history = await QuoteService.getOrmQuoteHistory(id)
      const quoteAssessments = await QuoteService.getQuoteAssessments(id)

      if (data) {
        console.log('from WebApi')
        entity = data
      } else {
        console.log('from sessionStorage')
      }
      entity.ormHistory = history
      entity.quoteAssessments = quoteAssessments
    }

    StoreUtil.setStorage(entity.quoteId, tag, entity)
    const user = getUser()
    commit('saveInitialSnapshots', entity)
    commit('setAudaNetLock', entity.isAudaNet && !user.info.isSupportUser && !user.info.isCustomerAdministrator)
  },
  async editStoreItem({ commit }, id) {
    store.dispatch('$showSpinner')
    const entity = StoreUtil.getStorage(id, tag) || (await QuoteService.getEntity(id, ''))
    const quoteAssessments = await QuoteService.getQuoteAssessments(id)
    entity.quoteAssessments = quoteAssessments
    StoreUtil.setStorage(entity.quoteId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(QuoteRoutes.QuoteListView.path, entity.quoteId, false)
  },
  async addStoreItem({ commit }, quoteNo) {
    const entity = await QuoteService.getNewEntity(quoteNo, false)
    StoreUtil.setStorage(entity.quoteId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(QuoteRoutes.QuoteListView.path, entity.quoteId, entity.isNew)
  },
  async copyStoreQuote({ commit, dispatch }, payload) {
    const entity = await QuoteService.getCopyEntity(payload.sourceId, payload.destinationId, payload.isCopyImages, payload.isOverwrite)

    const promises = [
      dispatch('getQuoteInsurer', { id: entity.insurerId, refresh: true }),
      dispatch('getQuoteCustomer', { id: entity.customerId, refresh: true }),
      dispatch('getQuoteVehicle', { id: entity.vehicleId, refresh: true })
    ]
    await Promise.all(promises)
    StoreUtil.setStorage(entity.quoteId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(QuoteRoutes.QuoteListView.path, entity.quoteId, true)
  },
  async addStoreSupplementaryItem({ commit }, quoteNo) {
    const entity = await QuoteService.getNewEntity(quoteNo, true)
    StoreUtil.setStorage(entity.quoteId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(QuoteRoutes.QuoteListView.path, entity.quoteId, true)
  },
  saveSnapshot({ commit }, entity) {
    commit('saveSnapshot', entity)
  },
  clearSnapshots({ commit }, id) {
    StoreUtil.removeStorage(id, tag)
    commit('clearSnapshots')
  },
  clearHeaders({ commit }) {
    commit('removeQuoteVehicle')
    commit('removeQuoteCustomer')
    commit('removeQuoteInsurer')
  },
  setReturnRoute({ commit }, route) {
    commit('setReturnRoute', route)
  },
  async getCompanyItemTypes({ commit }) {
    if (!state.companyItemTypes || _isEmpty(state.companyItemTypes)) {
      let itemTypes = StoreUtil.getLocalStorage('1', 'companyItemType')
      if (!itemTypes || _isEmpty(itemTypes)) {
        itemTypes = await QuoteService.getItemTypes()
        StoreUtil.setLocalStorage(1, 'companyItemType', itemTypes)
        // console.log('companyItemType from WebApi')
      }
      commit('setCompanyItemTypes', itemTypes)
    }
  },
  async getQuoteVehicle({ commit }, payload) {
    if (!state.quoteVehicle || payload.refresh) {
      const vehicle = await VehicleService.getEntity(payload.id)
      commit('setQuoteVehicle', vehicle)
    }
  },
  async getNewVehicle({ commit }) {
    if (!state.quoteVehicle) {
      const vehicle = await VehicleService.getNewEntity('')
      commit('setQuoteVehicle', vehicle)
    }
  },
  removeQuoteVehicle({ commit }) {
    commit('removeQuoteVehicle')
  },
  async getQuoteCustomer({ commit }, payload) {
    if (!state.quoteCustomer || payload.refresh) {
      const entity = await CustomerService.getEntity(payload.id)
      commit('setQuoteCustomer', entity)
    }
  },
  async getNewCustomer({ commit }) {
    if (!state.quoteCustomer) {
      const entity = await CustomerService.getNewEntity('')
      commit('setQuoteCustomer', entity)
    }
  },
  removeQuoteCustomer({ commit }) {
    commit('removeQuoteCustomer')
  },
  setEmtaVehicle({ commit }, vehicle) {
    // StoreUtil.setStorage('quote', 'emtaVehicle', vehicle)
    commit('setEmtaVehicle', vehicle)
  },
  resetEmtaVehicle({ commit }) {
    commit('resetEmtaVehicle')
  },
  setIsUpdateInvoice({ commit }, isUpdateInvoice) {
    commit('setIsUpdateInvoice', isUpdateInvoice)
  },
  setIsUpdateExcessInvoice({ commit }, isUpdateInvoice) {
    commit('setIsUpdateExcessInvoice', isUpdateInvoice)
  },
  addInvoiceIncludedQuotes({ commit }, quote) {
    commit('addInvoiceIncludedQuotes', quote)
  },
  removeInvoiceIncludedQuotes({ commit }, quote) {
    commit('removeInvoiceIncludedQuotes', quote)
  },
  setIsFixedGst({ commit }, changed) {
    commit('setIsFixedGst', changed)
  },
  clearInvoiceIncludedQuotes({ commit }) {
    commit('clearInvoiceIncludedQuotes')
  },
  clearInvoiceState({ commit }) {
    commit('clearInvoiceState')
  },
  setVehicleCustomer({ commit }, payload) {
    commit('setVehicleCustomer', payload)
  },
  async setInsurer({ commit }, insurer) {
    commit('setInsurer', insurer)
  },
  async getQuoteInsurer({ commit }, payload) {
    if (!state.quoteInsurer || payload.refresh) {
      const entity = await InsurerService.getEntity(payload.id)
      commit('setQuoteInsurer', entity)
      commit('setAudaNetLock', state.audanetLock && entity.audaNetLockType === AudaNetLockTypes.LockOnRFQ)
    }
  },
  async getNewInsurer({ commit }) {
    if (!state.quoteInsurer) {
      const entity = await InsurerService.getNewEntity('')
      commit('setQuoteInsurer', entity)
    }
  },
  async setQuoteInsurer({ commit }, insurer) {
    commit('setQuoteInsurer', insurer)
  },
  removeQuoteInsurer({ commit }) {
    commit('removeQuoteInsurer')
  },
  async getStandardLabourItems({ commit }) {
    if (!state.standardLabourItems) {
      const items = await ItemService.getItemLabourStandardByItemNos(['9998', '9999', '8999'])
      commit('setStandardLabourItems', items)
    }
  },
  async getNtarLoadingItems({ commit }) {
    if (!state.ntarLoadingItems) {
      const items = await ItemService.getNtarLoadingItemsProto()
      commit('setNtarLoadingItems', items)
    }
  },
  async getNtarOtherLoadingItems({ commit }) {
    if (!state.ntarOtherLoadingItems) {
      const items = await ItemService.getNtarOtherLoadingItemsProto()
      commit('setOtherNtarLoadingItems', items)
    }
  },
  async getNtarLoadingValues({ commit }) {
    if (!state.ntarLoadingValues) {
      const values = await ItemService.getNtarLoadingValuesProto()
      commit('setNtarLoadingValues', values)
    }
  },
  removeLabourItems({ commit }) {
    commit('removeLabourItems')
  },
  async getJobStages({ commit }) {
    if (!state.jobStages) {
      const jobStages = await QuoteService.getJobStagesProto()
      commit('setJobStages', jobStages)
    }
  },
  removeJobStages({ commit }) {
    commit('removeJobStages')
  },
  async getExternalSetting({ commit }, settingName) {
    if (!state.partsCheckSetting) {
      const setting = await CompanyService.getExternalSetting(settingName)
      commit('setPartsCheckSetting', setting)
    }
    commit('setPartsCheckSettingExists', state.partsCheckSetting && state.partsCheckSetting.username && state.partsCheckSetting.serverName)
  },
  setInvoiceExtraCharge({ commit }, value) {
    commit('setInvoiceExtraCharge', value)
  },
  setReadOnlyView({ commit }, value) {
    commit('setReadOnlyView', value)
  },
  setPreviousQuoteId({ commit }, value) {
    commit('setPreviousQuoteId', value)
  },
  setAudaNetLock({ commit }, value) {
    commit('setAudaNetLock', value)
  },
  saveVehicleSnapshot({ commit }, value) {
    commit('saveVehicleSnapshot', value)
  },
  saveCustomerSnapshot({ commit }, value) {
    commit('saveCustomerSnapshot', value)
  },
  saveInsurerSnapshot({ commit }, value) {
    commit('saveInsurerSnapshot', value)
  },
  addEmailer({ commit }, emailer) {
    commit('addEmailer', emailer)
  },
  removeEmailer({ commit }, emailer) {
    commit('removeEmailer', emailer)
  }
}

const mutations = {
  saveInitialSnapshots(state, entity) {
    state.snapshots = []
    state.snapshots.splice(0, 1, entity)
    state.snapshots.splice(1, 1, entity)
    state.currentIndex = state.snapshots.length - 1
  },
  saveSnapshot(state, entity) {
    state.snapshots.splice(state.currentIndex, 1, entity)
  },
  clearSnapshots(state) {
    state.snapshots.splice(0, state.snapshots.length)
    state.baseIndex = 0
    state.currentIndex = 0
  },
  setReturnRoute(state, route) {
    state.returnRoute = route
  },
  setCompanyItemTypes(state, itemTypes) {
    state.companyItemTypes = itemTypes
  },
  setQuoteVehicle(state, vehicle) {
    state.quoteVehicle = vehicle
    state.currentVehicleSnapshot = vehicle
  },
  removeQuoteVehicle(state) {
    state.quoteVehicle = null
    state.currentVehicleSnapshot = null
  },
  setQuoteCustomer(state, customer) {
    state.quoteCustomer = customer
    state.currentCustomerSnapshot = customer
  },
  removeQuoteCustomer(state) {
    state.quoteCustomer = null
    state.currentCustomerSnapshot = null
  },
  setEmtaVehicle(state, vehicle) {
    state.emtaVehicle = vehicle
  },
  resetEmtaVehicle(state) {
    state.emtaVehicle.makeId = ''
    state.emtaVehicle.modelId = ''
    state.emtaVehicle.year = null
    state.emtaVehicle.variantId = ''
    state.emtaVehicle.nvic = ''
  },
  addInvoiceIncludedQuotes(state, quote) {
    state.invoiceIncludedQuotes.splice(state.invoiceIncludedQuotes.length, 1, quote)
  },
  removeInvoiceIncludedQuotes(state, quote) {
    const index = state.invoiceIncludedQuotes.findIndex((s) => s.quoteId === quote.quoteId)
    state.invoiceIncludedQuotes.splice(index, 1)
  },
  setIsUpdateInvoice(state, isUpdateInvoice) {
    state.isUpdateInvoice = isUpdateInvoice
  },
  setIsUpdateExcessInvoice(state, isUpdateInvoice) {
    state.isUpdateExcessInvoice = isUpdateInvoice
  },
  setIsFixedGst(state, changed) {
    state.isFixedGst = changed
  },
  clearInvoiceIncludedQuotes(state) {
    state.invoiceIncludedQuotes.splice(0, state.invoiceIncludedQuotes.length)
  },
  clearInvoiceState(state) {
    state.invoiceIncludedQuotes.splice(0, state.invoiceIncludedQuotes.length)
    state.isUpdateInvoice = false
    state.isUpdateExcessInvoice = false
    state.isFixedGst = false
  },
  setVehicleCustomer(state, payload) {
    state.snapshots[state.currentIndex].vehicleId = payload.vehicleId
    state.snapshots[state.currentIndex].customerId = payload.customerId
  },
  setInsurer(state, insurer) {
    if (!insurer.isNew) {
      state.quoteInsurer = insurer
      state.snapshots[state.currentIndex].insurerId = insurer.insurerId
      // const isItemsEmpty =
      //   this.innerValue.labours.length === 0 &&
      //   this.innerValue.parts.length === 0 &&
      //   this.innerValue.others.length === 0 &&
      //   this.innerValue.opgs.length === 0 &&
      //   this.innerValue.miscs.length === 0 &&
      //   this.innerValue.sublets.length === 0
      if (state.snapshots[state.currentIndex].isNew) {
        updateMarkups(state, insurer)
        addInsurerLabourRates(state, insurer)
        addInsurerAutoItems(state, insurer)
        recalculateTotals(state)
      }
    }
  },
  setQuoteInsurer(state, insurer) {
    state.quoteInsurer = insurer
    state.currentInsurerSnapshot = insurer
  },
  removeQuoteInsurer(state) {
    state.quoteInsurer = null
    state.currentInsurerSnapshot = null
  },
  setStandardLabourItems(state, items) {
    state.standardLabourItems = items
  },
  setNtarLoadingItems(state, items) {
    state.ntarLoadingItems = items
  },
  setOtherNtarLoadingItems(state, items) {
    state.ntarOtherLoadingItems = items
  },
  setNtarLoadingValues(state, values) {
    state.ntarLoadingValues = values
  },
  removeLabourItems(state) {
    state.standardLabourItems = null
    state.ntarLoadingItems = null
    state.ntarOtherLoadingItems = null
    state.ntarLoadingValues = null
  },
  setJobStages(state, jobStages) {
    state.jobStages = jobStages
  },
  removeJobStages(state) {
    state.jobStages = null
  },
  setPartsCheckSetting(state, setting) {
    state.partsCheckSetting = setting
  },
  setPartsCheckSettingExists(state, exists) {
    state.partsCheckSettingExists = exists !== '' && exists !== undefined && exists !== null
  },
  setInvoiceExtraCharge(state, value) {
    state.invoiceExtraCharge = value
  },
  setReadOnlyView(state, value) {
    state.readOnlyView = value
  },
  setPreviousQuoteId(state, value) {
    state.previousQuoteId = value
  },
  setAudaNetLock(state, value) {
    state.audanetLock = value
  },
  saveVehicleSnapshot(state, value) {
    state.currentVehicleSnapshot = _cloneDeep(value)
  },
  saveCustomerSnapshot(state, value) {
    state.currentCustomerSnapshot = _cloneDeep(value)
  },
  saveInsurerSnapshot(state, value) {
    state.currentInsurerSnapshot = _cloneDeep(value)
  },
  addEmailer(state, emailer) {
    state.emailers.push(emailer)
  },
  removeEmailer(state, id) {
    const index = state.emailers.findIndex(e => e.id === id)
    if (index !== -1) {
      state.emailers.splice(index, 1)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
