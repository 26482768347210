export default {
  SR: 'SR',
  RR: 'RR',
  RWA: 'RWA',
  RA: 'RA',
  PAINT: 'PAINT',
  FG: 'FG',
  CRUSH: 'CRUSH',
  CD: 'CD',
  MECH: 'MECH',
  PRT: 'PRT',
  OPG: 'OPG',
  MIS: 'MIS',
  SUBL: 'SUBL',
  ADJ: 'ADJ',
  CADJ: 'CADJ',
  PDRI: 'PDRI',
  PDRR: 'PDRR',
  TS: 'TS',
  DISC: 'DISC',
  Other: 'Other',
  MBA: 'MBA',
  UND: 'UND',
  AR: 'AR',
  AP: 'AP',
  PROF: 'PROF',
  GL_PU_ACC: 'GL_PU_ACC',
  GST_COL: 'GST_COL',
  GST_SPENT: 'GST_SPENT',
  TAXWH_ACC: 'TAXWH_ACC',
  TAX_LIAB: 'TAX_LIAB',
  SUPER_LIAB: 'SUPER_LIAB',
  CS_LIAB: 'CS_LIAB'
}
