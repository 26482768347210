export default {
  None: 0,
  ToBeQuoted: 1,
  PhysicalAssessmentPending: 2,
  AuthorityPending: 3,
  FollowUpRequired: 4,
  BookedIn: 5,
  WorkInProgress: 6,
  SupplementaryRequired: 7,
  InvoicePending: 8,
  JobCancelled: 9,
  Closed: 10,
  QuoteToBeChecked: 11,
  WorkInProgressRR: 12,
  WorkInProgressREP: 13,
  WorkInProgressPaint: 14,
  VehicleReady: 19,
  WaitingForParts: 20,
  Authorised :21,
  VehicleArrived : 22
}
