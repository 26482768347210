import routeTypes from './route-types'
import store from '@/store'
import ContactComponentListing from '@/views/contact'
// import ContactComponentListing from '@/views/contact/ContactComponentListing'

// Lazy-load components. Webpack will split each into individual file
const InsurerListView = r => require.ensure([], () => r(require('./InsurerListView.vue')), 'insurerlist-chunk')
const InsurerView = r => require.ensure([], () => r(require('./InsurerView.vue')), 'insurer-chunk') // (resolve) => require(['./InsurerView.vue'], resolve)
const InsurerDetail = r => require.ensure([], () => r(require('./InsurerDetail.vue')), 'insurer-chunk') // (resolve) => require(['./InsurerDetail.vue'], resolve)
const InsurerRatesMarkups = r => require.ensure([], () => r(require('./InsurerRatesMarkups.vue')), 'insurer-rates-chunk') // (resolve) => require(['./InsurerRatesMarkups.vue'], resolve)
const InsurerAddress = r => require.ensure([], () => r(require('./InsurerAddress.vue')), 'insurer-addr-chunk') // (resolve) => require(['./InsurerAddress.vue'], resolve)
const InsurerPhoneEmail = r => require.ensure([], () => r(require('./InsurerPhoneEmail.vue')), 'insurer-addr-chunk') // (resolve) => require(['./InsurerPhoneEmail.vue'], resolve)
const InsurerConsumables = r => require.ensure([], () => r(require('./InsurerConsumables.vue')), 'insurer-ntar-chunk') // (resolve) => require(['./InsurerPhoneEmail.vue'], resolve)
const InsurerLoadingValues = r => require.ensure([], () => r(require('./InsurerLoadingValues.vue')), 'insurer-ntar-chunk') // (resolve) => require(['./InsurerPhoneEmail.vue'], resolve)
const InsurerPaintMaterials = r => require.ensure([], () => r(require('./InsurerPaintMaterials.vue')), 'insurer-ntar-chunk') // (resolve) => require(['./InsurerPhoneEmail.vue'], resolve)
const InsurerSpecialRates = r => require.ensure([], () => r(require('./InsurerSpecialRates.vue')), 'insurer-ntar-chunk') // (resolve) => require(['./InsurerPhoneEmail.vue'], resolve)
const InsurerAutoItems = r => require.ensure([], () => r(require('./InsurerAutoItems.vue')), 'insurer-autoitem-chunk') // (resolve) => require(['./InsurerPhoneEmail.vue'], resolve)

// Non shorthand version
// const InsurerView = resolve => {
//   // The empty array is for specifying other dependencies that need to be loaded.
//   require.ensure([], () => {
//     resolve(require('./InsurerView.vue'))
//   })
// }

// Get sessionStorage
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|insurer|${query}`)
}

export default [
  {
    path: routeTypes.InsurerListView.path,
    name: routeTypes.InsurerListView.name,
    component: InsurerListView,
    meta: {
      id: 'A098',
      route: 'listing',
      title: 'Insurers',
      fkey: 'insurer|filter',
      mkey: 'insurer|modules',
      ckey: 'insurer|consumables',
      report: 'rptInsurerList',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.InsurerDetail.path,
    name: '',
    component: InsurerView,
    props: route => ({
      isNew: route.query.mode === 'new' || route.meta.isNew || getStorage('isNew') === 'true',
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl'),
      type: route.query.type || route.meta.type || getStorage('type'),
      name: route.query.name || route.meta.name || getStorage('name'),
      iid: route.query.iid || route.meta.iid || getStorage('iid')
    }),
    meta: {
      id: 'A098',
      route: 'detail',
      title: 'Insurer',
      mkey: 'insurer|modules',
      ckey: 'insurer|consumables'
    },
    children: [
      {
        path: '',
        name: routeTypes.InsurerDetail.name,
        component: InsurerDetail,
        meta: {
          id: 'A098',
          route: 'detail',
          title: 'Detail',
          mkey: 'insurer|modules',
          ckey: 'insurer|consumables',
          report: 'rptTableInsurerDetails',
          reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
        }
      },
      {
        path: routeTypes.InsurerRatesMarkups.path,
        name: routeTypes.InsurerRatesMarkups.name,
        component: InsurerRatesMarkups,
        meta: {
          id: 'A098',
          title: 'Rates & Markups'
        }
      },
      {
        path: routeTypes.InsurerAddress.path,
        name: routeTypes.InsurerAddress.name,
        component: InsurerAddress,
        meta: {
          id: 'A098',
          title: 'Addresses'
        }
      },
      {
        path: routeTypes.InsurerPhoneEmail.path,
        name: routeTypes.InsurerPhoneEmail.name,
        component: InsurerPhoneEmail,
        props: {
          isTiled: true
        },
        meta: {
          id: 'A098',
          title: 'Phones & Emails'
        }
      },
      {
        path: routeTypes.InsurerConsumables.path,
        name: routeTypes.InsurerConsumables.name,
        component: InsurerConsumables,
        meta: {
          id: 'A098',
          title: 'Consumables'
        }
      },
      {
        path: routeTypes.InsurerLoadingValues.path,
        name: routeTypes.InsurerLoadingValues.name,
        component: InsurerLoadingValues,
        meta: {
          id: 'A098',
          title: 'Loading Values'
        }
      },
      {
        path: routeTypes.InsurerPaintMaterials.path,
        name: routeTypes.InsurerPaintMaterials.name,
        component: InsurerPaintMaterials,
        meta: {
          id: 'A098',
          title: 'Paint Materials'
        }
      },
      {
        path: routeTypes.InsurerSpecialRates.path,
        name: routeTypes.InsurerSpecialRates.name,
        component: InsurerSpecialRates,
        meta: {
          id: 'A098',
          title: 'Special Rates'
        }
      },
      {
        path: routeTypes.InsurerAutoItems.path,
        name: routeTypes.InsurerAutoItems.name,
        component: InsurerAutoItems,
        meta: {
          id: 'A098',
          title: 'Auto Items'
        }
      },
      {
        path: routeTypes.InsurerContacts.path,
        name: routeTypes.InsurerContacts.name,
        component: ContactComponentListing,
        meta: {
          id: 'A098',
          title: 'Contacts'
        }
      }
    ]
  }
]
