exports.EstImageMessageListView = {
  path: '/estimagemessages',
  name: 'EstImageMessageListView'
}
exports.EstImageMessageView = {
  path: '/estimagemessages/:messageId',
  name: 'EstImageMessageView'
}
exports.EstImageMessageNew = {
  path: '/estimagemessages/new/:messageId',
  name: 'EstImageMessageNew'
}
