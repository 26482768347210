<template>
  <div>
    <h2 class="subtitle is-6">
      Routes
    </h2>
    <ul>
      <li v-for="(v, idx) in routes"
        :key="idx">
        <div class="columns">
          <div class="column">
            <div class="field has-addons">
              <p class="control">
                <input class="input is-small"
                  type="text"
                  v-model="v.route"
                  placeholder="Route"
                  @change="update(v)">
              </p>
              <p class="control is-expanded">
                <input class="input is-small"
                  type="text"
                  v-model="v.link"
                  placeholder="Link"
                  @change="update(v)">
              </p>
              <p class="control">
                <button @click="deleteChild(v, idx)"
                  class="button is-danger is-small">
                  <span class="icon">
                    <i class="mdi mdi-trash-can-outline mdi-20px" />
                  </span>
                </button>
              </p>
            </div>
          </div>
        </div>
      </li>
      <li class="has-text-right"
        style="margin-top:1em;">
        <button @click="addChild()"
          class="button is-success is-small"
          :disabled="!canAddChild()">
          <span class="icon">
            <i class="mdi mdi-plus"
              aria-hidden="true" />
          </span>
          <span>
            Route
          </span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PermissionRecordChilds',
  props: {
    routes: {
      type: Array,
      required: true
    }
  },
  methods: {
    canAddChild: function () {
      for (var i = 0; i < this.routes.length; i++) {
        var v = this.routes[i]
        if (v.route && v.link) {
          continue
        }
        return false
      }

      return true
    },
    addChild: function () {
      this.$emit('addChild')
    },
    update: function (c) {
      if (c.route && c.link) {
        this.$emit('accessChanged', c)
      }
    },
    deleteChild: function (c, idx) {
      if (!c.route || !c.link) {
        this.routes.splice(idx, 1)
        return
      }

      if (confirm('Are you sure you want to delete this route?')) {
        this.routes.splice(idx, 1)
        this.$emit('deleteRoute', c)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
