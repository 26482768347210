import ProtoBufApi from '@/services/protobuf-api'

export default {
  async getCachedUserInfo() {
    const protoDefinition = require('../assets/proto/UserInfoModel.proto')
    const message = protoDefinition.User.UserInfoModel
    const result = await ProtoBufApi.get('/access/userinfo')
    const rs = message.decode(new Uint8Array(result.data))
    return rs
  },
  async getLicenceData() {
    // const result = await FqApi.get(`/companies/licencedata`)
    // return result
  },
  async getUserPermissions()
  {
    const protoDefinition = require('../assets/proto/user/UserPermissionModels.proto')
    const message = protoDefinition.UserPermissions.UserPermissionModels
    const result = await ProtoBufApi.get('/access/permissions')
    const rs = message.decode(new Uint8Array(result.data))
    return rs
  }
}
