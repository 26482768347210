<template>
  <div class="is-flex"
    :content="fullName"
    v-tippy="{ enabled: true }">
    <svg-circle v-if="isTextProfile"
      name="userProfile"
      :text="initials"
      :width="width"
      :height="height" />
    <img v-else
      name="userProfileIcon"
      :src="base64Image"
      class="is-rounded"
      :style="`width: ${width}px;`"
      aria-haspopup="true"
      aria-expanded="false">
  </div>
</template>

<script>
import SvgCircle from '@/components/SvgCircle/SvgCircle'

export default {
  name: 'UserProfileIcon',
  components: { SvgCircle },
  mixins: [],
  props: {
    width: {
      type: Number,
      default: 30
    },
    height: {
      type: Number,
      default: 30
    },
    isTextProfile: {
      type: Boolean,
      default: true
    },
    firstName: {
      type: String,
      default: null
    },
    lastName: {
      type: String,
      default: null
    },
    displayName: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      base64Image: 'https://i.pravatar.cc/300',
      initials: '',
      fullName: ''
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getUserProfileNames()
  },
  mounted() {},
  methods: {
    getUserProfileNames() {
      if (this.firstName && this.lastName) {
        const userIntitails = this.firstName.charAt(0).concat(this.lastName.charAt(0))
        const fullName = this.firstName + ' ' + this.lastName
        this.initials = userIntitails.toUpperCase()
        this.fullName = fullName
      } else {
        const userIntitails = this.displayName.slice(0, 2)
        this.initials = userIntitails.toUpperCase()
        this.fullName = this.displayName
      }
    }
  }
}
</script>

<style lang="scss">
.is-rounded {
  border-radius: 50%;
}
</style>
