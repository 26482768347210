// import { OrmItemCommentModel } from './OrmItemCommentModel'

export class OrmSendQuoteModel {
  constructor() {
    this.quoteId = ''
    this.sendQuoteType = 0
    this.inspectionReason = ''
    this.totalLossReason = ''
    this.vehicleLocation = ''
    this.vehicleSuburb = ''
    this.overwriteLoad = false
    this.itemComments = [] // Array of OrmItemCommentModel
    this.imageIds = []
  }
}
