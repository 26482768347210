import Guid from '@/components/Guid'
import { calcTotalExGst, calcGst, roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default class InvoiceItemModel {
  constructor(companyId, invoiceId, invoiceGlCode = '', description = '', quantity = 1, unitPrice = 0, gstRate = 0) {
    const gst = calcGst(1, unitPrice, gstRate)
    const totalExGst = calcTotalExGst(quantity, unitPrice)
    this.companyId = companyId
    this.costCentre = null
    this.deleted = false
    this.invoiceGlCode = invoiceGlCode // TODO = Should not be hard coded
    this.glCategory = ''
    this.invoiceId = invoiceId
    this.invoiceItemDesc = description
    this.invoiceItemId = Guid.newGuid()
    this.isDeleted = false
    this.isNew = true
    this.quantity = quantity
    this.quoteItemId = null
    this.totalExGst = roundAwayFromZero(totalExGst, 2)
    this.gst = gst
    this.totalIncGst = roundAwayFromZero(totalExGst + gst, 2)
    this.unitPrice = unitPrice
    this.unitPriceIncGst = roundAwayFromZero(totalExGst + gst, 2)
  }
}
