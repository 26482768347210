import PricePartsVehicleBodyStayle from './PricePartsVehicleBodyStayle'

export default class PricePartsRequestModel {
  constructor(bodyStayle= new PricePartsVehicleBodyStayle()) {
    this.BodyStyle='',
    this.SelectedImageIds=[],
    this.VehicleBodyStyle= bodyStayle,
    this.SelectedParts = []
  }
}
