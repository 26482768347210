import JcniRoutes from './route-types'
import JcniView from './JcniView'

const moduleId = 'A150'

export default [
  {
    path: JcniRoutes.JcniView.path,
    name: JcniRoutes.JcniView.name,
    component: JcniView,
    meta: {
      id: moduleId,
      title: 'Jobs Completed Not Invoiced',
      route: 'listing',
      fkey: 'jcni|filter',
      access: {
        isPrint: true
      }
    }
  }
]