import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('labourTypes', { $labourTypes: 'labourTypes' })
  },
  methods: {
    ...mapActions('labourTypes', { getLabourTypes: 'getLabourTypes' })
  }
}
