import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntity(id, hash) {
    const url = !hash ? `/emailsmslogs/${id}` : `/emailsmslogs/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseEmailSmsLogFilter(filter)
    const url = `/emailsmslogs/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseEmailSmsLogFilter(filter) {
    const entityProto = require('../../assets/proto/emailsmslog/EmailSmsLogFilter.proto')
    const filterMessage = entityProto.EmailSmsLogFilter.EmailSmsLogFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  deleteEntity(id) {
    return FqApi.delete('/emailsmslogs/' + id)
  },
  async getAssetEmail(assetId, assetType) {
    const url = `/emailsmslogs/getassetemail/${assetId}/${assetType}`
    const result = await FqApi.get(url)
    return result.data
  }
}
