/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.NavParams = (function() {

    /**
     * Namespace NavParams.
     * @exports NavParams
     * @namespace
     */
    var NavParams = {};

    NavParams.NavParamsModel = (function() {

        /**
         * Properties of a NavParamsModel.
         * @memberof NavParams
         * @interface INavParamsModel
         * @property {string|null} [returnUrl] NavParamsModel returnUrl
         */

        /**
         * Constructs a new NavParamsModel.
         * @memberof NavParams
         * @classdesc Represents a NavParamsModel.
         * @implements INavParamsModel
         * @constructor
         * @param {NavParams.INavParamsModel=} [p] Properties to set
         */
        function NavParamsModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * NavParamsModel returnUrl.
         * @member {string|null|undefined} returnUrl
         * @memberof NavParams.NavParamsModel
         * @instance
         */
        NavParamsModel.prototype.returnUrl = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * NavParamsModel _returnUrl.
         * @member {"returnUrl"|undefined} _returnUrl
         * @memberof NavParams.NavParamsModel
         * @instance
         */
        Object.defineProperty(NavParamsModel.prototype, "_returnUrl", {
            get: $util.oneOfGetter($oneOfFields = ["returnUrl"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified NavParamsModel message. Does not implicitly {@link NavParams.NavParamsModel.verify|verify} messages.
         * @function encode
         * @memberof NavParams.NavParamsModel
         * @static
         * @param {NavParams.INavParamsModel} m NavParamsModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NavParamsModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.returnUrl != null && Object.hasOwnProperty.call(m, "returnUrl"))
                w.uint32(10).string(m.returnUrl);
            return w;
        };

        /**
         * Encodes the specified NavParamsModel message, length delimited. Does not implicitly {@link NavParams.NavParamsModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof NavParams.NavParamsModel
         * @static
         * @param {NavParams.INavParamsModel} message NavParamsModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NavParamsModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NavParamsModel message from the specified reader or buffer.
         * @function decode
         * @memberof NavParams.NavParamsModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {NavParams.NavParamsModel} NavParamsModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NavParamsModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.NavParams.NavParamsModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.returnUrl = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a NavParamsModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof NavParams.NavParamsModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {NavParams.NavParamsModel} NavParamsModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NavParamsModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return NavParamsModel;
    })();

    return NavParams;
})();

module.exports = $root;
