exports.VendorListView = {
  path: '/vendors',
  name: 'VendorListView'
}
exports.VendorNew = {
  path: '/vendors/new',
  name: 'VendorNew'
}
exports.VendorView = {
  path: '/vendors/:vendorId',
  name: 'VendorView'
}
exports.VendorDetail = {
  path: '/vendors/:vendorId',
  name: 'VendorDetail'
}
exports.VendorPhoneEmail = {
  path: 'phoneemail',
  name: 'VendorPhoneEmail'
}
exports.VendorAddress = {
  path: 'address',
  name: 'VendorAddress'
}
exports.VendorContacts = {
  path: 'contacts',
  name: 'VendorContacts'
}
