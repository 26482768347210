import { mapGetters, mapActions } from 'vuex'

const moduleName = 'agent'

export default {
  computed: {
    ...mapGetters(moduleName, {
      $agentIsActive: 'isActive',
      $agentIsScannerReady: 'isScannerReady'
    })
  },
  methods: {
    ...mapActions(moduleName, {
      $setIsActive: 'setIsActive',
      $setIsScannerReady: 'setIsScannerReady'
    })
  }
}
