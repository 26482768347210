import FqApi from '@/services/fq-api'
//import ProtoBufApi from '@/services/protobuf-api'
//import { GlAccountTypes } from '@/enums'
//import ProtoBuf from 'protobufjs'

export default {
  async getNewEntity() {
    const result = await FqApi.get('/advancereceipts/new')
    return result.data
  },
  async getEntity(id) {
    const result = await FqApi.get(`/advancereceipts/${id}`)
    return result.data
  },
  async getEntityByInvoiceId(id) {
    const result = await FqApi.get(`/advancereceipts/invoiceid/${id}`)
    return result.data
  },
  async postEntity(entity) {
    return FqApi.post('/advancereceipts', entity)
  },
  async getTotalAdvPaymentAmount(id) {
    const result = await FqApi.get(`/advancereceipts/customer?id=${id}`)
    return result.data
  }
}