import ContactComponentListing from '@/views/contact'
import routeTypes from './route-types'

const VendorListView = r => require.ensure([], () => r(require('./VendorListing.vue')), 'vendorlist-chunk')
const VendorNew = r => require.ensure([], () => r(require('./VendorNew.vue')), 'vendor-chunk')
const VendorView = r => require.ensure([], () => r(require('./VendorView.vue')), 'vendor-chunk')
const VendorDetail = r => require.ensure([], () => r(require('./VendorDetail.vue')), 'vendor-chunk')
const VendorAddress = r => require.ensure([], () => r(require('./VendorAddress.vue')), 'vendor-chunk')
const VendorPhoneEmail = r => require.ensure([], () => r(require('./VendorPhoneEmail.vue')), 'vendor-chunk')

const id = 'A067'

export default [
  {
    path: routeTypes.VendorListView.path,
    name: routeTypes.VendorListView.name,
    component: VendorListView,
    meta: {
      id: id,
      route: 'listing',
      title: 'Vendors',
      fkey: 'vendor-filter',
      report: 'rptVendorList',
      reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.VendorNew.path,
    name: routeTypes.VendorNew.name,
    component: VendorNew,
    meta: {
      id: id,
      route: 'detail',
      title: 'New Vendor'
    }
  },
  {
    path: routeTypes.VendorView.path,
    component: VendorView,
    meta: {
      id: id,
      route: 'detail',
      title: 'Vendor'
    },
    children: [
      {
        path: '',
        name: routeTypes.VendorDetail.name,
        component: VendorDetail,
        props: {
          isTiled: true
        },
        meta: {
          title: 'Detail',
          report: 'rptVendorDetails',
          reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
        }
      },
      {
        path: routeTypes.VendorAddress.path,
        name: routeTypes.VendorAddress.name,
        component: VendorAddress,
        props: {
          isTiled: true
        },
        meta: {
          title: 'Addresses'
        }
      },
      {
        path: routeTypes.VendorPhoneEmail.path,
        name: routeTypes.VendorPhoneEmail.name,
        component: VendorPhoneEmail,
        props: {
          isTiled: true
        },
        meta: {
          title: 'Phones / Emails'
        }
      },
      {
        path: routeTypes.VendorContacts.path,
        name: routeTypes.VendorContacts.name,
        component: ContactComponentListing,
        props: {
          isTiled: true
        },
        meta: {
          title: 'Contacts'
        }
      }
    ]
  }
]
