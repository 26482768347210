exports.PnetMessageListView = {
  path: '/pnetmessages',
  name: 'PnetMessageListView'
}
exports.PnetMessageView = {
  path: '/pnetmessages/:messageId',
  name: 'PnetMessageView'
}
exports.PnetMessageNew = {
  path: '/pnetmessages/new/:messageId',
  name: 'PnetMessageNew'
}