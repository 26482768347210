import { ComponentRoute } from '@/classes'

const SundryCreditorRoutes = {
  SundryCreditorListView: new ComponentRoute('/sundrycreditors', 'SundryCreditorListView'),
  SundryCreditorNew: new ComponentRoute('/sundrycreditors/new', 'SundryCreditorNew'),
  SundryCreditorView: new ComponentRoute('/sundrycreditors/:sundryCreditorId', 'SundryCreditorView'),
  SundryCreditorDetail: new ComponentRoute('/sundrycreditors/:sundryCreditorId', 'SundryCreditorDetail'),
  SundryCreditorItems: new ComponentRoute('moregl', 'SundryCreditorItems'),
  SundryCreditorPayments: new ComponentRoute('payments', 'SundryCreditorPayments'),
  SundryCreditorAttachments: new ComponentRoute('attachments', 'SundryCreditorAttachments'),
  SundryCreditorContacts: new ComponentRoute('contacts', 'SundryCreditorContacts')
}

export default SundryCreditorRoutes
