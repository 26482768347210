<template>
  <div class="booking-capacity-calender thin-scrollbar animate__animated animate__fadeIn"
    :class="[
      activeTimePeriod,
      { 'is-full-width' : !isSidePanelOpen, 'loading': !!isLoading,
        'six': numberOfDays === 6, 'seven': numberOfDays === 7,
        'is-flex is-flex-direction-column is-align-items-stretch': isMonthly
      }
    ]
    ">

    <!-- weekly/day mode, show times on the left hand side of calendar -->
    <div v-if="!isMonthly"
      class="calendar-sidebar-times">
      <div class="is-flex is-justify-content-center time-label">
        <span>Time</span>
      </div>
      <div class="calendar-row"
        v-for="(time, index) in weeklySideTimeBar"
        :key="time.hour + '-' + time.minutes + '-' + index"
        :class="{ 'mounted': isComponentMounted}">
        <div class="calendar-time-cell">
          <div>
            <span v-if="time.showTime">{{ time.dateObj.toFormat('h:mma') }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- week day names in monthly mode -->
    <div v-if="isMonthly"
      class="calendar-row box-shadow no-border-bottom"
      style="z-index: 1;"
      :class="{ 'mounted': isComponentMounted }">
      <div v-for="(day, index) in weekDaysArr"
        class="calendar-cell unclickable"
        :class="{'summary': day.className === 'summary-cell'}"
        style="height: 40px"
        :key="index">
        <div class="cell-weekday">
          <span>
            {{ day.name }}
          </span>
        </div>
      </div>
    </div>

    <div v-for="(week, index) in calendarArr"
      :key="index"
      class="calendar-row"
      style="flex-grow: 1;"
      :class="{ 'mounted': isComponentMounted,
                'is-flex-direction-row': isMonthly,
                'is-flex-direction-column': !isMonthly }">

      <!-- if weekly mode add a header for week day name and date -->
      <div v-if="!isMonthly"
        class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center calendar-day-cell"
        :class="{ 'has-text-weight-bold': week.isToday, 'first-cell': index === 0 }">
        <span>{{ week.dateObj.toFormat('ccc') }}</span>
        <span>{{ week.dateObj.toFormat('d') }}</span>
      </div>

      <div v-for="(day, index1) in week.days"
        :draggable="!(isQuoteDragging && !day.isCurrentOrFuture)"
        @dragover="dragOver($event)"
        @dragleave="dragExit($event)"
        @drop="drop($event, day, isMonthly)"
        @click="handleCellClick([day])"
        :key="index-index1"
        :ref="day.date"
        class="calendar-cell"
        :class="{ 'is-today': day.isToday,
                  'disabled-cell': (!day.isThisMonth || !day.isNonWorkingDay || (isQuoteDragging && !day.isCurrentOrFuture)),
                  // selected cell is an array of selected cells, check if only one cell is selected and if it is the same as the current cell
                  'is-active':selectedCell?.length == 1 && selectedCellDateStr == day.dateString && isSidePanelOpen,
                  // 'unclickable': !day.bookings.length,
                  'monthMode': isMonthly
        }">
        <div v-if="isMonthly"
          class="cell-date">
          <div> {{ day.date }}</div>
        </div>

        <!-- only show the booked quote number and total cost when in quote mode -->
        <div class="cell-bookings has-text-weight-bold"
          v-if="showBookingsAndCost && isMonthly">
          <div class="is-flex is-flex-direction-column is-align-items-center ">
            <span class="booking-cell-text-booked">{{ day.booked }}</span>
            <span class="jobs-label is-uppercase">Jobs</span>
          </div>
          <div class="is-flex is-flex-direction-column is-align-items-center booking-cell-booked-cost">
            <span>{{ $filters.formatCurrency(calculateTotalSingleCellBookings(day.bookings).toFixed(0), $userInfo.locale, false, 0) }}</span>
          </div>
        </div>

        <!-- bookings in month mode -->
        <div v-else-if="isMonthly"
          class="is-flex is-justify-content-center is-align-content-center">
          <div v-if="day.bookings.length < 4"
            style="width: 100%;">
            <div v-for="(booking, indexbooking) in day.bookings"
              :key="indexbooking">
              <div class="quote-number-label">
                <span>#{{ booking.jobNumber }}</span>
              </div>
            </div>
          </div>
          <div v-else
            class="is-flex is-justify-content-center is-align-content-center">
            <span class="total-bookings-number">
              {{ day.bookings.length }}
            </span>
          </div>
        </div>

        <!-- bookings in weekly/day mode and there is atleast one booking -->
        <div v-else-if="day.bookings.length === 1">
          <calendar-weekly-bookings :booking="day.bookings[0]"
            :min-time="minTime"
            :max-time="maxTime"
            :index="index1" />
        </div>
      </div>

      <!-- <calendar-stacked-bookings v-if="!isMonthly"
        :bookings="week.days"
        :min-time="minTime"
        :max-time="maxTime" /> -->

      <!-- shows totals in the last cell if showBookingsAndCost mode  -->
      <div v-if="showBookingsAndCost && isMonthly"
        class="calendar-cell monthMode summary"
        :class="{ 'is-selected': isSidePanelOpen && selectedCell?.length > 1 && selectedCellDateStr === week.days[0].dateString + ' - ' + week.days[week.days.length - 1].dateString }"
        @click="handleCellClick(week.days)">
        <div class="cell-bookings">
          <div class="is-flex is-flex-direction-column is-align-items-center mt-5">
            <span class="booking-cell-text-booked-summary">{{ calculateTotals(week.days, 'booked') }}</span>
            <span class="total-jobs-text">Total Jobs</span>
          </div>
          <div class="is-flex is-flex-direction-column is-align-items-center booking-cell-booked-cost">
            <span class="is-size-5 has-text-weight-semibold">{{ $filters.formatCurrency(calculateTotalCosts(week.days, 'totalIncGst').toFixed(0), $userInfo.locale, false, 0) }}</span>
          </div>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import { nextTick } from 'vue'
import CalendarWeeklyBookings from './CalendarWeeklyBookings'
// import CalendarStackedBookings from './CalendarStackedBookings.vue'
export default {
  name: 'CalendarComponent',
  components: {
    CalendarWeeklyBookings
    // , CalendarStackedBookings
  },
  props: {
    calendarArr: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isMonthly: {
      type: Boolean,
      required: true
    },
    showBookingsAndCost: {
      type: Boolean,
      required: false,
      default: true
    },
    weeklySideTimeBar: {
      type: Array,
      required: true
    },
    isQuoteDragging: {
      type: Boolean,
      required: false,
      default: false
    },
    activeTimePeriod: {
      type: String,
      required: true
    },
    isSidePanelOpen: {
      type: Boolean,
      required: true
    },
    numberOfDays: {
      type: Number,
      required: true
    },
    scrollToTimeString: {
      type: String,
      required: false
    },
    minTime: {
      type: Object,
      required: false
    },
    maxTime: {
      type: Object,
      required: false
    },
    hiddenWeekdays: {
      type: Array,
      required: false,
      default: () => []
    },
    selectedCell: {
      type: Array,
      required: false
    },
    selectedCellDateStr: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      ref: null,
      isComponentMounted: true,
      draggableList: [],
      weekDays: [
        { name: 'Sunday', className: '', dayIndex: 7 },
        { name: 'Monday', className: '', dayIndex: 1 },
        { name: 'Tuesday', className: '', dayIndex: 2 },
        { name: 'Wednesday', className: '', dayIndex: 3 },
        { name: 'Thursday', className: '', dayIndex: 4 },
        { name: 'Friday', className: '', dayIndex: 5 },
        { name: 'Saturday', className: '', dayIndex: 6 },
        { name: 'Totals', className: 'summary-cell' }
      ]
    }
  },
  computed: {
    weekDaysArr() {
      return this.weekDays.filter((day) => !this.hiddenWeekdays.includes(day.dayIndex) || !!day.className)
    }
  },
  watch: {
    activeTimePeriod: function (val) {
      nextTick(() => {
        this.scrollToTime()
      })
    }
  },
  mounted() {
    this.scrollToTime()
  },
  methods: {
    dragOver(e) {
      e.target.classList.add('dropzone')
      e.preventDefault()
      return false
    },
    dragExit(e) {
      e.target.classList.remove('dropzone')
    },
    drop(e, day, isMonthly) {
      e.target.classList.remove('dropzone')
      this.$emit('openModal')
    },
    scrollToTime() {
      if (!this.isMonthly) {
        this.$refs[this.scrollToTimeString][0].scrollIntoView({ behavior: 'smooth' })
      }
    },
    handleCellClick(day) {
      this.$emit('handleCellClick', day)
    },
    calculateTotalSingleCellBookings(bookings) {
      return bookings.reduce((a, b) => a + (b.totalIncGst || 0), 0)
    },
    calculateTotals(arr, key) {
      return arr.reduce((a, b) => a + (b[key] || 0), 0)
    },
    calculateTotalCosts(arr, key) {
      let total = 0
      arr.forEach((day) => {
        if (!!day.bookings.length) {
          total += day.bookings.reduce((a, b) => a + (b.totalIncGst || 0), 0)
        }
      })
      return total
    },
    isActive(day) {
      if (!this.selectedCell) {
        return false
      }
      if (this.selectedCell.dateObj === day.dateObj) {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>