/* eslint-disable no-useless-escape */

// https://github.com/richardkundl/shortguid
// Convert GUID string to Base-64 in Javascript
// by Mark Seecof, 2012-03-31
const hexlist = '0123456789abcdef'
const b64list = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

export default {
  newGuid() {
    // return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    //   (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    // )

    var d = new Date().getTime()
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
    return uuid

    // var d = new Date().getTime() //Timestamp
    // var d2 = (performance && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
    // return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    //   var r = Math.random() * 16 //random number between 0 and 16
    //   if (d > 0) {
    //     //Use timestamp until depleted
    //     r = (d + r) % 16 | 0
    //     d = Math.floor(d / 16)
    //   } else {
    //     //Use microseconds since page-load if supported
    //     r = (d2 + r) % 16 | 0
    //     d2 = Math.floor(d2 / 16)
    //   }
    //   return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    // })
  },
  empty() {
    return '00000000-0000-0000-0000-000000000000'
  },
  guidToBase64(g, le) {
    var s = g.replace(/[^0-9a-f]/gi, '').toLowerCase()
    if (s.length !== 32) return ''
    if (le) {
      s = s.slice(6, 8) + s.slice(4, 6) + s.slice(2, 4) + s.slice(0, 2) + s.slice(10, 12) + s.slice(8, 10) + s.slice(14, 16) + s.slice(12, 14) + s.slice(16)
    }
    s += '0'

    var a, p, q
    var r = ''
    var i = 0
    while (i < 33) {
      a = (hexlist.indexOf(s.charAt(i++)) << 8) | (hexlist.indexOf(s.charAt(i++)) << 4) | hexlist.indexOf(s.charAt(i++))

      p = a >> 6
      q = a & 63
      r += b64list.charAt(p) + b64list.charAt(q)
    }
    r += '=='

    return r
  },
  base64ToGuid(base64, le) {
    var hex = ''
    for (var i = 0; i < 24; ) {
      var e1 = b64list.indexOf(base64[i++])
      var e2 = b64list.indexOf(base64[i++])
      var e3 = b64list.indexOf(base64[i++])
      var e4 = b64list.indexOf(base64[i++])
      var c1 = (e1 << 2) | (e2 >> 4)
      var c2 = ((e2 & 15) << 4) | (e3 >> 2)
      var c3 = ((e3 & 3) << 6) | e4
      hex += hexlist[c1 >> 4]
      hex += hexlist[c1 & 15]
      if (e3 !== 64) {
        hex += hexlist[c2 >> 4]
        hex += hexlist[c2 & 15]
      }
      if (e4 !== 64) {
        hex += hexlist[c3 >> 4]
        hex += hexlist[c3 & 15]
      }
    }
    var a = hex.substr(6, 2) + hex.substr(4, 2) + hex.substr(2, 2) + hex.substr(0, 2)
    var b = hex.substr(10, 2) + hex.substr(8, 2)
    var c = hex.substr(14, 2) + hex.substr(12, 2)
    var d = hex.substr(16, 16)
    hex = a + b + c + d
    var uuid = hex.substr(0, 8) + '-' + hex.substr(8, 4) + '-' + hex.substr(12, 4) + '-' + hex.substr(16, 4) + '-' + hex.substr(20, 12)
    return uuid
  },
  createShortGuid(guid) {
    var encoded = this.guidToBase64(guid, true)
    encoded = encoded.replace(/\//g, '_').replace(/\+/g, '-')
    return encoded.substring(0, 22)
  },
  createGuid(shortGuid) {
    var encoded = shortGuid.replace(/\_/g, '/').replace(/\-/g, '+')
    encoded = encoded + '=='
    var decoded = this.base64ToGuid(encoded, true)
    console.log(decoded)
    return decoded
  },
  guidToBytes(guid) {
    var bytes = []
    guid.split('-').map((number, index) => {
      var bytesInChar = index < 3 ? number.match(/.{1,2}/g).reverse() : number.match(/.{1,2}/g)
      bytesInChar.map(byte => {
        bytes.push(parseInt(byte, 16))
      })
    })
    return bytes
  },
  bytesToGuid(b) {
    // @ Made by Zura Dalakishvili, Tbilisi, Georgia 2016
    // Guid representation created from  byte[] array for Javascript
    // Usage:
    //  var inst = new Guid(array)
    //   var guidStr = inst.guidString
    var _a
    var _b
    var _c
    var _d
    var _e
    var _f
    var _g
    var _h
    var _i
    var _j
    var _k

    if (b == null) {
      return
    }
    if (b.length !== 16) {
      return
    }

    _a = (b[3] << 24) | (b[2] << 16) | (b[1] << 8) | b[0]
    _b = (b[5] << 8) | b[4]
    _c = (b[7] << 8) | b[6]
    _d = b[8]
    _e = b[9]
    _f = b[10]
    _g = b[11]
    _h = b[12]
    _i = b[13]
    _j = b[14]
    _k = b[15]

    function HexToChar(a) {
      a = a & 0xf
      return String.fromCharCode(a > 9 ? a - 10 + 0x61 : a + 0x30)
    }

    function HexsToChars(guidChars, offset, a, b) {
      return HexsToCharsEx(guidChars, offset, a, b, false)
    }

    function HexsToCharsEx(guidChars, offset, a, b, hex) {
      if (hex) {
        guidChars[offset++] = '0'
        guidChars[offset++] = 'x'
      }
      guidChars[offset++] = HexToChar(a >> 4)
      guidChars[offset++] = HexToChar(a)
      if (hex) {
        guidChars[offset++] = ','
        guidChars[offset++] = '0'
        guidChars[offset++] = 'x'
      }
      guidChars[offset++] = HexToChar(b >> 4)
      guidChars[offset++] = HexToChar(b)
      return offset
    }
    this.b = b
    // _toGUID(this.b)
    var _toString = function(format, provider) {
      if (format == null || format.length === 0) {
        format = 'D'
      }

      var guidChars = [] // new Array()
      var offset = 0
      var dash = true
      var hex = false

      if (format.length !== 1) {
        // all acceptable format strings are of length 1
        return null
      }

      // var strLength = 38
      var formatCh = format[0]

      if (formatCh === 'D' || formatCh === 'd') {
        guidChars = new Array(36)
        // strLength = 36
      } else if (formatCh === 'N' || formatCh === 'n') {
        guidChars = new Array(32)
        // strLength = 32
        dash = false
      } else if (formatCh === 'B' || formatCh === 'b') {
        guidChars = new Array(38)
        guidChars[offset++] = '{'
        guidChars[37] = '}'
      } else if (formatCh === 'P' || formatCh === 'p') {
        guidChars = new Array(38)
        guidChars[offset++] = '('
        guidChars[37] = ')'
      } else if (formatCh === 'X' || formatCh === 'x') {
        guidChars = new Array(68)
        guidChars[offset++] = '{'
        guidChars[67] = '}'
        // strLength = 68
        dash = false
        hex = true
      } else {
        return null
      }

      if (hex) {
        // {0xdddddddd,0xdddd,0xdddd,{0xdd,0xdd,0xdd,0xdd,0xdd,0xdd,0xdd,0xdd}}
        guidChars[offset++] = '0'
        guidChars[offset++] = 'x'
        offset = HexsToChars(guidChars, offset, _a >> 24, _a >> 16)
        offset = HexsToChars(guidChars, offset, _a >> 8, _a)
        guidChars[offset++] = ','
        guidChars[offset++] = '0'
        guidChars[offset++] = 'x'
        offset = HexsToChars(guidChars, offset, _b >> 8, _b)
        guidChars[offset++] = ','
        guidChars[offset++] = '0'
        guidChars[offset++] = 'x'
        offset = HexsToChars(guidChars, offset, _c >> 8, _c)
        guidChars[offset++] = ','
        guidChars[offset++] = '{'
        offset = HexsToChars(guidChars, offset, _d, _e, true)
        guidChars[offset++] = ','
        offset = HexsToChars(guidChars, offset, _f, _g, true)
        guidChars[offset++] = ','
        offset = HexsToChars(guidChars, offset, _h, _i, true)
        guidChars[offset++] = ','
        offset = HexsToChars(guidChars, offset, _j, _k, true)
        guidChars[offset++] = '}'
      } else {
        // [{|(]dddddddd[-]dddd[-]dddd[-]dddd[-]dddddddddddd[}|)]
        offset = HexsToChars(guidChars, offset, _a >> 24, _a >> 16)
        offset = HexsToChars(guidChars, offset, _a >> 8, _a)
        if (dash) guidChars[offset++] = '-'
        offset = HexsToChars(guidChars, offset, _b >> 8, _b)
        if (dash) guidChars[offset++] = '-'
        offset = HexsToChars(guidChars, offset, _c >> 8, _c)
        if (dash) guidChars[offset++] = '-'
        offset = HexsToChars(guidChars, offset, _d, _e)
        if (dash) guidChars[offset++] = '-'
        offset = HexsToChars(guidChars, offset, _f, _g)
        offset = HexsToChars(guidChars, offset, _h, _i)
        offset = HexsToChars(guidChars, offset, _j, _k)
      }
      // return String(guidChars)
      return guidChars
    }

    this.guidString = function() {
      return _toString('D', null)
    }
    return this.guidString()
      .join('')
      .toUpperCase()
  },
  validGuid(value) {
    // https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
    return (
      value !== undefined &&
      value &&
      value !== this.empty() &&
      value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i) !== null
    )
  }
}
