// export default {
//   0: 'Work',
//   1: 'Home',
//   2: 'Mobile',
//   3: 'Fax'
// }

export default {
  Work: 0,
  Home: 1,
  Mobile: 2,
  Fax: 3
}
