import LabourTypesService from './LabourTypesService.js'

// export const STORE_USERINFO = 'UPDATE_USERINFO'

const state = {
  data: null
}

const getters = {
  labourTypes: state => state.data
}

const actions = {
  async getLabourTypes({ commit, state }) {
    const types = await LabourTypesService.getLabourTypes()
    commit('storeLabourTypes', types)
    // LabourTypesService.getLabourTypes().then(res => {
    //   commit('storeLabourTypes', res)
    // })
  }
}

const mutations = {
  storeLabourTypes(state, data) {
    state.data = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
