export default {
  QuoteRequestActive: 10,
  QuoteRequestResponded: 20,
  QuoteRequestWin: 30,
  QuoteRequestLoss: 40,
  QuoteRequestTotalLoss: 50,
  QuoteRequestCancelled: 60,
  Quotenotfullysubmitted: 70,
  QuoteSubmitted: 80,
  QuoteDeleted: 90,
  QuoteAuthorised: 100,
  AuthorisedQuoteDeleted: 110,
  QuoteInvoiceSubmitted: 120,
  SupplementaryActive: 130,
  SupplementarySubmitted: 140,
  SupplementaryAuthorised: 150,
  SupplementaryRejected: 160,
  SupplementaryDeleted: 170
}
