/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.PnetFilter = (function() {

    /**
     * Namespace PnetFilter.
     * @exports PnetFilter
     * @namespace
     */
    var PnetFilter = {};

    PnetFilter.PnetFilterModel = (function() {

        /**
         * Properties of a PnetFilterModel.
         * @memberof PnetFilter
         * @interface IPnetFilterModel
         * @property {string|null} [rego] PnetFilterModel rego
         * @property {string|null} [quoteNo] PnetFilterModel quoteNo
         * @property {string|null} [claimNo] PnetFilterModel claimNo
         * @property {number|null} [messageTypeId] PnetFilterModel messageTypeId
         * @property {number|null} [messageStatusId] PnetFilterModel messageStatusId
         * @property {string|null} [sortColumn] PnetFilterModel sortColumn
         * @property {string|null} [sortOrder] PnetFilterModel sortOrder
         * @property {number|null} [pageIndex] PnetFilterModel pageIndex
         * @property {number|null} [pageSize] PnetFilterModel pageSize
         */

        /**
         * Constructs a new PnetFilterModel.
         * @memberof PnetFilter
         * @classdesc Represents a PnetFilterModel.
         * @implements IPnetFilterModel
         * @constructor
         * @param {PnetFilter.IPnetFilterModel=} [p] Properties to set
         */
        function PnetFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PnetFilterModel rego.
         * @member {string|null|undefined} rego
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        PnetFilterModel.prototype.rego = null;

        /**
         * PnetFilterModel quoteNo.
         * @member {string|null|undefined} quoteNo
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        PnetFilterModel.prototype.quoteNo = null;

        /**
         * PnetFilterModel claimNo.
         * @member {string|null|undefined} claimNo
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        PnetFilterModel.prototype.claimNo = null;

        /**
         * PnetFilterModel messageTypeId.
         * @member {number|null|undefined} messageTypeId
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        PnetFilterModel.prototype.messageTypeId = null;

        /**
         * PnetFilterModel messageStatusId.
         * @member {number|null|undefined} messageStatusId
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        PnetFilterModel.prototype.messageStatusId = null;

        /**
         * PnetFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        PnetFilterModel.prototype.sortColumn = null;

        /**
         * PnetFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        PnetFilterModel.prototype.sortOrder = null;

        /**
         * PnetFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        PnetFilterModel.prototype.pageIndex = null;

        /**
         * PnetFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        PnetFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * PnetFilterModel _rego.
         * @member {"rego"|undefined} _rego
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        Object.defineProperty(PnetFilterModel.prototype, "_rego", {
            get: $util.oneOfGetter($oneOfFields = ["rego"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PnetFilterModel _quoteNo.
         * @member {"quoteNo"|undefined} _quoteNo
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        Object.defineProperty(PnetFilterModel.prototype, "_quoteNo", {
            get: $util.oneOfGetter($oneOfFields = ["quoteNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PnetFilterModel _claimNo.
         * @member {"claimNo"|undefined} _claimNo
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        Object.defineProperty(PnetFilterModel.prototype, "_claimNo", {
            get: $util.oneOfGetter($oneOfFields = ["claimNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PnetFilterModel _messageTypeId.
         * @member {"messageTypeId"|undefined} _messageTypeId
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        Object.defineProperty(PnetFilterModel.prototype, "_messageTypeId", {
            get: $util.oneOfGetter($oneOfFields = ["messageTypeId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PnetFilterModel _messageStatusId.
         * @member {"messageStatusId"|undefined} _messageStatusId
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        Object.defineProperty(PnetFilterModel.prototype, "_messageStatusId", {
            get: $util.oneOfGetter($oneOfFields = ["messageStatusId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PnetFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        Object.defineProperty(PnetFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PnetFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        Object.defineProperty(PnetFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PnetFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        Object.defineProperty(PnetFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PnetFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof PnetFilter.PnetFilterModel
         * @instance
         */
        Object.defineProperty(PnetFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified PnetFilterModel message. Does not implicitly {@link PnetFilter.PnetFilterModel.verify|verify} messages.
         * @function encode
         * @memberof PnetFilter.PnetFilterModel
         * @static
         * @param {PnetFilter.IPnetFilterModel} m PnetFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PnetFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.rego != null && Object.hasOwnProperty.call(m, "rego"))
                w.uint32(10).string(m.rego);
            if (m.quoteNo != null && Object.hasOwnProperty.call(m, "quoteNo"))
                w.uint32(18).string(m.quoteNo);
            if (m.claimNo != null && Object.hasOwnProperty.call(m, "claimNo"))
                w.uint32(26).string(m.claimNo);
            if (m.messageTypeId != null && Object.hasOwnProperty.call(m, "messageTypeId"))
                w.uint32(32).int32(m.messageTypeId);
            if (m.messageStatusId != null && Object.hasOwnProperty.call(m, "messageStatusId"))
                w.uint32(40).int32(m.messageStatusId);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(50).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(58).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(64).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(72).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified PnetFilterModel message, length delimited. Does not implicitly {@link PnetFilter.PnetFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof PnetFilter.PnetFilterModel
         * @static
         * @param {PnetFilter.IPnetFilterModel} message PnetFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PnetFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PnetFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof PnetFilter.PnetFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {PnetFilter.PnetFilterModel} PnetFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PnetFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.PnetFilter.PnetFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.rego = r.string();
                    break;
                case 2:
                    m.quoteNo = r.string();
                    break;
                case 3:
                    m.claimNo = r.string();
                    break;
                case 4:
                    m.messageTypeId = r.int32();
                    break;
                case 5:
                    m.messageStatusId = r.int32();
                    break;
                case 6:
                    m.sortColumn = r.string();
                    break;
                case 7:
                    m.sortOrder = r.string();
                    break;
                case 8:
                    m.pageIndex = r.int32();
                    break;
                case 9:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a PnetFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof PnetFilter.PnetFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {PnetFilter.PnetFilterModel} PnetFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PnetFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return PnetFilterModel;
    })();

    return PnetFilter;
})();

module.exports = $root;
