import routerTypes from './route-types'

// Lazy-load components. Webpack will split each into individual file
const DebtorReceiptListView = r => require.ensure([], () => r(require('./DebtorReceiptListView.vue')), 'debtorreceipt-chunk')

export default [
  {
    path: routerTypes.DebtorReceiptListView.path,
    name: routerTypes.DebtorReceiptListView.name,
    component: DebtorReceiptListView,
    meta: {
      id: 'A029',
      route: 'listing',
      title: 'Debtor Receipt List',
      fkey: 'debtorreceipt|filter',
      report: 'rptReceiptListV2',
      reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
    }
  }
]
