export default {
  RR: 'RR',
  REP: 'REP',
  RWA: 'RWA',
  PAINT: 'PAINT',
  OPG: 'OPG',
  MECH: 'MECH',
  CD: 'CD',
  CRUSH: 'CRUSH',
  FIBER: 'FIBER',
  PART: 'PART',
  MISC: 'MISC',
  SUBL: 'SUBL',
  PDRRR: 'PDRRR',
  PDRRE: 'PDRRE'
}
