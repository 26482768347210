/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.ReturnPartsFilter = (function() {

    /**
     * Namespace ReturnPartsFilter.
     * @exports ReturnPartsFilter
     * @namespace
     */
    var ReturnPartsFilter = {};

    ReturnPartsFilter.ReturnPartsFilterModel = (function() {

        /**
         * Properties of a ReturnPartsFilterModel.
         * @memberof ReturnPartsFilter
         * @interface IReturnPartsFilterModel
         * @property {string|null} [searchValue] ReturnPartsFilterModel searchValue
         * @property {string|null} [dateFrom] ReturnPartsFilterModel dateFrom
         * @property {string|null} [dateTo] ReturnPartsFilterModel dateTo
         * @property {string|null} [sortColumn] ReturnPartsFilterModel sortColumn
         * @property {string|null} [sortOrder] ReturnPartsFilterModel sortOrder
         * @property {number|null} [pageIndex] ReturnPartsFilterModel pageIndex
         * @property {number|null} [pageSize] ReturnPartsFilterModel pageSize
         */

        /**
         * Constructs a new ReturnPartsFilterModel.
         * @memberof ReturnPartsFilter
         * @classdesc Represents a ReturnPartsFilterModel.
         * @implements IReturnPartsFilterModel
         * @constructor
         * @param {ReturnPartsFilter.IReturnPartsFilterModel=} [p] Properties to set
         */
        function ReturnPartsFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReturnPartsFilterModel searchValue.
         * @member {string|null|undefined} searchValue
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @instance
         */
        ReturnPartsFilterModel.prototype.searchValue = null;

        /**
         * ReturnPartsFilterModel dateFrom.
         * @member {string|null|undefined} dateFrom
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @instance
         */
        ReturnPartsFilterModel.prototype.dateFrom = null;

        /**
         * ReturnPartsFilterModel dateTo.
         * @member {string|null|undefined} dateTo
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @instance
         */
        ReturnPartsFilterModel.prototype.dateTo = null;

        /**
         * ReturnPartsFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @instance
         */
        ReturnPartsFilterModel.prototype.sortColumn = null;

        /**
         * ReturnPartsFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @instance
         */
        ReturnPartsFilterModel.prototype.sortOrder = null;

        /**
         * ReturnPartsFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @instance
         */
        ReturnPartsFilterModel.prototype.pageIndex = null;

        /**
         * ReturnPartsFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @instance
         */
        ReturnPartsFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ReturnPartsFilterModel _searchValue.
         * @member {"searchValue"|undefined} _searchValue
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsFilterModel.prototype, "_searchValue", {
            get: $util.oneOfGetter($oneOfFields = ["searchValue"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReturnPartsFilterModel _dateFrom.
         * @member {"dateFrom"|undefined} _dateFrom
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsFilterModel.prototype, "_dateFrom", {
            get: $util.oneOfGetter($oneOfFields = ["dateFrom"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReturnPartsFilterModel _dateTo.
         * @member {"dateTo"|undefined} _dateTo
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsFilterModel.prototype, "_dateTo", {
            get: $util.oneOfGetter($oneOfFields = ["dateTo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReturnPartsFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReturnPartsFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReturnPartsFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReturnPartsFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified ReturnPartsFilterModel message. Does not implicitly {@link ReturnPartsFilter.ReturnPartsFilterModel.verify|verify} messages.
         * @function encode
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @static
         * @param {ReturnPartsFilter.IReturnPartsFilterModel} m ReturnPartsFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReturnPartsFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.searchValue != null && Object.hasOwnProperty.call(m, "searchValue"))
                w.uint32(10).string(m.searchValue);
            if (m.dateFrom != null && Object.hasOwnProperty.call(m, "dateFrom"))
                w.uint32(18).string(m.dateFrom);
            if (m.dateTo != null && Object.hasOwnProperty.call(m, "dateTo"))
                w.uint32(26).string(m.dateTo);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(34).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(42).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(48).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(56).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified ReturnPartsFilterModel message, length delimited. Does not implicitly {@link ReturnPartsFilter.ReturnPartsFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @static
         * @param {ReturnPartsFilter.IReturnPartsFilterModel} message ReturnPartsFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReturnPartsFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReturnPartsFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ReturnPartsFilter.ReturnPartsFilterModel} ReturnPartsFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReturnPartsFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ReturnPartsFilter.ReturnPartsFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.searchValue = r.string();
                    break;
                case 2:
                    m.dateFrom = r.string();
                    break;
                case 3:
                    m.dateTo = r.string();
                    break;
                case 4:
                    m.sortColumn = r.string();
                    break;
                case 5:
                    m.sortOrder = r.string();
                    break;
                case 6:
                    m.pageIndex = r.int32();
                    break;
                case 7:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a ReturnPartsFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ReturnPartsFilter.ReturnPartsFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ReturnPartsFilter.ReturnPartsFilterModel} ReturnPartsFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReturnPartsFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return ReturnPartsFilterModel;
    })();

    return ReturnPartsFilter;
})();

module.exports = $root;
