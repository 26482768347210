export default {

  bind(el, binding) {

    el.addEventListener('keydown', function (e) {
      if (e.key === 'Tab') {
        e.preventDefault()
        // get parent with class quote-builder-item-row
        const parent = el.closest('.quote-builder-item-row')
        // get all focusable elements inside the parent exclude class .skip-tab
        const focusableElements = Array.from(parent.querySelectorAll('input, textarea, select'))
        .filter((el) => !el.disabled && !el.hidden)
        const currentElementIndex = focusableElements.indexOf(e.target)
        let nextElement = focusableElements[currentElementIndex + 1]
        // if the next element has a class of skip-tab, then find the next element that doesn't have the class
        while (nextElement && nextElement.classList.contains('skip-tab')) {
          nextElement = focusableElements[focusableElements.indexOf(nextElement) + 1]
        }
        if (nextElement) nextElement.focus()
          // if there is no next element, then emit the event to focus the next row
         else binding.value()

      }
    })
  }
}