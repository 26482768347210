// import Guid from '@/components/Guid'

export default class CallLogModel {
  constructor(companyId = '', userId = '', username = '', tier = 1, rego = '', stateCode = '', callLocation = '', ipAddress = '') {
    this.companyId = companyId
    this.userId = userId
    this.username = username
    this.callLocation = callLocation
    this.ipAddress = ipAddress
    const utcDate = new Date().toISOString()
    this.callTimestamp = utcDate.split('.')[0] + 'Z'
    this.tier = tier
    this.rego = rego
    this.stateCode = stateCode
    this.vehicleData = ''
  }
}
