/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.QuickItemFilter = (function() {

    /**
     * Namespace QuickItemFilter.
     * @exports QuickItemFilter
     * @namespace
     */
    var QuickItemFilter = {};

    QuickItemFilter.QuickItemFilterModel = (function() {

        /**
         * Properties of a QuickItemFilterModel.
         * @memberof QuickItemFilter
         * @interface IQuickItemFilterModel
         * @property {string|null} [query] QuickItemFilterModel query
         * @property {number|null} [active] QuickItemFilterModel active
         * @property {string|null} [sortColumn] QuickItemFilterModel sortColumn
         * @property {string|null} [sortOrder] QuickItemFilterModel sortOrder
         * @property {number|null} [pageIndex] QuickItemFilterModel pageIndex
         * @property {number|null} [pageSize] QuickItemFilterModel pageSize
         */

        /**
         * Constructs a new QuickItemFilterModel.
         * @memberof QuickItemFilter
         * @classdesc Represents a QuickItemFilterModel.
         * @implements IQuickItemFilterModel
         * @constructor
         * @param {QuickItemFilter.IQuickItemFilterModel=} [p] Properties to set
         */
        function QuickItemFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QuickItemFilterModel query.
         * @member {string|null|undefined} query
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @instance
         */
        QuickItemFilterModel.prototype.query = null;

        /**
         * QuickItemFilterModel active.
         * @member {number|null|undefined} active
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @instance
         */
        QuickItemFilterModel.prototype.active = null;

        /**
         * QuickItemFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @instance
         */
        QuickItemFilterModel.prototype.sortColumn = null;

        /**
         * QuickItemFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @instance
         */
        QuickItemFilterModel.prototype.sortOrder = null;

        /**
         * QuickItemFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @instance
         */
        QuickItemFilterModel.prototype.pageIndex = null;

        /**
         * QuickItemFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @instance
         */
        QuickItemFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * QuickItemFilterModel _query.
         * @member {"query"|undefined} _query
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @instance
         */
        Object.defineProperty(QuickItemFilterModel.prototype, "_query", {
            get: $util.oneOfGetter($oneOfFields = ["query"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickItemFilterModel _active.
         * @member {"active"|undefined} _active
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @instance
         */
        Object.defineProperty(QuickItemFilterModel.prototype, "_active", {
            get: $util.oneOfGetter($oneOfFields = ["active"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickItemFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @instance
         */
        Object.defineProperty(QuickItemFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickItemFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @instance
         */
        Object.defineProperty(QuickItemFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickItemFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @instance
         */
        Object.defineProperty(QuickItemFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickItemFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @instance
         */
        Object.defineProperty(QuickItemFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified QuickItemFilterModel message. Does not implicitly {@link QuickItemFilter.QuickItemFilterModel.verify|verify} messages.
         * @function encode
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @static
         * @param {QuickItemFilter.IQuickItemFilterModel} m QuickItemFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuickItemFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.query != null && Object.hasOwnProperty.call(m, "query"))
                w.uint32(10).string(m.query);
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(16).int32(m.active);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(26).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(34).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(40).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(48).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified QuickItemFilterModel message, length delimited. Does not implicitly {@link QuickItemFilter.QuickItemFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @static
         * @param {QuickItemFilter.IQuickItemFilterModel} message QuickItemFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuickItemFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QuickItemFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {QuickItemFilter.QuickItemFilterModel} QuickItemFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuickItemFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.QuickItemFilter.QuickItemFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.query = r.string();
                    break;
                case 2:
                    m.active = r.int32();
                    break;
                case 3:
                    m.sortColumn = r.string();
                    break;
                case 4:
                    m.sortOrder = r.string();
                    break;
                case 5:
                    m.pageIndex = r.int32();
                    break;
                case 6:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a QuickItemFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof QuickItemFilter.QuickItemFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {QuickItemFilter.QuickItemFilterModel} QuickItemFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuickItemFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return QuickItemFilterModel;
    })();

    return QuickItemFilter;
})();

module.exports = $root;
