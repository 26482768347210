<template>
  <div class="tile is-child box has-sticky-tile"
    style="margin-bottom: 1rem !important;">
    <div class="is-flex is-align-items-center">
      <div class="toggle-buttons-vertical ml-2 is-flex is-flex-direction-column">
        <span class="button m-0"
          style="border-radius: 5px 5px 0px 0px;"
          :class="{ 'is-primary is-selected' : selectedJcni === 0}"
          @click="changeActive(0)"
          v-tippy="{ enabled: true, placement: 'right-start', arrow: false, allowHTML: true, theme: 'info' }"
          :content="tooltipContent0">
          <span>Jobs Completed Not Invoiced</span>
        </span>
        <span class="button m-0"
          style="border-radius: 0px 0px 5px 5px;"
          :class="{ 'is-primary is-selected' : selectedJcni === 2}"
          @click="changeActive(2)"
          v-tippy="{ enabled: true, placement: 'right-start', arrow: false, allowHTML: true, theme: 'info' }"
          :content="tooltipContent2">
          <span>Past Due Date Audit</span>
        </span>
      </div>

      <div class="is-flex is-flex-direction-row is-justify-content-space-evenly"
        style="width: 100%;">
        <div class="is-flex  is-flex-direction-column is-align-items-center">
          <span class="header-text-divider">Total Jobs</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ data.length }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>

        <div class="is-flex  is-flex-direction-column is-align-items-center">
          <span class="header-text-divider">Job Total
            <span v-if="includeGst">
              Inc GST
            </span>
          </span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ totalIncGst }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>

        <div class="is-flex  is-flex-direction-column is-align-items-center">
          <span class="header-text-divider">Debtor Invoice
            <span v-if="includeGst">
              Inc GST
            </span>
          </span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ debtorInvoice }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>

        <div class="is-flex  is-flex-direction-column is-align-items-center">
          <span class="header-text-divider">Excess Invoice
            <span v-if="includeGst">
              Inc GST
            </span>
          </span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ totalExcess }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>

        <div class="is-flex  is-flex-direction-column is-align-items-center">
          <span class="header-text-divider">Total Not Invoiced
            <span v-if="includeGst">
              Inc GST
            </span>
          </span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ totalNotInvoiced }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>

      </div>
    </div>
    <div ref="jcni0"
      class="is-hidden">
      <ul style="list-style-type: disc; padding-left: 1rem;">
        <li>Quotes that are in Car Ready, Invoice Pending and Closed job stage</li>
        <li>Have an outstanding not invoiced amount</li>
      </ul>
    </div>
    <div ref="jcni1"
      class="is-hidden">
      <ul style="list-style-type: disc; padding-left: 1rem;">
        <li>Quotes that are in Car Ready, Invoice Pending and Closed job stage</li>
        <li>Are 7 days or more past the Job End date</li>
        <li>Have an outstanding not invoiced amount</li>
      </ul>
    </div>
    <div ref="jcni2"
      class="is-hidden">
      <ul style="list-style-type: disc; padding-left: 1rem;">
        <li>Quotes that are past the Job End date</li>
        <li>Have an outstanding not invoiced amount</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JcniMenuBar',
  props: {
    selectedJcni: {
      type: Number,
      value: 1
    },
    isLoading: {
      type: Boolean,
      value: false
    },
    data: {
      type: Array,
      value: []
    },
    includeGst: {
      type: Boolean,
      value: false
    }
  },
  data() {
    return {
      tooltipContent0: '',
      tooltipContent1: '',
      tooltipContent2: ''
    }
  },
  computed: {
    totalIncGst() {
      const total = this.data.reduce((acc, item) => {
        return acc + item.jobTotal
      }, 0)
      return this.$filters.formatCurrency(total, this.$userInfo.locale)
    },
    totalExcess() {
      const total = this.data.reduce((acc, item) => {
        return acc + item.excessInvoice
      }, 0)
      return this.$filters.formatCurrency(total, this.$userInfo.locale)
    },
    debtorInvoice() {
      const total = this.data.reduce((acc, item) => {
        return acc + item.debtorInvoice
      }, 0)
      return this.$filters.formatCurrency(total, this.$userInfo.locale)
    },
    totalNotInvoiced() {
      const total = this.data.reduce((acc, item) => {
        return acc + item.totalNotInvoiced
      }, 0)
      return this.$filters.formatCurrency(total, this.$userInfo.locale)
    }
  },
  created() {},
  mounted() {
    this.tooltipContent0 = this.getTooltipContent(0)
    this.tooltipContent1 = this.getTooltipContent(1)
    this.tooltipContent2 = this.getTooltipContent(2)
  },
  methods: {
    changeActive(index) {
      this.$emit('change', index)
    },
    getTooltipContent(index) {
      return this.$refs[`jcni${index}`].innerHTML
    }
  }
}
</script>

<style lang="scss" scoped>
.spins {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.tile.is-child.has-sticky-tile {
  position: sticky;
  top: 3.25rem;
  z-index: 31;
}

.header-text-divider {
  border-bottom: 1px solid lightgray;
}
</style>