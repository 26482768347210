<template>
  <div>
    <div ref="trigger">
      <slot name="trigger" />
    </div>
    <div ref="content">
      <slot />
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import humps from 'humps'
import defaultProps, { booleanProps } from './props'

export default {
  props: {
    to: null,
    toSelector: '',
    toElement: null,
    content: '',
    enabled: true,
    visible: true,
    triggerTarget: null
  },
  data() {
    return {
      tip: null,
      options: {}
    }
  },
  computed: {
    isManualTrigger() {
      return this.options.trigger === 'manual'
    }
  },
  watch: {
    content() {
      if (this.tip) {
        this.tip.setProps(this.getOptions())
      }
    },
    enabled(val) {
      if (!this.tip) return
      if (val) {
        this.tip.enable()
      } else {
        this.tip.hide()
        this.tip.disable()
      }
    },
    visible(val) {
      if (!this.tip) return
      if (val) {
        this.tip.show()
      } else {
        this.tip.hide()
      }
    }
  },
  mounted() {
    this.init()
  },
  updated() {
    if (this.tip && !this.content) {
      this.tip.setProps(this.getOptions())
    }
  },
  beforeDestroy() {
    if (!this.tip) return
    this.tip.destroy()
  },
  methods: {
    init() {
      if (this.tip) {
        try {
          this.tip.destroy()
        } catch (error) {}
        this.tip = null
      }
      let elm = this.toElement
      if (elm == null) {
        if (this.to) {
          elm = document.querySelector(`[name='${this.to}']`)
        } else if (this.toSelector) {
          elm = document.querySelector(this.toSelector)
        } else if (this.$refs.trigger && this.$refs.trigger.childElementCount > 0) {
          elm = this.$refs.trigger
        } else {
          elm = this.$el.parentElement
        }
      }
      if (!elm) {
        return
      }
      const tip = tippy(elm, this.getOptions())
      if (!tip) {
        return
      }
      if (Array.isArray(tip)) {
        if (tip.length > 0) {
          this.tip = tip[0]
        } else {
          return
        }
      }
      this.tip = tip
      this.$emit('onCreate', this.tip)
      this.$emit('init', this.tip)
      if (this.enabled === false) {
        this.tip.disable()
      }
      if (this.isManualTrigger && this.visible === true) {
        this.tip.show()
      }
    },
    tippy() {
      return this.tip
    },
    filterOptions() {
      const getValue = (key, value) => {
        if (Object.prototype.hasOwnProperty.call(booleanProps, key)) {
          if (value === '') return true
          if (value === 'false') return false
          if (value === 'true') return true
        }
        return value
      }
      for (const key of Object.keys(this.options)) {
        if (!Object.prototype.hasOwnProperty.call(defaultProps, key)) {
          // We're replacing this.options anyway, we don't have to worry about modifying the object
          delete this.options[key]
        }
        this.options[key] = getValue(key, this.options[key])
      }
      return this.options
    },
    getOptions() {
      Object.assign(this.options, humps.camelizeKeys(this.$attrs))
      this.filterOptions()
      if (!this.options.onShow && this.$listeners && this.$listeners['show-tippy']) {
        this.options.onShow = (...args) => {
          return this.$listeners['show-tippy'].fns(...args)
        }
      }
      if (!this.options.onShown) {
        this.options.onShown = (...args) => {
          this.$emit('shown-tippy', ...args)
        }
      }
      if (!this.options.onHidden) {
        this.options.onHidden = (...args) => {
          this.$emit('hidden-tippy', ...args)
        }
      }
      if (!this.options.onHide && this.$listeners && this.$listeners['hide-tippy']) {
        this.options.onHide = (...args) => {
          return this.$listeners['hide-tippy'].fns(...args)
        }
      }
      if (!this.options.onMount) {
        this.options.onMount = (...args) => {
          this.$emit('mount-tippy', ...args)
        }
      }
      if (!Object.prototype.hasOwnProperty.call(this.options, 'content')) {
        this.options.content = this.content ? this.content : this.$refs.content
      }
      this.options.triggerTarget = this.triggerTarget
      return this.options
    }
  }
}
</script>
