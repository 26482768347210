import routeTypes from './route-types'

const ContactDetail = r => require.ensure([], () => r(require('./ContactDetail.vue')), 'contact-chunk')
const ContactAsset = r => require.ensure([], () => r(require('./ContactAsset.vue')), 'contact-chunk')
const ContactAddress = r => require.ensure([], () => r(require('./ContactAddress.vue')), 'contact-chunk')
const ContactPhoneEmail = r => require.ensure([], () => r(require('./ContactPhoneEmail.vue')), 'contact-chunk')
const ContactRemark = r => require.ensure([], () => r(require('./ContactRemark.vue')), 'contact-chunk')

export default [
  {
    path: routeTypes.ContactDetail.path,
    name: routeTypes.ContactDetail.name,
    component: ContactDetail,
    props: { isTiled: true },
    meta: {
      title: 'Detail',
      isContactChild: true,
      report: 'rptTableContactProfileDetails',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx?`
    }
  },
  {
    path: routeTypes.ContactAsset.path,
    name: routeTypes.ContactAsset.name,
    component: ContactAsset,
    props: { isTiled: true },
    meta: {
      title: 'Links',
      isContactChild: true
    }
  },
  {
    path: routeTypes.ContactAddress.path,
    name: routeTypes.ContactAddress.name,
    component: ContactAddress,
    props: { isTiled: true },
    meta: {
      title: 'Addresses',
      isContactChild: true
    }
  },
  {
    path: routeTypes.ContactPhoneEmail.path,
    name: routeTypes.ContactPhoneEmail.name,
    component: ContactPhoneEmail,
    props: { isTiled: true },
    meta: {
      title: 'Phones / Emails',
      isContactChild: true
    }
  },
  {
    path: routeTypes.ContactRemark.name,
    name: routeTypes.ContactRemark.name,
    component: ContactRemark,
    props: { isTiled: true },
    meta: {
      title: 'Remarks',
      isContactChild: true
    }
  }
]
