module.exports.CustomerListView = {
  path: '/customers',
  name: 'CustomerListView'
}
module.exports.CustomerDetail = {
  path: '/customers/:customerId',
  name: 'CustomerDetail'
}
module.exports.CustomerPhoneEmail = {
  path: 'phoneemail',
  name: 'CustomerPhoneEmail'
}
module.exports.CustomerAddress = {
  path: 'address',
  name: 'CustomerAddress'
}
module.exports.CustomerContact = {
  path: 'contacts',
  name: 'CustomerContact'
}
