import routeTypes from './route-types'
const OutstandingCreditListView = r => require.ensure([], () => r(require('./OutstandingCreditListView.vue')), 'outstandingcreditlist-chunk')

export default [
  {
    path: routeTypes.OutstandingCreditListView.path,
    name: routeTypes.OutstandingCreditListView.name,
    component: OutstandingCreditListView,
    meta: {
      id: 'A012',
      route: 'listing',
      title: 'Outstanding Credit',
      fkey: 'outstandingcredit|filter',
      mkey: 'outstandingcredit|modules',
      ckey: 'outstandingcredit|consumables',
      report: 'AT_OutstandingCredits',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  }
]
