import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntity(id, hash) {
    const url = !hash ? `/quickitems/${id}` : `/quickitems/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseQuickItemFilter(filter)
    const url = `/quickitems/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseQuickItemFilter(filter) {
    const entityProto = require('../../assets/proto/quickitem/QuickItemFilter.proto')
    const filterMessage = entityProto.QuickItemFilter.QuickItemFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  putQuickItem(entity, deepDiff) {
    if (deepDiff) {
      entity.deepDiff = JSON.stringify(deepDiff)
    }
    return FqApi.put(`/quickitems/${entity.itemQuickId}`, entity)
  },
  async postQuickItem(entity) {
    return FqApi.post('/quickitems', entity)
  },
  async getNewEntity() {
    const result = await FqApi.get('/quickitems/new')
    return result.data
  },
  async getAssetEmail(assetId, assetType) {
    const url = `/quickitems/getassetemail/${assetId}/${assetType}`
    const result = await FqApi.get(url)
    return result.data
  }
}
