// import Guid from '@/components/Guid'
import { CreditorInvoiceTypes } from '@/enums'
// import { PurchaseOrderReceiptItemModel } from './PurchaseOrderReceiptItemModel'

export default class PurchaseOrderReceiptModel {
  constructor(companyId, purchaseOrderId, creditorInvoiceId, vendorId, abn, remarks, quoteId, glCode, gstRate, invoiceNo = '', invoiceDate = '', amount = 0, totalAmount = 0, gstAmount = 0, receiptItems = []) {
    // const dealerCost = unitPrice * quantity
    this.companyId = companyId
    // this.dealerCost = dealerCost

    this.purchaseOrderId = purchaseOrderId
    this.creditorInvoiceId = creditorInvoiceId
    this.vendorId = vendorId
    this.quoteId = quoteId
    this.abn = abn
    // this.deleted = false
    this.isDeleted = false
    this.isNew = true
    this.invoiceNo = invoiceNo
    this.invoiceDate = invoiceDate
    this.amount = amount
    this.totalAmount = totalAmount
    this.gstAmount = gstAmount
    this.overrideGst = 0
    this.overrideIncGst = 0
    this.invoiceType = CreditorInvoiceTypes.OrderReceipt
    this.glCode = glCode
    this.gstRate = gstRate
    this.gstPaid = null
    this.capital = 0
    this.bas = 1
    this.due = null
    this.factored = 0
    this.remarks = remarks
    this.receiptItems = receiptItems
    this.isValid = false
    //this.attachments = ''
    this.createdBy = ''
    this.createdDate = ''
    this.modifiedBy = ''
    this.modifiedDate = ''
  }
}
