import FqApi from '@/services/fq-api'

const delayTime = function(order) {
  return order * 3000
}

const sleep = m => new Promise(r => setTimeout(r, m))

// function timeout(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms))
// }
// async function sleep(fn, ...args) {
//   await timeout(3000)
//   return fn(...args)
// }

export default {
  async loadQuote(quoteId, overwrite) {
    const url = `audanet/loadquote/${quoteId}?overwrite=${overwrite}`
    const result = FqApi.put(url)
    return result
  },
  async updateClaimInfo(quoteId) {
    const url = `audanet/updateclaiminfo/${quoteId}`
    const result = FqApi.put(url)
    return result
  },
  async loadVehicle(quoteId) {
    const url = `audanet/loadvehicle/${quoteId}`
    const result = FqApi.put(url)
    return result
  },
  async loadImages(quoteId) {
    const url = `audanet/loadimages/${quoteId}`
    const result = FqApi.put(url)
    return result
  },
  async submitImages(quoteId) {
    const url = `audanet/submitimages/${quoteId}`
    const result = FqApi.put(url)
    return result
  },
  async submitMultipleImages(quoteId, imageIds, isResetSent) {
    const url = `audanet/submitmultipleimages/${quoteId}/${isResetSent}`
    const result = FqApi.post(url, imageIds)
    return result
  },
  async submitSublets(quoteId, order = 0) {
    await sleep(delayTime(order))
    const url = `audanet/submitsublets/${quoteId}`
    const result = await FqApi.put(url)
    return result
  },
  async submitParts(quoteId, order = 0) {
    await sleep(delayTime(order))
    const url = `audanet/submitparts/${quoteId}`
    const result = await FqApi.put(url)
    return result
  },
  async submitLabours(quoteId, order = 0) {
    await sleep(delayTime(order))
    const url = `audanet/submitlabours/${quoteId}`
    const result = await FqApi.put(url)
    return result
  },
  async submitPaints(quoteId, order = 0) {
    await sleep(delayTime(order))
    const url = `audanet/submitpaints/${quoteId}`
    const result = await FqApi.put(url)
    return result
  },
  async checkSubQuoteAdded(quoteId) {
    const url = `audanet/checksubquoteadded/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getAudaNetTask(quoteId) {
    const url = `audanet/getAudaNetTask/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async sleep(ms) {
    await sleep(ms)
  }
}
