<template>

  <div class="tile is-child has-background-dark tile-border">
    <DashboardPanel>
      <div class="panel-heading has-text-centered has-text-white custom-panel-heading">
        PNet
      </div>

      <DashboardProgressBar :is-loading="isLoading"
        :initial-color="'#306F67'"
        :loading-color="'#18ad63'" />

      <div class="has-background-dark tile-border"
        v-if="pnet">

        <div class="panel-block is-flex is-justify-content-space-between custom-panel custom-panel-border pb-3 clickable-panel"
          :class="[{'custom-panel-with-no-chart': isUninvoicedJobsAndChartDisabled}]"
          @click="onClick(messageStatusTypes.UnRead)">
          <p class="is-flex is-align-items-center">Inbox</p>
          <DashboardWebServiceCircularStatus :value="pnet.unRead"
            :color-code="'#306F67'"
            :width="'40px'"
            :height="'40px'" />
        </div>

        <div class="panel-block is-flex is-justify-content-space-between custom-panel custom-panel-border pb-3 clickable-panel"
          :class="[{'custom-panel-with-no-chart': isUninvoicedJobsAndChartDisabled}]"
          @click="onClick(messageStatusTypes.ToSend)">
          <p class="is-flex is-align-items-center">Failed</p>

          <DashboardWebServiceCircularStatus :value="pnet.sendFailed"
            :color-code="getButtonColorCode(pnet.sendFailed)"
            :width="'40px'"
            :height="'40px'" />
        </div>

        <div class="panel-block is-flex is-justify-content-space-between custom-panel custom-panel-border pb-3 clickable-panel"
          :class="[{'custom-panel-with-no-chart': isUninvoicedJobsAndChartDisabled}]"
          @click="onClick(messageStatusTypes.ToSend)">
          <p class="is-flex is-align-items-center">Outbox</p>
          <DashboardWebServiceCircularStatus :value="pnet.toSend"
            :color-code="'#306F67'"
            :width="'40px'"
            :height="'40px'" />
        </div>

      </div>
    </DashboardPanel>
  </div>

</template>

<script>
import DashboardPanel from '@/views/dashboard/DashboardPanel.vue'
import DashboardProgressBar from '@/views/dashboard/DashboardProgressBar.vue'
import DashboardService from '@/views/dashboard/DashboardService'
import DashboardWebServiceCircularStatus from '@/views/dashboard/DashboardWebServiceCircularStatus.vue'
import { MessageStatusTypes } from '@/enums'
import { Columns } from '@/views/pnetmessage/columns'

export default {
  name: 'DashboardPnet',
  components: { DashboardWebServiceCircularStatus, DashboardProgressBar, DashboardPanel },
  props: {
    isUninvoicedJobsAndChartDisabled: Boolean
  },
  data() {
    return {
      pnet: null,
      isLoading: false,
      timeOutId: 0,
      filter: {
        quoteNo: '',
        rego: '',
        claimNo: '',
        messageTypeId: 0,
        messageStatusId: this.tab,
        sortColumn: Columns[Columns.length - 2].name,
        sortOrder: Columns[Columns.length - 2].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      }
    }
  },
  computed: {
    messageStatusTypes() {
      return MessageStatusTypes
    },
    filterKey() {
      if (!this.$userInfo) return ''
      return `${this.$userInfo.sessionId}|pnetmessage|filter`
    }
  },
  created() {
    this.constantlyUpdateData()
  },
  beforeDestroy() {
    if (this.timeOutId) clearTimeout(this.timeOutId)
  },
  methods: {
    async getData() {
      this.isLoading = true
      const data = await DashboardService.getDashboardPnetStatus()
      this.pnet = data
      this.isLoading = false
    },
    constantlyUpdateData() {
      this.getData()
      // we want to update the data every 5 minutes
      this.timeOutId = setTimeout(this.constantlyUpdateData, 1000 * 300)
    },
    getButtonColorCode(value) {
      if (value > 0) return '#D92550'
      return '#306F67'
    },
    onClick(tab) {
      const obj = {
        ...this.filter,
        messageStatusId: tab
      }
      sessionStorage.setItem(this.filterKey, JSON.stringify(obj))
      this.$router.push('pnetMessages')
    }
  }
}
</script>

<style scoped>
.custom-panel {
  color: #fff;
  height: 6.5vh;
}

.custom-panel:last-child {
  border-radius: 0px 0px 20px 20px !important;
}

.custom-panel-with-no-chart {
  color: #fff;
  height: 10vh;
}
.custom-panel-border:not(:last-child) {
  border-bottom: 1px solid black;
}

.tile-border {
  border-radius: 20px !important;
}

.custom-panel-heading {
  border-radius: 20px 20px 0 0 !important;
  background-color: #495057 !important;
}
</style>