/* eslint-disable dot-notation */
import tippy from 'tippy.js'

import TippyComponent from './Tippy.vue'

var tippyDirective = 'tippy'

const plugin = {
  install(Vue, options = {}) {
    tippyDirective = options.directive || 'tippy'

    tippy.setDefaultProps(options || {})

    function createTippy(el, binding, vnode) {
      const handlers = (vnode.data && vnode.data.on) ||
        (vnode.componentOptions && vnode.componentOptions.listeners)

      // console.log(handlers)

      let opts = binding.value || {}

      opts = Object.assign({}, options, opts)

      if (handlers && handlers['after-update-tippy']) {
        opts.onAfterUpdate = function (...args) {
          return handlers['after-update-tippy'].fns(...args)
        }
      }
      if (handlers && handlers['before-update-tippy']) {
        opts.onBeforeUpdate = function (...args) {
          return handlers['before-update-tippy'].fns(...args)
        }
      }
      if (handlers && handlers['trigger-tippy']) {
        opts.onTrigger = function (...args) {
          return handlers['trigger-tippy'].fns(...args)
        }
      }
      if (handlers && handlers['untrigger-tippy']) {
        opts.onUntrigger = function (...args) {
          return handlers['untrigger-tippy'].fns(...args)
        }
      }
      if (handlers && handlers['click-outside-tippy']) {
        opts.onClickOutside = function (...args) {
          return handlers['click-outside-tippy'].fns(...args)
        }
      }
      if (handlers && handlers['show-tippy']) {
        opts.onShow = function (...args) {
          return handlers['show-tippy'].fns(...args)
        }
      }
      if (handlers && handlers['shown-tippy']) {
        opts.onShown = function (...args) {
          handlers['shown-tippy'].fns(...args)
        }
      }
      if (handlers && handlers['hidden']) {
        opts.onHidden = function (...args) {
          handlers['hidden-tippy'].fns(...args)
        }
      }

      if (handlers && handlers['hide']) {
        opts.onHide = function (...args) {
          return handlers['hide-tippy'].fns(...args)
        }
      }

      if (handlers && handlers['mount']) {
        opts.onMount = function (...args) {
          handlers['mount-tippy'].fns(...args)
        }
      }

      if (el.getAttribute('content') && !opts.content) {
        opts.content = el.getAttribute('content')
      }

      const enabled = typeof opts.enabled !== 'undefined' ? opts.enabled : true

      delete opts.enabled
      tippy(el, opts)
      // tippy(el, {
      //   onShow(instance) {
      //     console.log(instance)
      //   },
      //   onTrigger(instance, event) {
      //     console.log(instance, event)
      //   }
      // })

      if (opts.showOnCreate) {
        el._tippy.show()
      }

      if (enabled) {
        el._tippy.enable()
      } else {
        el._tippy.disable()
      }

      // Vue.nextTick(() => {
      //   if (handlers && handlers['init']) {
      //     handlers['init'].fns(el._tippy, el)
      //   }
      // })
    }

    Vue.directive(tippyDirective, {
      inserted(el, binding, vnode) {
        Vue.nextTick(() => {
          createTippy(el, binding, vnode)
        })
      },
      unbind(el) {
        el._tippy && el._tippy.destroy()
      },
      componentUpdated(el, binding, vnode) {
        if (el._tippy) {
          const opts = binding.value || {}
          const enabled = typeof opts.enabled !== 'undefined' ? opts.enabled : true

          if (el.getAttribute('content') && !opts.content) {
            opts.content = el.getAttribute('content')
          }
          // console.log(el._tippy)
          delete opts.enabled
          el._tippy.setProps(opts)
          if (enabled) {
            el._tippy.enable()
          } else {
            el._tippy.disable()
          }
        }
      }
    })
  }
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(plugin)
  window.Vue.component('tippy', TippyComponent)
}

export default plugin
export {
  TippyComponent,
  tippy
}
