/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.InsurerFilter = (function() {

    /**
     * Namespace InsurerFilter.
     * @exports InsurerFilter
     * @namespace
     */
    var InsurerFilter = {};

    InsurerFilter.InsurerFilterModel = (function() {

        /**
         * Properties of an InsurerFilterModel.
         * @memberof InsurerFilter
         * @interface IInsurerFilterModel
         * @property {string|null} [name] InsurerFilterModel name
         * @property {string|null} [suburb] InsurerFilterModel suburb
         * @property {string|null} [state] InsurerFilterModel state
         * @property {string|null} [labourType] InsurerFilterModel labourType
         * @property {string|null} [ecomType] InsurerFilterModel ecomType
         * @property {string|null} [quotingMethod] InsurerFilterModel quotingMethod
         * @property {number|null} [isActive] InsurerFilterModel isActive
         * @property {string|null} [sortColumn] InsurerFilterModel sortColumn
         * @property {string|null} [sortOrder] InsurerFilterModel sortOrder
         * @property {number|null} [pageIndex] InsurerFilterModel pageIndex
         * @property {number|null} [pageSize] InsurerFilterModel pageSize
         */

        /**
         * Constructs a new InsurerFilterModel.
         * @memberof InsurerFilter
         * @classdesc Represents an InsurerFilterModel.
         * @implements IInsurerFilterModel
         * @constructor
         * @param {InsurerFilter.IInsurerFilterModel=} [p] Properties to set
         */
        function InsurerFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * InsurerFilterModel name.
         * @member {string|null|undefined} name
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        InsurerFilterModel.prototype.name = null;

        /**
         * InsurerFilterModel suburb.
         * @member {string|null|undefined} suburb
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        InsurerFilterModel.prototype.suburb = null;

        /**
         * InsurerFilterModel state.
         * @member {string|null|undefined} state
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        InsurerFilterModel.prototype.state = null;

        /**
         * InsurerFilterModel labourType.
         * @member {string|null|undefined} labourType
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        InsurerFilterModel.prototype.labourType = null;

        /**
         * InsurerFilterModel ecomType.
         * @member {string|null|undefined} ecomType
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        InsurerFilterModel.prototype.ecomType = null;

        /**
         * InsurerFilterModel quotingMethod.
         * @member {string|null|undefined} quotingMethod
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        InsurerFilterModel.prototype.quotingMethod = null;

        /**
         * InsurerFilterModel isActive.
         * @member {number|null|undefined} isActive
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        InsurerFilterModel.prototype.isActive = null;

        /**
         * InsurerFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        InsurerFilterModel.prototype.sortColumn = null;

        /**
         * InsurerFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        InsurerFilterModel.prototype.sortOrder = null;

        /**
         * InsurerFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        InsurerFilterModel.prototype.pageIndex = null;

        /**
         * InsurerFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        InsurerFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * InsurerFilterModel _name.
         * @member {"name"|undefined} _name
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        Object.defineProperty(InsurerFilterModel.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerFilterModel _suburb.
         * @member {"suburb"|undefined} _suburb
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        Object.defineProperty(InsurerFilterModel.prototype, "_suburb", {
            get: $util.oneOfGetter($oneOfFields = ["suburb"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerFilterModel _state.
         * @member {"state"|undefined} _state
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        Object.defineProperty(InsurerFilterModel.prototype, "_state", {
            get: $util.oneOfGetter($oneOfFields = ["state"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerFilterModel _labourType.
         * @member {"labourType"|undefined} _labourType
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        Object.defineProperty(InsurerFilterModel.prototype, "_labourType", {
            get: $util.oneOfGetter($oneOfFields = ["labourType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerFilterModel _ecomType.
         * @member {"ecomType"|undefined} _ecomType
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        Object.defineProperty(InsurerFilterModel.prototype, "_ecomType", {
            get: $util.oneOfGetter($oneOfFields = ["ecomType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerFilterModel _quotingMethod.
         * @member {"quotingMethod"|undefined} _quotingMethod
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        Object.defineProperty(InsurerFilterModel.prototype, "_quotingMethod", {
            get: $util.oneOfGetter($oneOfFields = ["quotingMethod"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerFilterModel _isActive.
         * @member {"isActive"|undefined} _isActive
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        Object.defineProperty(InsurerFilterModel.prototype, "_isActive", {
            get: $util.oneOfGetter($oneOfFields = ["isActive"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        Object.defineProperty(InsurerFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        Object.defineProperty(InsurerFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        Object.defineProperty(InsurerFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InsurerFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof InsurerFilter.InsurerFilterModel
         * @instance
         */
        Object.defineProperty(InsurerFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified InsurerFilterModel message. Does not implicitly {@link InsurerFilter.InsurerFilterModel.verify|verify} messages.
         * @function encode
         * @memberof InsurerFilter.InsurerFilterModel
         * @static
         * @param {InsurerFilter.IInsurerFilterModel} m InsurerFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InsurerFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.suburb != null && Object.hasOwnProperty.call(m, "suburb"))
                w.uint32(18).string(m.suburb);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                w.uint32(26).string(m.state);
            if (m.labourType != null && Object.hasOwnProperty.call(m, "labourType"))
                w.uint32(34).string(m.labourType);
            if (m.ecomType != null && Object.hasOwnProperty.call(m, "ecomType"))
                w.uint32(42).string(m.ecomType);
            if (m.quotingMethod != null && Object.hasOwnProperty.call(m, "quotingMethod"))
                w.uint32(50).string(m.quotingMethod);
            if (m.isActive != null && Object.hasOwnProperty.call(m, "isActive"))
                w.uint32(56).int32(m.isActive);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(66).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(74).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(80).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(88).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified InsurerFilterModel message, length delimited. Does not implicitly {@link InsurerFilter.InsurerFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof InsurerFilter.InsurerFilterModel
         * @static
         * @param {InsurerFilter.IInsurerFilterModel} message InsurerFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InsurerFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InsurerFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof InsurerFilter.InsurerFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {InsurerFilter.InsurerFilterModel} InsurerFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InsurerFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.InsurerFilter.InsurerFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.name = r.string();
                    break;
                case 2:
                    m.suburb = r.string();
                    break;
                case 3:
                    m.state = r.string();
                    break;
                case 4:
                    m.labourType = r.string();
                    break;
                case 5:
                    m.ecomType = r.string();
                    break;
                case 6:
                    m.quotingMethod = r.string();
                    break;
                case 7:
                    m.isActive = r.int32();
                    break;
                case 8:
                    m.sortColumn = r.string();
                    break;
                case 9:
                    m.sortOrder = r.string();
                    break;
                case 10:
                    m.pageIndex = r.int32();
                    break;
                case 11:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes an InsurerFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof InsurerFilter.InsurerFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {InsurerFilter.InsurerFilterModel} InsurerFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InsurerFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return InsurerFilterModel;
    })();

    return InsurerFilter;
})();

module.exports = $root;
