import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
import ProtoBufApi from '@/services/protobuf-api'
import { GlAccountTypes } from '@/enums'

export default {
  async getEntity(id, hash) {
    const url = !hash ? `/purchaseorders/${id}` : `/purchaseorders/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialisePurchaseOrderFilter(filter)
    const url = `/purchaseorders/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialisePurchaseOrderFilter(filter) {
    const entityProto = require('../../assets/proto/purchaseorder/PurchaseOrderFilter.proto')
    const filterMessage = entityProto.PurchaseOrderFilter.PurchaseOrderFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getAttachments(creditorInvoiceId) {
    return FqApi.get(`/attachments/list?objectId=${creditorInvoiceId}`)
  },
  async getNewEntity() {
    const result = await FqApi.get('/purchaseorders/new')
    return result.data
  },
  putEntity(entity, deepdiff) {
    if (deepdiff) {
      entity.deepDiff = JSON.stringify(deepdiff)
    }
    return FqApi.put(`/purchaseorders/${entity.purchaseOrderId}`, entity)
  },
  async postEntity(entity) {
    return FqApi.post('/purchaseorders', entity)
  },
  async getOrderersDropdown() {
    const url = 'quotes/quoters'
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteItemsByQuoteId(quoteId, purchaseOrderId, type) {
    let url = `purchaseorders/quoteitems/${quoteId}/${purchaseOrderId}/${type}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getGlAccountListComboProto() {
    const result = await ProtoBufApi(
      `/protobuf/glaccounts/listcombo?glTypes=${GlAccountTypes.CostOfSales}&glTypes=${GlAccountTypes.Expenses}`
    )
    const glAccountProto = require('../../assets/proto/listcombo/GlAccount.proto')
    const accountMessage = await glAccountProto.GlAccount.GlAccountListComboModel
    const entity = accountMessage.decode(new Uint8Array(result.data))
    return entity.glAccounts
  },
  async checkInvoiceNoExists(no, vendorId, creditorInvoiceId) {
    let url = `/purchaseorders/exists?no=${no}&vendorId=${vendorId}&creditorInvoiceId=${creditorInvoiceId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getAssetEmail(assetId) {
    const url = `/purchaseorders/getassetemail/${assetId}`
    const result = await FqApi.get(url)
    return result.data
  }
}
