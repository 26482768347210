import { ComponentRoute } from '@/classes'

const MiscQuoteRoutes = {
  MiscQuoteListView: new ComponentRoute('/miscquotes', 'MiscQuoteListView'),
  MiscQuoteView: new ComponentRoute('/miscquotes/:entityId', 'MiscQuoteView'),
  MiscQuoteDetail: new ComponentRoute('/miscquotes/:entityId', 'MiscQuoteDetail'),
  MiscQuoteItems: new ComponentRoute('items', 'MiscQuoteItems'),
  MiscQuoteInvoice: new ComponentRoute('invoice', 'MiscQuoteInvoice')
}

export default MiscQuoteRoutes
