<template>
  <div class="tile is-6 is-parent is-vertical pl-0 border">
    <p class="panel-heading has-text-centered has-text-white border custom-panel-heading">
      TOTAL JOBS COMPLETED
    </p>

    <DashboardProgressBar
      :is-loading="isLoading"
      :initial-color="'#99C8F7'"
      :loading-color="'#0a91a8'" />

    <DashboardJobsChart v-if="!!monthlyReport.length"
      class="has-background-dark canvas-border"
      :correct-stage-monthly-jobs="correctStageMonthlyReport"
      :wrong-stage-monthly-jobs="monthlyReport" />
  </div>

</template>

<script>

import DashboardService from '@/views/dashboard/DashboardService'
import DashboardJobsChart from '@/views/dashboard/DashboardJobsChart.vue'
import {getCorrectStagedMonthlyJobs, getWronglyStagedMonthlyJobs} from '@/views/dashboard/helper'
import DashboardProgressBar from '@/views/dashboard/DashboardProgressBar.vue'

export default {
  name: 'DashboardTotalJobs',
  components: {DashboardProgressBar, DashboardJobsChart},

  data: () => ({
    isLoading: false,
    monthlyReport: [],
    correctStageMonthlyReport: [],
    timeOutId:0
  }),

  created() {
    this.constantlyUpdateData()
  },
  beforeDestroy() {
    if(this.timeOutId)
      clearTimeout(this.timeOutId)
  },
  methods: {
    async getData() {
      this.isLoading = true
      const data = await DashboardService.getDashboardJobsChartData()
      this.correctStageMonthlyReport = getCorrectStagedMonthlyJobs(data.correctStageMonthlyReport)
      // our monthlyReport is the wrongly staged jobs
      // vue's not happy when create a new variable to store this
      this.monthlyReport = getWronglyStagedMonthlyJobs( data.monthlyReport, this.correctStageMonthlyReport )
      this.isLoading = false
    },
    constantlyUpdateData(){
      this.getData()
      // we want to update the data every 30 seconds
      this.timeOutId = setTimeout(this.constantlyUpdateData, 1000*30)
    }
  }
}

</script>

<style scoped>
.border {
  border-radius: 20px 20px 0px 0px !important;
}
.canvas-border {
  border-radius: 0px 0px 20px 20px !important;
}
.custom-panel-heading {
  border-radius: 20px 20px 0 0 !important;
  background-color:#495057 !important; ;
}

</style>