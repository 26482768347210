import ItemService from './ItemService'
import ItemRoutes from './route-types'
import StoreUtil from '@/store/utils'
import DeepDiff from 'deep-diff'
import { md5 } from '@/components/crypto'
// import { nowDate } from '@/components/utils/DateTimeFunctions'

const tag = 'item'

const state = {
  returnRoute: {},
  baseIndex: 0,
  currentIndex: 0,
  snapshots: []
}

const getters = {
  returnRoute: state => state.returnRoute,
  snapshots: state => state.snapshots,
  baseSnapshot: state => state.snapshots[state.baseIndex],
  currentSnapshot: state => state.snapshots[state.currentIndex],
  snapshotDiff: state => {
    if (state.snapshots[state.baseIndex] && state.snapshots[state.currentIndex]) {
      return DeepDiff.diff(state.snapshots[state.baseIndex], state.snapshots[state.currentIndex])
    } else {
      return null
    }
  }
}

const actions = {
  async getStoreItem({ commit }, item) {
    var id = item.id
    var isCustom = false
    var itemRoute = ''

    switch (item.source) {
      case 'LC':
        isCustom = true
        itemRoute = 'labour'
        break
      case 'LS':
        isCustom = false
        itemRoute = 'labour'
        break
      case 'PC':
        isCustom = true
        itemRoute = 'part'
        break
      case 'PS':
        isCustom = false
        itemRoute = 'part'
        break
    }
    // const entity = StoreUtil.getStorage(id, tag) || (await QuickInvoiceService.getEntity(id, ''))
    // The following for refreshing data from WebApi regardless
    let entity = StoreUtil.getStorage(id, tag)
    if (!entity || (entity && !entity.isNew)) {
      const hash = entity ? md5(JSON.stringify(entity)) : ''
      // const data = await ItemService.getEntity(id, hash)
      const data = itemRoute == 'labour' ? await ItemService.getItem(isCustom, id, hash) : await ItemService.getPart(isCustom, id, hash)

      if (data) {
        console.log('from WebApi')
        entity = data
      } else {
        console.log('from sessionStorage')
      }
    }
    if (itemRoute == 'labour') {
      StoreUtil.setStorage(entity.itemLabourCustomId, tag, entity)
    } else {
      StoreUtil.setStorage(entity.itemPartCustomId, tag, entity)
    }

    commit('saveInitialSnapshots', entity)
  },
  async addStoreItem({ commit }, itemType) {
    const entity = await ItemService.getNewEntity(itemType)

    var id = entity.itemLabourCustomId
    var path = entity.itemLabourCustomId + '/LC'
    StoreUtil.setStorage(id, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(ItemRoutes.ItemListView.path, path, true)
  },
  saveSnapshot({ commit }, entity) {
    commit('saveSnapshot', entity)
  },
  clearSnapshots({ commit }, id) {
    StoreUtil.removeStorage(id, tag)
    commit('clearSnapshots')
  },
  setReturnRoute({ commit }, route) {
    commit('setReturnRoute', route)
  }
}

const mutations = {
  saveInitialSnapshots(state, entity) {
    state.snapshots = []
    state.snapshots.splice(0, 1, entity)
    state.snapshots.splice(1, 1, entity)
    state.currentIndex = state.snapshots.length - 1
  },
  saveSnapshot(state, entity) {
    state.snapshots.splice(state.currentIndex, 1, entity)
  },
  clearSnapshots(state) {
    state.snapshots = []
    state.baseIndex = 0
    state.currentIndex = 0
  },
  setReturnRoute(state, route) {
    state.returnRoute = route
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
