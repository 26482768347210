import { QuickInvoiceFilter } from './filters'
import { Emailer } from './emails'
import { PartTypes } from './parttypes'
import { ComponentRoute } from './component-routes'
import { TableColumn } from './table-columns'
import { UniqError } from './errors'
import { ExtraVehicleInformationModel } from './pnet/ExtraVehicleInformationModel'
import { PnetSubmitQuoteModel } from './pnet/PnetSubmitQuoteModel'
import { PnetSubmitQuoteUpdateModel } from './pnet/PnetSubmitQuoteUpdateModel'
import { OrmDeclineQuoteRequestModel } from './orm/OrmDeclineQuoteRequestModel'
import { OrmItemCommentModel } from './orm/OrmItemCommentModel'
import { OrmSendQuoteModel } from './orm/OrmSendQuoteModel'
import { EstImageSendQuoteModel } from './estimage/EstImageSendQuoteModel'

export {
  QuickInvoiceFilter,
  Emailer,
  PartTypes,
  ComponentRoute,
  TableColumn,
  UniqError,
  ExtraVehicleInformationModel,
  PnetSubmitQuoteModel,
  PnetSubmitQuoteUpdateModel,
  OrmDeclineQuoteRequestModel,
  OrmItemCommentModel,
  OrmSendQuoteModel,
  EstImageSendQuoteModel
}

export class ContactPayload {
  constructor(name = '', assetId = '', assetType = '', assetName = '', gotoRoute = true) {
    const nameParts = name.split(' ')
    this.firstname = nameParts[0].trim()
    this.lastname = nameParts[1] ? nameParts[1].trim() : ''
    this.assetId = assetId
    this.assetType = assetType
    this.assetName = assetName
    this.gotoRoute = gotoRoute
    this.companyContact = companyContact
  }
}

export class InsurerPayload {
  constructor(name = '', gotoRoute = true) {
    this.name = name
    this.gotoRoute = gotoRoute
  }
}

export class QuickInvoiceAssetPayload {
  constructor(assetId = '', assetType = 1, assetGlCode = '') {
    this.assetId = assetId
    this.assetType = assetType.toString()
    this.assetGlCode = assetGlCode
  }
}

export class GstModel {
  constructor(gstRate = 10) {
    const factor = gstRate / 100
    const multiplier = factor + 1
    this.gstRate = gstRate
    this.factor = factor
    this.multiplier = multiplier
    this.divisor = this.factor ? multiplier / factor : 0
  }
}

export class EmailAttachment {
  constructor(fileId = '', filename = '', contentType = '', contentData = '') {
    this.fileId = fileId
    this.filename = filename
    this.contentType = contentType
    this.contentData = contentData
  }
}
