/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.CustomerFilter = (function() {

    /**
     * Namespace CustomerFilter.
     * @exports CustomerFilter
     * @namespace
     */
    var CustomerFilter = {};

    CustomerFilter.CustomerFilterModel = (function() {

        /**
         * Properties of a CustomerFilterModel.
         * @memberof CustomerFilter
         * @interface ICustomerFilterModel
         * @property {string|null} [fullName] CustomerFilterModel fullName
         * @property {string|null} [street] CustomerFilterModel street
         * @property {string|null} [suburb] CustomerFilterModel suburb
         * @property {number|null} [active] CustomerFilterModel active
         * @property {string|null} [sortColumn] CustomerFilterModel sortColumn
         * @property {string|null} [sortOrder] CustomerFilterModel sortOrder
         * @property {number|null} [pageSize] CustomerFilterModel pageSize
         * @property {number|null} [pageIndex] CustomerFilterModel pageIndex
         */

        /**
         * Constructs a new CustomerFilterModel.
         * @memberof CustomerFilter
         * @classdesc Represents a CustomerFilterModel.
         * @implements ICustomerFilterModel
         * @constructor
         * @param {CustomerFilter.ICustomerFilterModel=} [p] Properties to set
         */
        function CustomerFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CustomerFilterModel fullName.
         * @member {string} fullName
         * @memberof CustomerFilter.CustomerFilterModel
         * @instance
         */
        CustomerFilterModel.prototype.fullName = "";

        /**
         * CustomerFilterModel street.
         * @member {string} street
         * @memberof CustomerFilter.CustomerFilterModel
         * @instance
         */
        CustomerFilterModel.prototype.street = "";

        /**
         * CustomerFilterModel suburb.
         * @member {string} suburb
         * @memberof CustomerFilter.CustomerFilterModel
         * @instance
         */
        CustomerFilterModel.prototype.suburb = "";

        /**
         * CustomerFilterModel active.
         * @member {number} active
         * @memberof CustomerFilter.CustomerFilterModel
         * @instance
         */
        CustomerFilterModel.prototype.active = 0;

        /**
         * CustomerFilterModel sortColumn.
         * @member {string} sortColumn
         * @memberof CustomerFilter.CustomerFilterModel
         * @instance
         */
        CustomerFilterModel.prototype.sortColumn = "";

        /**
         * CustomerFilterModel sortOrder.
         * @member {string} sortOrder
         * @memberof CustomerFilter.CustomerFilterModel
         * @instance
         */
        CustomerFilterModel.prototype.sortOrder = "";

        /**
         * CustomerFilterModel pageSize.
         * @member {number} pageSize
         * @memberof CustomerFilter.CustomerFilterModel
         * @instance
         */
        CustomerFilterModel.prototype.pageSize = 0;

        /**
         * CustomerFilterModel pageIndex.
         * @member {number} pageIndex
         * @memberof CustomerFilter.CustomerFilterModel
         * @instance
         */
        CustomerFilterModel.prototype.pageIndex = 0;

        /**
         * Encodes the specified CustomerFilterModel message. Does not implicitly {@link CustomerFilter.CustomerFilterModel.verify|verify} messages.
         * @function encode
         * @memberof CustomerFilter.CustomerFilterModel
         * @static
         * @param {CustomerFilter.ICustomerFilterModel} m CustomerFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.fullName != null && Object.hasOwnProperty.call(m, "fullName"))
                w.uint32(10).string(m.fullName);
            if (m.street != null && Object.hasOwnProperty.call(m, "street"))
                w.uint32(18).string(m.street);
            if (m.suburb != null && Object.hasOwnProperty.call(m, "suburb"))
                w.uint32(26).string(m.suburb);
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(32).int32(m.active);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(42).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(50).string(m.sortOrder);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(56).int32(m.pageSize);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(64).int32(m.pageIndex);
            return w;
        };

        /**
         * Encodes the specified CustomerFilterModel message, length delimited. Does not implicitly {@link CustomerFilter.CustomerFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CustomerFilter.CustomerFilterModel
         * @static
         * @param {CustomerFilter.ICustomerFilterModel} message CustomerFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CustomerFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof CustomerFilter.CustomerFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {CustomerFilter.CustomerFilterModel} CustomerFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.CustomerFilter.CustomerFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.fullName = r.string();
                    break;
                case 2:
                    m.street = r.string();
                    break;
                case 3:
                    m.suburb = r.string();
                    break;
                case 4:
                    m.active = r.int32();
                    break;
                case 5:
                    m.sortColumn = r.string();
                    break;
                case 6:
                    m.sortOrder = r.string();
                    break;
                case 7:
                    m.pageSize = r.int32();
                    break;
                case 8:
                    m.pageIndex = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a CustomerFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CustomerFilter.CustomerFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CustomerFilter.CustomerFilterModel} CustomerFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return CustomerFilterModel;
    })();

    return CustomerFilter;
})();

module.exports = $root;
