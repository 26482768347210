import { ComponentRoute } from '@/classes'

const OrmBatchingRoutes = {
  OrmBatchingListView: new ComponentRoute('/ormbatching', 'OrmBatchingListView'),
  OrmBatchingView: new ComponentRoute('/ormbatching/:batchId', 'OrmBatchingView'),
  OrmBatchingDetail: new ComponentRoute('/ormbatching/:batchId/draggable', 'OrmBatchingDetail'),
  OrmBatchingAvailableQuotes: new ComponentRoute('/ormbatching/:batchId/available', 'OrmBatchingAvailableQuotes'),
  OrmBatchingQuotes: new ComponentRoute('/ormbatching/:batchId', 'OrmBatchingQuotes')
}

export default OrmBatchingRoutes
