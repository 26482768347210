import { required } from 'vuelidate/lib/validators'
import { DateTime } from 'luxon'

const dateValidator = function (value) {
  if (value && value !== '__/__/____') {
    const date = DateTime.fromISO(value)
    return date.isValid
  } else {
    return value !== undefined
  }
}

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    filter: {
      dateFrom: {
        required,
        validDate: dateValidator
      },
      dateTo: {
        required,
        validDate: dateValidator
      }
    }
    // newNote : {
    //   remarks: {
    //     required
    //   }
    // }
  }
}
