<template>
  <div
    :style="styles"
    class="spinner spinner--circle-9">
    <div
      :style="innerStyles"
      class="spinner-inner">
      <div class="loading spin-1">
        <div class="loading spin-2">
          <div class="loading spin-3">
            <div class="loading spin-4">
              <div class="loading spin-5">
                <div class="loading spin-6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spinner-message">{{ message }}</div>
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: '70px'
    },
    message: {
      type: String,
      default: 'Loading...'
    }
  },
  computed: {
    innerStyles() {
      const size = parseInt(this.size)
      return {
        transform: 'scale(' + size / 120 + ')'
      }
    },
    styles() {
      return {
        width: this.size,
        height: this.size
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
  * {
    line-height: 0;
    box-sizing: border-box;
  }
}

.spinner-message {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // padding: 0.3em;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.85);
  font-size: 1rem; // align-items: flex-end;
  // align-content: flex-end;
}

.spinner-inner {
  display: flex;
  // align-content: flex-start;
  // width: 120px;
  // height: 120px;
}

@keyframes circle-9-loading {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(160deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(160deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.loading {
  background-color: transparent;
  border-radius: 50%;
  margin: 5px auto;
  animation: circle-9-loading 5s infinite linear;
}

.spin-1 {
  border: 5px solid #f7484e;
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  width: 120px;
  height: 120px;
}

.spin-2 {
  border: 5px solid #41b883;
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  width: 100px;
  height: 100px;
  animation-delay: 1s;
}

.spin-3 {
  border: 5px solid #d2d947;
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  width: 80px;
  height: 80px;
  animation-delay: 1s;
}

.spin-4 {
  border: 5px solid #f2a342;
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  width: 60px;
  height: 60px;
  animation-delay: 1s;
}

.spin-5 {
  border: 5px solid #34495e;
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  width: 40px;
  height: 40px;
  animation-delay: 1s;
}

.spin-6 {
  border: 5px solid #fff;
  width: 20px;
  height: 20px;
  animation-delay: 1s;
}
</style>
