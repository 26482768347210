import FqApi from '@/services/fq-api'

export default {
  async getEntity(id) {
    const url = '/companysettings/getcompanysetting'
    const result = await FqApi.get(url)
    return result.data
  },
  async setItemAutoCompleteEnabled(value) {
    const enabled = !!value
    const url = `/companysettings/itemautocompleteenabled/${enabled}`
    return await FqApi.put(url)
  },
  async UpdateFiscalSetting(fiscalStart, fiscalDuration) {
    const url = `/companysettings/updatefiscalsetting?fiscalstart=${fiscalStart}&fiscalduration=${fiscalDuration}`
    return await FqApi.put(url)
  },
  async alterCompanySettings(settingName, enable = true) {
    const url = `/companysettings/alterCompanySettings?name=${settingName}&enable=${enable}`
    const result = await FqApi.post(url)
    return result.data
  },
  async getCompanyDetails() {
    const url = '/companysettings/getCompanyDetails'
    const result = await FqApi.get(url)
    return result.data
  },
  async getCompanyLicense() {
    const url = '/companysettings/getCompanyLicense'
    const result = await FqApi.get(url)
    return result.data
  },
  async getCompanyModules() {
    const url = '/companysettings/getCompanyModules'
    const result = await FqApi.get(url)
    return result.data
  },
  async getCompanyItemCategories() {
    const url = '/companysettings/getCompanyItemCategories'
    const result = await FqApi.get(url)
    return result.data
  },
  async getCompanyExternalSettings() {
    const url = '/companysettings/getCompanyExternalSetting'
    const result = await FqApi.get(url)
    return result.data
  },
  async getCompanyATGLAccounts() {
    const url = '/companysettings/getCompanyATGLAccounts'
    const result = await FqApi.get(url)
    return result.data
  },
  async updateCompanyDetails(companyDetails) {
    const url = '/companysettings/updateCompanyDetails'
    const result = await FqApi.post(url, companyDetails)
    return result.data
  },
  async updateCompanyLicense(companyLicense) {
    const url = '/companysettings/updateCompanyLicense'
    const result = await FqApi.post(url, companyLicense)
    return result.data
  },
  async updateCompanyModules(companyModules) {
    const url = '/companysettings/updateCompanyModules'
    const result = await FqApi.post(url, companyModules)
    return result.data
  },
  async updateCompanyItemCategories(companyItemCategories) {
    const url = '/companysettings/updateCompanyItemCategories'
    const result = await FqApi.post(url, companyItemCategories)
    return result.data
  },
  async updateCompanyExternalSetting(companyExternalSettings) {
    const url = '/companysettings/updateCompanyExternalSetting'
    const result = await FqApi.post(url, companyExternalSettings)
    return result.data
  },
  async updateCompanySetting(companySetting) {
    const url = '/companysettings/updateCompanySetting'
    const result = await FqApi.post(url, companySetting)
    return result.data
  },
  async getCompanyMonthlySettingByFY(fiscalYear) {
    const url = `companymonthlysettings/getcompanymonthlysettingbyfy/${fiscalYear}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getFiscalYearList() {
    const url = 'companymonthlysettings/getfiscalyearlist'
    const result = await FqApi.get(url)
    return result.data
  },
  async getAllFiscalYear() {
    const url = 'companymonthlysettings/getallfiscalyear'
    const result = await FqApi.get(url)
    return result.data
  },
  async postMonthlySettingEntity(entity) {
    const result = await FqApi.post('/companymonthlysettings', entity)
    return result.data
  },
  async putMonthlySettingEntity(entity, fiscalYear) {
    const result = await FqApi.put(`/companymonthlysettings?fiscalyear=${fiscalYear}`, entity)
    return result.data
  },
  async checkExists(year, month) {
    const url = `companymonthlysettings/checkexists/${year}/${month}`
    const result = await FqApi.get(url)
    return result.data
  }
}
