/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.User = (function() {

    /**
     * Namespace User.
     * @exports User
     * @namespace
     */
    var User = {};

    User.UserInfoModel = (function() {

        /**
         * Properties of a UserInfoModel.
         * @memberof User
         * @interface IUserInfoModel
         * @property {string|null} [companyId] UserInfoModel companyId
         * @property {boolean|null} [isSupportUser] UserInfoModel isSupportUser
         * @property {string|null} [displayName] UserInfoModel displayName
         * @property {string|null} [companyName] UserInfoModel companyName
         * @property {number|null} [withHoldingTax] UserInfoModel withHoldingTax
         * @property {string|null} [locale] UserInfoModel locale
         * @property {string|null} [sessionId] UserInfoModel sessionId
         * @property {string|null} [userId] UserInfoModel userId
         * @property {string|null} [countryCode] UserInfoModel countryCode
         * @property {boolean|null} [isCustomerAdministrator] UserInfoModel isCustomerAdministrator
         * @property {boolean|null} [isSystemAdministrator] UserInfoModel isSystemAdministrator
         * @property {boolean|null} [isSystemAccount] UserInfoModel isSystemAccount
         * @property {string|null} [firstName] UserInfoModel firstName
         * @property {string|null} [lastName] UserInfoModel lastName
         */

        /**
         * Constructs a new UserInfoModel.
         * @memberof User
         * @classdesc Represents a UserInfoModel.
         * @implements IUserInfoModel
         * @constructor
         * @param {User.IUserInfoModel=} [p] Properties to set
         */
        function UserInfoModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserInfoModel companyId.
         * @member {string|null|undefined} companyId
         * @memberof User.UserInfoModel
         * @instance
         */
        UserInfoModel.prototype.companyId = null;

        /**
         * UserInfoModel isSupportUser.
         * @member {boolean|null|undefined} isSupportUser
         * @memberof User.UserInfoModel
         * @instance
         */
        UserInfoModel.prototype.isSupportUser = null;

        /**
         * UserInfoModel displayName.
         * @member {string|null|undefined} displayName
         * @memberof User.UserInfoModel
         * @instance
         */
        UserInfoModel.prototype.displayName = null;

        /**
         * UserInfoModel companyName.
         * @member {string|null|undefined} companyName
         * @memberof User.UserInfoModel
         * @instance
         */
        UserInfoModel.prototype.companyName = null;

        /**
         * UserInfoModel withHoldingTax.
         * @member {number|null|undefined} withHoldingTax
         * @memberof User.UserInfoModel
         * @instance
         */
        UserInfoModel.prototype.withHoldingTax = null;

        /**
         * UserInfoModel locale.
         * @member {string|null|undefined} locale
         * @memberof User.UserInfoModel
         * @instance
         */
        UserInfoModel.prototype.locale = null;

        /**
         * UserInfoModel sessionId.
         * @member {string|null|undefined} sessionId
         * @memberof User.UserInfoModel
         * @instance
         */
        UserInfoModel.prototype.sessionId = null;

        /**
         * UserInfoModel userId.
         * @member {string|null|undefined} userId
         * @memberof User.UserInfoModel
         * @instance
         */
        UserInfoModel.prototype.userId = null;

        /**
         * UserInfoModel countryCode.
         * @member {string|null|undefined} countryCode
         * @memberof User.UserInfoModel
         * @instance
         */
        UserInfoModel.prototype.countryCode = null;

        /**
         * UserInfoModel isCustomerAdministrator.
         * @member {boolean|null|undefined} isCustomerAdministrator
         * @memberof User.UserInfoModel
         * @instance
         */
        UserInfoModel.prototype.isCustomerAdministrator = null;

        /**
         * UserInfoModel isSystemAdministrator.
         * @member {boolean|null|undefined} isSystemAdministrator
         * @memberof User.UserInfoModel
         * @instance
         */
        UserInfoModel.prototype.isSystemAdministrator = null;

        /**
         * UserInfoModel isSystemAccount.
         * @member {boolean|null|undefined} isSystemAccount
         * @memberof User.UserInfoModel
         * @instance
         */
        UserInfoModel.prototype.isSystemAccount = null;

        /**
         * UserInfoModel firstName.
         * @member {string|null|undefined} firstName
         * @memberof User.UserInfoModel
         * @instance
         */
        UserInfoModel.prototype.firstName = null;

        /**
         * UserInfoModel lastName.
         * @member {string|null|undefined} lastName
         * @memberof User.UserInfoModel
         * @instance
         */
        UserInfoModel.prototype.lastName = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UserInfoModel _companyId.
         * @member {"companyId"|undefined} _companyId
         * @memberof User.UserInfoModel
         * @instance
         */
        Object.defineProperty(UserInfoModel.prototype, "_companyId", {
            get: $util.oneOfGetter($oneOfFields = ["companyId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserInfoModel _isSupportUser.
         * @member {"isSupportUser"|undefined} _isSupportUser
         * @memberof User.UserInfoModel
         * @instance
         */
        Object.defineProperty(UserInfoModel.prototype, "_isSupportUser", {
            get: $util.oneOfGetter($oneOfFields = ["isSupportUser"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserInfoModel _displayName.
         * @member {"displayName"|undefined} _displayName
         * @memberof User.UserInfoModel
         * @instance
         */
        Object.defineProperty(UserInfoModel.prototype, "_displayName", {
            get: $util.oneOfGetter($oneOfFields = ["displayName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserInfoModel _companyName.
         * @member {"companyName"|undefined} _companyName
         * @memberof User.UserInfoModel
         * @instance
         */
        Object.defineProperty(UserInfoModel.prototype, "_companyName", {
            get: $util.oneOfGetter($oneOfFields = ["companyName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserInfoModel _withHoldingTax.
         * @member {"withHoldingTax"|undefined} _withHoldingTax
         * @memberof User.UserInfoModel
         * @instance
         */
        Object.defineProperty(UserInfoModel.prototype, "_withHoldingTax", {
            get: $util.oneOfGetter($oneOfFields = ["withHoldingTax"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserInfoModel _locale.
         * @member {"locale"|undefined} _locale
         * @memberof User.UserInfoModel
         * @instance
         */
        Object.defineProperty(UserInfoModel.prototype, "_locale", {
            get: $util.oneOfGetter($oneOfFields = ["locale"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserInfoModel _sessionId.
         * @member {"sessionId"|undefined} _sessionId
         * @memberof User.UserInfoModel
         * @instance
         */
        Object.defineProperty(UserInfoModel.prototype, "_sessionId", {
            get: $util.oneOfGetter($oneOfFields = ["sessionId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserInfoModel _userId.
         * @member {"userId"|undefined} _userId
         * @memberof User.UserInfoModel
         * @instance
         */
        Object.defineProperty(UserInfoModel.prototype, "_userId", {
            get: $util.oneOfGetter($oneOfFields = ["userId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserInfoModel _countryCode.
         * @member {"countryCode"|undefined} _countryCode
         * @memberof User.UserInfoModel
         * @instance
         */
        Object.defineProperty(UserInfoModel.prototype, "_countryCode", {
            get: $util.oneOfGetter($oneOfFields = ["countryCode"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserInfoModel _isCustomerAdministrator.
         * @member {"isCustomerAdministrator"|undefined} _isCustomerAdministrator
         * @memberof User.UserInfoModel
         * @instance
         */
        Object.defineProperty(UserInfoModel.prototype, "_isCustomerAdministrator", {
            get: $util.oneOfGetter($oneOfFields = ["isCustomerAdministrator"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserInfoModel _isSystemAdministrator.
         * @member {"isSystemAdministrator"|undefined} _isSystemAdministrator
         * @memberof User.UserInfoModel
         * @instance
         */
        Object.defineProperty(UserInfoModel.prototype, "_isSystemAdministrator", {
            get: $util.oneOfGetter($oneOfFields = ["isSystemAdministrator"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserInfoModel _isSystemAccount.
         * @member {"isSystemAccount"|undefined} _isSystemAccount
         * @memberof User.UserInfoModel
         * @instance
         */
        Object.defineProperty(UserInfoModel.prototype, "_isSystemAccount", {
            get: $util.oneOfGetter($oneOfFields = ["isSystemAccount"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserInfoModel _firstName.
         * @member {"firstName"|undefined} _firstName
         * @memberof User.UserInfoModel
         * @instance
         */
        Object.defineProperty(UserInfoModel.prototype, "_firstName", {
            get: $util.oneOfGetter($oneOfFields = ["firstName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserInfoModel _lastName.
         * @member {"lastName"|undefined} _lastName
         * @memberof User.UserInfoModel
         * @instance
         */
        Object.defineProperty(UserInfoModel.prototype, "_lastName", {
            get: $util.oneOfGetter($oneOfFields = ["lastName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified UserInfoModel message. Does not implicitly {@link User.UserInfoModel.verify|verify} messages.
         * @function encode
         * @memberof User.UserInfoModel
         * @static
         * @param {User.IUserInfoModel} m UserInfoModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserInfoModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.companyId != null && Object.hasOwnProperty.call(m, "companyId"))
                w.uint32(10).string(m.companyId);
            if (m.isSupportUser != null && Object.hasOwnProperty.call(m, "isSupportUser"))
                w.uint32(16).bool(m.isSupportUser);
            if (m.displayName != null && Object.hasOwnProperty.call(m, "displayName"))
                w.uint32(26).string(m.displayName);
            if (m.companyName != null && Object.hasOwnProperty.call(m, "companyName"))
                w.uint32(34).string(m.companyName);
            if (m.withHoldingTax != null && Object.hasOwnProperty.call(m, "withHoldingTax"))
                w.uint32(41).double(m.withHoldingTax);
            if (m.locale != null && Object.hasOwnProperty.call(m, "locale"))
                w.uint32(50).string(m.locale);
            if (m.sessionId != null && Object.hasOwnProperty.call(m, "sessionId"))
                w.uint32(58).string(m.sessionId);
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(66).string(m.userId);
            if (m.countryCode != null && Object.hasOwnProperty.call(m, "countryCode"))
                w.uint32(74).string(m.countryCode);
            if (m.isCustomerAdministrator != null && Object.hasOwnProperty.call(m, "isCustomerAdministrator"))
                w.uint32(80).bool(m.isCustomerAdministrator);
            if (m.isSystemAdministrator != null && Object.hasOwnProperty.call(m, "isSystemAdministrator"))
                w.uint32(88).bool(m.isSystemAdministrator);
            if (m.isSystemAccount != null && Object.hasOwnProperty.call(m, "isSystemAccount"))
                w.uint32(96).bool(m.isSystemAccount);
            if (m.firstName != null && Object.hasOwnProperty.call(m, "firstName"))
                w.uint32(106).string(m.firstName);
            if (m.lastName != null && Object.hasOwnProperty.call(m, "lastName"))
                w.uint32(114).string(m.lastName);
            return w;
        };

        /**
         * Encodes the specified UserInfoModel message, length delimited. Does not implicitly {@link User.UserInfoModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof User.UserInfoModel
         * @static
         * @param {User.IUserInfoModel} message UserInfoModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserInfoModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserInfoModel message from the specified reader or buffer.
         * @function decode
         * @memberof User.UserInfoModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {User.UserInfoModel} UserInfoModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserInfoModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.User.UserInfoModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.companyId = r.string();
                    break;
                case 2:
                    m.isSupportUser = r.bool();
                    break;
                case 3:
                    m.displayName = r.string();
                    break;
                case 4:
                    m.companyName = r.string();
                    break;
                case 5:
                    m.withHoldingTax = r.double();
                    break;
                case 6:
                    m.locale = r.string();
                    break;
                case 7:
                    m.sessionId = r.string();
                    break;
                case 8:
                    m.userId = r.string();
                    break;
                case 9:
                    m.countryCode = r.string();
                    break;
                case 10:
                    m.isCustomerAdministrator = r.bool();
                    break;
                case 11:
                    m.isSystemAdministrator = r.bool();
                    break;
                case 12:
                    m.isSystemAccount = r.bool();
                    break;
                case 13:
                    m.firstName = r.string();
                    break;
                case 14:
                    m.lastName = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a UserInfoModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof User.UserInfoModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {User.UserInfoModel} UserInfoModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserInfoModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return UserInfoModel;
    })();

    return User;
})();

module.exports = $root;
