import { ComponentRoute } from '@/classes'

const DebtorAdjustmentRoutes = {
  DebtorAdjustmentListView: new ComponentRoute('/debtoradjustments', 'DebtorAdjustmentListView'),
  DebtorAdjustmentView: new ComponentRoute('/debtoradjustments/:invoiceId', 'DebtorAdjustmentView'),
  DebtorAdjustmentDetail: new ComponentRoute('/debtoradjustments/:invoiceId', 'DebtorAdjustmentDetail'),
  DebtorAdjustmentQuickMiscInvoice: new ComponentRoute('miscquick', 'DebtorAdjustmentQuickMiscInvoice'),
  DebtorAdjustmentRevenueTotals: new ComponentRoute('revenuetotals', 'DebtorAdjustmentRevenueTotals')
}

export default DebtorAdjustmentRoutes
