import * as types from './mutation-types'

export default {
  [types.UPDATE_LICENCEEXPIRED](state, value) {
    state.licenceExpired = value
  },
  [types.SHOW_SPINNER](state, value) {
    if (value === true) {
      state.showSpinnerRequestCount++
    } else if (value === false && state.showSpinnerRequestCount > 0) {
      state.showSpinnerRequestCount--
    }
  },
  [types.RESET_SPINNER](state) {
    state.showSpinnerRequestCount = 0
  },
  [types.UPDATE_SPINNER_MESSAGE](state, value) {
    state.spinnerMessage = value
  }
}
