import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
import ProtoBufApi from '@/services/protobuf-api'
import AuditTrailApi from '@/services/audittrail-api'
import { GlAccountTypes } from '@/enums'

export default {
  async getEntity(insurerId, hash) {
    const url = !hash ? `/insurers/${insurerId}` : `/insurers/${insurerId}?hash=${hash}`
    const response = await FqApi.get(url)
    return response.data
  },
  async getNewEntity() {
    const result = await FqApi.get('/insurers/new')
    return result.data
  },
  async getInsurerProto(insurerId) {
    const result = await ProtoBufApi(`/insurers/protobuf/${insurerId}`)
    const protoDefinition = require('../../assets/proto/insurer/InsurerModel.proto')
    const protoMessage = await protoDefinition.Insurer.InsurerModel
    const entity = protoMessage.decode(new Uint8Array(result.data))
    return entity
  },
  putInsurer(insurer, deepdiff) {
    if (deepdiff) {
      insurer.deepDiff = JSON.stringify(deepdiff)
    }
    return FqApi.put(`/insurers/${insurer.insurerId}`, insurer)
  },
  async postInsurer(insurer) {
    return FqApi.post('/insurers', insurer)
  },
  async getGlAccountListCombo() {
    const result = await FqApi.get('/glaccounts/listcombo')
    return result.data.list
  },
  async getGlAccountListComboProto() {
    const result = await ProtoBufApi(`/protobuf/glaccounts/listcombo?glTypes=${GlAccountTypes.Income}`)
    const glAccountProto = require('../../assets/proto/listcombo/GlAccount.proto')
    const accountMessage = await glAccountProto.GlAccount.GlAccountListComboModel
    const entity = accountMessage.decode(new Uint8Array(result.data))
    return entity.glAccounts
  },
  async getQuoteTemplateListComboProto() {
    const result = await ProtoBufApi('/protobuf/quotetemplates/listcombo')
    const quoteTemplateProto = require('../../assets/proto/listcombo/QuoteTemplate.proto')
    const qtMessage = quoteTemplateProto.QuoteTemplate.QuoteTemplateListComboModel
    const entity = qtMessage.decode(new Uint8Array(result.data))
    return entity.quoteTemplates
  },
  async getStateListComboProto() {
    const result = await ProtoBufApi('/protobuf/country/states/listcombo')
    const stateProto = require('../../assets/proto/listcombo/States.proto')
    const protoMessage = stateProto.State.StateListComboModel
    const entity = protoMessage.decode(new Uint8Array(result.data))
    return entity.states
  },
  async getArnieBrand() {
    const result = await FqApi.get('/settings/arniebrands')
    return result.data.list
  },
  searchInsurerByName(key) {
    return FqApi.get('/insurers/searchname?key=' + key)
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseInsurerFilter(filter)
    const url = `/insurers/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseInsurerFilter(filter) {
    const insurerProto = require('../../assets/proto/insurer/InsurerFilter.proto')
    const filterMessage = insurerProto.InsurerFilter.InsurerFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getAccessRoute(codeName, type) {
    const protoDefinition = require('../../assets/proto/UserAccessModel.proto')
    const message = protoDefinition.UserAccess.UserAccessModel
    const url = `/access/${codeName}/${type}`
    const result = await ProtoBufApi.get(url)
    return message.decode(new Uint8Array(result.data))
  },
  async getModules() {
    const result = await FqApi.get('/companies/modules')
    return result.data
  },
  async getDefaultNtarConsumables() {
    const result = await FqApi.get('/insurers/ntarconsumables')
    return result.data
  },
  async assertUsed(entityId) {
    const result = await FqApi.get(`/insurers/assertused/${entityId}`)
    return result.data
  },
  async assertNameExists(id, name) {
    if (name) {
      const result = await FqApi.get(`/insurers/assertexists/${id}?name=${name}`)
      return result.data
    } else {
      return false
    }
  },
  async getOpgRate(insurerId, opgCode) {
    const url = `/insurers/opgrate/${insurerId}/${opgCode}`
    const result = await FqApi.get(url)
    return result.data
  },
  async testPostDeepDiff(deepdiff) {
    // console.log(deepdiff)
    return AuditTrailApi.post(`/v1/audittrails?value=${deepdiff}`)
  },
  async testPostDeepDiffObject(deepdiff) {
    // console.log(deepdiff)
    return AuditTrailApi.post('/v1/audittrails/deepdiff', deepdiff)
  },
  clearPreviousSessionStorage(sessionId) {
    if (sessionId) {
      const regAll = new RegExp('insurer')
      const regCurrent = new RegExp(`${sessionId}`)
      Object.keys(sessionStorage)
        .filter(function(s) {
          return regAll.test(s) && !regCurrent.test(s)
        })
        .forEach(function(s) {
          // console.log(s)
          sessionStorage.removeItem(s)
        })
    }
  },
  serialiseQuoteAvailableMiscFilter(filter) {
    const entityProto = require('../../assets/proto/quoteitem/QuoteAvailableMiscFilter.proto')
    const filterMessage = entityProto.QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getAvailableMiscItems(filter) {
    const serialised = this.serialiseQuoteAvailableMiscFilter(filter)
    const result = await FqApi.get(`quotes/availables/miscs/${serialised}`)
    return result.data
  },
  async getAvailableMiscItemsProto(filter) {
    const serialised = this.serialiseQuoteAvailableMiscFilter(filter)
    const url = `/miscitems/protobuf/list/${serialised}`
    const result = await ProtoBufApi(url)
    const protoDefinition = require('../../assets/proto/quoteitem/QuoteAvailableMiscItems.proto')
    const binaryMessage = protoDefinition.QuoteAvailableMiscItems.QuoteAvailableMiscItemsModel
    const entity = binaryMessage.decode(new Uint8Array(result.data))
    return entity.items
  },
  async testPostAuditTrail(companyId, userId, entityId, entityType, deepdiff) {
    // console.log(deepdiff)
    const audtiTrailItem = {
      auditTrailId: null,
      performedAt: '',
      performedBy: userId,
      userRole: '',
      userAgent: '',
      userHostAddress: '',
      userHostName: '',
      url: '',
      companyId: companyId,
      entityId: entityId,
      entityType: entityType,
      operationType: '',
      deepdiff: JSON.stringify(deepdiff)
    }
    return AuditTrailApi.post('/v1/audittrails/audittrailitem', audtiTrailItem)
  }
}
