export class ExtraVehicleInformationModel {
  constructor() {
    this.make = ''
    this.yearOfManufacture = null
    this.monthOfManufacture = null
    this.bodyShape = ''
    this.transmissionType = ''
    this.numberOfCylinders = ''
    this.vinChassis = ''
    this.color = ''
    this.paintType = ''
    this.odometer = null
    this.fuelLevel = null
    this.damageDescription = ''
    this.oldDamageDescription = ''
  }
}
