import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('userSetting', {
      $printOptions: 'printOptions',
      $showMoreVehicle: 'showMoreVehicle',
      $showMoreCustomer: 'showMoreCustomer',
      $showMoreInsurer: 'showMoreInsurer'
    })
  },
  methods: {
    ...mapActions('userSetting', {
      $loadPrintOptions: 'loadPrintOptions',
      $setInvoicePrintShowItemNo: 'setInvoicePrintShowItemNo',
      $toggleShowMoreVehicle: 'toggleShowMoreVehicle',
      $toggleShowMoreCustomer: 'toggleShowMoreCustomer',
      $toggleShowMoreInsurer: 'toggleShowMoreInsurer'
    })
  }
}
