export default class PartsCheckDataLinesModel {
  constructor() {
    this.QuoteNo = '',
    this.LineNo='',
    this.AddlQuoteNo='',
    this.LineQty = '',
    this.ItemType = '',
    this.PartNR = '',
    this.TradePrice = '',
    this.Description = ''
  }
}
