import routerTypes from './route-types'

// Lazy-load components. Webpack will split each into individual file
const SalesAnalysisListView = r => require.ensure([], () => r(require('./SalesAnalysisListView.vue')), 'salesanalysislist-chunk')

export default [
  {
    path: routerTypes.SalesAnalysisListView.path,
    name: routerTypes.SalesAnalysisListView.name,
    component: SalesAnalysisListView,
    meta: {
      id: 'A006',
      route: 'listing',
      title: 'Sales Analysis',
      fkey: 'salesanalysis|filter',
      report: 'rptSalesList',
      reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
    }
  }
]
