// import Guid from '@/components/Guid'

export default class SubscriptionSummaryModel {
  constructor(companyId = '', userId = '', amount = 0, previousBalance = 0, balance = 0, notes = '', totalRows = 0) {
    this.subscriptionId = 99999  //Guid.newGuid()
    this.companyId = companyId
    const utcDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString()
    this.purchaseDate = utcDate.split('.')[0] + 'Z'
    this.amount = amount
    this.previousBalance = previousBalance
    this.balance = balance
    this.usage = 0
    this.notes = notes
    this.createdBy = userId
    this.createdDate = new Date().toISOString()
    this.modifiedby = userId
    this.modifiedDate = new Date().toISOString()
    this.deleted = false
    this.active = true
    this.totalRows = totalRows
    this.isNew = true
  }
}