import Guid from '@/components/Guid'

export default class PurchaseOrderItemModel {
//constructor(companyId, purchaseOrderId, quoteItemId, itemNo, description, dealerCost, quotedItemQty = 0, totalOrderedQty = 0, unitPrice = 0, partNo = '', mark = '', side = '') {
  constructor(companyId, purchaseOrderId, quoteItemId, itemNo, description = '', partNo = '', quotedItemQty = 0, otherPoOrderedQty = 0, side = null, mark = null, totalInStock = 0, quantity = 0, unitPrice = 0, dealerCost = 0) {
    // const quantity = itemQuantity - quantityOrdered
    // const dealerCost = unitPrice * quantity
    this.companyId = companyId
    this.dealerCost = dealerCost
    this.deleted = false
    this.purchaseOrderId = purchaseOrderId
    this.itemNo = itemNo
    this.description = description
    this.purchaseOrderItemId = Guid.newGuid()
    this.isDeleted = false
    this.isNew = true
    this.quantity = quantity
    this.receivedQty = 0
    this.returnQty = 0
    this.otherPoOrderedQty = otherPoOrderedQty
    this.totalInStock = totalInStock
    this.unitPrice = unitPrice
    this.quoteItemId = quoteItemId
    this.quotedItemQty = quotedItemQty
    this.partNo = partNo
    this.mark = mark
    this.side = side
    this.tag = null
    this.quoteItemDeleted = false
    this.createdBy = ''
    this.createdDate = ''
    this.modifiedBy = ''
    this.modifiedDate = ''
  }
}
