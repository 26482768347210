import routeTypes from './route-types'
//import store from '@/store'

const DealerCreditEntryView = r => require.ensure([], () => r(require('./DealerCreditEntryView.vue')), 'dealercreditentry-chunk')
const DealerCreditEntryAttachments = r => require.ensure([], () => r(require('./DealerCreditEntryAttachments.vue')), 'dealercreditentry-chunk')
// const DealerCreditEntryDetail = r => require.ensure([], () => r(require('./DealerCreditEntryDetail.vue')), 'dealercreditentry-chunk')

export default [
  {
    path: routeTypes.DealerCreditEntryView.path,
    name: routeTypes.DealerCreditEntryView.name,
    component: DealerCreditEntryView,
    meta: {
      id: 'A060',
      route: 'listing',
      title: 'Dealer Credit Entry',
      fkey: 'dealercreditentry|filter',
      report: 'AT_DealerCreditEntry',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    },
    children: [
      {
        path: routeTypes.DealerCreditEntryAttachments.path,
        name: routeTypes.DealerCreditEntryAttachments.name,
        component: DealerCreditEntryAttachments,
        props: {
          isTiled: true
        },
        meta: {
          title: 'Attachments'
        }
      }
    ]
  }
]
