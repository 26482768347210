export default {
  itemTypes: [
    { code: 'RR', category: 'Labour', valueCalculationType: 'Labour', displayName: 'R&R' },
    { code: 'RWA', category: 'Labour', valueCalculationType: 'Labour', displayName: 'RWA' },
    { code: 'REP', category: 'Labour', valueCalculationType: 'Labour', displayName: 'Repair' },
    { code: 'PAINT', category: 'Labour', valueCalculationType: 'Labour', displayName: 'Paint' },
    { code: 'OPG', category: 'OPG', valueCalculationType: 'Labour', displayName: 'OPG' },
    { code: 'MECH', category: 'OtherLabour', valueCalculationType: 'Labour', displayName: 'Mechanical' },
    { code: 'CD', category: 'OtherLabour', valueCalculationType: 'Labour', displayName: 'CD' },
    { code: 'CRUSH', category: 'OtherLabour', valueCalculationType: 'Labour', displayName: 'Crush' },
    { code: 'FIBER', category: 'OtherLabour', valueCalculationType: 'Labour', displayName: 'Fiber' },
    { code: 'PART', category: 'Part', displayName: 'Part' },
    { code: 'MISC', category: 'Misc', displayName: 'Misc' },
    { code: 'SUBL', category: 'Sublet', displayName: 'Sublet' },
    { code: 'PDRRR', category: 'PDR', valueCalculationType: 'Labour', displayName: 'PDRRR' },
    { code: 'PDRRE', category: 'PDR', valueCalculationType: 'Labour', displayName: 'PDRRE' }
  ]
}