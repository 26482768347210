/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.UserAccess = (function() {

    /**
     * Namespace UserAccess.
     * @exports UserAccess
     * @namespace
     */
    var UserAccess = {};

    UserAccess.UserAccessModel = (function() {

        /**
         * Properties of a UserAccessModel.
         * @memberof UserAccess
         * @interface IUserAccessModel
         * @property {string|null} [code] UserAccessModel code
         * @property {string|null} [version] UserAccessModel version
         * @property {string|null} [route] UserAccessModel route
         * @property {string|null} [link] UserAccessModel link
         * @property {boolean|null} [isRead] UserAccessModel isRead
         * @property {boolean|null} [isDelete] UserAccessModel isDelete
         * @property {boolean|null} [isAdd] UserAccessModel isAdd
         * @property {boolean|null} [isEdit] UserAccessModel isEdit
         * @property {boolean|null} [isPrint] UserAccessModel isPrint
         */

        /**
         * Constructs a new UserAccessModel.
         * @memberof UserAccess
         * @classdesc Represents a UserAccessModel.
         * @implements IUserAccessModel
         * @constructor
         * @param {UserAccess.IUserAccessModel=} [p] Properties to set
         */
        function UserAccessModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserAccessModel code.
         * @member {string|null|undefined} code
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        UserAccessModel.prototype.code = null;

        /**
         * UserAccessModel version.
         * @member {string|null|undefined} version
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        UserAccessModel.prototype.version = null;

        /**
         * UserAccessModel route.
         * @member {string|null|undefined} route
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        UserAccessModel.prototype.route = null;

        /**
         * UserAccessModel link.
         * @member {string|null|undefined} link
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        UserAccessModel.prototype.link = null;

        /**
         * UserAccessModel isRead.
         * @member {boolean|null|undefined} isRead
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        UserAccessModel.prototype.isRead = null;

        /**
         * UserAccessModel isDelete.
         * @member {boolean|null|undefined} isDelete
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        UserAccessModel.prototype.isDelete = null;

        /**
         * UserAccessModel isAdd.
         * @member {boolean|null|undefined} isAdd
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        UserAccessModel.prototype.isAdd = null;

        /**
         * UserAccessModel isEdit.
         * @member {boolean|null|undefined} isEdit
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        UserAccessModel.prototype.isEdit = null;

        /**
         * UserAccessModel isPrint.
         * @member {boolean|null|undefined} isPrint
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        UserAccessModel.prototype.isPrint = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UserAccessModel _code.
         * @member {"code"|undefined} _code
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        Object.defineProperty(UserAccessModel.prototype, "_code", {
            get: $util.oneOfGetter($oneOfFields = ["code"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserAccessModel _version.
         * @member {"version"|undefined} _version
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        Object.defineProperty(UserAccessModel.prototype, "_version", {
            get: $util.oneOfGetter($oneOfFields = ["version"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserAccessModel _route.
         * @member {"route"|undefined} _route
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        Object.defineProperty(UserAccessModel.prototype, "_route", {
            get: $util.oneOfGetter($oneOfFields = ["route"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserAccessModel _link.
         * @member {"link"|undefined} _link
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        Object.defineProperty(UserAccessModel.prototype, "_link", {
            get: $util.oneOfGetter($oneOfFields = ["link"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserAccessModel _isRead.
         * @member {"isRead"|undefined} _isRead
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        Object.defineProperty(UserAccessModel.prototype, "_isRead", {
            get: $util.oneOfGetter($oneOfFields = ["isRead"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserAccessModel _isDelete.
         * @member {"isDelete"|undefined} _isDelete
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        Object.defineProperty(UserAccessModel.prototype, "_isDelete", {
            get: $util.oneOfGetter($oneOfFields = ["isDelete"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserAccessModel _isAdd.
         * @member {"isAdd"|undefined} _isAdd
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        Object.defineProperty(UserAccessModel.prototype, "_isAdd", {
            get: $util.oneOfGetter($oneOfFields = ["isAdd"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserAccessModel _isEdit.
         * @member {"isEdit"|undefined} _isEdit
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        Object.defineProperty(UserAccessModel.prototype, "_isEdit", {
            get: $util.oneOfGetter($oneOfFields = ["isEdit"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * UserAccessModel _isPrint.
         * @member {"isPrint"|undefined} _isPrint
         * @memberof UserAccess.UserAccessModel
         * @instance
         */
        Object.defineProperty(UserAccessModel.prototype, "_isPrint", {
            get: $util.oneOfGetter($oneOfFields = ["isPrint"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified UserAccessModel message. Does not implicitly {@link UserAccess.UserAccessModel.verify|verify} messages.
         * @function encode
         * @memberof UserAccess.UserAccessModel
         * @static
         * @param {UserAccess.IUserAccessModel} m UserAccessModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserAccessModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                w.uint32(10).string(m.code);
            if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                w.uint32(18).string(m.version);
            if (m.route != null && Object.hasOwnProperty.call(m, "route"))
                w.uint32(26).string(m.route);
            if (m.link != null && Object.hasOwnProperty.call(m, "link"))
                w.uint32(34).string(m.link);
            if (m.isRead != null && Object.hasOwnProperty.call(m, "isRead"))
                w.uint32(40).bool(m.isRead);
            if (m.isDelete != null && Object.hasOwnProperty.call(m, "isDelete"))
                w.uint32(48).bool(m.isDelete);
            if (m.isAdd != null && Object.hasOwnProperty.call(m, "isAdd"))
                w.uint32(56).bool(m.isAdd);
            if (m.isEdit != null && Object.hasOwnProperty.call(m, "isEdit"))
                w.uint32(64).bool(m.isEdit);
            if (m.isPrint != null && Object.hasOwnProperty.call(m, "isPrint"))
                w.uint32(72).bool(m.isPrint);
            return w;
        };

        /**
         * Encodes the specified UserAccessModel message, length delimited. Does not implicitly {@link UserAccess.UserAccessModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof UserAccess.UserAccessModel
         * @static
         * @param {UserAccess.IUserAccessModel} message UserAccessModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserAccessModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserAccessModel message from the specified reader or buffer.
         * @function decode
         * @memberof UserAccess.UserAccessModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {UserAccess.UserAccessModel} UserAccessModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserAccessModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.UserAccess.UserAccessModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.code = r.string();
                    break;
                case 2:
                    m.version = r.string();
                    break;
                case 3:
                    m.route = r.string();
                    break;
                case 4:
                    m.link = r.string();
                    break;
                case 5:
                    m.isRead = r.bool();
                    break;
                case 6:
                    m.isDelete = r.bool();
                    break;
                case 7:
                    m.isAdd = r.bool();
                    break;
                case 8:
                    m.isEdit = r.bool();
                    break;
                case 9:
                    m.isPrint = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a UserAccessModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof UserAccess.UserAccessModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {UserAccess.UserAccessModel} UserAccessModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserAccessModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return UserAccessModel;
    })();

    return UserAccess;
})();

module.exports = $root;
