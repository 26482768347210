/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.NtarLoadingValues = (function() {

    /**
     * Namespace NtarLoadingValues.
     * @exports NtarLoadingValues
     * @namespace
     */
    var NtarLoadingValues = {};

    NtarLoadingValues.NtarLoadingValues = (function() {

        /**
         * Properties of a NtarLoadingValues.
         * @memberof NtarLoadingValues
         * @interface INtarLoadingValues
         * @property {Array.<NtarLoadingValues.NtarLoadingValues.INtarLoadingValueModel>|null} [items] NtarLoadingValues items
         */

        /**
         * Constructs a new NtarLoadingValues.
         * @memberof NtarLoadingValues
         * @classdesc Represents a NtarLoadingValues.
         * @implements INtarLoadingValues
         * @constructor
         * @param {NtarLoadingValues.INtarLoadingValues=} [p] Properties to set
         */
        function NtarLoadingValues(p) {
            this.items = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * NtarLoadingValues items.
         * @member {Array.<NtarLoadingValues.NtarLoadingValues.INtarLoadingValueModel>} items
         * @memberof NtarLoadingValues.NtarLoadingValues
         * @instance
         */
        NtarLoadingValues.prototype.items = $util.emptyArray;

        /**
         * Encodes the specified NtarLoadingValues message. Does not implicitly {@link NtarLoadingValues.NtarLoadingValues.verify|verify} messages.
         * @function encode
         * @memberof NtarLoadingValues.NtarLoadingValues
         * @static
         * @param {NtarLoadingValues.INtarLoadingValues} m NtarLoadingValues message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NtarLoadingValues.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.items != null && m.items.length) {
                for (var i = 0; i < m.items.length; ++i)
                    $root.NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel.encode(m.items[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Encodes the specified NtarLoadingValues message, length delimited. Does not implicitly {@link NtarLoadingValues.NtarLoadingValues.verify|verify} messages.
         * @function encodeDelimited
         * @memberof NtarLoadingValues.NtarLoadingValues
         * @static
         * @param {NtarLoadingValues.INtarLoadingValues} message NtarLoadingValues message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NtarLoadingValues.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NtarLoadingValues message from the specified reader or buffer.
         * @function decode
         * @memberof NtarLoadingValues.NtarLoadingValues
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {NtarLoadingValues.NtarLoadingValues} NtarLoadingValues
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NtarLoadingValues.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.NtarLoadingValues.NtarLoadingValues();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.items && m.items.length))
                        m.items = [];
                    m.items.push($root.NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a NtarLoadingValues message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof NtarLoadingValues.NtarLoadingValues
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {NtarLoadingValues.NtarLoadingValues} NtarLoadingValues
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NtarLoadingValues.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        NtarLoadingValues.NtarLoadingValueModel = (function() {

            /**
             * Properties of a NtarLoadingValueModel.
             * @memberof NtarLoadingValues.NtarLoadingValues
             * @interface INtarLoadingValueModel
             * @property {string|null} [ntarLoadingValueId] NtarLoadingValueModel ntarLoadingValueId
             * @property {number|null} [loadingValueType] NtarLoadingValueModel loadingValueType
             * @property {string|null} [description] NtarLoadingValueModel description
             * @property {string|null} [category] NtarLoadingValueModel category
             * @property {number|null} [value] NtarLoadingValueModel value
             * @property {number|null} [lowerThreshold] NtarLoadingValueModel lowerThreshold
             * @property {number|null} [upperThreshold] NtarLoadingValueModel upperThreshold
             */

            /**
             * Constructs a new NtarLoadingValueModel.
             * @memberof NtarLoadingValues.NtarLoadingValues
             * @classdesc Represents a NtarLoadingValueModel.
             * @implements INtarLoadingValueModel
             * @constructor
             * @param {NtarLoadingValues.NtarLoadingValues.INtarLoadingValueModel=} [p] Properties to set
             */
            function NtarLoadingValueModel(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * NtarLoadingValueModel ntarLoadingValueId.
             * @member {string} ntarLoadingValueId
             * @memberof NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel
             * @instance
             */
            NtarLoadingValueModel.prototype.ntarLoadingValueId = "";

            /**
             * NtarLoadingValueModel loadingValueType.
             * @member {number} loadingValueType
             * @memberof NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel
             * @instance
             */
            NtarLoadingValueModel.prototype.loadingValueType = 0;

            /**
             * NtarLoadingValueModel description.
             * @member {string} description
             * @memberof NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel
             * @instance
             */
            NtarLoadingValueModel.prototype.description = "";

            /**
             * NtarLoadingValueModel category.
             * @member {string} category
             * @memberof NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel
             * @instance
             */
            NtarLoadingValueModel.prototype.category = "";

            /**
             * NtarLoadingValueModel value.
             * @member {number} value
             * @memberof NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel
             * @instance
             */
            NtarLoadingValueModel.prototype.value = 0;

            /**
             * NtarLoadingValueModel lowerThreshold.
             * @member {number} lowerThreshold
             * @memberof NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel
             * @instance
             */
            NtarLoadingValueModel.prototype.lowerThreshold = 0;

            /**
             * NtarLoadingValueModel upperThreshold.
             * @member {number} upperThreshold
             * @memberof NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel
             * @instance
             */
            NtarLoadingValueModel.prototype.upperThreshold = 0;

            /**
             * Encodes the specified NtarLoadingValueModel message. Does not implicitly {@link NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel.verify|verify} messages.
             * @function encode
             * @memberof NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel
             * @static
             * @param {NtarLoadingValues.NtarLoadingValues.INtarLoadingValueModel} m NtarLoadingValueModel message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NtarLoadingValueModel.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.ntarLoadingValueId != null && Object.hasOwnProperty.call(m, "ntarLoadingValueId"))
                    w.uint32(10).string(m.ntarLoadingValueId);
                if (m.loadingValueType != null && Object.hasOwnProperty.call(m, "loadingValueType"))
                    w.uint32(16).int32(m.loadingValueType);
                if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                    w.uint32(26).string(m.description);
                if (m.category != null && Object.hasOwnProperty.call(m, "category"))
                    w.uint32(34).string(m.category);
                if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                    w.uint32(41).double(m.value);
                if (m.lowerThreshold != null && Object.hasOwnProperty.call(m, "lowerThreshold"))
                    w.uint32(49).double(m.lowerThreshold);
                if (m.upperThreshold != null && Object.hasOwnProperty.call(m, "upperThreshold"))
                    w.uint32(57).double(m.upperThreshold);
                return w;
            };

            /**
             * Encodes the specified NtarLoadingValueModel message, length delimited. Does not implicitly {@link NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel
             * @static
             * @param {NtarLoadingValues.NtarLoadingValues.INtarLoadingValueModel} message NtarLoadingValueModel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NtarLoadingValueModel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a NtarLoadingValueModel message from the specified reader or buffer.
             * @function decode
             * @memberof NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel} NtarLoadingValueModel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NtarLoadingValueModel.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.ntarLoadingValueId = r.string();
                        break;
                    case 2:
                        m.loadingValueType = r.int32();
                        break;
                    case 3:
                        m.description = r.string();
                        break;
                    case 4:
                        m.category = r.string();
                        break;
                    case 5:
                        m.value = r.double();
                        break;
                    case 6:
                        m.lowerThreshold = r.double();
                        break;
                    case 7:
                        m.upperThreshold = r.double();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Decodes a NtarLoadingValueModel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {NtarLoadingValues.NtarLoadingValues.NtarLoadingValueModel} NtarLoadingValueModel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NtarLoadingValueModel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            return NtarLoadingValueModel;
        })();

        return NtarLoadingValues;
    })();

    return NtarLoadingValues;
})();

$root.bcl = (function() {

    /**
     * Namespace bcl.
     * @exports bcl
     * @namespace
     */
    var bcl = {};

    bcl.TimeSpan = (function() {

        /**
         * Properties of a TimeSpan.
         * @memberof bcl
         * @interface ITimeSpan
         * @property {number|Long|null} [value] TimeSpan value
         * @property {bcl.TimeSpan.TimeSpanScale|null} [scale] TimeSpan scale
         */

        /**
         * Constructs a new TimeSpan.
         * @memberof bcl
         * @classdesc Represents a TimeSpan.
         * @implements ITimeSpan
         * @constructor
         * @param {bcl.ITimeSpan=} [p] Properties to set
         */
        function TimeSpan(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TimeSpan value.
         * @member {number|Long} value
         * @memberof bcl.TimeSpan
         * @instance
         */
        TimeSpan.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TimeSpan scale.
         * @member {bcl.TimeSpan.TimeSpanScale} scale
         * @memberof bcl.TimeSpan
         * @instance
         */
        TimeSpan.prototype.scale = 0;

        /**
         * Encodes the specified TimeSpan message. Does not implicitly {@link bcl.TimeSpan.verify|verify} messages.
         * @function encode
         * @memberof bcl.TimeSpan
         * @static
         * @param {bcl.ITimeSpan} m TimeSpan message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeSpan.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(8).sint64(m.value);
            if (m.scale != null && Object.hasOwnProperty.call(m, "scale"))
                w.uint32(16).int32(m.scale);
            return w;
        };

        /**
         * Encodes the specified TimeSpan message, length delimited. Does not implicitly {@link bcl.TimeSpan.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.TimeSpan
         * @static
         * @param {bcl.ITimeSpan} message TimeSpan message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeSpan.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TimeSpan message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.TimeSpan
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.TimeSpan} TimeSpan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeSpan.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.TimeSpan();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.value = r.sint64();
                    break;
                case 2:
                    m.scale = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a TimeSpan message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.TimeSpan
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.TimeSpan} TimeSpan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeSpan.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * TimeSpanScale enum.
         * @name bcl.TimeSpan.TimeSpanScale
         * @enum {number}
         * @property {number} DAYS=0 DAYS value
         * @property {number} HOURS=1 HOURS value
         * @property {number} MINUTES=2 MINUTES value
         * @property {number} SECONDS=3 SECONDS value
         * @property {number} MILLISECONDS=4 MILLISECONDS value
         * @property {number} MINMAX=15 MINMAX value
         */
        TimeSpan.TimeSpanScale = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DAYS"] = 0;
            values[valuesById[1] = "HOURS"] = 1;
            values[valuesById[2] = "MINUTES"] = 2;
            values[valuesById[3] = "SECONDS"] = 3;
            values[valuesById[4] = "MILLISECONDS"] = 4;
            values[valuesById[15] = "MINMAX"] = 15;
            return values;
        })();

        return TimeSpan;
    })();

    bcl.DateTime = (function() {

        /**
         * Properties of a DateTime.
         * @memberof bcl
         * @interface IDateTime
         * @property {number|Long|null} [value] DateTime value
         * @property {bcl.DateTime.TimeSpanScale|null} [scale] DateTime scale
         */

        /**
         * Constructs a new DateTime.
         * @memberof bcl
         * @classdesc Represents a DateTime.
         * @implements IDateTime
         * @constructor
         * @param {bcl.IDateTime=} [p] Properties to set
         */
        function DateTime(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DateTime value.
         * @member {number|Long} value
         * @memberof bcl.DateTime
         * @instance
         */
        DateTime.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DateTime scale.
         * @member {bcl.DateTime.TimeSpanScale} scale
         * @memberof bcl.DateTime
         * @instance
         */
        DateTime.prototype.scale = 0;

        /**
         * Encodes the specified DateTime message. Does not implicitly {@link bcl.DateTime.verify|verify} messages.
         * @function encode
         * @memberof bcl.DateTime
         * @static
         * @param {bcl.IDateTime} m DateTime message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateTime.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(8).sint64(m.value);
            if (m.scale != null && Object.hasOwnProperty.call(m, "scale"))
                w.uint32(16).int32(m.scale);
            return w;
        };

        /**
         * Encodes the specified DateTime message, length delimited. Does not implicitly {@link bcl.DateTime.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.DateTime
         * @static
         * @param {bcl.IDateTime} message DateTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateTime.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DateTime message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.DateTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.DateTime} DateTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateTime.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.DateTime();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.value = r.sint64();
                    break;
                case 2:
                    m.scale = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a DateTime message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.DateTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.DateTime} DateTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateTime.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * TimeSpanScale enum.
         * @name bcl.DateTime.TimeSpanScale
         * @enum {number}
         * @property {number} DAYS=0 DAYS value
         * @property {number} HOURS=1 HOURS value
         * @property {number} MINUTES=2 MINUTES value
         * @property {number} SECONDS=3 SECONDS value
         * @property {number} MILLISECONDS=4 MILLISECONDS value
         * @property {number} MINMAX=15 MINMAX value
         */
        DateTime.TimeSpanScale = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DAYS"] = 0;
            values[valuesById[1] = "HOURS"] = 1;
            values[valuesById[2] = "MINUTES"] = 2;
            values[valuesById[3] = "SECONDS"] = 3;
            values[valuesById[4] = "MILLISECONDS"] = 4;
            values[valuesById[15] = "MINMAX"] = 15;
            return values;
        })();

        return DateTime;
    })();

    bcl.Guid = (function() {

        /**
         * Properties of a Guid.
         * @memberof bcl
         * @interface IGuid
         * @property {number|Long|null} [lo] Guid lo
         * @property {number|Long|null} [hi] Guid hi
         */

        /**
         * Constructs a new Guid.
         * @memberof bcl
         * @classdesc Represents a Guid.
         * @implements IGuid
         * @constructor
         * @param {bcl.IGuid=} [p] Properties to set
         */
        function Guid(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Guid lo.
         * @member {number|Long} lo
         * @memberof bcl.Guid
         * @instance
         */
        Guid.prototype.lo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Guid hi.
         * @member {number|Long} hi
         * @memberof bcl.Guid
         * @instance
         */
        Guid.prototype.hi = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Encodes the specified Guid message. Does not implicitly {@link bcl.Guid.verify|verify} messages.
         * @function encode
         * @memberof bcl.Guid
         * @static
         * @param {bcl.IGuid} m Guid message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Guid.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.lo != null && Object.hasOwnProperty.call(m, "lo"))
                w.uint32(9).fixed64(m.lo);
            if (m.hi != null && Object.hasOwnProperty.call(m, "hi"))
                w.uint32(17).fixed64(m.hi);
            return w;
        };

        /**
         * Encodes the specified Guid message, length delimited. Does not implicitly {@link bcl.Guid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.Guid
         * @static
         * @param {bcl.IGuid} message Guid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Guid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Guid message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.Guid
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.Guid} Guid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Guid.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.Guid();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.lo = r.fixed64();
                    break;
                case 2:
                    m.hi = r.fixed64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a Guid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.Guid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.Guid} Guid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Guid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return Guid;
    })();

    bcl.Decimal = (function() {

        /**
         * Properties of a Decimal.
         * @memberof bcl
         * @interface IDecimal
         * @property {number|Long|null} [lo] Decimal lo
         * @property {number|null} [hi] Decimal hi
         * @property {number|null} [signScale] Decimal signScale
         */

        /**
         * Constructs a new Decimal.
         * @memberof bcl
         * @classdesc Represents a Decimal.
         * @implements IDecimal
         * @constructor
         * @param {bcl.IDecimal=} [p] Properties to set
         */
        function Decimal(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Decimal lo.
         * @member {number|Long} lo
         * @memberof bcl.Decimal
         * @instance
         */
        Decimal.prototype.lo = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Decimal hi.
         * @member {number} hi
         * @memberof bcl.Decimal
         * @instance
         */
        Decimal.prototype.hi = 0;

        /**
         * Decimal signScale.
         * @member {number} signScale
         * @memberof bcl.Decimal
         * @instance
         */
        Decimal.prototype.signScale = 0;

        /**
         * Encodes the specified Decimal message. Does not implicitly {@link bcl.Decimal.verify|verify} messages.
         * @function encode
         * @memberof bcl.Decimal
         * @static
         * @param {bcl.IDecimal} m Decimal message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Decimal.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.lo != null && Object.hasOwnProperty.call(m, "lo"))
                w.uint32(8).uint64(m.lo);
            if (m.hi != null && Object.hasOwnProperty.call(m, "hi"))
                w.uint32(16).uint32(m.hi);
            if (m.signScale != null && Object.hasOwnProperty.call(m, "signScale"))
                w.uint32(24).sint32(m.signScale);
            return w;
        };

        /**
         * Encodes the specified Decimal message, length delimited. Does not implicitly {@link bcl.Decimal.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.Decimal
         * @static
         * @param {bcl.IDecimal} message Decimal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Decimal.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Decimal message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.Decimal
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.Decimal} Decimal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Decimal.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.Decimal();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.lo = r.uint64();
                    break;
                case 2:
                    m.hi = r.uint32();
                    break;
                case 3:
                    m.signScale = r.sint32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a Decimal message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.Decimal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.Decimal} Decimal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Decimal.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return Decimal;
    })();

    return bcl;
})();

module.exports = $root;
