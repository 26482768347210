<template>
  <div
    :style="styles"
    class="spinner spinner--circle-8">
    <div
      :style="innerStyles"
      class="spinner-inner">
      <div class="ball-container">
        <div class="contener_mixte">
          <div class="ballcolor ball_1">&nbsp;</div>
        </div>
        <div class="contener_mixte">
          <div class="ballcolor ball_2">&nbsp;</div>
        </div>
        <div class="contener_mixte">
          <div class="ballcolor ball_3">&nbsp;</div>
        </div>
        <div class="contener_mixte">
          <div class="ballcolor ball_4">&nbsp;</div>
        </div>
      </div>
    </div>
    <div class="spinner-message">{{ message }}</div>
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: '60px'
    },
    message: {
      type: String,
      default: 'Loading...'
    }
  },
  computed: {
    innerStyles() {
      const size = parseInt(this.size)
      return {
        transform: 'scale(' + size / 44 + ')'
      }
    },
    styles() {
      return {
        width: this.size,
        height: this.size
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
  * {
    line-height: 0;
    box-sizing: border-box;
  }
}

.spinner-inner {
  display: flex;
  padding-bottom: 1em;
}

.spinner-message {
  padding-top: 1em;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.85);
  font-size: 1rem; // align-items: flex-end;
}

.ball-container {
  animation: animball_two 1.5s infinite;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  position: relative;
}

.contener_mixte {
  width: 44px;
  height: 44px;
  position: absolute;
}

.ballcolor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.ball_1,
.ball_2,
.ball_3,
.ball_4 {
  position: absolute;
  animation: animball_one 1.5s infinite ease;
}

.ball_1 {
  background-color: #f7484e;
  top: 0;
  left: 0;
}

.ball_2 {
  background-color: #f8b334;
  top: 0;
  left: 24px;
}

.ball_3 {
  background-color: #41b883;
  top: 24px;
  left: 0;
}

.ball_4 {
  background-color: #34495e;
  top: 24px;
  left: 24px;
}

@keyframes animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 12px;
    left: 12px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@keyframes animball_two {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(360deg) scale(1.3);
  }
  100% {
    transform: rotate(720deg) scale(1);
  }
}
</style>
