import BookingCalendarRoutes from './route-types'

import CapacityPage from './CapacityPage.vue'

const moduleId = 'A170'

export default [
  {
    path: BookingCalendarRoutes.DashboardView.path,
    name: BookingCalendarRoutes.DashboardView.name,
    component: CapacityPage,
    meta: {
      id: moduleId,
      title: 'Capacity Calendar'
    }
  }
]
