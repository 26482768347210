export default {
  EntitySaved: 'entitySaved',
  EntityReload: 'entityReload',
  EntityReloaded: 'entityReloaded',
  ToggleSideMenu: 'toggleSideMenu',
  AutoHideSideMenu: 'autoHideSideMenu',
  AppDetailHeaderButtonClicked: 'appDetailHeaderButtonClicked',
  SetActiveMenuCode: 'setActiveMenuCode',
  ImageCountChanged: 'imageCountChanged',
  DocumentCountChanged: 'documentCountChanged',
  ItemValueChanged: 'itemValueChanged',
  LoadQuoteNextStatus: 'loadQuoteNextStatus'
}
