<template>
  <VChart class="chart"
    :option="option"
    autoresize />
</template>

<script>
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { GridComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { ref } from 'vue'
import { TooltipComponent } from 'echarts/components'
import { getRelativeMonths } from '@/views/dashboard/helper'

use([GridComponent, BarChart, CanvasRenderer, TooltipComponent])

export default {
  name: 'DashboardJobsChart',
  components: {
    VChart
  },
  props: {
    wrongStageMonthlyJobs:Array,
    correctStageMonthlyJobs:Array
  },
  setup(props) {
    const option = ref({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis:{
        type: 'category',
        data: getRelativeMonths()
      },
      yAxis: {
        show:false,
        type: 'value'
      },

      series: [
        {
          name:'Jobs Completed In Correct Stage',
          type: 'bar',
          itemStyle: {color: '#2874BF'},
          data: props.correctStageMonthlyJobs,
          label: {
            show: true,
            position: 'top',
            shadowBlur:0,
            color: '#fff'
          }
        },
        {
          name:'Jobs Completed In Wrong Stage',
          type: 'bar',
          itemStyle: {color: '#99C8F7'},
          data: props.wrongStageMonthlyJobs,
          label: {
            show: true,
            position: 'top',
            shadowBlur:0,
            color: '#fff'
          }
        }
      ]
    })
    return {option}
  }
}

</script>

<style scoped>
</style>