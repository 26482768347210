import * as classHelper from '../../classHelper'
// import { RemarkModel } from '@/classes/viewmodels'
import store from '@/store'

const locale = () => {
  const company = store.getters['company/company']
  return company.info.locale
}

export default class ProductionInOutModel {
  constructor(productionInOutModel = null) {
    this.quoteId = null
    this.jobStage = 0
		this.jobStageDesc = null
    this.quoteNoRef = null
		this.owner = null
		this.rego = null
		this.makeModel = null
		this.insurer = null
		this.jobInOutDate = null
		this.jobStart = null
		this.jobEnd = null
		this.daysBooked = 0
		this.ownersContribution = 0
		this.isNoteExists = false
		this.rrTotal = 0
		this.repairTotal = 0
		this.paintTotal = 0
		this.partTotal = 0
		this.otherTotal = 0
		this.excessWithGst = 0
		this.excess = 0
		this.totalIncGst = 0
		this.rowNumber = 0
		this.totalRows = 0
    this.jobLocaleDate = null
		this.notes = null
    if (productionInOutModel !== null) {
      classHelper.shallowCopyValues(this, productionInOutModel)
      this.getLocaleDateString()
    }
  }

  getLocaleDateString()
  {
    let date = new Date(this.jobInOutDate)
    date = date.toLocaleString(locale, { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })
    this.jobLocaleDate = date
  }
}