exports.ContactListView = {
  path: '/contacts',
  name: 'ContactListView'
}
exports.ContactView = {
  path: '/contacts/:contactId',
  name: 'ContactView'
}
exports.ContactDetail = {
  path: '',
  name: 'ContactDetail'
}
exports.ContactAsset = {
  path: 'asset',
  name: 'ContactAsset'
}
exports.ContactAddress = {
  path: 'address',
  name: 'ContactAddress'
}
exports.ContactPhoneEmail = {
  path: 'phoneemail',
  name: 'ContactPhoneEmail'
}
exports.ContactRemark = {
  path: 'remark',
  name: 'ContactRemark'
}
