<template>
  <div class="spinner-container">
    <div
      :style="styles"
      class="spinner spinner--double-bounce">
      <div
        class="double-bounce1"
        :style="bounceStyle" />
      <div
        class="double-bounce2"
        :style="bounceStyle" />
    </div>
    <div class="spinner-message">{{ message }}</div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '60px'
    },
    background: {
      type: String,
      default: '#41b883'
    },
    duration: {
      type: String,
      default: '2.0s'
    },
    message: {
      type: String,
      default: 'Loading...'
    }
  },
  computed: {
    bounceStyle() {
      return {
        backgroundColor: this.background,
        animationDuration: this.duration
      }
    },
    styles() {
      return {
        width: this.size,
        height: this.size
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
}

.spinner-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3em;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.85);
  font-size: 1rem;
}

.spinner {
  position: relative;
  display: inline-block;
  * {
    line-height: 0;
    box-sizing: border-box;
  }
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: double-bounce 2s ease-in-out infinite;
}

.double-bounce2 {
  animation-delay: -1s;
}

@keyframes double-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
</style>
