import routeTypes from './route-types'
import store from '@/store'

const EmailSmsLogListView = r => require.ensure([], () => r(require('./EmailSmsLogListView.vue')), 'emailsmsloglist-chunk')
const EmailSmsLogView = r => require.ensure([], () => r(require('./EmailSmsLogView.vue')), 'emailsmslog-chunk')
const EmailSmsLogDetail = r => require.ensure([], () => r(require('./EmailSmsLogDetail.vue')), 'emailsmslog-chunk')

// const code = 'A061'

// Get sessionStorage
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|emailsmslog|${query}`)
}

export default [
  {
    path: routeTypes.EmailSmsLogListView.path,
    name: routeTypes.EmailSmsLogListView.name,
    component: EmailSmsLogListView,
    meta: {
      id: 'A054',
      route: 'listing',
      title: 'Email/SMS Log',
      fkey: 'emailsmslog|filter',
      report: 'rptEmailSmsList',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.EmailSmsLogDetail.path,
    name: '',
    component: EmailSmsLogView,
    props: route => ({
      isNew: route.query.mode === 'new' || route.meta.isNew || getStorage('isNew') === 'true',
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl'),
      name: route.query.name || route.meta.name || getStorage('name')
    }),
    meta: {
      id: 'A054',
      route: 'detail',
      title: 'Email/SMS Log'
    },
    query: {
      filter: ''
    },
    children: [
      {
        path: '',
        name: routeTypes.EmailSmsLogDetail.name,
        component: EmailSmsLogDetail,
        meta: {
          title: 'Detail',
          fkey: 'emailsmslog|filter',
          report: 'rptEmailSmsLogsDetail',
          reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
        }
      }
    ]
  }
]
