import { required } from 'vuelidate/lib/validators'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    newNote : {
      remarks: {
        required
      }
    }
  }
}
