export default {
  'Mr.': 'Mr.',
  'Mrs.': 'Mrs.',
  Miss: 'Miss',
  Ms: 'Ms',
  'Messrs.': 'Messrs.',
  'Rev.': 'Rev.',
  'Hon.': 'Hon.',
  'Mr. & Mrs.': 'Mr. & Mrs.',
  'Dr.': 'Dr.'
}
