<template>
  <div>
    <app-detail-header :show-delete="false"
      :show-new="false"
      :show-save="false"
      :show-cancel="false"
      :title="productionObj[filter.jobInOut]"
      :show-print="true"
      @appDetailHeaderButtonClicked="print" />
    <div class="tile is-ancestor is-parent">
      <div class="tile is-vertical is-parent">
        <production-in-out-menu-bar v-model="filter"
          :is-loading="isLoading"
          :data="entitySummaries"
          :days="groupEntitySummaries.length"
          :total-jobs="totalRows"
          :include-gst="includeGst"
          @filter-list="filterList" />
        <div v-if="groupEntitySummaries.length > 0"
          class="tile is-child box p-1">
          <div v-for="(entity, entityIndex) in groupEntitySummaries"
            :key="entityIndex"
            class="column">
            <div class="columns has-text-primary has-text-weight-bold mt-0 mb-1 p-0">
              <div class="column is-one-fifths ml-2 p-1">
                <a @click="dateHeaderClick(filter.sortColumn)">{{ entity.date }}
                  <span v-show="filter.sortColumn === 'JobInOutDate'"
                    class="icon is-small">
                    <i class="mdi"
                      :class="filter.sortOrder === 'asc' ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' " />
                  </span>
                </a>
              </div>
              <div class="column is-one-fifths p-1">
                <span>Total Jobs: </span>
                <span>{{ entity.jobs.length }}</span>
              </div>
              <div class="column is-one-fifths p-1">
                <span>Total Amount: </span>
                <span>{{ $filters.formatCurrency(entity.subtotal, $userInfo.locale) }}</span>
              </div>
            </div>
            <div class="is-divider divider-color m-0" />
            <bulma-table class="table is-striped is-narrow is-fullwidth is-hoverable"
              :key="entity.date"
              :columns="columns"
              :total-rows="entitySummaries.length"
              :is-loading="isLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              :show-pagination="false"
              @sort="sort">
              <tr v-for="(job, index) in entity.jobs"
                :key="job.quoteId"
                :class="{ 'is-selected' : selectedRow === index }">
                <td class="col-5"><a @click="quoteClick(job.quoteId)">{{ job.quoteNoRef }}</a></td>
                <td class="col-5">{{ $filters.formatTimeLocale(job.jobInOutDate, $userInfo.locale) }}</td>
                <td class="col-10">{{ job.jobStageDesc }}</td>
                <td class="col-10">{{ job.owner }}</td>
                <td class="col-5">{{ job.rego }}</td>
                <td class="col-10">{{ job.makeModel }}</td>
                <td class="col-10">{{ job.insurer }}</td>
                <td v-if="filter.jobInOut === 0"
                  class="col-10">{{ $filters.formatDateTimeLocale(job.jobEnd, $userInfo.locale) }} </td>
                <td v-if="filter.jobInOut === 0"
                  class="has-text-centered col-8">{{ job.daysBooked }}</td>
                <td v-if="filter.jobInOut === 1"
                  class="has-text-right col-8">{{ $filters.formatNumber(job.ownersContribution, $userInfo.locale) }}</td>
                <td v-if="$user.hasEdit($route.meta.id)"
                  class="has-text-right col-5">{{ $filters.formatNumber(job.totalIncGst, $userInfo.locale) }}</td>
                <td class="has-text-centered col-1">
                  <a @mouseover="getNotesContent(job.isNoteExists, job.quoteId)"
                    @mouseout="resetNotesContent()"
                    @click="notesClick(job.quoteId, job.quoteNoRef)"
                    class="button is-dark is-small is-inverted">
                    <span class="icon is-medium"
                      v-tippy="{ enabled: showNoteContent, placement: 'left-start', arrow: false, allowHTML: true }"
                      :content="notesContent">
                      <i class="mdi mdi-24px"
                        :class="[job.isNoteExists ? 'mdi-note-edit has-text-warning': 'mdi-note-edit-outline has-text-grey']" />
                    </span>
                  </a>
                </td>
              </tr>
            </bulma-table>
          </div>
        </div>
        <div v-if="entitySummaries.length === 0 && !isLoading">
          <bulma-table class="table is-striped is-narrow is-fullwidth is-hoverable"
            :columns="columns"
            :show-pagination="false">
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-large">
                    <i class="mdi mdi-48px mdi-emoticon-sad" />
                  </span>
                  <p>Nothing</p>
                </div>
              </section>
            </template>
          </bulma-table>
        </div>
      </div>
    </div>
    <div ref="notesList"
      class="is-hidden">
      <ul style="list-style-type: disc; padding-left: 1rem;">
        <li v-for="(note, index) in notes"
          :key="index">
          <span class="is-size-6">{{ note.remarks }}</span>
          <div class="is-flex is-align-items-center is-size-7">
            <user-profile-icon v-if="note.firstName || note.displayName"
              :is-text-profile="true"
              :first-name="note.firstName"
              :last-name="note.lastName"
              :display-name="note.displayName"
              :width="20"
              :height="20"
              class="pr-1" />
            <span class="help is-italic has-text-grey">{{ $filters.formatDateTimeLocale(note.remarkDateTime, $userInfo.locale) }}</span>
          </div>
        </li>
      </ul>
    </div>
    <note-modal v-if="isNoteModalActive"
      :active.sync="isNoteModalActive"
      :quote-id="selectedQuoteId"
      :job-no="selectedJobNo"
      :remark-type="remarkType"
      :remark-desc="remarkDesc"
      @close="closeNoteModal" />
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import ProductionInOutMenuBar from './ProductionInOutMenuBar'
import ProductionInOutService from './ProductionInOutService'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import BulmaTable from '@/components/BulmaTable'
import { DueInColumns, DueOutColumns } from './columns'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { KeyValuePairModel } from '@/classes/viewmodels'
import { Emailer } from '@/classes'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import ProductionInOutValidation from './ProductionInOutValidation'
import NoteModal from '@/components/NoteModal/NoteModal'
import UserProfileIcon from '@/components/UserProfileIcon/UserProfileIcon'
import { groupBy } from '@/components/utils/ArrayFunctions'
import { RemarkTypes } from '@/enums'
import RemarkService from '@/services/RemarkService'
// import { RemarkModel } from '@/classes/viewmodels'
import _orderBy from 'lodash/orderBy'

export default {
  name: 'ProductionInOutView',
  components: {
    AppDetailHeader,
    BulmaTable,
    UserProfileIcon,
    ProductionInOutMenuBar,
    NoteModal
  },
  mixins: [StoreMixin, DateTimeFiltersMixin, NumberFiltersMixin, ProductionInOutValidation],
  data() {
    return {
      productionObj: {
        0: 'Due In Report',
        1: 'Due Out Report'
      },
      isLoading: false,
      entitySummaries: [],
      groupEntitySummaries: [],
      totalRows: 0,
      selectedQuoteId: null,
      selectedJobNo: null,
      filter: {
        dateFrom: null,
        dateTo: null,
        jobInOut: 0,
        selectDateType: 'next7days',
        sortColumn: 'JobInOutDate',
        sortOrder: 'asc'
      },
      toggle: true,
      selectedRow: null,
      isNoteModalActive: false,
      newNote: null,
      notesContent: '',
      showNoteContent: false,
      notes: [],
      maxNotesShow: 3,
      remarkType: RemarkTypes.DueInNote,
      remarkDesc: 'Due In Notes'
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    validateError() {
      return this.$v.$error || this.$v.filter.$error
    },
    includeGst() {
      return this.$company?.info?.isGst
    },
    columns() {
      let cols = DueInColumns
      if (this.filter.jobInOut === 1) {
        cols = _cloneDeep(DueOutColumns)
        cols[2].title = 'Departure'
      } else {
        cols[2].title = 'Arrival'
      }

      if (!this.$user.hasEdit(this.$route.meta.id)) {
        const col = cols.find(c => c.name == 'totalIncGst')
        col.isVisible = false
      }
      return cols
    }
  },
  watch: {},
  created() {
    this.filter.jobInOut = 0
    this.filter.selectDateType = 'next7days'

    let fromDate = new Date(new Date().setDate(new Date().getDate() + 1))
    fromDate = new Date(fromDate.setHours(0, 0, 0, 0))

    let toDate = new Date(new Date().setDate(new Date().getDate() + 7))
    toDate = new Date(toDate.setHours(0, 0, 0, 0))

    this.filter.dateFrom = fromDate.toUTCString()
    this.filter.dateTo = toDate.toUTCString()

    this.getEntitySummaries()
  },
  mounted() {},
  methods: {
    filterList() {
      this.persistFilter()
      this.getEntitySummaries()
    },
    async getEntitySummaries() {
      this.isLoading = true
      this.retrieveFilter()
      this.entitySummaries = await ProductionInOutService.getEntitySummaries(this.filter)

      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0

      this.groupEntitySummaries = groupBy(this.entitySummaries, 'jobLocaleDate')

      //convert object to array
      this.groupEntitySummaries = Object.entries(this.groupEntitySummaries).map(([date, jobs]) => ({
        date,
        jobs,
        subtotal: jobs.reduce((total, job) => {
          return total + job.totalIncGst
        }, 0)
      }))

      this.isLoading = false
    },
    resetNotesContent() {
      this.showNoteContent = false
      this.notesContent = ''
      this.notes = []
    },
    async getNotesContent(isNoteExists, quoteId) {
      if (isNoteExists) {
        this.notesContent = ''
        this.showNoteContent = true
        this.remarkType = this.filter.jobInOut === 0 ? RemarkTypes.DueInNote : RemarkTypes.DueOutNote
        this.notes = await RemarkService.getRemarkEntities(quoteId, this.remarkType)
        this.notes = _orderBy(this.notes, 'createdDate', 'desc')
        this.notes = this.notes.splice(0, this.maxNotesShow)
        this.$nextTick(() => {
          this.notesContent = this.$refs['notesList'].innerHTML
        })
      } else {
        this.resetNotesContent()
      }
    },
    quoteClick(quoteId) {
      this.setQuoteReturnRoute(this.$route)
      this.editStoreQuote(quoteId)
    },
    notesClick(quoteId, jobNo) {
      this.remarkType = this.filter.jobInOut === 0 ? RemarkTypes.DueInNote : RemarkTypes.DueOutNote
      this.remarkDesc = this.filter.jobInOut === 0 ? 'Due In Notes' : 'Due Out Note'
      this.selectedQuoteId = quoteId
      this.selectedJobNo = jobNo
      this.isNoteModalActive = true
    },
    dateHeaderClick(currentSortColumn) {
      if (currentSortColumn === 'JobInOutDate') {
        this.filter.sortOrder = this.filter.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.filter.sortOrder = 'asc'
      }
      this.filter.sortColumn = 'JobInOutDate'
      this.persistFilter()
      this.getEntitySummaries()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    closeNoteModal(entity) {
      if (entity.length !== 0) {
        const index = this.entitySummaries.findIndex((i) => i.quoteId === this.selectedQuoteId)
        if (index !== -1) {
          this.entitySummaries[index].isNoteExists = true
          this.entitySummaries[index].notes = entity
        }
      }
      this.isNoteModalActive = false
    },
    print() {
      const title = this.filter.jobInOut === 0 ? 'Due In Report' : 'Due Out Report'
      if (this.filter.dateFrom === null || this.filter.dateTo === null) {
        this.$notification.openNotificationWithType('danger', title, 'Date cannot be blank.')
        return false
      }
      const params = Object.assign({
        CompanyId: this.$userInfo.companyId,
        DateFrom: this.filter.dateFrom,
        DateTo: this.filter.dateTo,
        JobInOut: this.filter.jobInOut,
        SortColumn: this.filter.sortColumn,
        SortOrder: this.filter.sortOrder
      })
      const reportName = title
      const emailer = new Emailer()
      emailer.subject = reportName
      emailer.reportName = reportName
      this.addEmailer(emailer)
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'rptProductionInOut' },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    highlightSelected(index, event) {
      this.selectedRow = index
      this.filter.selectedRow = index
      this.persistFilter()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.divider-color {
  border-color: #2196f3;
}
.col-1 {
  width: 1%;
}
.col-5 {
  width: 5%;
}
.col-8 {
  width: 8%;
}
.col-10 {
  width: 10%;
}
.release-notes-content {
  max-height: 10em;
  overflow-y: scroll;
}
</style>