export default {
  RR: { code: 'RR', type: 'labour', order: 1 },
  REP: { code: 'REP', type: 'labour', order: 2 },
  RWA: { code: 'RWA', type: 'labour', order: 3 },
  MECH: { code: 'MECH', type: 'otherlabour', order: 4 },
  CD: { code: 'CD', type: 'otherlabour', order: 5 },
  CRUSH: { code: 'CRUSH', type: 'otherlabour', order: 6 },
  FIBER: { code: 'FIBER', type: 'otherlabour', order: 7 },
  S1: { code: 'S1', type: 'paint', order: 1 },
  S2: { code: 'S2', type: 'paint', order: 2 },
  S3: { code: 'S3', type: 'paint', order: 3 },
  M1: { code: 'M1', type: 'paint', order: 4 },
  M2: { code: 'M2', type: 'paint', order: 5 },
  M3: { code: 'M3', type: 'paint', order: 6 }
}
