export default {
  NRMA: 'NRMA',
  FQT: 'FQT',
  NTAR: 'NTAR',
  LTAR: 'LTAR',
  VERO: 'VERO-NZ',
  eMTA: 'eMTA',
  WESF: 'WESF',
  RACQ: 'RACQ'
}
