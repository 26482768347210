import FqApi from '@/services/fq-api'

export default {
  async getEntitySummaries(filters) {
    const res = await FqApi.get(`/vehicles/list/${filters}`)
    return res.data
  },
  async getEntity(id) {
    const res = await FqApi.get(`/vehicles?id=${id}`)
    return res.data
  },
  async postEntity(entity) {
    return FqApi.post('/vehicles', entity)
  },
  async putEntity(entity, deepdiff) {
    if (deepdiff) {
      entity.deepDiff = JSON.stringify(deepdiff)
    }
    return FqApi.put(`/vehicles?id=${entity.id}`, entity)
  },
  async getNewEntity(rego) {
    if (rego) {
      const res = await FqApi.get(`/vehicles/new?rego=${rego}`)
      return res.data
    } else {
      const res = await FqApi.get('/vehicles/new')
      return res.data
    }
  },
  searchModelByName(key) {
    return FqApi.get(`/carmodels/searchname?key=${key}`)
  },
  async assertUsed(entityId) {
    const result = await FqApi.get(`/vehicles/assertused/${entityId}`)
    return result.data
  },
  async assertRegoUsed(entityId, rego) {
    const result = await FqApi.get(`/vehicles/assertregoused/${entityId}?rego=${rego}`)
    return result.data
  },
  async getConnectedQuoteNos(id) {
    const results = await FqApi.get(`/vehicles/quoteNos/${id}`)
    return results.data
  },
  async getNtarLtarStatusById(vehicleId) {
    const results = await FqApi.get(`/vehicles/ntarltarstatus/${vehicleId}`)
    return results.data
  },
  async getNtarLtarStatusByScheduleBody(scheduleId, bodyId) {
    const results = await FqApi.get(`/vehicles/ntarltarstatus/${scheduleId}/${bodyId}`)
    return results.data
  }
}
