// export default {
//   New: '--',
//   Used: 'U',
//   Exchange: 'X',
//   AlternatePart: 'AL',
//   Aftermarket: 'A',
//   CertifiedAftermarket: 'CA',
//   AftermarketAccessories: 'AC'
// }
import { PartTypes } from '@/classes'

export default {
  NEW: new PartTypes('--', 'New'),
  USED: new PartTypes('U', 'Used'),
  EXCH: new PartTypes('X', 'Exchange'),
  RECR: new PartTypes('C', 'Recore'),
  RECD: new PartTypes('R', 'Re-conditioned'),
  ALTN: new PartTypes('AL', 'Alternate Part'),
  AFT: new PartTypes('A', 'Aftermarket'),
  CAFT: new PartTypes('CA', 'Certified Aftermarket'),
  AFTA: new PartTypes('AC', 'Aftermarket Accessories'),
  PAR: new PartTypes('P', 'Parallel')
}
