/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.SubletTypes = (function() {

    /**
     * Namespace SubletTypes.
     * @exports SubletTypes
     * @namespace
     */
    var SubletTypes = {};

    SubletTypes.SubletTypesModel = (function() {

        /**
         * Properties of a SubletTypesModel.
         * @memberof SubletTypes
         * @interface ISubletTypesModel
         * @property {Array.<SubletTypes.SubletTypesModel.ISubletType>|null} [subletTypes] SubletTypesModel subletTypes
         */

        /**
         * Constructs a new SubletTypesModel.
         * @memberof SubletTypes
         * @classdesc Represents a SubletTypesModel.
         * @implements ISubletTypesModel
         * @constructor
         * @param {SubletTypes.ISubletTypesModel=} [p] Properties to set
         */
        function SubletTypesModel(p) {
            this.subletTypes = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * SubletTypesModel subletTypes.
         * @member {Array.<SubletTypes.SubletTypesModel.ISubletType>} subletTypes
         * @memberof SubletTypes.SubletTypesModel
         * @instance
         */
        SubletTypesModel.prototype.subletTypes = $util.emptyArray;

        /**
         * Encodes the specified SubletTypesModel message. Does not implicitly {@link SubletTypes.SubletTypesModel.verify|verify} messages.
         * @function encode
         * @memberof SubletTypes.SubletTypesModel
         * @static
         * @param {SubletTypes.ISubletTypesModel} m SubletTypesModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubletTypesModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.subletTypes != null && m.subletTypes.length) {
                for (var i = 0; i < m.subletTypes.length; ++i)
                    $root.SubletTypes.SubletTypesModel.SubletType.encode(m.subletTypes[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Encodes the specified SubletTypesModel message, length delimited. Does not implicitly {@link SubletTypes.SubletTypesModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof SubletTypes.SubletTypesModel
         * @static
         * @param {SubletTypes.ISubletTypesModel} message SubletTypesModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubletTypesModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SubletTypesModel message from the specified reader or buffer.
         * @function decode
         * @memberof SubletTypes.SubletTypesModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {SubletTypes.SubletTypesModel} SubletTypesModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubletTypesModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.SubletTypes.SubletTypesModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.subletTypes && m.subletTypes.length))
                        m.subletTypes = [];
                    m.subletTypes.push($root.SubletTypes.SubletTypesModel.SubletType.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a SubletTypesModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof SubletTypes.SubletTypesModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {SubletTypes.SubletTypesModel} SubletTypesModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubletTypesModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        SubletTypesModel.SubletType = (function() {

            /**
             * Properties of a SubletType.
             * @memberof SubletTypes.SubletTypesModel
             * @interface ISubletType
             * @property {string|null} [subletTypeId] SubletType subletTypeId
             * @property {string|null} [type] SubletType type
             * @property {string|null} [script] SubletType script
             * @property {boolean|null} [sublet] SubletType sublet
             */

            /**
             * Constructs a new SubletType.
             * @memberof SubletTypes.SubletTypesModel
             * @classdesc Represents a SubletType.
             * @implements ISubletType
             * @constructor
             * @param {SubletTypes.SubletTypesModel.ISubletType=} [p] Properties to set
             */
            function SubletType(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * SubletType subletTypeId.
             * @member {string|null|undefined} subletTypeId
             * @memberof SubletTypes.SubletTypesModel.SubletType
             * @instance
             */
            SubletType.prototype.subletTypeId = null;

            /**
             * SubletType type.
             * @member {string|null|undefined} type
             * @memberof SubletTypes.SubletTypesModel.SubletType
             * @instance
             */
            SubletType.prototype.type = null;

            /**
             * SubletType script.
             * @member {string|null|undefined} script
             * @memberof SubletTypes.SubletTypesModel.SubletType
             * @instance
             */
            SubletType.prototype.script = null;

            /**
             * SubletType sublet.
             * @member {boolean|null|undefined} sublet
             * @memberof SubletTypes.SubletTypesModel.SubletType
             * @instance
             */
            SubletType.prototype.sublet = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * SubletType _subletTypeId.
             * @member {"subletTypeId"|undefined} _subletTypeId
             * @memberof SubletTypes.SubletTypesModel.SubletType
             * @instance
             */
            Object.defineProperty(SubletType.prototype, "_subletTypeId", {
                get: $util.oneOfGetter($oneOfFields = ["subletTypeId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SubletType _type.
             * @member {"type"|undefined} _type
             * @memberof SubletTypes.SubletTypesModel.SubletType
             * @instance
             */
            Object.defineProperty(SubletType.prototype, "_type", {
                get: $util.oneOfGetter($oneOfFields = ["type"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SubletType _script.
             * @member {"script"|undefined} _script
             * @memberof SubletTypes.SubletTypesModel.SubletType
             * @instance
             */
            Object.defineProperty(SubletType.prototype, "_script", {
                get: $util.oneOfGetter($oneOfFields = ["script"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SubletType _sublet.
             * @member {"sublet"|undefined} _sublet
             * @memberof SubletTypes.SubletTypesModel.SubletType
             * @instance
             */
            Object.defineProperty(SubletType.prototype, "_sublet", {
                get: $util.oneOfGetter($oneOfFields = ["sublet"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified SubletType message. Does not implicitly {@link SubletTypes.SubletTypesModel.SubletType.verify|verify} messages.
             * @function encode
             * @memberof SubletTypes.SubletTypesModel.SubletType
             * @static
             * @param {SubletTypes.SubletTypesModel.ISubletType} m SubletType message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubletType.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.subletTypeId != null && Object.hasOwnProperty.call(m, "subletTypeId"))
                    w.uint32(10).string(m.subletTypeId);
                if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                    w.uint32(18).string(m.type);
                if (m.script != null && Object.hasOwnProperty.call(m, "script"))
                    w.uint32(26).string(m.script);
                if (m.sublet != null && Object.hasOwnProperty.call(m, "sublet"))
                    w.uint32(32).bool(m.sublet);
                return w;
            };

            /**
             * Encodes the specified SubletType message, length delimited. Does not implicitly {@link SubletTypes.SubletTypesModel.SubletType.verify|verify} messages.
             * @function encodeDelimited
             * @memberof SubletTypes.SubletTypesModel.SubletType
             * @static
             * @param {SubletTypes.SubletTypesModel.ISubletType} message SubletType message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubletType.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SubletType message from the specified reader or buffer.
             * @function decode
             * @memberof SubletTypes.SubletTypesModel.SubletType
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {SubletTypes.SubletTypesModel.SubletType} SubletType
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubletType.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.SubletTypes.SubletTypesModel.SubletType();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.subletTypeId = r.string();
                        break;
                    case 2:
                        m.type = r.string();
                        break;
                    case 3:
                        m.script = r.string();
                        break;
                    case 4:
                        m.sublet = r.bool();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Decodes a SubletType message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof SubletTypes.SubletTypesModel.SubletType
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {SubletTypes.SubletTypesModel.SubletType} SubletType
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubletType.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            return SubletType;
        })();

        return SubletTypesModel;
    })();

    return SubletTypes;
})();

module.exports = $root;
