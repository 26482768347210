import routerTypes from './route-types'
import store from '@/store'

// Lazy-load components. Webpack will split each into individual file
const PartsCatalogueListView = r => require.ensure([], () => r(require('./PartsCatalogueListView.vue')), 'partscataloguelist-chunk')
const PartsCatalogueItemListView = r => require.ensure([], () => r(require('./PartsCatalogueItemListView.vue')), 'partscataloguelist-chunk')

// Get sessionStorage
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|partscatalogue|${query}`)
}
export default [
  {
    path: routerTypes.PartsCatalogueListView.path,
    name: routerTypes.PartsCatalogueListView.name,
    component: PartsCatalogueListView,
    meta: {
      id: 'A033',
      route: 'listing',
      title: 'Parts Catalogue',
      fkey: 'partscatalogue|filter',
      report: 'rptReportPartsCatalog',
      reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routerTypes.PartsCatalogueItemListView.path,
    name: routerTypes.PartsCatalogueItemListView.name,
    component: PartsCatalogueItemListView,
    props: route => ({
      isNew: route.meta.isNew,
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl')
    }),
    meta: {
      id: 'A033',
      route: 'listing',
      title: 'Parts Catalogue Item',
      isNew: false,
      fkey: 'partscatalogue|filter',
      report: 'rptPartsCatalogls',
      reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
    }
  }
]
