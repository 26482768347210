const state = {
  hasNtarLtarItems: false,
  hasNonNtarLtarItems: false,
  quoteTemplate: null,
  quoteType: null,
  templateBodyId: null,
  templateTimesType: null
}

const getters = {
  hasNtarLtarItems: state => state.hasNtarLtarItems,
  hasNonNtarLtarItems: state => state.hasNonNtarLtarItems,
  quoteTemplate: state => state.quoteTemplate,
  quoteType: state => state.quoteType,
  templateBodyId: state => state.templateBodyId,
  templateTimesType: state => state.templateTimesType
}

const actions = {
  flagNtarLtarItems({ commit }, flag) {
    commit('flagNtarLtarItems', flag)
  },

  flagNonNtarLtarItems({ commit }, flag) {
    commit('flagNonNtarLtarItems', flag)
  },

  saveQuoteTemplate({ commit }, { entity }) {
    commit('saveQuoteTemplate', entity)
    commit('saveQuoteBodyId', entity?.bodyId)
    commit('saveQuoteTimesType', entity?.timesType)
    commit('saveQuoteType', entity?.quoteType)
  },

  saveQuoteType({ commit }, type) {
    commit('saveQuoteType', type)
  },
  saveQuoteTimesType({ commit }, type) {
    commit('saveQuoteTimesType', type)
  }
}

const mutations = {
  flagNtarLtarItems(state, flag) {
    state.hasNtarLtarItems = flag
  },
  flagNonNtarLtarItems(state, flag) {
    state.hasNonNtarLtarItems = flag
  },
  saveQuoteTemplate(state, entity) {
    state.quoteTemplate = entity
  },
  saveQuoteBodyId(state, bodyId) {
    state.templateBodyId = bodyId
  },
  saveQuoteType(state, type) {
    state.quoteType = type
  },
  saveQuoteTimesType(state, type) {
    state.templateTimesType = type
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}