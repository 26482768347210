import routeTypes from './route-types'
// import store from '@/store'

const ImageUploader = r => require.ensure([], () => r(require('./ImageUploader.vue')), 'image-uploader-chunk')

// const tag = 'ImageUploader'
// const getStorage = function(query) {
//   const sessionId = store.getters['userInfo/info'].sessionId
//   return sessionStorage.getItem(`${sessionId}|${tag}|${query}`)
// }

const moduleId = 'A118'

export default [
  {
    path: routeTypes.ImageUploader.path,
    name: routeTypes.ImageUploader.name,
    component: ImageUploader,
    meta: {
      id: moduleId,
      route: 'listing',
      title: 'Image Uploader'
    }
  }
]
