<template>
  <div class="tile is-parent is-vertical pl-0 border">
    <div class="tile is-child tile-border">
      <div class="panel-heading border custom-panel-heading is-flex is-justify-content-space-around">
        <div class="has-text-centered has-text-white">
          <p>LATEST RELEASES</p>
        </div>
      </div>

      <DashboardProgressBar
        :is-loading="false"
        :initial-color="'#53B700'"
        :loading-color="'#53B700'" />

      <div class="content release-notes-content has-background-dark p-3 pb-6">
        <div v-for="(release, index) in dashboardReleases"
          :key="index">
          <div class="is-flex is-align-items-center">
            <span v-if="index == 0"
              class="tag is-success mr-2">New</span>
            <span class="has-text-weight-bold is-size-6">
              {{ $filters.formatDateLocale(release.date, $user.info.locale) }} - {{ release.title }}</span>
          </div>

          <ul class="pb-4 is-size-7">
            <li v-for="(note, noteIndex) in release.notes"
              :key="noteIndex"><span v-html="formatNote(note)" /></li>
          </ul>
        </div>

        <div class="has-text-centered">
          <a href="#"
            @click="displayAllReleases">
            <span class="has-text-success is-size-7 has-text-weight-bold is-underlined">View All</span>
          </a>
        </div>
      </div>

      <ReleaseNotesModal v-if="isReleaseModalActive"
        :active.sync="isReleaseModalActive"
        @close="closeModal">
        <p slot="text-title">Release Notes</p>
      </ReleaseNotesModal>
    </div>
  </div>
</template>

<script>
import DashboardProgressBar from '@/views/dashboard/DashboardProgressBar.vue'
import Releases from '@/assets/json/release-notes.json'
import { ReleaseNotesModal } from '@/components/BulmaModal'

export default {
  name: 'DashboardReleaseNotes',
  components: {DashboardProgressBar, ReleaseNotesModal},

  data() {
    return {
      isReleaseModalActive: false
    }
  },

  computed: {
    dashboardReleases() {
      return Releases.slice(0, 5)
    }
  },

  methods: {
    closeModal() {
      this.isReleaseModalActive = false
    },
    displayAllReleases() {
      this.isReleaseModalActive = true
    },
    formatNote(note) {
      if (typeof(note) == 'string' && note.includes(':')) {
        const [key, value] = note.split(':')
        return `<b>${key}</b>: ${value}`
      }
      return note
    }
  }
}

</script>

<style scoped>
.border {
  border-radius: 20px 20px 0px 0px !important;
}
.canvas-border {
  border-radius: 0px 0px 20px 20px !important;
}
.custom-panel-heading {
  border-radius: 20px 20px 0 0 !important;
  background-color:#495057 !important; ;
}
.release-notes-content {
  min-height: 100%;
  max-height: 10em;
  overflow-y: scroll;
  padding: 1rem;
}
</style>