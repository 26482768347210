import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('company', { $company: 'company' })
  },
  methods: {
    ...mapActions('company', ['getCompanyItemCategories', 'getCompanySetting', 'getCompanyInfo', 'setItemAutoCompleteEnabled', 'alterCompanySettings'])
  }
}
