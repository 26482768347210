import routeTypes from './route-types'
import store from '@/store'

// Lazy-load components. Webpack will split each into individual file
const PurchaseOrderListView = r => require.ensure([], () => r(require('./PurchaseOrderListView.vue')), 'purchaseorderlist-chunk')
const PurchaseOrderView = r => require.ensure([], () => r(require('./PurchaseOrderView.vue')), 'purchaseorder-chunk')
const PurchaseOrderDetail = r => require.ensure([], () => r(require('./PurchaseOrderDetail.vue')), 'purchaseorder-chunk')
const PurchaseOrderVehicleCustomerInsurer = r => require.ensure([], () => r(require('./PurchaseOrderVehicleCustomerInsurer.vue')), 'purchaseorder-chunk')
const PurchaseOrderRemarks = r => require.ensure([], () => r(require('./PurchaseOrderRemarks.vue')), 'purchaseorder-chunk')
const PurchaseOrderOverview = r => require.ensure([], () => r(require('./PurchaseOrderOverview.vue')), 'purchaseorder-chunk')
const PurchaseOrderOtherPurchases = r => require.ensure([], () => r(require('./PurchaseOrderOtherPurchases.vue')), 'purchaseorder-chunk')
const PurchaseOrderSublet = r => require.ensure([], () => r(require('./PurchaseOrderSublet.vue')), 'purchaseorder-chunk')
const PurchaseOrderMisc = r => require.ensure([], () => r(require('./PurchaseOrderMisc.vue')), 'purchaseorder-chunk')
const PurchaseOrderParts = r => require.ensure([], () => r(require('./PurchaseOrderParts.vue')), 'purchaseorder-chunk')
const PurchaseOrderOrderReceipt = r => require.ensure([], () => r(require('./PurchaseOrderOrderReceipt.vue')), 'purchaseorder-chunk')
const PurchaseOrderOrderReceiptDetail = r => require.ensure([], () => r(require('./PurchaseOrderOrderReceiptDetail.vue')), 'purchaseorder-chunk')

// Get sessionStorage
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|purchaseorder|${query}`)
}

const moduleId = 'A106'
const orderReceiptModuleId = 'A140'

export default [
  {
    path: routeTypes.PurchaseOrderListView.path,
    name: routeTypes.PurchaseOrderListView.name,
    component: PurchaseOrderListView,
    meta: {
      id: moduleId,
      route: 'listing',
      title: 'Purchase Order',
      fkey: 'purchaseorder|filter',
      report: 'AT_CreditorPurchaseOrderListV2',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.PurchaseOrderDetail.path,
    name: '',
    component: PurchaseOrderView,
    props: route => ({
      isNew: route.meta.isNew,
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl')
    }),
    meta: {
      id: moduleId,
      route: 'detail',
      title: 'Purchase Order',
      isNew: false
    },
    children: [
      {
        path: '',
        name: routeTypes.PurchaseOrderDetail.name,
        component: PurchaseOrderDetail,
        meta: {
          id: moduleId,
          route: 'detail',
          title: 'Detail',
          isNew: false
        }
      },
      {
        path: routeTypes.PurchaseOrderRemarks.name,
        name: routeTypes.PurchaseOrderRemarks.name,
        component: PurchaseOrderRemarks,
        meta: {
          id: moduleId,
          route: 'remarks',
          title: 'Remarks',
          isNew: false
        }
      },
      {
        path: routeTypes.PurchaseOrderVehicleCustomerInsurer.path,
        name: routeTypes.PurchaseOrderVehicleCustomerInsurer.name,
        component: PurchaseOrderVehicleCustomerInsurer,
        meta: {
          id: moduleId,
          title: 'Vehicle Customer Insurer'
        }
      },
      {
        path: routeTypes.PurchaseOrderOtherPurchases.path,
        name: routeTypes.PurchaseOrderOtherPurchases.name,
        component: PurchaseOrderOtherPurchases,
        meta: {
          id: moduleId,
          title: 'Other Purchases'
        }
      },
      {
        path: routeTypes.PurchaseOrderOverview.path,
        name: routeTypes.PurchaseOrderOverview.name,
        component: PurchaseOrderOverview,
        meta: {
          id: moduleId,
          title: 'Overview'
        }
      },
      {
        path: routeTypes.PurchaseOrderSublet.path,
        name: routeTypes.PurchaseOrderSublet.name,
        component: PurchaseOrderSublet,
        meta: {
          id: moduleId,
          title: 'Sublet'
        }
      },
      {
        path: routeTypes.PurchaseOrderMisc.path,
        name: routeTypes.PurchaseOrderMisc.name,
        component: PurchaseOrderMisc,
        meta: {
          id: moduleId,
          title: 'Misc'
        }
      },
      {
        path: routeTypes.PurchaseOrderParts.path,
        name: routeTypes.PurchaseOrderParts.name,
        component: PurchaseOrderParts,
        meta: {
          id: moduleId,
          title: 'Parts'
        }
      },
      {
        path: routeTypes.PurchaseOrderOrderReceipt.path,
        name: routeTypes.PurchaseOrderOrderReceipt.name,
        component: PurchaseOrderOrderReceipt,
        meta: {
          id: orderReceiptModuleId,
          title: 'Order Receipt'
        }
        // children: [
        //   {
        //     path: routeTypes.PurchaseOrderOrderReceiptDetail.path,
        //     name: routeTypes.PurchaseOrderOrderReceiptDetail.name,
        //     component: PurchaseOrderOrderReceiptDetail,
        //     meta: {
        //       id: moduleId,
        //       title: 'detail'
        //     }
        //   }
        // ]
      },
      {
        path: routeTypes.PurchaseOrderOrderReceiptDetail.path,
        name: routeTypes.PurchaseOrderOrderReceiptDetail.name,
        component: PurchaseOrderOrderReceiptDetail,
        meta: {
          id: moduleId,
          title: 'Order Receipt Detail'
        }
      }
    ]
  }
]
