<template>
  <div class="">
    <div>
      <a name="btnNotifications"
        class="button is-info"
        :data-badge="$notifications.length > 0 ? $notifications.length : null"
        :class="[{ 'has-badge-danger has-badge-rounded has-badge-small': $notifications.length > 0 }]">
        <span class="icon">
          <i class="mdi mdi-bell mdi-24px" />
        </span>
      </a>
    </div>
    <tippy ref="tippyNotifications"
      to="btnNotifications"
      arrow
      :interactive="true"
      placement="bottom"
      theme="light"
      :max-width="500">
      <div class="container p-2 is-flex is-justify-content-space-between is-align-content-center">
        <span class="is-size-5">Notifications</span>
      </div>
      <div class="is-divider mb-1 mt-0" />
      <div class="container notification-container">
        <div v-if="$notifications.length === 0"
          class="notification is-light px-5 mb-1">
          <span>No new notifications</span>
        </div>
        <template v-for="(notification) in $notifications">
          <a v-if="notification.route"
            @click="gotoRoute(notification.route)"
            :key="notification.id">
            <div class="notification is-light px-5 mb-1"
              :class="[{'is-primary': notification.severity === 1}, {'is-warning': notification.severity === 2}, {'is-danger': notification.severity === 3}]"
              :key="notification.id">
              <button v-if="notification.type !== 3"
                class="delete"
                @click="setAsRead(notification.id, notification.type)" />
              <div class="is-flex is-align-items-center">
                <span class="icon mr-3">
                  <i :class="getIconCss(notification.type)"
                    aria-hidden="true" />
                </span>
                <span v-html="notification.content" />
              </div>
            </div>
          </a>
          <div v-else
            class="notification is-light px-5 mb-1"
            :class="[{'is-primary': notification.severity === 1}, {'is-warning': notification.severity === 2}, {'is-danger': notification.severity === 3}]"
            :key="notification.id">
            <button v-if="notification.type !== 3 && notification.type !== 4"
              class="delete"
              @click="setAsRead(notification.id, notification.type)" />
            <div class="is-flex is-align-items-center">
              <span class="icon mr-3">
                <i :class="getIconCss(notification.type)"
                  aria-hidden="true" />
              </span>
              <span v-html="notification.content" />
            </div>
          </div>
        </template>
      </div>
    </tippy>
  </div>
</template>

<script>
import { NotificationService } from '@/services'
import { NotificationType } from '../../enums'

export default {
  name: 'Notification',
  components: {},
  mixins: [],
  props: {},
  data: () => {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    async setAsRead(id, type) {
      // this.stopNotificationPoll()
      await NotificationService.setNotificationStatus(id, type, 2)
      this.getNotifications()
      // this.startNotificationPoll()
    },
    gotoRoute(route) {
      this.$router.push(route)
    },
    gotoRouteNewTab(route) {
      window.open(route, '_blank')
    },
    getIconCss(type) {
      return {
        mdi: true,
        'mdi-whatsapp': type == NotificationType.QuoteWhatsAppIncoming,
        'mdi-message-text': type == NotificationType.QuoteSmsIncoming,
        'mdi-email': type == NotificationType.QuoteEmailIncoming,
        'mdi-license': type == NotificationType.CompanyLicense,
        'mdi-20px': true,
        'icon-green': type == NotificationType.QuoteWhatsAppIncoming,
        'icon-white': type == NotificationType.QuoteEmailIncoming
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.notification {
  width: 30em;
}
i.icon-green {
  color: #2bb741;
}
i.icon-white {
  color: #a09e9e;
}
.notification-container {
  max-height: 45vh;
  overflow-y: auto;
}
</style>