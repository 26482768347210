export default {
  directives: {
    fqValidate: {
      bind: function(el, binding, vnode) {
        el.addEventListener('input', function() {
          binding.value.$touch()
        })
      },
      componentUpdated: function(el, binding, vnode) {
        if (binding.value.$error) {
          el.classList.add('is-danger')
        } else {
          el.classList.remove('is-danger')
        }
      }
    }
  }
}
