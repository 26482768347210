exports.QuickInvoiceListView = {
  path: '/quickinvoices',
  name: 'QuickInvoiceListView'
}
exports.QuickInvoiceView = {
  path: '/quickinvoices/:invoiceId',
  name: 'QuickInvoiceView'
}
exports.QuickInvoiceDetail = {
  path: '/quickinvoices/:invoiceId',
  name: 'QuickInvoiceDetail'
}
exports.QuickInvoiceItems = {
  path: 'items',
  name: 'QuickInvoiceItems'
}
