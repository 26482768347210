import routerTypes from './route-types'

// Lazy-load components. Webpack will split each into individual file
const GstReportView = r => require.ensure([], () => r(require('./GstReportView.vue')), 'gstreport-chunk')

export default [
  {
    path: routerTypes.GstReportView.path,
    name: routerTypes.GstReportView.name,
    component: GstReportView,
    meta: {
      id: 'A024',
      route: 'listing',
      title: 'GST Report',
      fkey: 'gstreport|filter',
      report: 'AT_GSTPaymentSummaryV2',
      reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
    }
  }
]
