/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.AuditTrailFilter = (function() {

    /**
     * Namespace AuditTrailFilter.
     * @exports AuditTrailFilter
     * @namespace
     */
    var AuditTrailFilter = {};

    AuditTrailFilter.AuditTrailFilterModel = (function() {

        /**
         * Properties of an AuditTrailFilterModel.
         * @memberof AuditTrailFilter
         * @interface IAuditTrailFilterModel
         * @property {string|null} [entityShortName] AuditTrailFilterModel entityShortName
         * @property {string|null} [tableName] AuditTrailFilterModel tableName
         * @property {string|null} [operation] AuditTrailFilterModel operation
         * @property {string|null} [userName] AuditTrailFilterModel userName
         * @property {string|null} [dateFrom] AuditTrailFilterModel dateFrom
         * @property {string|null} [dateTo] AuditTrailFilterModel dateTo
         * @property {string|null} [userId] AuditTrailFilterModel userId
         * @property {string|null} [sortColumn] AuditTrailFilterModel sortColumn
         * @property {string|null} [sortOrder] AuditTrailFilterModel sortOrder
         * @property {number|null} [pageIndex] AuditTrailFilterModel pageIndex
         * @property {number|null} [pageSize] AuditTrailFilterModel pageSize
         */

        /**
         * Constructs a new AuditTrailFilterModel.
         * @memberof AuditTrailFilter
         * @classdesc Represents an AuditTrailFilterModel.
         * @implements IAuditTrailFilterModel
         * @constructor
         * @param {AuditTrailFilter.IAuditTrailFilterModel=} [p] Properties to set
         */
        function AuditTrailFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AuditTrailFilterModel entityShortName.
         * @member {string|null|undefined} entityShortName
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        AuditTrailFilterModel.prototype.entityShortName = null;

        /**
         * AuditTrailFilterModel tableName.
         * @member {string|null|undefined} tableName
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        AuditTrailFilterModel.prototype.tableName = null;

        /**
         * AuditTrailFilterModel operation.
         * @member {string|null|undefined} operation
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        AuditTrailFilterModel.prototype.operation = null;

        /**
         * AuditTrailFilterModel userName.
         * @member {string|null|undefined} userName
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        AuditTrailFilterModel.prototype.userName = null;

        /**
         * AuditTrailFilterModel dateFrom.
         * @member {string|null|undefined} dateFrom
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        AuditTrailFilterModel.prototype.dateFrom = null;

        /**
         * AuditTrailFilterModel dateTo.
         * @member {string|null|undefined} dateTo
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        AuditTrailFilterModel.prototype.dateTo = null;

        /**
         * AuditTrailFilterModel userId.
         * @member {string|null|undefined} userId
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        AuditTrailFilterModel.prototype.userId = null;

        /**
         * AuditTrailFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        AuditTrailFilterModel.prototype.sortColumn = null;

        /**
         * AuditTrailFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        AuditTrailFilterModel.prototype.sortOrder = null;

        /**
         * AuditTrailFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        AuditTrailFilterModel.prototype.pageIndex = null;

        /**
         * AuditTrailFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        AuditTrailFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * AuditTrailFilterModel _entityShortName.
         * @member {"entityShortName"|undefined} _entityShortName
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        Object.defineProperty(AuditTrailFilterModel.prototype, "_entityShortName", {
            get: $util.oneOfGetter($oneOfFields = ["entityShortName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AuditTrailFilterModel _tableName.
         * @member {"tableName"|undefined} _tableName
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        Object.defineProperty(AuditTrailFilterModel.prototype, "_tableName", {
            get: $util.oneOfGetter($oneOfFields = ["tableName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AuditTrailFilterModel _operation.
         * @member {"operation"|undefined} _operation
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        Object.defineProperty(AuditTrailFilterModel.prototype, "_operation", {
            get: $util.oneOfGetter($oneOfFields = ["operation"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AuditTrailFilterModel _userName.
         * @member {"userName"|undefined} _userName
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        Object.defineProperty(AuditTrailFilterModel.prototype, "_userName", {
            get: $util.oneOfGetter($oneOfFields = ["userName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AuditTrailFilterModel _dateFrom.
         * @member {"dateFrom"|undefined} _dateFrom
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        Object.defineProperty(AuditTrailFilterModel.prototype, "_dateFrom", {
            get: $util.oneOfGetter($oneOfFields = ["dateFrom"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AuditTrailFilterModel _dateTo.
         * @member {"dateTo"|undefined} _dateTo
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        Object.defineProperty(AuditTrailFilterModel.prototype, "_dateTo", {
            get: $util.oneOfGetter($oneOfFields = ["dateTo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AuditTrailFilterModel _userId.
         * @member {"userId"|undefined} _userId
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        Object.defineProperty(AuditTrailFilterModel.prototype, "_userId", {
            get: $util.oneOfGetter($oneOfFields = ["userId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AuditTrailFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        Object.defineProperty(AuditTrailFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AuditTrailFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        Object.defineProperty(AuditTrailFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AuditTrailFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        Object.defineProperty(AuditTrailFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * AuditTrailFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @instance
         */
        Object.defineProperty(AuditTrailFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified AuditTrailFilterModel message. Does not implicitly {@link AuditTrailFilter.AuditTrailFilterModel.verify|verify} messages.
         * @function encode
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @static
         * @param {AuditTrailFilter.IAuditTrailFilterModel} m AuditTrailFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuditTrailFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.entityShortName != null && Object.hasOwnProperty.call(m, "entityShortName"))
                w.uint32(10).string(m.entityShortName);
            if (m.tableName != null && Object.hasOwnProperty.call(m, "tableName"))
                w.uint32(18).string(m.tableName);
            if (m.operation != null && Object.hasOwnProperty.call(m, "operation"))
                w.uint32(26).string(m.operation);
            if (m.userName != null && Object.hasOwnProperty.call(m, "userName"))
                w.uint32(34).string(m.userName);
            if (m.dateFrom != null && Object.hasOwnProperty.call(m, "dateFrom"))
                w.uint32(42).string(m.dateFrom);
            if (m.dateTo != null && Object.hasOwnProperty.call(m, "dateTo"))
                w.uint32(50).string(m.dateTo);
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(58).string(m.userId);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(66).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(74).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(80).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(88).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified AuditTrailFilterModel message, length delimited. Does not implicitly {@link AuditTrailFilter.AuditTrailFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @static
         * @param {AuditTrailFilter.IAuditTrailFilterModel} message AuditTrailFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuditTrailFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuditTrailFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {AuditTrailFilter.AuditTrailFilterModel} AuditTrailFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuditTrailFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.AuditTrailFilter.AuditTrailFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.entityShortName = r.string();
                    break;
                case 2:
                    m.tableName = r.string();
                    break;
                case 3:
                    m.operation = r.string();
                    break;
                case 4:
                    m.userName = r.string();
                    break;
                case 5:
                    m.dateFrom = r.string();
                    break;
                case 6:
                    m.dateTo = r.string();
                    break;
                case 7:
                    m.userId = r.string();
                    break;
                case 8:
                    m.sortColumn = r.string();
                    break;
                case 9:
                    m.sortOrder = r.string();
                    break;
                case 10:
                    m.pageIndex = r.int32();
                    break;
                case 11:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes an AuditTrailFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof AuditTrailFilter.AuditTrailFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {AuditTrailFilter.AuditTrailFilterModel} AuditTrailFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuditTrailFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return AuditTrailFilterModel;
    })();

    return AuditTrailFilter;
})();

module.exports = $root;
