/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.DealerCreditEntryFilter = (function() {

    /**
     * Namespace DealerCreditEntryFilter.
     * @exports DealerCreditEntryFilter
     * @namespace
     */
    var DealerCreditEntryFilter = {};

    DealerCreditEntryFilter.DealerCreditEntryFilterModel = (function() {

        /**
         * Properties of a DealerCreditEntryFilterModel.
         * @memberof DealerCreditEntryFilter
         * @interface IDealerCreditEntryFilterModel
         * @property {string|null} [vendorId] DealerCreditEntryFilterModel vendorId
         * @property {string|null} [cutOffDate] DealerCreditEntryFilterModel cutOffDate
         * @property {boolean|null} [returnedParts] DealerCreditEntryFilterModel returnedParts
         * @property {string|null} [creditorInvoiceId] DealerCreditEntryFilterModel creditorInvoiceId
         */

        /**
         * Constructs a new DealerCreditEntryFilterModel.
         * @memberof DealerCreditEntryFilter
         * @classdesc Represents a DealerCreditEntryFilterModel.
         * @implements IDealerCreditEntryFilterModel
         * @constructor
         * @param {DealerCreditEntryFilter.IDealerCreditEntryFilterModel=} [p] Properties to set
         */
        function DealerCreditEntryFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DealerCreditEntryFilterModel vendorId.
         * @member {string|null|undefined} vendorId
         * @memberof DealerCreditEntryFilter.DealerCreditEntryFilterModel
         * @instance
         */
        DealerCreditEntryFilterModel.prototype.vendorId = null;

        /**
         * DealerCreditEntryFilterModel cutOffDate.
         * @member {string|null|undefined} cutOffDate
         * @memberof DealerCreditEntryFilter.DealerCreditEntryFilterModel
         * @instance
         */
        DealerCreditEntryFilterModel.prototype.cutOffDate = null;

        /**
         * DealerCreditEntryFilterModel returnedParts.
         * @member {boolean|null|undefined} returnedParts
         * @memberof DealerCreditEntryFilter.DealerCreditEntryFilterModel
         * @instance
         */
        DealerCreditEntryFilterModel.prototype.returnedParts = null;

        /**
         * DealerCreditEntryFilterModel creditorInvoiceId.
         * @member {string|null|undefined} creditorInvoiceId
         * @memberof DealerCreditEntryFilter.DealerCreditEntryFilterModel
         * @instance
         */
        DealerCreditEntryFilterModel.prototype.creditorInvoiceId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * DealerCreditEntryFilterModel _vendorId.
         * @member {"vendorId"|undefined} _vendorId
         * @memberof DealerCreditEntryFilter.DealerCreditEntryFilterModel
         * @instance
         */
        Object.defineProperty(DealerCreditEntryFilterModel.prototype, "_vendorId", {
            get: $util.oneOfGetter($oneOfFields = ["vendorId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DealerCreditEntryFilterModel _cutOffDate.
         * @member {"cutOffDate"|undefined} _cutOffDate
         * @memberof DealerCreditEntryFilter.DealerCreditEntryFilterModel
         * @instance
         */
        Object.defineProperty(DealerCreditEntryFilterModel.prototype, "_cutOffDate", {
            get: $util.oneOfGetter($oneOfFields = ["cutOffDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DealerCreditEntryFilterModel _returnedParts.
         * @member {"returnedParts"|undefined} _returnedParts
         * @memberof DealerCreditEntryFilter.DealerCreditEntryFilterModel
         * @instance
         */
        Object.defineProperty(DealerCreditEntryFilterModel.prototype, "_returnedParts", {
            get: $util.oneOfGetter($oneOfFields = ["returnedParts"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DealerCreditEntryFilterModel _creditorInvoiceId.
         * @member {"creditorInvoiceId"|undefined} _creditorInvoiceId
         * @memberof DealerCreditEntryFilter.DealerCreditEntryFilterModel
         * @instance
         */
        Object.defineProperty(DealerCreditEntryFilterModel.prototype, "_creditorInvoiceId", {
            get: $util.oneOfGetter($oneOfFields = ["creditorInvoiceId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified DealerCreditEntryFilterModel message. Does not implicitly {@link DealerCreditEntryFilter.DealerCreditEntryFilterModel.verify|verify} messages.
         * @function encode
         * @memberof DealerCreditEntryFilter.DealerCreditEntryFilterModel
         * @static
         * @param {DealerCreditEntryFilter.IDealerCreditEntryFilterModel} m DealerCreditEntryFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DealerCreditEntryFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.vendorId != null && Object.hasOwnProperty.call(m, "vendorId"))
                w.uint32(10).string(m.vendorId);
            if (m.cutOffDate != null && Object.hasOwnProperty.call(m, "cutOffDate"))
                w.uint32(18).string(m.cutOffDate);
            if (m.returnedParts != null && Object.hasOwnProperty.call(m, "returnedParts"))
                w.uint32(24).bool(m.returnedParts);
            if (m.creditorInvoiceId != null && Object.hasOwnProperty.call(m, "creditorInvoiceId"))
                w.uint32(34).string(m.creditorInvoiceId);
            return w;
        };

        /**
         * Encodes the specified DealerCreditEntryFilterModel message, length delimited. Does not implicitly {@link DealerCreditEntryFilter.DealerCreditEntryFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof DealerCreditEntryFilter.DealerCreditEntryFilterModel
         * @static
         * @param {DealerCreditEntryFilter.IDealerCreditEntryFilterModel} message DealerCreditEntryFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DealerCreditEntryFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DealerCreditEntryFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof DealerCreditEntryFilter.DealerCreditEntryFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {DealerCreditEntryFilter.DealerCreditEntryFilterModel} DealerCreditEntryFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DealerCreditEntryFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.DealerCreditEntryFilter.DealerCreditEntryFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.vendorId = r.string();
                    break;
                case 2:
                    m.cutOffDate = r.string();
                    break;
                case 3:
                    m.returnedParts = r.bool();
                    break;
                case 4:
                    m.creditorInvoiceId = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a DealerCreditEntryFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof DealerCreditEntryFilter.DealerCreditEntryFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {DealerCreditEntryFilter.DealerCreditEntryFilterModel} DealerCreditEntryFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DealerCreditEntryFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return DealerCreditEntryFilterModel;
    })();

    return DealerCreditEntryFilter;
})();

module.exports = $root;
