import axios from 'axios'

const service = axios.create({
  baseURL: process.env.VUE_APP_AGENT_URI
})

// Add a response interceptor
service.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    //const status = error.response ? error.response.status : ''
    //const statusText = error.response ? error.response.statusText : ''
    //const url = error.response ? error.response.config.url : ''
    return Promise.reject(error)
  }
)

/**
 * Retry the original request.
 *
 * Let's retry the user's original target request that had recieved a invalid token response
 * (which we fixed with a token refresh).
 *
 * @param {Request} request The Vue-resource Request instance to use to repeat an http call.
 * @return {Promise}
 */
// function _retry(config) {
//   return FqApi.request(config)
// }

/**
 * Refresh the access token
 *
 * Make an ajax call to the OAuth2 server to refresh the access token (using our refresh token).
 *
 * @private
 * @param {Request} request Vue-resource Request instance, the original request that we'll retry.
 * @return {Promise}
 */
// function _refreshToken(request) {
//   // return service.post('api/refresh_token', '=' + getCookie('UniqApi.Token'))
//   //   .then((result) => {
//   // self._storeToken(result)
//   // return self._retry(request)
//   // })
//   // .catch((error) => {
//   //   if (self._isInvalidToken(error)) {
//   auth.logout()
//   // }
//   // })
// }

/**
 * Check if the Vue-resource Response is an invalid token response.
 *
 * @private
 * @param {Response} response The Vue-resource Response instance received from an http call.
 * @return {boolean}
 */
// function _isInvalidToken(error) {
//   const status = error.response.status
//   const statusText = error.response.statusText
//   console.log(error)
//   console.log(`Invalid token ${status} ${statusText}`)
//   return (status === 401 && statusText === 'Invalid token')
// }

// function _isExpiredToken(error) {
//   const status = error.response.status
//   const statusText = error.response.statusText
//   console.log(error)
//   console.log(`Token expired ${status} ${statusText}`)

//   return (status === 401 && statusText === 'Token expired')
// }

export default service
