import routeTypes from './route-types'

const VehicleLookupView = () => import(/* webpackChunkName: "superadmin-vehicle-lookup-chunk" */ './VehicleLookupView.vue')
const PricingView = () => import(/* webpackChunkName: "superadmin-vehicle-lookup-chunk" */ './PricingView.vue')
const PricingDetail = () => import(/* webpackChunkName: "superadmin-vehicle-lookup-chunk" */ './PricingDetail.vue')
const SubscriptionView = () => import(/* webpackChunkName: "superadmin-vehicle-lookup-chunk" */ './SubscriptionView.vue')
const SubscriptionListing = () => import(/* webpackChunkName: "superadmin-vehicle-lookup-chunk" */ './SubscriptionListing.vue')
const SubscriptionDetail = () => import(/* webpackChunkName: "superadmin-vehicle-lookup-chunk" */ './SubscriptionDetail.vue')

const moduleId = 'A120'

export default [
  {
    path: routeTypes.VehicleLookupView.path,
    name: '',
    component: VehicleLookupView,
    meta: {
      id: moduleId,
      route: 'listing',
      title: 'Vehicle Lookup',
      fkey: 'vehicle-lookup-filter'
    },
    children: [
      {
        path: '', //routeTypes.PricingView.path,
        name: '',
        component: PricingView,
        meta: {
          id: moduleId,
          route: 'listing',
          title: '',
          fkey: 'vehicle-lookup-filter'
        },
        children: [
          {
            path: '', //routeTypes.PricingView.path,
            name: routeTypes.PricingView.name,
            component: PricingDetail,
            meta: {
              id: moduleId,
              route: 'pricing',
              title: 'Pricing',
              isNew: false
            }
          }
        ]
      },
      {
        path: routeTypes.SubscriptionView.path,
        name: '',
        component: SubscriptionView,
        meta: {
          id: moduleId,
          route: 'listing',
          title: 'Subscription'
        },
        children: [
          {
            path: '',
            name: routeTypes.SubscriptionView.name,
            component: SubscriptionListing,
            meta: {
              id: moduleId,
              route: 'listing',
              title: ''
            }
          },
          {
            path: routeTypes.SubscriptionDetail.path,
            name: routeTypes.SubscriptionDetail.name,
            component: SubscriptionDetail,
            meta: {
              id: moduleId,
              route: 'detail',
              title: ''
            }
          }
        ]
      }
    ]
  }
]
