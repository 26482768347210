/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.SubscriptionFilter = (function() {

    /**
     * Namespace SubscriptionFilter.
     * @exports SubscriptionFilter
     * @namespace
     */
    var SubscriptionFilter = {};

    SubscriptionFilter.SubscriptionFilterModel = (function() {

        /**
         * Properties of a SubscriptionFilterModel.
         * @memberof SubscriptionFilter
         * @interface ISubscriptionFilterModel
         * @property {string|null} [companyId] SubscriptionFilterModel companyId
         * @property {string|null} [sortBy] SubscriptionFilterModel sortBy
         * @property {string|null} [sortOrder] SubscriptionFilterModel sortOrder
         * @property {number|null} [pageSize] SubscriptionFilterModel pageSize
         * @property {number|null} [pageIndex] SubscriptionFilterModel pageIndex
         */

        /**
         * Constructs a new SubscriptionFilterModel.
         * @memberof SubscriptionFilter
         * @classdesc Represents a SubscriptionFilterModel.
         * @implements ISubscriptionFilterModel
         * @constructor
         * @param {SubscriptionFilter.ISubscriptionFilterModel=} [p] Properties to set
         */
        function SubscriptionFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * SubscriptionFilterModel companyId.
         * @member {string|null|undefined} companyId
         * @memberof SubscriptionFilter.SubscriptionFilterModel
         * @instance
         */
        SubscriptionFilterModel.prototype.companyId = null;

        /**
         * SubscriptionFilterModel sortBy.
         * @member {string|null|undefined} sortBy
         * @memberof SubscriptionFilter.SubscriptionFilterModel
         * @instance
         */
        SubscriptionFilterModel.prototype.sortBy = null;

        /**
         * SubscriptionFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof SubscriptionFilter.SubscriptionFilterModel
         * @instance
         */
        SubscriptionFilterModel.prototype.sortOrder = null;

        /**
         * SubscriptionFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof SubscriptionFilter.SubscriptionFilterModel
         * @instance
         */
        SubscriptionFilterModel.prototype.pageSize = null;

        /**
         * SubscriptionFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof SubscriptionFilter.SubscriptionFilterModel
         * @instance
         */
        SubscriptionFilterModel.prototype.pageIndex = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * SubscriptionFilterModel _companyId.
         * @member {"companyId"|undefined} _companyId
         * @memberof SubscriptionFilter.SubscriptionFilterModel
         * @instance
         */
        Object.defineProperty(SubscriptionFilterModel.prototype, "_companyId", {
            get: $util.oneOfGetter($oneOfFields = ["companyId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SubscriptionFilterModel _sortBy.
         * @member {"sortBy"|undefined} _sortBy
         * @memberof SubscriptionFilter.SubscriptionFilterModel
         * @instance
         */
        Object.defineProperty(SubscriptionFilterModel.prototype, "_sortBy", {
            get: $util.oneOfGetter($oneOfFields = ["sortBy"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SubscriptionFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof SubscriptionFilter.SubscriptionFilterModel
         * @instance
         */
        Object.defineProperty(SubscriptionFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SubscriptionFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof SubscriptionFilter.SubscriptionFilterModel
         * @instance
         */
        Object.defineProperty(SubscriptionFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * SubscriptionFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof SubscriptionFilter.SubscriptionFilterModel
         * @instance
         */
        Object.defineProperty(SubscriptionFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified SubscriptionFilterModel message. Does not implicitly {@link SubscriptionFilter.SubscriptionFilterModel.verify|verify} messages.
         * @function encode
         * @memberof SubscriptionFilter.SubscriptionFilterModel
         * @static
         * @param {SubscriptionFilter.ISubscriptionFilterModel} m SubscriptionFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubscriptionFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.companyId != null && Object.hasOwnProperty.call(m, "companyId"))
                w.uint32(10).string(m.companyId);
            if (m.sortBy != null && Object.hasOwnProperty.call(m, "sortBy"))
                w.uint32(18).string(m.sortBy);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(26).string(m.sortOrder);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(32).int32(m.pageSize);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(40).int32(m.pageIndex);
            return w;
        };

        /**
         * Encodes the specified SubscriptionFilterModel message, length delimited. Does not implicitly {@link SubscriptionFilter.SubscriptionFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof SubscriptionFilter.SubscriptionFilterModel
         * @static
         * @param {SubscriptionFilter.ISubscriptionFilterModel} message SubscriptionFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubscriptionFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SubscriptionFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof SubscriptionFilter.SubscriptionFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {SubscriptionFilter.SubscriptionFilterModel} SubscriptionFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubscriptionFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.SubscriptionFilter.SubscriptionFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.companyId = r.string();
                    break;
                case 2:
                    m.sortBy = r.string();
                    break;
                case 3:
                    m.sortOrder = r.string();
                    break;
                case 4:
                    m.pageSize = r.int32();
                    break;
                case 5:
                    m.pageIndex = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a SubscriptionFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof SubscriptionFilter.SubscriptionFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {SubscriptionFilter.SubscriptionFilterModel} SubscriptionFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubscriptionFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return SubscriptionFilterModel;
    })();

    return SubscriptionFilter;
})();

module.exports = $root;
