import FqApi from '@/services/fq-api'

export default {
  /**
   * submitQuote
   *
   * @param {PnetSubmitQuoteModel} object PnetSubmitQuoteModel object
   * @return {Promise}
   */
  async submitQuote(object) {
    const url = '/pnetmessages/submitquote'
    const result = await FqApi.put(url, object)
    return result
  },
  async submitSupplementaryQuote(object) {
    const url = '/pnetmessages/submitsupplementaryquote'
    const result = await FqApi.put(url, object)
    return result
  },
  /**
   * submitQuoteUpdate
   *
   * @param {PnetSubmitQuoteUpdateModel} object PnetSubmitQuoteUpdateModel object
   * @return {Promise}
   */
  async submitQuoteUpdate(object) {
    const url = '/pnetmessages/submitquotestatus'
    const result = await FqApi.put(url, object)
    return result
  },
  /**
   * submitTaxInvoice
   *
   * @param {String} quoteId QuoteId
   * @return {Promise}
   */
  async submitTaxInvoice(quoteId) {
    const url = `/pnetmessages/submittaxinvoice?quoteId=${quoteId}`
    const result = await FqApi.put(url)
    return result
  }
}
