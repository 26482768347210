import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
import AuditTrailApi from '@/services/audittrail-api'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialiseMessageFilter(filter)
    const url = `/pnetmessages/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseMessageFilter(filter) {
    const proto = require('../../assets/proto/pnet/PnetFilter.proto')
    const filterMessage = proto.PnetFilter.PnetFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getCountSummaries() {
    const url = '/pnetmessages/list/counts'
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntity(id) {
    const url = `/pnetmessages/${id}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getNewEntity() {
    const res = await FqApi.get('/pnetmessages/new')
    return res.data
  },
  putEntity(entity, deepdiff) {
    if (deepdiff) {
      entity.deepDiff = JSON.stringify(deepdiff)
    }
    return FqApi.put(`/pnetmessages/${entity.messageId}`, entity)
  },
  async postEntity(entity) {
    return FqApi.post(`/pnetmessages/${entity.messageId}`, entity)
  },
  updateMessageStatus(messageId, statusId) {
    return FqApi.put(`/pnetmessages/${messageId}?statusId=${statusId}`)
  },
  loadMessage(options) {
    return FqApi.put(`/pnetmessages/load/${options.messageId}`, options)
  },
  async assertVehicleExists(rego) {
    const url = `/ormmessages/load/vehicleexists?rego=${encodeURIComponent(rego)}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteNo(quoteId) {
    const url = `/ormmessages/load/getquoteno/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteEntity(quoteId) {
    const url = `/quotes/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async postAuditTrailItem(audtiTrailItem) {
    return AuditTrailApi.post('/v1/audittrails/audittrailitem', audtiTrailItem)
  },
  async getQuotePnet(quoteId) {
    const url = `/pnetmessages/quotepnet/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  }
}
