/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.GlAccount = (function() {

    /**
     * Namespace GlAccount.
     * @exports GlAccount
     * @namespace
     */
    var GlAccount = {};

    GlAccount.GlAccountListComboModel = (function() {

        /**
         * Properties of a GlAccountListComboModel.
         * @memberof GlAccount
         * @interface IGlAccountListComboModel
         * @property {Array.<GlAccount.GlAccountListComboModel.IGlAccount>|null} [glAccounts] GlAccountListComboModel glAccounts
         */

        /**
         * Constructs a new GlAccountListComboModel.
         * @memberof GlAccount
         * @classdesc Represents a GlAccountListComboModel.
         * @implements IGlAccountListComboModel
         * @constructor
         * @param {GlAccount.IGlAccountListComboModel=} [p] Properties to set
         */
        function GlAccountListComboModel(p) {
            this.glAccounts = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * GlAccountListComboModel glAccounts.
         * @member {Array.<GlAccount.GlAccountListComboModel.IGlAccount>} glAccounts
         * @memberof GlAccount.GlAccountListComboModel
         * @instance
         */
        GlAccountListComboModel.prototype.glAccounts = $util.emptyArray;

        /**
         * Encodes the specified GlAccountListComboModel message. Does not implicitly {@link GlAccount.GlAccountListComboModel.verify|verify} messages.
         * @function encode
         * @memberof GlAccount.GlAccountListComboModel
         * @static
         * @param {GlAccount.IGlAccountListComboModel} m GlAccountListComboModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GlAccountListComboModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.glAccounts != null && m.glAccounts.length) {
                for (var i = 0; i < m.glAccounts.length; ++i)
                    $root.GlAccount.GlAccountListComboModel.GlAccount.encode(m.glAccounts[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Encodes the specified GlAccountListComboModel message, length delimited. Does not implicitly {@link GlAccount.GlAccountListComboModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof GlAccount.GlAccountListComboModel
         * @static
         * @param {GlAccount.IGlAccountListComboModel} message GlAccountListComboModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GlAccountListComboModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GlAccountListComboModel message from the specified reader or buffer.
         * @function decode
         * @memberof GlAccount.GlAccountListComboModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {GlAccount.GlAccountListComboModel} GlAccountListComboModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GlAccountListComboModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.GlAccount.GlAccountListComboModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.glAccounts && m.glAccounts.length))
                        m.glAccounts = [];
                    m.glAccounts.push($root.GlAccount.GlAccountListComboModel.GlAccount.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a GlAccountListComboModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof GlAccount.GlAccountListComboModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {GlAccount.GlAccountListComboModel} GlAccountListComboModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GlAccountListComboModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        GlAccountListComboModel.GlAccount = (function() {

            /**
             * Properties of a GlAccount.
             * @memberof GlAccount.GlAccountListComboModel
             * @interface IGlAccount
             * @property {string|null} [displayName] GlAccount displayName
             * @property {string|null} [accountNo] GlAccount accountNo
             * @property {string|null} [accountId] GlAccount accountId
             * @property {string|null} [accountType] GlAccount accountType
             * @property {string|null} [glCategory] GlAccount glCategory
             */

            /**
             * Constructs a new GlAccount.
             * @memberof GlAccount.GlAccountListComboModel
             * @classdesc Represents a GlAccount.
             * @implements IGlAccount
             * @constructor
             * @param {GlAccount.GlAccountListComboModel.IGlAccount=} [p] Properties to set
             */
            function GlAccount(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * GlAccount displayName.
             * @member {string|null|undefined} displayName
             * @memberof GlAccount.GlAccountListComboModel.GlAccount
             * @instance
             */
            GlAccount.prototype.displayName = null;

            /**
             * GlAccount accountNo.
             * @member {string|null|undefined} accountNo
             * @memberof GlAccount.GlAccountListComboModel.GlAccount
             * @instance
             */
            GlAccount.prototype.accountNo = null;

            /**
             * GlAccount accountId.
             * @member {string|null|undefined} accountId
             * @memberof GlAccount.GlAccountListComboModel.GlAccount
             * @instance
             */
            GlAccount.prototype.accountId = null;

            /**
             * GlAccount accountType.
             * @member {string|null|undefined} accountType
             * @memberof GlAccount.GlAccountListComboModel.GlAccount
             * @instance
             */
            GlAccount.prototype.accountType = null;

            /**
             * GlAccount glCategory.
             * @member {string|null|undefined} glCategory
             * @memberof GlAccount.GlAccountListComboModel.GlAccount
             * @instance
             */
            GlAccount.prototype.glCategory = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GlAccount _displayName.
             * @member {"displayName"|undefined} _displayName
             * @memberof GlAccount.GlAccountListComboModel.GlAccount
             * @instance
             */
            Object.defineProperty(GlAccount.prototype, "_displayName", {
                get: $util.oneOfGetter($oneOfFields = ["displayName"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * GlAccount _accountNo.
             * @member {"accountNo"|undefined} _accountNo
             * @memberof GlAccount.GlAccountListComboModel.GlAccount
             * @instance
             */
            Object.defineProperty(GlAccount.prototype, "_accountNo", {
                get: $util.oneOfGetter($oneOfFields = ["accountNo"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * GlAccount _accountId.
             * @member {"accountId"|undefined} _accountId
             * @memberof GlAccount.GlAccountListComboModel.GlAccount
             * @instance
             */
            Object.defineProperty(GlAccount.prototype, "_accountId", {
                get: $util.oneOfGetter($oneOfFields = ["accountId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * GlAccount _accountType.
             * @member {"accountType"|undefined} _accountType
             * @memberof GlAccount.GlAccountListComboModel.GlAccount
             * @instance
             */
            Object.defineProperty(GlAccount.prototype, "_accountType", {
                get: $util.oneOfGetter($oneOfFields = ["accountType"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * GlAccount _glCategory.
             * @member {"glCategory"|undefined} _glCategory
             * @memberof GlAccount.GlAccountListComboModel.GlAccount
             * @instance
             */
            Object.defineProperty(GlAccount.prototype, "_glCategory", {
                get: $util.oneOfGetter($oneOfFields = ["glCategory"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified GlAccount message. Does not implicitly {@link GlAccount.GlAccountListComboModel.GlAccount.verify|verify} messages.
             * @function encode
             * @memberof GlAccount.GlAccountListComboModel.GlAccount
             * @static
             * @param {GlAccount.GlAccountListComboModel.IGlAccount} m GlAccount message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GlAccount.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.displayName != null && Object.hasOwnProperty.call(m, "displayName"))
                    w.uint32(10).string(m.displayName);
                if (m.accountNo != null && Object.hasOwnProperty.call(m, "accountNo"))
                    w.uint32(18).string(m.accountNo);
                if (m.accountId != null && Object.hasOwnProperty.call(m, "accountId"))
                    w.uint32(26).string(m.accountId);
                if (m.accountType != null && Object.hasOwnProperty.call(m, "accountType"))
                    w.uint32(34).string(m.accountType);
                if (m.glCategory != null && Object.hasOwnProperty.call(m, "glCategory"))
                    w.uint32(42).string(m.glCategory);
                return w;
            };

            /**
             * Encodes the specified GlAccount message, length delimited. Does not implicitly {@link GlAccount.GlAccountListComboModel.GlAccount.verify|verify} messages.
             * @function encodeDelimited
             * @memberof GlAccount.GlAccountListComboModel.GlAccount
             * @static
             * @param {GlAccount.GlAccountListComboModel.IGlAccount} message GlAccount message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GlAccount.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GlAccount message from the specified reader or buffer.
             * @function decode
             * @memberof GlAccount.GlAccountListComboModel.GlAccount
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {GlAccount.GlAccountListComboModel.GlAccount} GlAccount
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GlAccount.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.GlAccount.GlAccountListComboModel.GlAccount();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.displayName = r.string();
                        break;
                    case 2:
                        m.accountNo = r.string();
                        break;
                    case 3:
                        m.accountId = r.string();
                        break;
                    case 4:
                        m.accountType = r.string();
                        break;
                    case 5:
                        m.glCategory = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Decodes a GlAccount message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof GlAccount.GlAccountListComboModel.GlAccount
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {GlAccount.GlAccountListComboModel.GlAccount} GlAccount
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GlAccount.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            return GlAccount;
        })();

        return GlAccountListComboModel;
    })();

    return GlAccount;
})();

module.exports = $root;
