import PnetMessageService from './PnetMessageService'
import PnetMessageRoutes from './route-types'
import StoreUtil from '@/store/utils'
import DeepDiff from 'deep-diff'
import { md5 } from '@/components/crypto'
// import { nowDate } from '@/components/utils/DateTimeFunctions'

const tag = 'pnetmessage'

const state = {
  returnRoute: {},
  baseIndex: 0,
  currentIndex: 0,
  snapshots: [],
  messageSummary: {}
}

const getters = {
  returnRoute: state => state.returnRoute,
  snapshots: state => state.snapshots,
  baseSnapshot: state => state.snapshots[state.baseIndex],
  currentSnapshot: state => state.snapshots[state.currentIndex],
  snapshotDiff: state => {
    if (state.snapshots[state.baseIndex] && state.snapshots[state.currentIndex]) {
      return DeepDiff.diff(state.snapshots[state.baseIndex], state.snapshots[state.currentIndex])
    } else {
      return null
    }
  },
  messageSummary: state => state.messageSummary
}

const actions = {
  async getStoreItem({ commit }, id) {
    // const entity = StoreUtil.getStorage(id, tag) || (await PnetMessageService.getEntity(id, ''))
    // The following for refreshing data from WebApi regardless
    let entity = StoreUtil.getStorage(id, tag)
    if (!entity || (entity && !entity.isNew)) {
      const hash = entity ? md5(JSON.stringify(entity)) : ''
      const data = await PnetMessageService.getEntity(id, hash)
      if (data) {
        console.log('from WebApi')
        entity = data
      } else {
        console.log('from sessionStorage')
      }
    }
    StoreUtil.setStorage(entity.invoiceId, tag, entity)
    commit('saveInitialSnapshots', entity)
  },
  async editStoreItem({ commit }, id) {
    const entity = StoreUtil.getStorage(id, tag) || (await PnetMessageService.getEntity(id, ''))
    StoreUtil.setStorage(entity.invoiceId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(PnetMessageRoutes.QuickInvoiceListView.path, entity.invoiceId, false)
  },
  async addStoreItem({ commit }) {
    const entity = await PnetMessageService.getNewEntity()
    entity.isNew = true
    // entity.invoiceDate = nowDate().toISOString().split('.')[0] + 'Z'
    StoreUtil.setStorage(entity.messageId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail('/pnetmessages/new', entity.messageId, true)
  },
  saveSnapshot({ commit }, entity) {
    commit('saveSnapshot', entity)
  },
  clearSnapshots({ commit }, id) {
    StoreUtil.removeStorage(id, tag)
    commit('clearSnapshots')
  },
  setReturnRoute({ commit }, route) {
    commit('setReturnRoute', route)
  },
  setMessageSummary({ commit }, message) {
    commit('setMessageSummary', message)
  },
  clearMessageSummary({ commit }) {
    commit('clearMessageSummary')
  }
}

const mutations = {
  saveInitialSnapshots(state, entity) {
    state.snapshots = []
    state.snapshots.splice(0, 1, entity)
    state.snapshots.splice(1, 1, entity)
    state.currentIndex = state.snapshots.length - 1
  },
  saveSnapshot(state, entity) {
    state.snapshots.splice(state.currentIndex, 1, entity)
  },
  clearSnapshots(state) {
    state.snapshots = []
    state.baseIndex = 0
    state.currentIndex = 0
  },
  setReturnRoute(state, route) {
    state.returnRoute = route
  },
  setMessageSummary(state, message) {
    state.messageSummary = message
  },
  clearMessageSummary(state) {
    state.messageSummary = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
