import routeTypes from './route-types'

const VehicleLookupListView = () => import(/* webpackChunkName: "vehicle-lookup-chunk" */ './VehicleLookupListView.vue')
const VehicleLookupView = () => import(/* webpackChunkName: "vehicle-lookup-chunk" */ './VehicleLookupView.vue')
const VehicleLookupDetail = () => import(/* webpackChunkName: "vehicle-lookup-chunk" */ './VehicleLookupDetail.vue')

const moduleId = 'A120'

export default [
  {
    path: routeTypes.VehicleLookupListView.path,
    name: routeTypes.VehicleLookupListView.name,
    component: VehicleLookupListView,
    meta: {
      id: moduleId,
      route: 'listing',
      title: 'Vehicle Lookup',
      fkey: 'vehicle-lookup-filter'
    }
  },
  {
    path: routeTypes.VehicleLookupDetail.path,
    name: '',
    component: VehicleLookupView,
    meta: {
      id: moduleId,
      route: 'detail',
      title: 'Vehicle Lookup'
    },
    children: [
      {
        path: '',
        name: routeTypes.VehicleLookupDetail.name,
        component: VehicleLookupDetail,
        meta: {
          id: moduleId,
          route: 'detail',
          title: 'Detail'
        }
      }
    ]
  }
]
