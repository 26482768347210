import FqApi from '@/services/fq-api'

export default {
  async submitDeclineQuoteRequest(object) {
    const url = '/ormmessages/submitdecline'
    const result = await FqApi.put(url, object)
    return result
  },
  async submitQuote(object) {
    const url = '/ormmessages/submitquote'
    const result = await FqApi.put(url, object)
    return result
  },
  async submitTaxInvoice(object) {
    const url = '/ormmessages/submittaxinvoice'
    const result = await FqApi.put(url, object)
    return result
  },
  async submitReactiveTaxInvoice(object) {
    const url = '/ormmessages/submitreactivatetaxinvoice'
    const result = await FqApi.put(url, object)
    return result
  }
}
