import routeTypes from './route-types'
import store from '@/store'

const RecurringRemarkListView = r => require.ensure([], () => r(require('./RecurringRemarkListView.vue')), 'recurringremarks-chunk')
const RecurringRemarkView = r => require.ensure([], () => r(require('./RecurringRemarkView.vue')), 'recurringremarks-chunk')
const RecurringRemarkDetail = r => require.ensure([], () => r(require('./RecurringRemarkDetail.vue')), 'recurringremarks-chunk')
// const RecurringRemarksNew = r => require.ensure([], () => r(require('./RecurringRemarksNew.vue')), 'recurringremarks-chunk')

// const code = 'A061'

// Get sessionStorage
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|recurringremark|${query}`)
}

export default [
  // {
  //   path: '/recurringremarks',
  //   component: RecurringRemarksListing,
  //   meta: {
  //     id: code,
  //     route: 'listing',
  //     title: 'Recurring Remarks',
  //     fkey: 'recurringremarks-filter'
  //   }
  // },
  // {
  //   path: '/recurringremarks/new',
  //   component: RecurringRemarksNew,
  //   meta: {
  //     id: code,
  //     route: 'detail',
  //     title: 'New Recurring Remark'
  //   }
  // },
  // {
  //   path: '/recurringremarks/:recurringRemarkId',
  //   component: RecurringRemarksView,
  //   meta: {
  //     id: code,
  //     route: 'detail',
  //     title: 'Recurring Remarks'
  //   }
  // },
  {
    path: routeTypes.RecurringRemarkListView.path,
    name: routeTypes.RecurringRemarkListView.name,
    component: RecurringRemarkListView,
    meta: {
      id: 'A061',
      route: 'listing',
      title: 'Recurring Remarks',
      fkey: 'recurringremark|filter',
      report: 'rptRemarkList',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.RecurringRemarkDetail.path,
    name: '',
    component: RecurringRemarkView,
    props: route => ({
      isNew: route.query.mode === 'new' || route.meta.isNew || getStorage('isNew') === 'true',
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl'),
      name: route.query.name || route.meta.name || getStorage('name')
    }),
    meta: {
      id: 'A061',
      route: 'detail',
      title: 'Recurring Remarks'
    },
    query: {
      filter: ''
    },
    children: [
      {
        path: '',
        name: routeTypes.RecurringRemarkDetail.name,
        component: RecurringRemarkDetail,
        meta: {
          title: 'Detail',
          fkey: 'recurringremark|filter',
          report: 'rptRecurringRemarksDetail',
          reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
        }
      }
    ]
  }
]
