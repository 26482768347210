<template>
  <div class="is-flex is-align-items-center is-justify-content-center designed-button has-text-weight-bold is-clickable">
    <span>
      {{ value }}
    </span>
  </div>

</template>

<script>
export default {
  name: 'DashboardWebServiceCircularStatus',
  props: {
    value: Number,
    colorCode: String,
    width: String,
    height: String
  }
}
</script>

<style scoped>
.designed-button {
  background: v-bind(colorCode);
  border-radius: 50%;
  /*width: v-bind(width);*/
  /*height: v-bind(height);*/
  min-height: v-bind(width);
  min-width: v-bind(width);
}
</style>