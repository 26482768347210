import routeTypes from './route-types'
import store from '@/store'

// Lazy-load components. Webpack will split each into individual file
const ItemListView = r => require.ensure([], () => r(require('./ItemListView.vue')), 'itemlist-chunk')
const ItemView = r => require.ensure([], () => r(require('./ItemView.vue')), 'item-chunk')
const ItemDetail = r => require.ensure([], () => r(require('./ItemDetail.vue')), 'item-chunk')

// Get sessionStorage
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|item|${query}`)
}

export default [
  {
    path: routeTypes.ItemListView.path,
    name: routeTypes.ItemListView.name,
    component: ItemListView,
    meta: {
      id: 'A001',
      route: 'listing',
      title: 'Items',
      fkey: 'item|filter',
      report: 'rptItemList',
      reportUrl: `${process.env.ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.ItemDetail.path,
    name: '',
    component: ItemView,
    props: route => ({
      isNew: route.query.mode === 'new' || route.meta.isNew || getStorage('isNew') === 'true',
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl'),
      name: route.query.name || route.meta.name || getStorage('name')
    }),
    meta: {
      id: 'A001',
      route: 'detail',
      title: 'Item'
    },
    query: {
      filter: ''
    },
    children: [
      {
        path: '',
        name: routeTypes.ItemDetail.name,
        component: ItemDetail,
        meta: {
          title: 'Detail',
          fkey: 'item|filter',
          report: 'rptTableItemsDetails',
          reportUrl: `${process.env.ROOT_URI}/modules/reports/printpreview.aspx`
        }
      }
    ]
  }
]
