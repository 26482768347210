export default {
  ToSend: 10,
  Sent: 20,
  UnRead: 30,
  Read: 40,
  Failed: 50,
  InComplete: 60,
  Deleted: 70, // Deleted status is for Request for Quote message cancelled before it was loaded/processed.
  DeletedRecord: 80,
  SyncFailed: 90,
  Synced: 100
}
