/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.VendorDropdown2 = (function() {

    /**
     * Namespace VendorDropdown2.
     * @exports VendorDropdown2
     * @namespace
     */
    var VendorDropdown2 = {};

    VendorDropdown2.VendorDropdownModel2 = (function() {

        /**
         * Properties of a VendorDropdownModel2.
         * @memberof VendorDropdown2
         * @interface IVendorDropdownModel2
         * @property {Array.<VendorDropdown2.VendorDropdownModel2.IVendor>|null} [vendors] VendorDropdownModel2 vendors
         */

        /**
         * Constructs a new VendorDropdownModel2.
         * @memberof VendorDropdown2
         * @classdesc Represents a VendorDropdownModel2.
         * @implements IVendorDropdownModel2
         * @constructor
         * @param {VendorDropdown2.IVendorDropdownModel2=} [p] Properties to set
         */
        function VendorDropdownModel2(p) {
            this.vendors = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * VendorDropdownModel2 vendors.
         * @member {Array.<VendorDropdown2.VendorDropdownModel2.IVendor>} vendors
         * @memberof VendorDropdown2.VendorDropdownModel2
         * @instance
         */
        VendorDropdownModel2.prototype.vendors = $util.emptyArray;

        /**
         * Encodes the specified VendorDropdownModel2 message. Does not implicitly {@link VendorDropdown2.VendorDropdownModel2.verify|verify} messages.
         * @function encode
         * @memberof VendorDropdown2.VendorDropdownModel2
         * @static
         * @param {VendorDropdown2.IVendorDropdownModel2} m VendorDropdownModel2 message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VendorDropdownModel2.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.vendors != null && m.vendors.length) {
                for (var i = 0; i < m.vendors.length; ++i)
                    $root.VendorDropdown2.VendorDropdownModel2.Vendor.encode(m.vendors[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Encodes the specified VendorDropdownModel2 message, length delimited. Does not implicitly {@link VendorDropdown2.VendorDropdownModel2.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VendorDropdown2.VendorDropdownModel2
         * @static
         * @param {VendorDropdown2.IVendorDropdownModel2} message VendorDropdownModel2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VendorDropdownModel2.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VendorDropdownModel2 message from the specified reader or buffer.
         * @function decode
         * @memberof VendorDropdown2.VendorDropdownModel2
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {VendorDropdown2.VendorDropdownModel2} VendorDropdownModel2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VendorDropdownModel2.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.VendorDropdown2.VendorDropdownModel2();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.vendors && m.vendors.length))
                        m.vendors = [];
                    m.vendors.push($root.VendorDropdown2.VendorDropdownModel2.Vendor.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a VendorDropdownModel2 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VendorDropdown2.VendorDropdownModel2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VendorDropdown2.VendorDropdownModel2} VendorDropdownModel2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VendorDropdownModel2.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        VendorDropdownModel2.Vendor = (function() {

            /**
             * Properties of a Vendor.
             * @memberof VendorDropdown2.VendorDropdownModel2
             * @interface IVendor
             * @property {string|null} [id] Vendor id
             * @property {string|null} [name] Vendor name
             * @property {string|null} [abn] Vendor abn
             * @property {number|null} [totalRows] Vendor totalRows
             */

            /**
             * Constructs a new Vendor.
             * @memberof VendorDropdown2.VendorDropdownModel2
             * @classdesc Represents a Vendor.
             * @implements IVendor
             * @constructor
             * @param {VendorDropdown2.VendorDropdownModel2.IVendor=} [p] Properties to set
             */
            function Vendor(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Vendor id.
             * @member {string|null|undefined} id
             * @memberof VendorDropdown2.VendorDropdownModel2.Vendor
             * @instance
             */
            Vendor.prototype.id = null;

            /**
             * Vendor name.
             * @member {string|null|undefined} name
             * @memberof VendorDropdown2.VendorDropdownModel2.Vendor
             * @instance
             */
            Vendor.prototype.name = null;

            /**
             * Vendor abn.
             * @member {string|null|undefined} abn
             * @memberof VendorDropdown2.VendorDropdownModel2.Vendor
             * @instance
             */
            Vendor.prototype.abn = null;

            /**
             * Vendor totalRows.
             * @member {number|null|undefined} totalRows
             * @memberof VendorDropdown2.VendorDropdownModel2.Vendor
             * @instance
             */
            Vendor.prototype.totalRows = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Vendor _id.
             * @member {"id"|undefined} _id
             * @memberof VendorDropdown2.VendorDropdownModel2.Vendor
             * @instance
             */
            Object.defineProperty(Vendor.prototype, "_id", {
                get: $util.oneOfGetter($oneOfFields = ["id"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Vendor _name.
             * @member {"name"|undefined} _name
             * @memberof VendorDropdown2.VendorDropdownModel2.Vendor
             * @instance
             */
            Object.defineProperty(Vendor.prototype, "_name", {
                get: $util.oneOfGetter($oneOfFields = ["name"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Vendor _abn.
             * @member {"abn"|undefined} _abn
             * @memberof VendorDropdown2.VendorDropdownModel2.Vendor
             * @instance
             */
            Object.defineProperty(Vendor.prototype, "_abn", {
                get: $util.oneOfGetter($oneOfFields = ["abn"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Vendor _totalRows.
             * @member {"totalRows"|undefined} _totalRows
             * @memberof VendorDropdown2.VendorDropdownModel2.Vendor
             * @instance
             */
            Object.defineProperty(Vendor.prototype, "_totalRows", {
                get: $util.oneOfGetter($oneOfFields = ["totalRows"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified Vendor message. Does not implicitly {@link VendorDropdown2.VendorDropdownModel2.Vendor.verify|verify} messages.
             * @function encode
             * @memberof VendorDropdown2.VendorDropdownModel2.Vendor
             * @static
             * @param {VendorDropdown2.VendorDropdownModel2.IVendor} m Vendor message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Vendor.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                    w.uint32(10).string(m.id);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(18).string(m.name);
                if (m.abn != null && Object.hasOwnProperty.call(m, "abn"))
                    w.uint32(26).string(m.abn);
                if (m.totalRows != null && Object.hasOwnProperty.call(m, "totalRows"))
                    w.uint32(32).int32(m.totalRows);
                return w;
            };

            /**
             * Encodes the specified Vendor message, length delimited. Does not implicitly {@link VendorDropdown2.VendorDropdownModel2.Vendor.verify|verify} messages.
             * @function encodeDelimited
             * @memberof VendorDropdown2.VendorDropdownModel2.Vendor
             * @static
             * @param {VendorDropdown2.VendorDropdownModel2.IVendor} message Vendor message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Vendor.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Vendor message from the specified reader or buffer.
             * @function decode
             * @memberof VendorDropdown2.VendorDropdownModel2.Vendor
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {VendorDropdown2.VendorDropdownModel2.Vendor} Vendor
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Vendor.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.VendorDropdown2.VendorDropdownModel2.Vendor();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.id = r.string();
                        break;
                    case 2:
                        m.name = r.string();
                        break;
                    case 3:
                        m.abn = r.string();
                        break;
                    case 4:
                        m.totalRows = r.int32();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Decodes a Vendor message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof VendorDropdown2.VendorDropdownModel2.Vendor
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {VendorDropdown2.VendorDropdownModel2.Vendor} Vendor
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Vendor.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            return Vendor;
        })();

        return VendorDropdownModel2;
    })();

    return VendorDropdown2;
})();

module.exports = $root;
