import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Job No', 'quoteNoRef', true, 'asc', 'left'),
  new TableColumn('Job Stage', 'jobStage', true, 'desc', 'left'),
  new TableColumn('Owner', 'fullName', true, 'desc', 'left'),
  new TableColumn('Rego', 'rego', true, 'desc', 'centered'),
  new TableColumn('Insurer', 'insurer', true, 'desc', 'left'),
  new TableColumn('Days Elapsed', 'daysElapsed', true, 'desc', 'centered'),
  new TableColumn('Job End', 'jobEnd', true, 'desc', 'centered'),
  new TableColumn('Job Total', 'jobTotal', true, 'desc', 'right'),
  new TableColumn('Debtor Invoice', 'debtorInvoice', true, 'desc', 'right'),
  new TableColumn('Excess Invoice', 'excessInvoice', true, 'desc', 'right'),
  new TableColumn('Total Not Invoiced', 'totalNotInvoiced', true, 'desc', 'right'),
  new TableColumn('Notes', 'Notes', true, 'asc', 'centered')
]

export { Columns }