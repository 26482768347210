import MiscQuoteRoutes from './route-types'
// import store from '@/store'

// Lazy-load components. Webpack will split each into individual file
const MiscQuoteListView = () => import(/* webpackChunkName: "miscquote-chunk" */ './MiscQuoteListView.vue')
const MiscQuoteView = () => import(/* webpackChunkName: "miscquote-chunk" */ './MiscQuoteView.vue')
const MiscQuoteDetail = () => import(/* webpackChunkName: "miscquote-chunk" */ './MiscQuoteDetail.vue')
const MiscQuoteItems = () => import(/* webpackChunkName: "miscquote-chunk" */ './MiscQuoteItems.vue')
const MiscQuoteInvoice = () => import(/* webpackChunkName: "miscquote-chunk" */ './MiscQuoteInvoice.vue')

// Get sessionStorage
// const tag = 'MiscQuote'
// const getStorage = function(query) {
//   const sessionId = store.getters['userInfo/info'].sessionId
//   return sessionStorage.getItem(`${sessionId}|${tag}|${query}`)
// }

const moduleId = 'A091'
const invoiceModuleId = 'A086'

export default [
  {
    path: MiscQuoteRoutes.MiscQuoteListView.path,
    name: MiscQuoteRoutes.MiscQuoteListView.name,
    component: MiscQuoteListView,
    meta: {
      id: moduleId,
      route: 'listing',
      title: 'Misc Quotes',
      fkey: 'MiscQuote|filter',
      ekey: 'emailer',
      report: 'rptQuoteDollarHour',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: MiscQuoteRoutes.MiscQuoteDetail.path,
    name: '',
    component: MiscQuoteView,
    // props: route => ({
    //   isNew: route.meta.isNew,
    //   returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl'),
    //   invoiceType: Number(route.query.invoiceType) || Number(route.meta.invoiceType) || Number(getStorage('invoiceType'))
    // }),
    meta: {
      id: moduleId,
      route: 'detail',
      title: 'Misc Quote',
      isNew: false,
      ekey: 'emailer'
    },
    children: [
      {
        path: '',
        name: MiscQuoteRoutes.MiscQuoteDetail.name,
        component: MiscQuoteDetail,
        meta: {
          id: moduleId,
          route: 'detail',
          title: 'Detail',
          isNew: false,
          ekey: 'emailer'
        }
      },
      {
        path: MiscQuoteRoutes.MiscQuoteItems.path,
        name: MiscQuoteRoutes.MiscQuoteItems.name,
        component: MiscQuoteItems,
        meta: {
          id: moduleId,
          route: 'items',
          title: 'Items'
        }
      },
      {
        path: MiscQuoteRoutes.MiscQuoteInvoice.path,
        name: MiscQuoteRoutes.MiscQuoteInvoice.name,
        component: MiscQuoteInvoice,
        meta: {
          id: invoiceModuleId,
          route: 'invoice',
          title: 'Invoice'
        }
      }
    ]
  }
]
