export default {
  Johor: 'JHR',
  Kedah: 'KDH',
  Kelantan: 'KTN',
  Malacca: 'MLK',
  Melaka: 'MLK',
  'Negeri Sembilan': 'NSN',
  Pahang: 'PHG',
  Penang: 'PNG',
  'Pulau Pinang': 'PNG',
  Perak: 'PRK',
  Perlis: 'PLS',
  Sabah: 'SBH',
  Sarawak: 'SWK',
  Selangor: 'SGR',
  Terengganu: 'TRG',
  'Wilayah Persekutuan Kuala Lumpur': 'KUL',
  'Wilayah Persekutuan Labuan': 'LBN',
  'Wilayah Persekutuan Putrajaya': 'PJY'
}