import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntity(id, hash) {
    const url = !hash ? `/debtoradjustments/${id}` : `/debtoradjustments/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseDebtorAdjustmentFilter(filter)
    const url = `/debtoradjustments/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getNewEntity(applyTo) {
    const url = `/debtoradjustments/new/${applyTo}`
    const result = await FqApi.get(url)
    return result.data
  },
  putEntity(entity, deepdiff) {
    if (deepdiff) {
      entity.deepDiff = JSON.stringify(deepdiff)
    }
    return FqApi.put(`/debtoradjustments/${entity.invoiceId}`, entity)
  },
  async postEntity(entity) {
    return FqApi.post('/debtoradjustments', entity)
  },
  serialiseDebtorAdjustmentFilter(filter) {
    const entityProto = require('../../assets/proto/debtoradjustment/DebtorAdjustmentFilter.proto')
    const filterMessage = entityProto.DebtorAdjustmentFilter.DebtorAdjustmentFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  }
}
