import DebtorAdjustmentRoutes from './route-types'
import store from '@/store'

// Lazy-load components. Webpack will split each into individual file
const DebtorAdjustmentListView = r => require.ensure([], () => r(require('./DebtorAdjustmentListView.vue')), 'debtoradjustment-list-chunk')
const DebtorAdjustmentView = r => require.ensure([], () => r(require('./DebtorAdjustmentView.vue')), 'debtoradjustment-chunk')
const DebtorAdjustmentDetail = r => require.ensure([], () => r(require('./DebtorAdjustmentDetail.vue')), 'debtoradjustment-chunk')
const DebtorAdjustmentQuickMiscInvoice = r => require.ensure([], () => r(require('./DebtorAdjustmentQuickMiscInvoice.vue')), 'debtoradjustment-item-chunk')
const DebtorAdjustmentRevenueTotals = r => require.ensure([], () => r(require('./DebtorAdjustmentRevenueTotals.vue')), 'debtoradjustment-revenue-chunk')

// Get sessionStorage
const tag = 'debtoradjustment'
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|${tag}|${query}`)
}

const moduleId = 'A084'

export default [
  {
    path: DebtorAdjustmentRoutes.DebtorAdjustmentListView.path,
    name: DebtorAdjustmentRoutes.DebtorAdjustmentListView.name,
    component: DebtorAdjustmentListView,
    meta: {
      id: moduleId,
      route: 'listing',
      title: 'Debtor Adjustments',
      fkey: 'DebtorAdjustment|filter',
      ekey: 'emailer',
      report: 'rptDebtorAdjustment',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: DebtorAdjustmentRoutes.DebtorAdjustmentDetail.path,
    name: '',
    component: DebtorAdjustmentView,
    props: route => ({
      isNew: route.meta.isNew,
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl')
    }),
    meta: {
      id: moduleId,
      route: 'detail',
      title: 'Debtor Adjustment',
      isNew: false,
      ekey: 'emailer'
    },
    children: [
      {
        path: '',
        name: DebtorAdjustmentRoutes.DebtorAdjustmentDetail.name,
        component: DebtorAdjustmentDetail,
        meta: {
          id: moduleId,
          route: 'detail',
          title: 'Detail',
          isNew: false,
          ekey: 'emailer'
        }
      },
      {
        path: DebtorAdjustmentRoutes.DebtorAdjustmentQuickMiscInvoice.path,
        name: DebtorAdjustmentRoutes.DebtorAdjustmentQuickMiscInvoice.name,
        component: DebtorAdjustmentQuickMiscInvoice,
        meta: {
          id: moduleId,
          route: 'quickmisc',
          title: 'Items'
        }
      },
      {
        path: DebtorAdjustmentRoutes.DebtorAdjustmentRevenueTotals.path,
        name: DebtorAdjustmentRoutes.DebtorAdjustmentRevenueTotals.name,
        component: DebtorAdjustmentRevenueTotals,
        meta: {
          id: moduleId,
          route: 'revenuetotals',
          title: 'Revenue Totals'
        }
      }
    ]
  }
]
