/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.QuoteVehicleDropdownFilter = (function() {

    /**
     * Namespace QuoteVehicleDropdownFilter.
     * @exports QuoteVehicleDropdownFilter
     * @namespace
     */
    var QuoteVehicleDropdownFilter = {};

    QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel = (function() {

        /**
         * Properties of a QuoteVehicleDropdownFilterModel.
         * @memberof QuoteVehicleDropdownFilter
         * @interface IQuoteVehicleDropdownFilterModel
         * @property {string|null} [vehicleId] QuoteVehicleDropdownFilterModel vehicleId
         * @property {string|null} [rego] QuoteVehicleDropdownFilterModel rego
         * @property {number|null} [pageIndex] QuoteVehicleDropdownFilterModel pageIndex
         * @property {number|null} [pageSize] QuoteVehicleDropdownFilterModel pageSize
         */

        /**
         * Constructs a new QuoteVehicleDropdownFilterModel.
         * @memberof QuoteVehicleDropdownFilter
         * @classdesc Represents a QuoteVehicleDropdownFilterModel.
         * @implements IQuoteVehicleDropdownFilterModel
         * @constructor
         * @param {QuoteVehicleDropdownFilter.IQuoteVehicleDropdownFilterModel=} [p] Properties to set
         */
        function QuoteVehicleDropdownFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QuoteVehicleDropdownFilterModel vehicleId.
         * @member {string|null|undefined} vehicleId
         * @memberof QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel
         * @instance
         */
        QuoteVehicleDropdownFilterModel.prototype.vehicleId = null;

        /**
         * QuoteVehicleDropdownFilterModel rego.
         * @member {string|null|undefined} rego
         * @memberof QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel
         * @instance
         */
        QuoteVehicleDropdownFilterModel.prototype.rego = null;

        /**
         * QuoteVehicleDropdownFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel
         * @instance
         */
        QuoteVehicleDropdownFilterModel.prototype.pageIndex = null;

        /**
         * QuoteVehicleDropdownFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel
         * @instance
         */
        QuoteVehicleDropdownFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * QuoteVehicleDropdownFilterModel _vehicleId.
         * @member {"vehicleId"|undefined} _vehicleId
         * @memberof QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel
         * @instance
         */
        Object.defineProperty(QuoteVehicleDropdownFilterModel.prototype, "_vehicleId", {
            get: $util.oneOfGetter($oneOfFields = ["vehicleId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteVehicleDropdownFilterModel _rego.
         * @member {"rego"|undefined} _rego
         * @memberof QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel
         * @instance
         */
        Object.defineProperty(QuoteVehicleDropdownFilterModel.prototype, "_rego", {
            get: $util.oneOfGetter($oneOfFields = ["rego"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteVehicleDropdownFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel
         * @instance
         */
        Object.defineProperty(QuoteVehicleDropdownFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteVehicleDropdownFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel
         * @instance
         */
        Object.defineProperty(QuoteVehicleDropdownFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified QuoteVehicleDropdownFilterModel message. Does not implicitly {@link QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel.verify|verify} messages.
         * @function encode
         * @memberof QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel
         * @static
         * @param {QuoteVehicleDropdownFilter.IQuoteVehicleDropdownFilterModel} m QuoteVehicleDropdownFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuoteVehicleDropdownFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.vehicleId != null && Object.hasOwnProperty.call(m, "vehicleId"))
                w.uint32(10).string(m.vehicleId);
            if (m.rego != null && Object.hasOwnProperty.call(m, "rego"))
                w.uint32(18).string(m.rego);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(24).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(32).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified QuoteVehicleDropdownFilterModel message, length delimited. Does not implicitly {@link QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel
         * @static
         * @param {QuoteVehicleDropdownFilter.IQuoteVehicleDropdownFilterModel} message QuoteVehicleDropdownFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuoteVehicleDropdownFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QuoteVehicleDropdownFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel} QuoteVehicleDropdownFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuoteVehicleDropdownFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.vehicleId = r.string();
                    break;
                case 2:
                    m.rego = r.string();
                    break;
                case 3:
                    m.pageIndex = r.int32();
                    break;
                case 4:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a QuoteVehicleDropdownFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel} QuoteVehicleDropdownFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuoteVehicleDropdownFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return QuoteVehicleDropdownFilterModel;
    })();

    return QuoteVehicleDropdownFilter;
})();

module.exports = $root;
