import routeTypes from './route-types'
import store from '@/store'

// Lazy-load components. Webpack will split each into individual file
const UnscheduledModelListView = r => require.ensure([], () => r(require('./UnscheduledModelListView.vue')), 'unscheduledmodellist-chunk')
const UnscheduledModelView = r => require.ensure([], () => r(require('./UnscheduledModelView.vue')), 'unscheduledmodel-chunk')
const UnscheduledModelDetail = r => require.ensure([], () => r(require('./UnscheduledModelDetail.vue')), 'unscheduledmodel-chunk')

// Get sessionStorage
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|unscheduledmodel|${query}`)
}

const moduleId = 'A107'

export default [
  {
    path: routeTypes.UnscheduledModelListView.path,
    name: routeTypes.UnscheduledModelListView.name,
    component: UnscheduledModelListView,
    meta: {
      id: moduleId,
      route: 'listing',
      title: 'Unscheduled Model',
      fkey: 'unscheduledmodel|filter',
      report: 'rptTableUnscheduleModelLists',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.UnscheduledModelDetail.path,
    name: '',
    component: UnscheduledModelView,
    props: route => ({
      isNew: route.query.mode === 'new' || route.meta.isNew || getStorage('isNew') === 'true',
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl'),
      name: route.query.name || route.meta.name || getStorage('name')
    }),
    meta: {
      id: moduleId,
      route: 'detail',
      title: 'Unscheduled Model'
    },
    query: {
      filter: ''
    },
    children: [
      {
        path: '',
        name: routeTypes.UnscheduledModelDetail.name,
        component: UnscheduledModelDetail,
        meta: {
          id: moduleId,
          title: 'Detail',
          fkey: 'unscheduledmodel|filter',
          report: 'rptUnscheduledModelDetail',
          reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
        }
      }
    ]
  }
]
