import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
import ProtoBufApi from '@/services/protobuf-api'

export default {
  async getEntity(id, hash) {
    const url = !hash ? `/unscheduledmodels/${id}` : `/unscheduledmodels/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = encodeURIComponent(this.serialiseUnscheduledModelFilter(filter))
    const url = `/unscheduledmodels/list?filter=${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseUnscheduledModelFilter(filter) {
    const entityProto = require('../../assets/proto/unscheduledmodel/UnscheduledModelFilter.proto')
    const filterMessage = entityProto.UnscheduledModelFilter.UnscheduledModelFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getMakeDropdownProto(filter) {
    const serialised = encodeURIComponent(this.serialiseMakeDropdownFilter(filter))
    const result = await ProtoBufApi(`/unscheduledmodels/protobuf/make/listcombo?filter=${serialised}`)
    const proto = require('../../assets/proto/unscheduledmodel/UnscheduledModelMakeDropdown.proto')
    const message = proto.UnscheduledModelMakeDropdown.UnscheduledModelMakeDropdownModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.assets
  },
  serialiseMakeDropdownFilter(filter) {
    const entityProto = require('../../assets/proto/unscheduledmodel/UnscheduledModelMakeDropdownFilter.proto')
    const filterMessage = entityProto.UnscheduledModelMakeDropdownFilter.UnscheduledModelMakeDropdownFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  putUnscheduledModel(entity, deepDiff) {
    if (deepDiff) {
      entity.deepDiff = JSON.stringify(deepDiff)
    }
    return FqApi.put(`/unscheduledmodels/${entity.modelCustomId}`, entity)
  },
  async postUnscheduledModel(entity) {
    return FqApi.post('/unscheduledmodels', entity)
  },
  async assertUsed(entityId) {
    const result = await FqApi.get(`/unscheduledmodels/assertused/${entityId}`)
    return result.data
  },
  async getNewEntity() {
    const result = await FqApi.get('/unscheduledmodels/new')
    return result.data
  },
  async getAssetEmail(assetId, assetType) {
    const url = `/unscheduledmodels/getassetemail/${assetId}/${assetType}`
    const result = await FqApi.get(url)
    return result.data
  }
}
