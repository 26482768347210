import VehicleOptionsService from './VehicleOptionsService'

const STORE_BODYSHAPE = 'STORE_BODYSHAPE'
const STORE_COLOURS = 'STORE_COLOURS'
const STORE_PAINTGROUPS = 'STORE_PAINTGROUPS'

const STORAGE_KEY_BODYSHAPE = 'uniq-v2-bodyshapes'
const STORAGE_KEY_COLOURS = 'uniq-v2-colours'
const STORAGE_KEY_PAINTGROUPS = 'uniq-v2-paintgroups'

const state = {
  bodyShapes: [],
  colours: [],
  paintGroups: []
}

const getters = {
  bodyShapes: state => state.bodyShapes,
  colours: state => state.colours,
  paintGroups: state => state.paintGroups
}

const actions = {
  load({ commit, state }) {
    if (state.bodyShapes.length === 0) {
      VehicleOptionsService.getBodyShapes().then(res => {
        commit(STORE_BODYSHAPE, res.data.list)
      })
    }

    if (state.colours.length === 0) {
      VehicleOptionsService.getColours().then(res => {
        commit(STORE_COLOURS, res.data.list)
      })
    }

    if (state.paintGroups.length === 0) {
      VehicleOptionsService.getPaintGroups().then(res => {
        commit(STORE_PAINTGROUPS, res.data.list)
      })
    }
  },
  refreshColour({ commit, state }) {
    VehicleOptionsService.getColours().then(res => {
      commit(STORE_COLOURS, res.data.list)
    })
  }
}

const mutations = {
  [STORE_BODYSHAPE](state, data) {
    state.bodyShapes = data
    localStorage.setItem(STORAGE_KEY_BODYSHAPE, JSON.stringify(state.bodyShapes))
  },

  [STORE_COLOURS](state, data) {
    state.colours = data
    localStorage.setItem(STORAGE_KEY_COLOURS, JSON.stringify(state.colours))
  },

  [STORE_PAINTGROUPS](state, data) {
    state.paintGroups = data
    localStorage.setItem(STORAGE_KEY_PAINTGROUPS, JSON.stringify(state.paintGroups))
  }
}

// SYNC LOCAL STORAGE
// BODYSHAPE
if (localStorage.getItem(STORAGE_KEY_BODYSHAPE)) {
  state.bodyShapes = JSON.parse(localStorage.getItem(STORAGE_KEY_BODYSHAPE))
}

// COLOURS
if (localStorage.getItem(STORAGE_KEY_COLOURS)) {
  state.colours = JSON.parse(localStorage.getItem(STORAGE_KEY_COLOURS))
}

// PAINT GROUPS
if (localStorage.getItem(STORAGE_KEY_PAINTGROUPS)) {
  state.paintGroups = JSON.parse(localStorage.getItem(STORAGE_KEY_PAINTGROUPS))
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
