import StoreUtil from '@/store/utils'

const tag = 'gstreport'

const state = {
  returnRoute: {},
  baseIndex: 0,
  currentIndex: 0,
  snapshots: [],
  whPeriodStart: null,
  whPeriodEnd: null,
  reprintSelectedDate: null
}

const getters = {
  returnRoute: state => state.returnRoute,
  snapshots: state => state.snapshots,
  baseSnapshot: state => state.snapshots[state.baseIndex],
  currentSnapshot: state => state.snapshots[state.currentIndex],
  snapshotDiff: state => {
    if (state.snapshots[state.baseIndex] && state.snapshots[state.currentIndex]) {
      return DeepDiff.diff(state.snapshots[state.baseIndex], state.snapshots[state.currentIndex])
    } else {
      return null
    }
  },
  whPeriodStart: state => state.whPeriodStart,
  whPeriodEnd: state => state.whPeriodEnd,
  reprintSelectedDate: state => state.reprintSelectedDate
}

const actions = {
  async getStoreItem({ commit }, id) {},
  async editStoreItem({ commit }, id) {},
  async addStoreItem({ commit }, id) {},
  saveSnapshot({ commit }, entity) {
    commit('saveSnapshot', entity)
  },
  clearSnapshots({ commit }, id) {
    StoreUtil.removeStorage(id, tag)
    commit('clearSnapshots')
  },
  setReturnRoute({ commit }, route) {
    commit('setReturnRoute', route)
  },
  setWHPeriodStart({ commit }, date) {
    commit('setWHPeriodStart', date)
  },
  setWHPeriodEnd({ commit }, date) {
    commit('setWHPeriodEnd', date)
  },
  setReprintSelectedDate({ commit }, date) {
    commit('setReprintSelectedDate', date)
  }
}

const mutations = {
  saveInitialSnapshots(state, entity) {
    state.snapshots = []
    state.snapshots.splice(0, 1, entity)
    state.snapshots.splice(1, 1, entity)
    state.currentIndex = state.snapshots.length - 1
  },
  saveSnapshot(state, entity) {
    state.snapshots.splice(state.currentIndex, 1, entity)
  },
  clearSnapshots(state) {
    state.snapshots = []
    state.baseIndex = 0
    state.currentIndex = 0
  },
  setReturnRoute(state, route) {
    state.returnRoute = route
  },
  setWHPeriodStart(state, date) {
    state.whPeriodStart = date
  },
  setWHPeriodEnd(state, date) {
    state.whPeriodEnd = date
  },
  setReprintSelectedDate(state, date) {
    state.reprintSelectedDate = date
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
