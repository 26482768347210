import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
import { ProductionInOutModel } from '@/classes/viewmodels'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialiseProductionInOutFilter(filter)
    const url = `productioninout/list/${serialised}`
    const result = await FqApi.get(url)

    let data = []
    result.data.forEach((e, index) => {
      const productionInOutModel = new ProductionInOutModel(e)
      data.splice(data.length, 0, productionInOutModel)
    })

    return data
  },
  serialiseProductionInOutFilter(filter) {
    const entityProto = require('../../assets/proto/productioninout/ProductionInOutFilter.proto')
    const filterMessage = entityProto.ProductionInOutFilter.ProductionInOutFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  }
}
