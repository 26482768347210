import { ComponentRoute } from '@/classes'

const QuoteTemplateRoutes = {
  QuoteTemplateListView: new ComponentRoute('/quotetemplates', 'QuoteTemplateListView'),
  QuoteTemplateView: new ComponentRoute('/quotetemplates/:entityId', 'QuoteTemplateView'),
  QuoteTemplateLabourView: new ComponentRoute('labour', 'QuoteTemplateLabourView'),
  QuoteTemplateMiscView: new ComponentRoute('misc', 'QuoteTemplateMiscView'),
  QuoteTemplateSubletView: new ComponentRoute('sublet', 'QuoteTemplateSubletView')
}

export default QuoteTemplateRoutes
