/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.PurchaseOrderFilter = (function() {

    /**
     * Namespace PurchaseOrderFilter.
     * @exports PurchaseOrderFilter
     * @namespace
     */
    var PurchaseOrderFilter = {};

    PurchaseOrderFilter.PurchaseOrderFilterModel = (function() {

        /**
         * Properties of a PurchaseOrderFilterModel.
         * @memberof PurchaseOrderFilter
         * @interface IPurchaseOrderFilterModel
         * @property {string|null} [searchValue] PurchaseOrderFilterModel searchValue
         * @property {string|null} [sortColumn] PurchaseOrderFilterModel sortColumn
         * @property {string|null} [sortOrder] PurchaseOrderFilterModel sortOrder
         * @property {number|null} [pageIndex] PurchaseOrderFilterModel pageIndex
         * @property {number|null} [pageSize] PurchaseOrderFilterModel pageSize
         */

        /**
         * Constructs a new PurchaseOrderFilterModel.
         * @memberof PurchaseOrderFilter
         * @classdesc Represents a PurchaseOrderFilterModel.
         * @implements IPurchaseOrderFilterModel
         * @constructor
         * @param {PurchaseOrderFilter.IPurchaseOrderFilterModel=} [p] Properties to set
         */
        function PurchaseOrderFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PurchaseOrderFilterModel searchValue.
         * @member {string|null|undefined} searchValue
         * @memberof PurchaseOrderFilter.PurchaseOrderFilterModel
         * @instance
         */
        PurchaseOrderFilterModel.prototype.searchValue = null;

        /**
         * PurchaseOrderFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof PurchaseOrderFilter.PurchaseOrderFilterModel
         * @instance
         */
        PurchaseOrderFilterModel.prototype.sortColumn = null;

        /**
         * PurchaseOrderFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof PurchaseOrderFilter.PurchaseOrderFilterModel
         * @instance
         */
        PurchaseOrderFilterModel.prototype.sortOrder = null;

        /**
         * PurchaseOrderFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof PurchaseOrderFilter.PurchaseOrderFilterModel
         * @instance
         */
        PurchaseOrderFilterModel.prototype.pageIndex = null;

        /**
         * PurchaseOrderFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof PurchaseOrderFilter.PurchaseOrderFilterModel
         * @instance
         */
        PurchaseOrderFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * PurchaseOrderFilterModel _searchValue.
         * @member {"searchValue"|undefined} _searchValue
         * @memberof PurchaseOrderFilter.PurchaseOrderFilterModel
         * @instance
         */
        Object.defineProperty(PurchaseOrderFilterModel.prototype, "_searchValue", {
            get: $util.oneOfGetter($oneOfFields = ["searchValue"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PurchaseOrderFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof PurchaseOrderFilter.PurchaseOrderFilterModel
         * @instance
         */
        Object.defineProperty(PurchaseOrderFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PurchaseOrderFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof PurchaseOrderFilter.PurchaseOrderFilterModel
         * @instance
         */
        Object.defineProperty(PurchaseOrderFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PurchaseOrderFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof PurchaseOrderFilter.PurchaseOrderFilterModel
         * @instance
         */
        Object.defineProperty(PurchaseOrderFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PurchaseOrderFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof PurchaseOrderFilter.PurchaseOrderFilterModel
         * @instance
         */
        Object.defineProperty(PurchaseOrderFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified PurchaseOrderFilterModel message. Does not implicitly {@link PurchaseOrderFilter.PurchaseOrderFilterModel.verify|verify} messages.
         * @function encode
         * @memberof PurchaseOrderFilter.PurchaseOrderFilterModel
         * @static
         * @param {PurchaseOrderFilter.IPurchaseOrderFilterModel} m PurchaseOrderFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PurchaseOrderFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.searchValue != null && Object.hasOwnProperty.call(m, "searchValue"))
                w.uint32(10).string(m.searchValue);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(18).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(26).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(32).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(40).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified PurchaseOrderFilterModel message, length delimited. Does not implicitly {@link PurchaseOrderFilter.PurchaseOrderFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof PurchaseOrderFilter.PurchaseOrderFilterModel
         * @static
         * @param {PurchaseOrderFilter.IPurchaseOrderFilterModel} message PurchaseOrderFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PurchaseOrderFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PurchaseOrderFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof PurchaseOrderFilter.PurchaseOrderFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {PurchaseOrderFilter.PurchaseOrderFilterModel} PurchaseOrderFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PurchaseOrderFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.PurchaseOrderFilter.PurchaseOrderFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.searchValue = r.string();
                    break;
                case 2:
                    m.sortColumn = r.string();
                    break;
                case 3:
                    m.sortOrder = r.string();
                    break;
                case 4:
                    m.pageIndex = r.int32();
                    break;
                case 5:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a PurchaseOrderFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof PurchaseOrderFilter.PurchaseOrderFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {PurchaseOrderFilter.PurchaseOrderFilterModel} PurchaseOrderFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PurchaseOrderFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return PurchaseOrderFilterModel;
    })();

    return PurchaseOrderFilter;
})();

module.exports = $root;
