export default {
  filters: {
    formatDateLocale(value, locale, format = '2-digit') {
      const date = new Date(Date.parse(`${value}`))
      if (date && !isNaN(date)) {
        const options = { year: 'numeric', month: format, day: '2-digit' }
        if (Intl) {
          return Intl.DateTimeFormat(locale, options).format(date)
        } else {
          return date.toLocaleDateString(locale, options)
        }
      } else {
        return value
      }
    },
    /**
     * Use for UTC dates where there is no ending "Z"
     * e.g. DateTime from stored procedure for listing pages
     * @param {Date, String} value
     * @param {String} locale
     * @param {String} format
     */
    formatDateUtc(value, locale, format = '2-digit') {
      const date = new Date(Date.parse(`${value}Z`))
      if (date && !isNaN(date)) {
        const options = { year: 'numeric', month: format, day: '2-digit' }
        if (Intl) {
          return Intl.DateTimeFormat(locale, options).format(date)
        } else {
          return date.toLocaleDateString(locale, options)
        }
      } else {
        return value
      }
    },
    formatTimeLocale(value, locale, showSecond = false) {
      const date = new Date(Date.parse(value))
      if (date && !isNaN(date)) {
        if (Intl) {
          const options = {
            hour: 'numeric',
            minute: 'numeric', // second: 'numeric',
            hour12: true
          }
          if (showSecond) {
            Object.assign(options, { second: 'numeric' })
          }
          return Intl.DateTimeFormat(locale, options).format(date)
        } else {
          return date.toLocaleTimeString(locale)
        }
      } else {
        return value
      }
    },
    formatDateTimeLocale(value, locale, showSecond = false) {
      const date = new Date(Date.parse(value))
      if (date && !isNaN(date)) {
        if (Intl) {
          const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric', // second: 'numeric',
            hour12: true
          }
          if (showSecond) {
            Object.assign(options, { second: 'numeric' })
          }
          return Intl.DateTimeFormat(locale, options).format(date)
        } else {
          return `${date.toLocaleDateString(locale)} ${date.toLocaleTimeString(locale)}`
        }
      } else {
        return value
      }
    },
    formatDate(value, format) {
      // Not implemented yet
      return value
    }
  }
}
