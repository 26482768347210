// Based on https://github.com/buefy/buefy
// Date: 15/03/2019
// Version: 0.7.3
<template>
  <transition 
    :enter-active-class="transition.enter"
    :leave-active-class="transition.leave">
    <div 
      v-show="isActive"
      class="toast"
      :class="[type, position]"
      :aria-hidden="!isActive"
      role="alert">
      <div v-html="message" />
    </div>
  </transition>
</template>

<script>
import config from '../utils/config'
import NoticeMixin from '../utils/NoticeMixin.js'
export default {
  name: 'BulmaToast',
  mixins: [NoticeMixin],
  data() {
    return {
      newDuration: this.duration || config.defaultToastDuration
    }
  }
}
</script>
