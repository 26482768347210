import { ComponentRoute } from '@/classes'

const PurchaseOrderRoutes = {
  PurchaseOrderListView: new ComponentRoute('/purchaseorders', 'PurchaseOrderListView'),
  PurchaseOrderView: new ComponentRoute('/purchaseorders/:purchaseOrderId', 'PurchaseOrderView'),
  PurchaseOrderDetail: new ComponentRoute('/purchaseorders/:purchaseOrderId', 'PurchaseOrderDetail'),
  PurchaseOrderVehicleCustomerInsurer: new ComponentRoute('vehiclecustomerinsurer', 'PurchaseOrderVehicleCustomerInsurer'),
  PurchaseOrderRemarks: new ComponentRoute('remarks', 'PurchaseOrderRemarks'),
  PurchaseOrderOtherPurchases: new ComponentRoute('otherpurchases', 'PurchaseOrderOtherPurchases'),
  PurchaseOrderOverview: new ComponentRoute('overview', 'PurchaseOrderOverview'),
  PurchaseOrderSublet: new ComponentRoute('sublet', 'PurchaseOrderSublet'),
  PurchaseOrderMisc: new ComponentRoute('misc', 'PurchaseOrderMisc'),
  PurchaseOrderParts: new ComponentRoute('parts', 'PurchaseOrderParts'),
  PurchaseOrderOrderReceipt: new ComponentRoute('orderreceipt', 'PurchaseOrderOrderReceipt'),
  PurchaseOrderOrderReceiptDetail: new ComponentRoute('orderreceipt/:creditorInvoiceId', 'PurchaseOrderOrderReceiptDetail')
}

export default PurchaseOrderRoutes
