<template>

  <div class="tile is-parent pb-0 pl-4">

    <DashboardQuoteStatus :quotes="quotes"
      :is-loading="isLoading"
      :is-uninvoiced-jobs-and-chart-disabled="isUninvoicedJobsAndChartDisabled"
      @handleQuoteRedirect="handleQuoteRedirect" />
    <div class="ml-3" />
    <DashboardJobStatus :quotes="quotes"
      :is-loading="isLoading"
      :is-uninvoiced-jobs-and-chart-disabled="isUninvoicedJobsAndChartDisabled"
      @handleQuoteRedirect="handleQuoteRedirect" />

  </div>

</template>

<script>
import DashboardService from '@/views/dashboard/DashboardService'
import DashboardQuoteStatus from '@/views/dashboard/DashboardQuoteStatus.vue'
import DashboardJobStatus from '@/views/dashboard/DashboardJobStatus.vue'

export default {
  name: 'DashboardStatues',
  components: { DashboardJobStatus, DashboardQuoteStatus },
  props: {
    isUninvoicedJobsAndChartDisabled: Boolean,
    dataObj: Object
  },
  data() {
    return {
      quotes: null,
      isLoading: false,
      timeOutId: 0
    }
  },
  computed: {
    filterKey() {
      if (!this.$userInfo) return ''
      return `${this.$userInfo.sessionId}|Quote|filter`
    }
  },
  created() {
    this.constantlyUpdateData()
  },
  beforeDestroy() {
    if (this.timeOutId) clearTimeout(this.timeOutId)
  },
  methods: {
    async getData() {
      this.isLoading = true
      const data = await DashboardService.getDashboardJobStatus()
      this.quotes = data.quotes
      this.isLoading = false
    },
    constantlyUpdateData() {
      this.getData()
      // we want to update the data every 30 seconds
      this.timeOutId = setTimeout(this.constantlyUpdateData, 1000 * 30)
    },
    handleQuoteRedirect(jobStage) {
      const obj = this.dataObj
      obj.jobStage = [jobStage]
      sessionStorage.setItem(this.filterKey, JSON.stringify(obj))
      this.$router.push('/quotes')
    }
  }
}
</script>

<style>
.clickable-panel {
  cursor: pointer;
  border: 1px transparent solid;
  opacity: 1;
}

.clickable-panel:hover {
  opacity: 0.9;

  opacity: 1;
  background: linear-gradient(to right, #222 50%, hsl(0, 0%, 8%) 50%);
  background-size: 200% 120%;
  background-position: right;
  transition: background-position 0.3s ease-out;
}
</style>