<template>
  <div
    v-if="isLoading"
    class="progress2 progress-moved">
    <div class="progress-bar2" />
  </div>

  <div v-else
    class="header-border" />

</template>

<script>
export default {
  name: 'DashboardProgressBar',
  props: {
    initialColor:String,
    loadingColor:String,
    isLoading:Boolean
  }
}
</script>

<style scoped>

.progress2 {
  background-color: v-bind(initialColor)
}

.progress-bar2 {
  height: 5px;
  transition: 0.5s linear;
  transition-property: width, background-color;
}

.progress-moved .progress-bar2 {
  animation: progress 2s infinite;
}

@keyframes progress {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
    background: v-bind(loadingColor)
  }
}

.header-border{
  border-bottom: 5px solid v-bind(initialColor);
}

</style>