import Guid from '@/components/Guid'
// import { calcTotalExGst, calcGst, roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default class MiscQuoteItemModel {
  constructor(miscQuoteId, itemNo = '', itemDesc = '', quantity = 1, unitPrice = 0, itemGlCode = '', sortNo = 0) {
    // const gst = calcGst(1, unitPrice, gstRate)
    // const totalExGst = calcTotalExGst(quantity, unitPrice)
    this.miscQuoteItemId = Guid.newGuid()
    this.miscQuoteId = miscQuoteId
    this.itemNo = itemNo
    this.itemDesc = itemDesc
    this.quantity = quantity
    this.unitPrice = unitPrice
    this.itemGlCode = itemGlCode
    this.glCategory = ''
    this.sortNo = sortNo
    this.isDeleted = false
    this.isDirty = false
    this.isNew = true
    this.modifiedBy = null
    this.modifiedDate = null
    this.createdBy = null
    this.createdDate = null
  }
}
