import routeTypes from './route-types'
import store from '@/store'

// Lazy-load components. Webpack will split each into individual file
const QuickItemListView = r => require.ensure([], () => r(require('./QuickItemListView.vue')), 'quickitemlist-chunk')
const QuickItemView = r => require.ensure([], () => r(require('./QuickItemView.vue')), 'quickitem-chunk')
const QuickItemDetail = r => require.ensure([], () => r(require('./QuickItemDetail.vue')), 'quickitem-chunk')

// Get sessionStorage
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|quickitem|${query}`)
}

export default [
  {
    path: routeTypes.QuickItemListView.path,
    name: routeTypes.QuickItemListView.name,
    component: QuickItemListView,
    meta: {
      id: 'A081',
      route: 'listing',
      title: 'Quick Items',
      fkey: 'quickitem|filter',
      report: 'rptQuickItemListV2',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.QuickItemDetail.path,
    name: '',
    component: QuickItemView,
    props: route => ({
      isNew: route.query.mode === 'new' || route.meta.isNew || getStorage('isNew') === 'true',
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl'),
      name: route.query.name || route.meta.name || getStorage('name')
    }),
    meta: {
      id: 'A081',
      route: 'detail',
      title: 'Quick Item'
    },
    query: {
      filter: ''
    },
    children: [
      {
        path: '',
        name: routeTypes.QuickItemDetail.name,
        component: QuickItemDetail,
        meta: {
          id: 'A081',
          title: 'Detail',
          fkey: 'quickitem|filter',
          report: 'rptTableItemsQuick',
          reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
        }
      }
    ]
  }
]
