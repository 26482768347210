import QuickInvoiceService from './QuickInvoiceService'
import QuickInvoiceRoutes from './route-types'
import StoreUtil from '@/store/utils'
import DeepDiff from 'deep-diff'
import { md5 } from '@/components/crypto'
// import { DateTime } from 'luxon'

const tag = 'quickinvoice'

const state = {
  returnRoute: {},
  baseIndex: 0,
  currentIndex: 0,
  snapshots: [],
  readOnlyView: false
}

const getters = {
  returnRoute: state => state.returnRoute,
  snapshots: state => state.snapshots,
  baseSnapshot: state => state.snapshots[state.baseIndex],
  currentSnapshot: state => state.snapshots[state.currentIndex],
  snapshotDiff: state => {
    if (state.snapshots[state.baseIndex] && state.snapshots[state.currentIndex]) {
      return DeepDiff.diff(state.snapshots[state.baseIndex], state.snapshots[state.currentIndex])
    } else {
      return null
    }
  },
  readOnlyView: state => state.readOnlyView
}

const actions = {
  async getStoreItem({ commit }, id) {
    // const entity = StoreUtil.getStorage(id, tag) || (await QuickInvoiceService.getEntity(id, ''))
    // The following for refreshing data from WebApi regardless
    let entity = StoreUtil.getStorage(id, tag)
    if (!entity || (entity && !entity.isNew)) {
      const hash = entity ? md5(JSON.stringify(entity)) : ''
      const data = await QuickInvoiceService.getEntity(id, hash)
      if (data) {
        console.log('from WebApi')
        // data.invoiceDate = data.invoiceDate.split('.')[1] ? data.invoiceDate.split('.')[0] + 'Z' : data.invoiceDate
        entity = data
        if(entity.exported)
          commit('setReadOnlyView', true)
      } else {
        console.log('from sessionStorage')
      }
    }
    StoreUtil.setStorage(entity.invoiceId, tag, entity)
    commit('saveInitialSnapshots', entity)
  },
  async editStoreItem({ commit }, id) {
    const entity = StoreUtil.getStorage(id, tag) || (await QuickInvoiceService.getEntity(id, ''))
    StoreUtil.setStorage(entity.invoiceId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(QuickInvoiceRoutes.QuickInvoiceListView.path, entity.invoiceId, false)
  },
  async addStoreItem({ commit }) {
    const entity = await QuickInvoiceService.getNewEntity()
    // set invoiceDate to browser UTC date
    // const isoDate = DateTime.local().toISODate()
    // const utcDate = DateTime.fromISO(isoDate).toUTC()
    // entity.invoiceDate = utcDate.toISO().split('.')[0] + 'Z'
    // const utcDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString()
    // entity.invoiceDate = utcDate.split('.')[0] + 'Z'
    StoreUtil.setStorage(entity.invoiceId, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(QuickInvoiceRoutes.QuickInvoiceListView.path, entity.invoiceId, true)
  },
  saveSnapshot({ commit }, entity) {
    commit('saveSnapshot', entity)
  },
  clearSnapshots({ commit }, id) {
    StoreUtil.removeStorage(id, tag)
    commit('clearSnapshots')
  },
  setAsset({ commit }, asset) {
    commit('setAsset', asset)
  },
  setContactId({ commit }, id) {
    commit('setContactId', id)
  },
  setReturnRoute({ commit }, route) {
    commit('setReturnRoute', route)
  },
  setReadOnlyView({ commit }, value) {
    commit('setReadOnlyView', value)
  }
}

const mutations = {
  saveInitialSnapshots(state, entity) {
    state.snapshots = []
    state.snapshots.splice(0, 1, entity)
    state.snapshots.splice(1, 1, entity)
    state.currentIndex = state.snapshots.length - 1
  },
  saveSnapshot(state, entity) {
    state.snapshots.splice(state.currentIndex, 1, entity)
  },
  clearSnapshots(state) {
    state.snapshots = []
    state.baseIndex = 0
    state.currentIndex = 0
  },
  setAsset(state, payload) {
    /**
     * @param {QuickInvoiceAssetPayload} payload see src/classes/QuickInvoiceAssetPayload
     */
    state.snapshots[state.currentIndex].assetId = payload.assetId
    state.snapshots[state.currentIndex].assetType = payload.assetType
    state.snapshots[state.currentIndex].invoiceGlCode = payload.assetGlCode
  },
  setContactId(state, id) {
    state.snapshots[state.currentIndex].contactId = id
  },
  setReturnRoute(state, route) {
    state.returnRoute = route
  },
  setReadOnlyView(state, value) {
    state.readOnlyView = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
