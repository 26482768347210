import routeTypes from './route-types'

const AudanetTaskListView = r => require.ensure([], () => r(require('./AudanetTaskListView.vue')), 'audanettasklist-chunk')

const menuCode = 'A113'

// Get sessionStorage
// const getStorage = function(query) {
//   const sessionId = store.getters['userInfo/info'].sessionId
//   return sessionStorage.getItem(`${sessionId}|ormmessage|${query}`)
// }

export default [
  {
    path: routeTypes.AudanetTaskListView.path,
    name: routeTypes.AudanetTaskListView.name,
    component: AudanetTaskListView,
    meta: {
      id: menuCode,
      route: 'listing',
      title: 'AudaNet Tasks',
      fkey: 'audanettask|filter',
      report: '',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  }
]
