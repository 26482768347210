<template>
  <div class="tile is-child has-background-dark tile-border ml-3">
    <DashboardPanel>
      <div class="panel-heading has-text-centered has-text-white custom-panel-heading">
        JOB STATUS
      </div>

      <DashboardProgressBar :is-loading="isLoading"
        :initial-color="'#9057FF'"
        :loading-color="'#9d2dba'" />

      <div class="has-background-dark tile-border"
        v-if="quotes">

        <div class="panel-block is-flex is-justify-content-space-between custom-panel custom-panel-border pb-3 clickable-panel"
          :class="[{'custom-panel-with-no-chart': isUninvoicedJobsAndChartDisabled}]"
          @click="onClick(JobStageTypes.BookedIn)">
          <p class="is-flex is-align-items-center">Booked In</p>
          <DashboardCircularStatus :value="quotes.bookedIn"
            :color-code="'#9057FF'"
            :width="'50px'"
            :height="'50px'" />
        </div>

        <div class=" panel-block is-flex is-justify-content-space-between custom-panel custom-panel-border pb-3 clickable-panel"
          :class="[{'custom-panel-with-no-chart': isUninvoicedJobsAndChartDisabled}]"
          @click="onClick(JobStageTypes.WaitingForParts)">
          <p class="is-flex is-align-items-center">Waiting On Parts</p>
          <DashboardCircularStatus :value="quotes.waitingForParts"
            :color-code="'#9057FF'"
            :width="'50px'"
            :height="'50px'" />
        </div>

        <div class=" panel-block is-flex is-justify-content-space-between custom-panel custom-panel-border pb-3 clickable-panel"
          :class="[{'custom-panel-with-no-chart': isUninvoicedJobsAndChartDisabled}]"
          @click="onClick(JobStageTypes.VehicleArrived)">
          <p class="is-flex is-align-items-center">Vehicle Arrived</p>
          <DashboardCircularStatus :value="quotes.vehicleArrived"
            :color-code="'#9057FF'"
            :width="'50px'"
            :height="'50px'" />
        </div>

        <div class=" panel-block is-flex is-justify-content-space-between custom-panel custom-panel-border pb-3 clickable-panel"
          :class="[{'custom-panel-with-no-chart': isUninvoicedJobsAndChartDisabled}]"
          @click="onClick(JobStageTypes.WorkInProgress)">
          <p class="is-flex is-align-items-center">Work In Progress</p>
          <DashboardCircularStatus :value="quotes.workInProgress"
            :color-code="'#9057FF'"
            :width="'50px'"
            :height="'50px'" />
        </div>

        <div class="panel-block is-flex is-justify-content-space-between custom-panel custom-panel-border pb-3 clickable-panel"
          :class="[{'custom-panel-with-no-chart': isUninvoicedJobsAndChartDisabled}]"
          @click="onClick(JobStageTypes.VehicleReady)">
          <p class="is-flex is-align-items-center">Vehicle Ready</p>
          <DashboardCircularStatus :value="quotes.vehicleReady"
            :color-code="'#9057FF'"
            :width="'50px'"
            :height="'50px'" />
        </div>

        <div class="panel-block is-flex is-justify-content-space-between custom-panel custom-panel-border pb-3 clickable-panel"
          :class="[{'custom-panel-with-no-chart': isUninvoicedJobsAndChartDisabled}]"
          @click="onClick(JobStageTypes.InvoicePending)">
          <p class="is-flex is-align-items-center">Invoice Pending</p>
          <DashboardCircularStatus :value="quotes.invoicePending"
            :color-code="'#9057FF'"
            :width="'50px'"
            :height="'50px'" />
        </div>

      </div>
    </DashboardPanel>
  </div>

</template>

<script>
import DashboardCircularStatus from '@/views/dashboard/DashboardCircularStatus.vue'
import DashboardPanel from '@/views/dashboard/DashboardPanel.vue'
import JobStageTypes from '@/enums/JobStageTypes'
import DashboardProgressBar from '@/views/dashboard/DashboardProgressBar.vue'

export default {
  name: 'DashboardJobStatus',
  components: { DashboardProgressBar, DashboardPanel, DashboardCircularStatus },
  mixins: [],
  props: {
    quotes: Object,
    isLoading: Boolean,
    isUninvoicedJobsAndChartDisabled: Boolean
  },
  computed: {
    JobStageTypes() {
      return JobStageTypes
    }
  },
  methods: {
    onClick(jobStage) {
      this.$emit('handleQuoteRedirect', jobStage)
    }
  }
}
</script>

<style scoped>
.custom-panel {
  color: #fff;
  height: 9.5vh;
}

.custom-panel-with-no-chart {
  color: #fff;
  height: 14vh;
}

.custom-panel-border:not(:last-child) {
  border-bottom: 1px solid black;
}
.tile-border {
  border-radius: 20px !important;
}

.custom-panel-heading {
  border-radius: 20px 20px 0 0 !important;
  background-color: #495057 !important;
}
</style>