import FqApi from '@/services/fq-api'

const baseUrl = '/ormmessages'
const ormServiceBaseUrl = '/ormservice'

export default {
  async getOrmQuoteStatusTypes() {
    const result = await FqApi.get(`${baseUrl}/quotestatustypes`)
    return result.data
  },
  async testConnection() {
    const result = await FqApi.get(`${ormServiceBaseUrl}/testconnection`)
    return result
  },
  async sendQdeVersionChange(repairerId) {
    const result = await FqApi.post(`${ormServiceBaseUrl}/qdeversionchange/${repairerId}`)
    return result
  }
}