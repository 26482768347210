import store from '@/store'
import { QuoteItemModel } from '@/classes/viewmodels'
import { QuotingMethodTypes, ItemCategoryTypes } from '@/enums'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

// Update quote markups in store
const updateMarkups = function(state, insurer) {
  state.snapshots[state.currentIndex].newMarkup = insurer.newMarkup
  state.snapshots[state.currentIndex].usedMarkup = insurer.usedMarkup
  state.snapshots[state.currentIndex].recoreMarkup = insurer.recoreMarkup
  state.snapshots[state.currentIndex].afterMarketMarkup = insurer.afterMarketMarkup
  state.snapshots[state.currentIndex].exchangeMarkup = insurer.exchangeMarkup
  state.snapshots[state.currentIndex].reconditionedMarkup = insurer.reconditionedMarkup
  state.snapshots[state.currentIndex].shopRate = insurer.shopRate
  state.snapshots[state.currentIndex].labourType = insurer.labourType
}

// Add rates to quote in store
const addInsurerLabourRates = function(state, insurer) {
  // get labour types from store
  const labourTypes = store.getters['labourTypes/labourTypes']
  // remove all rates
  state.snapshots[state.currentIndex].rates.splice(0, state.snapshots[state.currentIndex].rates)
  // add rates
  insurer.insurerLabourRates.forEach(function(ir) {
    const index = state.snapshots[state.currentIndex].rates.findIndex(qr => qr.labourCodeId === ir.labourCode)
    if (index > -1) {
      state.snapshots[state.currentIndex].rates[index].rate = ir.rate
    } else {
      const labourType = labourTypes.find(t => t.labourTypeCode === ir.labourType)
      const newRate = {
        rate: ir.rate,
        quoteVersion: 0,
        quoteId: state.snapshots[state.currentIndex].quoteId,
        labourCodeId: ir.labourCode,
        labourTypeId: labourType.labourTypeId,
        deleted: false,
        createdBy: '',
        createdDate: '',
        modifiedBy: '',
        modifiedDate: '',
        isNew: true,
        isDeleted: false
      }
      state.snapshots[state.currentIndex].rates.splice(state.snapshots[state.currentIndex].rates.length, 1, newRate)
    }
  })
}

// Add automatic items to quote in store
const addInsurerAutoItems = function(state, insurer) {
  if (insurer.insurerAutoItems && insurer.insurerAutoItems.length > 0) {
    insurer.insurerAutoItems.forEach(function(autoItem) {
      let currentItem = state.snapshots[state.currentIndex].miscs.find(i => i.itemDesc === autoItem.itemDesc && i.itemNo === autoItem.itemNo)
      if (!currentItem) {
        let newItem = new QuoteItemModel(state.snapshots[state.currentIndex].quoteId, autoItem.itemNo, autoItem.itemDesc, ItemCategoryTypes.MISC)
        newItem.value = autoItem.price
        newItem.reportOnly = autoItem.reportOnly
        newItem.sortNo = state.snapshots[state.currentIndex].miscs.length ? Math.max(...state.snapshots[state.currentIndex].miscs.map(i => i.sortNo)) + 1 : 1
        state.snapshots[state.currentIndex].miscs.splice(state.snapshots[state.currentIndex].miscs.length, 1, newItem)
      } else {
        currentItem.value = autoItem.price
        currentItem.reportOnly = autoItem.reportOnly
      }
    })
  }
}

const getRate = function(state, itemType) {
  const rate = state.snapshots[state.currentIndex].rates.find(r => r.labourCodeId === itemType)
  // console.log(rate !== undefined ? rate.rate : 0, itemType)
  return rate !== undefined ? rate.rate : 0
}

const calculateItemCategoryTotal = function(state, quotingMethod, items, itemType) {
  let total = 0
  if (itemType === ItemCategoryTypes.PART) {
    total = items
      // eslint-disable-next-line no-unexpected-multiline
      .filter(i => !i.deleted && !i.reportOnly)
      .reduce(function(total, item) {
        return roundAwayFromZero(total + item.itemQuantity * item.markupValue)
      }, 0)
  } else if (itemType === ItemCategoryTypes.MISC) {
    total = items
      // eslint-disable-next-line no-unexpected-multiline
      .filter(i => !i.deleted && !i.reportOnly)
      .reduce(function(total, item) {
        return total + item.value
      }, 0)
  } else if (itemType === ItemCategoryTypes.OPG) {
    total = items
      // eslint-disable-next-line no-unexpected-multiline
      .filter(i => !i.deleted && !i.reportOnly)
      .reduce(function(total, item) {
        return total + (quotingMethod === QuotingMethodTypes.Dollar ? item.dollarValue : item.hourValue * item.rate)
      }, 0)
  } else if (itemType === ItemCategoryTypes.SUBL) {
    total = items
      // eslint-disable-next-line no-unexpected-multiline
      .filter(i => !i.deleted && !i.reportOnly)
      .reduce(function(total, item) {
        return total + item.value
      }, 0)
  } else {
    if (quotingMethod === QuotingMethodTypes.Hour) {
      let filteredItems = items.filter(i => !i.deleted && !i.reportOnly && i.itemType === itemType)
      for (var i = 0; i < filteredItems.length; i++) {
        items[i].rate = getRate(state, itemType)
        items[i].dollarValue = roundAwayFromZero(items[i].hourValue * items[i].rate)
      }
    }
    total = items
      // eslint-disable-next-line no-unexpected-multiline
      .filter(i => !i.deleted && !i.reportOnly && i.itemType === itemType)
      .reduce(function(total, item) {
        return roundAwayFromZero(total + (quotingMethod === QuotingMethodTypes.Dollar ? item.dollarValue : roundAwayFromZero(item.hourValue * item.rate)))
      }, 0)
  }
  return total
}

const recalculateTotals = function(state) {
  let entity = state.snapshots[state.currentIndex]
  const rrTotal = calculateItemCategoryTotal(state, entity.quotingMethod, entity.labours, ItemCategoryTypes.RR)
  const repairTotal = calculateItemCategoryTotal(state, entity.quotingMethod, entity.labours, ItemCategoryTypes.REP)
  const rwaTotal = calculateItemCategoryTotal(state, entity.quotingMethod, entity.labours, ItemCategoryTypes.RWA)
  const paintTotal = calculateItemCategoryTotal(state, entity.quotingMethod, entity.labours, ItemCategoryTypes.PAINT)
  const cdTotal = calculateItemCategoryTotal(state, entity.quotingMethod, entity.others, ItemCategoryTypes.CD)
  const crushTotal = calculateItemCategoryTotal(state, entity.quotingMethod, entity.others, ItemCategoryTypes.CRUSH)
  const mechTotal = calculateItemCategoryTotal(state, entity.quotingMethod, entity.others, ItemCategoryTypes.MECH)
  const fgTotal = calculateItemCategoryTotal(state, entity.quotingMethod, entity.others, ItemCategoryTypes.FIBER)
  const opgTotal = calculateItemCategoryTotal(state, entity.quotingMethod, entity.opgs, ItemCategoryTypes.OPG)
  const partTotal = calculateItemCategoryTotal(state, entity.quotingMethod, entity.parts, ItemCategoryTypes.PART)
  const miscTotal = calculateItemCategoryTotal(state, entity.quotingMethod, entity.miscs, ItemCategoryTypes.MISC)
  const subletTotal = calculateItemCategoryTotal(state, entity.quotingMethod, entity.sublets, ItemCategoryTypes.SUBL)
  const labourTotal = rrTotal + repairTotal + rwaTotal + paintTotal
  const otherTotal = cdTotal + crushTotal + mechTotal + fgTotal
  entity.partTotal = roundAwayFromZero(partTotal)
  entity.opgTotal = roundAwayFromZero(opgTotal)
  entity.miscTotal = roundAwayFromZero(miscTotal)
  entity.sublTotal = roundAwayFromZero(subletTotal)
  entity.labourTotal = labourTotal
  // Quote total
  entity.totalExGst = roundAwayFromZero(labourTotal + otherTotal + opgTotal + partTotal + miscTotal + subletTotal)
  entity.gst = roundAwayFromZero(entity.totalExGst * (entity.gstRate / 100))
  entity.totalIncGst = roundAwayFromZero(entity.totalExGst + entity.gst)
}

export { updateMarkups, addInsurerLabourRates, addInsurerAutoItems, recalculateTotals }
