import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
import ProtoBufApi from '@/services/protobuf-api'
// import { replace } from 'core-js/fn/symbol'

export const AvailableMiscTypes = {
  standard: 'standard',
  custom: 'custom',
  emta: 'emta',
  emtasundry: 'emtasundry',
  veronz: 'veronz'
}

export const AvailableSubletTypes = {
  standard: 'standard',
  veronz: 'veronz'
}

export const OverrideMappings = {
  rrTotal: 'rrOverride',
  repairTotal: 'repairOverride',
  paintTotal: 'paintOverride',
  rwaTotal: 'rwaOverride',
  fgTotal: 'fgOverride',
  crushTotal: 'crushOverride',
  cdTotal: 'cdOverride',
  mechTotal: 'mechOverride',
  pdrRrTotal: 'pdrRrOverride',
  pdrReTotal: 'pdrReOverride',
  labourTotal: 'labourOverride'
}

export const LabourPrintOptionTypes = {
  LumpSum: 'lumpSum',
  Category: 'category',
  Detailed: 'detailed'
}

export default {
  async getEntity(id, hash) {
    const url = !hash ? `/quotes/${id}?includeDeleted=true` : `/quotes/${id}?hash=${hash}&includeDeleted=true`
    const result = await FqApi.get(url)
    return result.data
  },
  async getMainQuoteEntity(quoteNo) {
    const url = `/quotes/mainquote/${quoteNo}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getNewEntity(quoteNo, isSupp) {
    const url = `/quotes/new/${quoteNo}?isSupp=${isSupp}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getNewSupplementaryEntity(quoteNo) {
    const url = `/quotes/newsupplementary/${quoteNo}`
    const result = await FqApi.get(url)
    return result.data
  },
  async deleteMultipleEntities(quoteIds) {
    let url = '/quotes/deletemultiple'
    const query = quoteIds.join('&quoteId=')
    if (query) {
      url = `${url}?quoteId=${query}`
    }
    const result = await FqApi.delete(url)
    return result
  },
  isQuoteNoExists(no) {
    const url = `/quotes/exists?no=${no}`
    return FqApi.get(url)
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseQuoteFilter(filter)
    const url = `/quotes/list?filter=${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseQuoteFilter(filter) {
    const entityProto = require('../../assets/proto/quote/QuoteFilter.proto')
    const filterMessage = entityProto.QuoteFilter.QuoteFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)

    return b64encoded
  },
  async getListComboInsurers(name) {
    const url = !name ? '/quotes/listcombo/insurers' : `/quotes/listcombo/insurers?name=${name}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getImageIds(quoteId) {
    const url = `/quotes/images/ids/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getImage(id) {
    const url = `/quotes/images/data/${id}`
    const result = await FqApi.get(url, { responseType: 'arraybuffer' })
    // console.log(result)

    // const image = btoa(String.fromCharCode(...new Uint8Array(result.data)))
    const image = Buffer.from(result.data, 'binary').toString('base64')
    return `data:${result.headers['content-type'].toLowerCase()};base64,${image}`
  },
  async getImageThumbnail(id, size) {
    const url = `/quotes/images/thumbnail/${id}?size=${size}`
    const result = await FqApi.get(url, { responseType: 'arraybuffer' })
    const image = Buffer.from(result.data, 'binary').toString('base64')
    return `data:${result.headers['content-type'].toLowerCase()};base64,${image}`
  },
  async getImageThumbnailWithInfos(id, size) {
    const url = `/quotes/images/thumbnailinfos/${id}?size=${size}`
    const result = await FqApi.get(url)
    result.data.forEach((item) => {
      item.imageContent = `data:${item.mimeType};base64,${item.imageContent}`
    })
    return result.data
  },
  async getImageThumbnailWithInfo(id, size) {
    const url = `/quotes/images/thumbnailinfo/${id}?size=${size}`
    const result = await FqApi.get(url)
    result.data.imageContent = `data:${result.data.mimeType};base64,${result.data.imageContent}`
    return result.data
  },
  async deleteImage(id) {
    const url = `/quotes/images/delete/${id}`
    const result = await FqApi.delete(url)
    return result
  },
  async deleteMultipleImages(imageIds) {
    let url = '/quotes/images/deletemultiple'
    const result = await FqApi.post(url, imageIds)
    return result
  },
  async copyImagesToQuote(quoteId, imageIds) {
    const url = `/quotes/images/copyto/${quoteId}`
    const result = await FqApi.post(url, imageIds)
    return result
  },
  async getQuoteIdByNo(quoteNo) {
    const result = await FqApi.get(`quotes/quoteidbyno?quoteNo=${encodeURIComponent(quoteNo)}`)
    return result.data
  },
  // async testReport(reportName) {
  //   const url = `/reports/getreport/${reportName}`
  //   const result = await FqApi.get(url, { responseType: 'arraybuffer' })
  //   const pdf = Buffer.from(result.data, 'binary').toString('base64')
  //   return `data:${result.headers['content-type'].toLowerCase()};base64,${pdf}`
  // },
  async getVehicleDropdownProto(filter) {
    const serialised = this.serialiseVehicleDropdownFilter(filter)
    const result = await ProtoBufApi(`/vehicles/protobuf/quotevehicles/listcombo/${serialised}`)
    const proto = require('../../assets/proto/quote/QuoteVehicleDropdown.proto')
    const message = proto.QuoteVehicleDropdown.QuoteVehicleDropdownModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.vehicles
  },
  serialiseVehicleDropdownFilter(filter) {
    const entityProto = require('../../assets/proto/quote/QuoteVehicleDropdownFilter.proto')
    const filterMessage = entityProto.QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getInsurerDropdownProto(filter) {
    const serialised = this.serialiseQuickInvoiceAssetDropdownFilter(filter)
    const result = await ProtoBufApi(`/quickinvoices/protobuf/assets/listcombo/${serialised}`)
    const proto = require('../../assets/proto/quickinvoice/QuickInvoiceAssetDropdown.proto')
    const message = proto.QuickInvoiceAssetDropdown.QuickInvoiceAssetDropdownModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.assets
  },
  serialiseQuickInvoiceAssetDropdownFilter(filter) {
    const entityProto = require('../../assets/proto/quickinvoice/QuickInvoiceAssetDropdownFilter.proto')
    const filterMessage = entityProto.QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getVehicleEntity(id) {
    const res = await FqApi.get(`/vehicles?id=${id}`)
    return res.data
  },
  async getCustomerEntity(id, hash = '') {
    const url = !hash ? `/customers/${id}` : `/customers/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getInsurerEntity(insurerId, hash) {
    const url = !hash ? `/insurers/nocontacts/${insurerId}` : `/insurers/nocontacts/${insurerId}?hash=${hash}`
    const response = await FqApi.get(url)
    return response.data
  },
  async getItemTypes() {
    const url = '/companies/itemtypes'
    const result = await FqApi.get(url)
    return result.data
  },
  async getSublets(isAudaNet) {
    const url = `quotes/sublettypes/${isAudaNet}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getSubletsProto(isAudaNet) {
    const url = `quotes/protobuf/sublettypes/${isAudaNet}`
    const result = await ProtoBufApi(url)
    const proto = require('../../assets/proto/quote/SubletTypes.proto')
    const message = proto.SubletTypes.SubletTypesModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.subletTypes
  },
  async getQuoters() {
    const url = 'quotes/quoters'
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseQuoteAvailablePartsFilter(filter) {
    const entityProto = require('../../assets/proto/quoteitem/QuoteAvailablePartsFilter.proto')
    const filterMessage = entityProto.QuoteAvailablePartsFilter.QuoteAvailablePartsFilter
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getAvailableParts(filter) {
    const serialised = this.serialiseQuoteAvailablePartsFilter(filter)
    const result = await FqApi.get(`quotes/availables/parts/${serialised}`)
    return result.data
  },
  serialiseQuoteAvailableMiscFilter(filter) {
    const entityProto = require('../../assets/proto/quoteitem/QuoteAvailableMiscFilter.proto')
    const filterMessage = entityProto.QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getAvailableMiscItems(filter, type) {
    const serialised = this.serialiseQuoteAvailableMiscFilter(filter)
    let url = `quotes/availables/miscs/${serialised}`
    if (type !== AvailableMiscTypes.standard && type != AvailableMiscTypes.custom) {
      url = `quotes/availables/miscs/${type}/${serialised}`
    }
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseQuoteAvailableSubletFilter(filter) {
    const entityProto = require('../../assets/proto/quoteitem/QuoteAvailableSubletFilter.proto')
    const filterMessage = entityProto.QuoteAvailableSubletFilter.QuoteAvailableSubletFilter
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getAvailableSubletItems(filter, type) {
    const serialised = this.serialiseQuoteAvailableSubletFilter(filter)
    let url = `quotes/availables/sublets/${serialised}`
    if (type !== AvailableMiscTypes.standard) {
      url = `quotes/availables/miscs/${type}/${serialised}`
    }
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseQuoteAvailableOpgFilter(filter) {
    const entityProto = require('../../assets/proto/quoteitem/QuoteAvailableOpgFilter.proto')
    const filterMessage = entityProto.QuoteAvailableOpgFilter.QuoteAvailableOpgFilter
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getAvailableOpgItems(filter) {
    const serialised = this.serialiseQuoteAvailableOpgFilter(filter)
    let url = `quotes/availables/opgs/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseQuoteAvailableOtherLabourFilter(filter) {
    const entityProto = require('../../assets/proto/quoteitem/QuoteAvailableOtherLabourFilter.proto')
    const filterMessage = entityProto.QuoteAvailableOtherLabourFilter.QuoteAvailableOtherLabourFilter
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getAvailableOtherLabourItems(filter) {
    const serialised = this.serialiseQuoteAvailableOtherLabourFilter(filter)
    let url = `quotes/availables/otherlabours/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseQuoteAvailableLabourFilter(filter) {
    const entityProto = require('../../assets/proto/quoteitem/QuoteAvailableLabourFilter.proto')
    const filterMessage = entityProto.QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getAvailableLabourItems(filter, type) {
    const serialised = encodeURIComponent(this.serialiseQuoteAvailableLabourFilter(filter))
    const url = `quotes/availables/labours/${type}?filter=${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  /**
   * Get quote's items by quote number, supp quote number & item types
   * @param {Number} quoteNo Quote number
   * @param {Number} subQuoteNo Supp number
   * @param {Array} types @/enums/ItemCategoryTypes
   */
  async getQuoteItems(quoteNo, subQuoteNo, types) {
    let url = `quotes/quoteitems/${quoteNo}/${subQuoteNo}`
    const requestQuery = types.join('&types=')
    if (requestQuery) {
      url = `${url}?types=${requestQuery}`
    }
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteItemsExcludeSubQuote(quoteNo, subQuoteNo, types) {
    let url = `quotes/quoteitems/${quoteNo}/exclude/${subQuoteNo}`
    const requestQuery = types.join('&types=')
    if (requestQuery) {
      url = `${url}?types=${requestQuery}`
    }
    const result = await FqApi.get(url)
    return result.data
  },
  async testConnection(username, password) {
    const url = `ormservice/testconnection/${username}/${password}`
    const result = await FqApi.get(url)
    return result.data
  },
  putEntity(entity, deepdiff) {
    if (deepdiff) {
      entity.deepDiff = JSON.stringify(deepdiff)
    }
    return FqApi.put(`/quotes/${entity.quoteId}`, entity)
  },
  async postEntity(entity) {
    return FqApi.post('/quotes', entity)
  },
  async getSupplementaryQuoteTotals(quoteId) {
    const url = `/quotes/supptotals/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getIncludedQuotesTotals(quoteIds) {
    let url = 'quotes/includedtotals'
    const requestQuery = quoteIds.join('&quoteIds=')
    if (requestQuery) {
      url = `${url}?quoteIds=${requestQuery}`
    }
    const result = await FqApi.get(url)
    return result.data
  },
  async getLabourTypes() {
    const url = '/quotes/protobuf/labourtypes'
    const result = await ProtoBufApi(url)
    const proto = require('@/assets/proto/quote/LabourTypes.proto')
    const message = await proto.LabourTypes.LabourTypesModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.labourTypes
  },
  async getCopyEntity(sourceId, destinationId, isCopyImages, isOverwrite) {
    const url = `/quotes/getCopyEntity/${sourceId}/${destinationId}/${isCopyImages}/${isOverwrite}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getExternalSetting(settingName) {
    const url = `/companyexternalsettings/${settingName}`
    const result = await FqApi.get(url)
    return result.data
  },
  async validateQuote(quoteId) {
    const url = `/quotes/validatequote?quoteId=${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getJobStagesProto() {
    const url = '/quotes/protobuf/jobstages'
    const result = await ProtoBufApi(url)
    const proto = require('@/assets/proto/quote/JobStages.proto')
    const message = await proto.JobStages.JobStages
    const entity = message.decode(new Uint8Array(result.data))
    return entity.jobStages
  },
  async getCompanyPhone() {
    const url = '/companies/assetphones'
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteAssessments(quoteId) {
    const url = `/quote/${quoteId}/assessments/list`
    const result = await FqApi.get(url)
    return result.data
  },
  async revertToAuthorised(quoteId) {
    const url = `/quote/${quoteId}/assessments/revertToAuthorised`
    const result = await FqApi.put(url)
    return result.data
  },
  async cancelLastAuthority(quoteId, merge) {
    let url = `/quote/${quoteId}/assessments/cancelLastAuthority?merge=${merge}`
    const result = await FqApi.put(url)
    return result.data
  },
  async cancelLastRequest(quoteId, merge) {
    let url = `/quote/${quoteId}/assessments/cancelLastRequest?merge=${merge}`
    const result = await FqApi.put(url)
    return result.data
  },
  async requestAuthorityAssessment(quoteId, assessment) {
    const url = `/quote/${quoteId}/assessments/requestAuthorityQuote`
    return await FqApi.post(url, assessment)
  },
  async authoriseAssessment(quoteId, assessment) {
    const url = `/quote/${quoteId}/assessments/authoriseQuote`
    return await FqApi.post(url, assessment)
  },
  async getQuoteArnieXml(quoteId, quoteArnieId) {
    const url = `/quotes/arniexml/${quoteId}/${quoteArnieId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async downloadImage(imageId) {
    const url = `/quotes/images/download/${imageId}`
    const result = await FqApi.get(url, { responseType: 'arraybuffer' })
    return result
  },
  async getQuoteArnies(quoteId) {
    const url = `/quotes/quotearnie/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async updateQuoteArnieSucessFail(quoteId, version, isSuccess) {
    const url = `/quotes/quotearniesucessfail/${quoteId}/${version}/${isSuccess}`
    return await FqApi.post(url)
  },
  async getMaxLineNumber(quoteId) {
    const url = `/quotes/maxlinenumber/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getOrmQuoteHistory(quoteId) {
    const url = `/ormmessages/quote/${quoteId}/history/`
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteAttachmentCategories() {
    const url = '/quotes/attachments/categories/list'
    return (await FqApi.get(url)).data
  },
  async getQuoteAttachments(quoteId) {
    const url = `/quotes/attachments/list/${quoteId}`
    return await FqApi.get(url)
  },
  async getQuoteAttachmentUploadUrl(quoteId) {
    const url = `/quotes/attachments/request/upload/${quoteId}`
    return await FqApi.get(url)
  },
  async setupQuoteAttachment(quoteId, quoteAttachmentCategoryId, uploadGuid, filename) {
    var data = new URLSearchParams()
    data.append('uploadGuid', uploadGuid)
    data.append('filename', filename)
    const url = `/quotes/attachments/upload/${quoteId}/${quoteAttachmentCategoryId}`
    return await FqApi.post(url, data)
  },
  async uploadQuoteAttachment(cloudUrl, fileBytes) {
    var data = new FormData()
    data.append('files[]', fileBytes, 'x.jpg')
    return await FqApi.post(cloudUrl, data)
  },
  async uploadQuoteAttachmentDerived(quoteId, quoteAttachmentId, attachmentType, pageIndex, fileBytes) {
    var data = new FormData()
    data.append('files[]', fileBytes, 'x.jpg')
    const url = `/quotes/attachments/upload/${quoteId}/${quoteAttachmentId}/${attachmentType}/${pageIndex}`
    return await FqApi.post(url, data)
  },
  async createQuoteAttachmentCategory(name) {
    var data = new URLSearchParams()
    data.append('name', name)
    const url = '/quotes/attachments/categories/create'
    return await FqApi.post(url, data)
  },
  async updateQuoteAttachmentCategory(quoteAttachmentCategoryId, name, deleted) {
    var data = new URLSearchParams()
    data.append('name', name)
    data.append('deleted', deleted)
    const url = `/quotes/attachments/categories/update/${quoteAttachmentCategoryId}`
    return await FqApi.post(url, data)
  },
  async deleteQuoteAttachment(quoteAttachmentId) {
    var data = new URLSearchParams()
    const url = `/quotes/attachments/delete/${quoteAttachmentId}`
    return await FqApi.post(url, data)
  },
  async copyQuoteAttachment(quoteId, quoteAttachmentId, targetQuoteAttachmentCategoryId, deleteOriginal) {
    var params = { deleteOriginal }
    const url = `/quotes/attachments/copy/${quoteId}/${quoteAttachmentId}/${targetQuoteAttachmentCategoryId}`
    return await FqApi.get(url, { params })
  },
  async copyQuoteAttachmentToQuote(sourceQuoteId, sourceQuoteAttachmentId, targetQuoteId, targetQuoteAttachmentCategoryId, deleteOriginal) {
    var params = { deleteOriginal }
    const url = `/quotes/attachments/copy/${sourceQuoteId}/${sourceQuoteAttachmentId}/${targetQuoteId}/${targetQuoteAttachmentCategoryId}`
    return await FqApi.get(url, { params })
  },
  async updateQuoteAttachmentOrderIndex(quoteAttachmentId, orderIndex) {
    const url = `/quotes/attachments/update/${quoteAttachmentId}/orderIndex/${orderIndex}`
    return await FqApi.get(url)
  },
  async downloadModifiedQuoteAttachment(quoteId, quoteAttachmentId) {
    const url = `/quotes/attachments/download/${quoteId}/modified/${quoteAttachmentId}`
    return await FqApi.get(url)
  },
  async setQuoteImageOrderIndex(quoteImageId, orderIndex) {
    const url = `/quotes/images/order/${quoteImageId}/${orderIndex}`
    const result = await FqApi.post(url, {})
    return result
  },
  async setQuoteImagesOrderIndex(quoteId, imageIds) {
    const url = `/quotes/images/order/${quoteId}`
    const result = await FqApi.post(url, imageIds)
    return result
  },
  async setQuoteImageIsPrimary(quoteImageId) {
    const url = `/quotes/images/primary/${quoteImageId}`
    const result = await FqApi.post(url, {})
    return result
  },
  async getQuoteImageCategories() {
    const url = '/quotes/images/categories/list'
    return (await FqApi.get(url)).data
  },
  async createImageCategory(name) {
    var data = new URLSearchParams()
    data.append('name', name)
    const url = '/quotes/images/categories/create'
    return await FqApi.post(url, data)
  },
  async getImageIdsWithCategory(quoteId, categoryId) {
    const url = `/quotes/images/ids/${quoteId}/${categoryId}`
    const result = await FqApi.get(url)
    return result.data
  },

  async getImageThumbnailWithInfoWithCategory(quoteId, categoryId, size) {
    const url = `/quotes/images/thumbnailinfoswithcategory/${quoteId}/${categoryId}?size=${size}`
    const result = await FqApi.get(url)
    result.data.forEach((item) => {
      item.imageContent = `data:${item.mimeType};base64,${item.imageContent}`
    })
    return result.data
  },

  async setImageCategory(quoteId, imageId, categoryId) {
    const url = `/quotes/images/setnewimagecategory/${quoteId}/${imageId}/${categoryId}`
    const result = await FqApi.post(url, {})
    return result
  },
  async updateQuoteImageCategory(quoteImageCategoryId, name, deleted) {
    var data = new URLSearchParams()
    data.append('name', name)
    data.append('deleted', deleted)
    const url = `/quotes/images/categories/update/${quoteImageCategoryId}`
    return await FqApi.post(url, data)
  },
  async getGeneralImageCategory() {
    const url = '/quotes/images/generalcategory'
    return (await FqApi.get(url)).data
  },
  async createDefaultImageCategories() {
    const url = '/quotes/images/categories/createdefaultimagecategories'
    return (await FqApi.get(url)).data
  },
  async setImageCategoriesOrderIndex(categoryIds) {
    const url = '/quotes/images/categories/setimagecateogoriesorderindex'
    const result = await FqApi.post(url, categoryIds)
    return result
  },
  async getImageCategoryImagesCount(quoteImageCategoryId) {
    const url = `/quotes/images/categories/getimagescount/${quoteImageCategoryId}`
    return (await FqApi.get(url)).data
  },
  async setQuoteImagesCategory(quoteId, categoryId, imageIds) {
    const url = `/quotes/images/setimagescategory/${quoteId}/${categoryId}`
    const result = await FqApi.post(url, imageIds)
    return result
  },
  async updateImageCategories(categories) {
    const url = '/quotes/images/categories/updateImageCategories'
    return await FqApi.post(url, categories)
  }
}
