import ContactComponentListing from '@/views/contact'
import routeTypes from './route-types'

const SundryCreditorListView = r => require.ensure([], () => r(require('./SundryCreditorListView.vue')), 'sundrycreditorlist-chunk')
const SundryCreditorNew = r => require.ensure([], () => r(require('./SundryCreditorNew.vue')), 'sundrycreditornew-chunk')
const SundryCreditorView = r => require.ensure([], () => r(require('./SundryCreditorView.vue')), 'sundrycreditor-chunk')
const SundryCreditorDetail = r => require.ensure([], () => r(require('./SundryCreditorDetail.vue')), 'sundrycreditor-chunk')
const SundryCreditorItems = r => require.ensure([], () => r(require('./SundryCreditorItems.vue')), 'sundrycreditor-chunk')
const SundryCreditorPayments = r => require.ensure([], () => r(require('./SundryCreditorPayments.vue')), 'sundrycreditor-chunk')
const SundryCreditorAttachments = r => require.ensure([], () => r(require('./SundryCreditorAttachments.vue')), 'sundrycreditor-chunk')

const id = 'A100'

export default [
  {
    path: routeTypes.SundryCreditorListView.path,
    name: routeTypes.SundryCreditorListView.name,
    component: SundryCreditorListView,
    meta: {
      id: id,
      route: 'listing',
      title: 'Sundry Creditors',
      fkey: 'sundrycreditor-filter',
      report: 'rptCreditorSundryCreditor',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx?`
    }
  },
  {
    path: routeTypes.SundryCreditorNew.path,
    name: routeTypes.SundryCreditorNew.name,
    component: SundryCreditorNew,
    meta: {
      id: id,
      route: 'detail',
      title: 'New Sundry Creditor'
    }
  },
  {
    path: routeTypes.SundryCreditorView.path,
    component: SundryCreditorView,
    meta: {
      id: id,
      route: 'detail',
      title: 'Sundry Creditor'
    },
    children: [
      {
        path: '',
        name: routeTypes.SundryCreditorDetail.name,
        component: SundryCreditorDetail,
        props: {
          isTiled: true
        },
        meta: {
          title: 'Detail',
          report: 'AT_SundryCreditorDetails',
          reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx?`
        }
      },
      // {
      //   path: 'detail-test',
      //   name: 'DetailTest',
      //   component: SundryCreditorDetail2,
      //   props: {
      //     isTiled: true
      //   },
      //   meta: {
      //     title: 'Detail'
      //   }
      // },
      {
        path: routeTypes.SundryCreditorItems.path,
        name: routeTypes.SundryCreditorItems.name,
        component: SundryCreditorItems,
        props: {
          isTiled: true
        },
        meta: {
          title: 'Invoice Items'
        }
      },
      {
        path: routeTypes.SundryCreditorPayments.path,
        name: routeTypes.SundryCreditorPayments.name,
        component: SundryCreditorPayments,
        props: {
          isTiled: true
        },
        meta: {
          title: 'Payments'
        }
      },
      {
        path: routeTypes.SundryCreditorAttachments.path,
        name: routeTypes.SundryCreditorAttachments.name,
        component: SundryCreditorAttachments,
        props: {
          isTiled: true
        },
        meta: {
          title: 'Attachments'
        }
      },
      {
        path: routeTypes.SundryCreditorContacts.path,
        name: routeTypes.SundryCreditorContacts.name,
        component: ContactComponentListing,
        props: { isTiled: true, assetType: '3', detailRoute: 'SundryCreditorContactDetail' },
        meta: {
          title: 'Contacts',
          isContactChild: true
        }
      }
    ]
  }
]
