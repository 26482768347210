/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.QuoteVehicleDropdown = (function() {

    /**
     * Namespace QuoteVehicleDropdown.
     * @exports QuoteVehicleDropdown
     * @namespace
     */
    var QuoteVehicleDropdown = {};

    QuoteVehicleDropdown.QuoteVehicleDropdownModel = (function() {

        /**
         * Properties of a QuoteVehicleDropdownModel.
         * @memberof QuoteVehicleDropdown
         * @interface IQuoteVehicleDropdownModel
         * @property {Array.<QuoteVehicleDropdown.QuoteVehicleDropdownModel.IVehicle>|null} [vehicles] QuoteVehicleDropdownModel vehicles
         */

        /**
         * Constructs a new QuoteVehicleDropdownModel.
         * @memberof QuoteVehicleDropdown
         * @classdesc Represents a QuoteVehicleDropdownModel.
         * @implements IQuoteVehicleDropdownModel
         * @constructor
         * @param {QuoteVehicleDropdown.IQuoteVehicleDropdownModel=} [p] Properties to set
         */
        function QuoteVehicleDropdownModel(p) {
            this.vehicles = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QuoteVehicleDropdownModel vehicles.
         * @member {Array.<QuoteVehicleDropdown.QuoteVehicleDropdownModel.IVehicle>} vehicles
         * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel
         * @instance
         */
        QuoteVehicleDropdownModel.prototype.vehicles = $util.emptyArray;

        /**
         * Encodes the specified QuoteVehicleDropdownModel message. Does not implicitly {@link QuoteVehicleDropdown.QuoteVehicleDropdownModel.verify|verify} messages.
         * @function encode
         * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel
         * @static
         * @param {QuoteVehicleDropdown.IQuoteVehicleDropdownModel} m QuoteVehicleDropdownModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuoteVehicleDropdownModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.vehicles != null && m.vehicles.length) {
                for (var i = 0; i < m.vehicles.length; ++i)
                    $root.QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle.encode(m.vehicles[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Encodes the specified QuoteVehicleDropdownModel message, length delimited. Does not implicitly {@link QuoteVehicleDropdown.QuoteVehicleDropdownModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel
         * @static
         * @param {QuoteVehicleDropdown.IQuoteVehicleDropdownModel} message QuoteVehicleDropdownModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuoteVehicleDropdownModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QuoteVehicleDropdownModel message from the specified reader or buffer.
         * @function decode
         * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {QuoteVehicleDropdown.QuoteVehicleDropdownModel} QuoteVehicleDropdownModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuoteVehicleDropdownModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.QuoteVehicleDropdown.QuoteVehicleDropdownModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.vehicles && m.vehicles.length))
                        m.vehicles = [];
                    m.vehicles.push($root.QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a QuoteVehicleDropdownModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {QuoteVehicleDropdown.QuoteVehicleDropdownModel} QuoteVehicleDropdownModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuoteVehicleDropdownModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        QuoteVehicleDropdownModel.Vehicle = (function() {

            /**
             * Properties of a Vehicle.
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel
             * @interface IVehicle
             * @property {number|Long|null} [rowNumber] Vehicle rowNumber
             * @property {string|null} [vehicleId] Vehicle vehicleId
             * @property {string|null} [customerId] Vehicle customerId
             * @property {string|null} [rego] Vehicle rego
             * @property {string|null} [model] Vehicle model
             * @property {string|null} [ownersName] Vehicle ownersName
             * @property {number|null} [totalRows] Vehicle totalRows
             */

            /**
             * Constructs a new Vehicle.
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel
             * @classdesc Represents a Vehicle.
             * @implements IVehicle
             * @constructor
             * @param {QuoteVehicleDropdown.QuoteVehicleDropdownModel.IVehicle=} [p] Properties to set
             */
            function Vehicle(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Vehicle rowNumber.
             * @member {number|Long|null|undefined} rowNumber
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @instance
             */
            Vehicle.prototype.rowNumber = null;

            /**
             * Vehicle vehicleId.
             * @member {string|null|undefined} vehicleId
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @instance
             */
            Vehicle.prototype.vehicleId = null;

            /**
             * Vehicle customerId.
             * @member {string|null|undefined} customerId
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @instance
             */
            Vehicle.prototype.customerId = null;

            /**
             * Vehicle rego.
             * @member {string|null|undefined} rego
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @instance
             */
            Vehicle.prototype.rego = null;

            /**
             * Vehicle model.
             * @member {string|null|undefined} model
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @instance
             */
            Vehicle.prototype.model = null;

            /**
             * Vehicle ownersName.
             * @member {string|null|undefined} ownersName
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @instance
             */
            Vehicle.prototype.ownersName = null;

            /**
             * Vehicle totalRows.
             * @member {number|null|undefined} totalRows
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @instance
             */
            Vehicle.prototype.totalRows = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Vehicle _rowNumber.
             * @member {"rowNumber"|undefined} _rowNumber
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @instance
             */
            Object.defineProperty(Vehicle.prototype, "_rowNumber", {
                get: $util.oneOfGetter($oneOfFields = ["rowNumber"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Vehicle _vehicleId.
             * @member {"vehicleId"|undefined} _vehicleId
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @instance
             */
            Object.defineProperty(Vehicle.prototype, "_vehicleId", {
                get: $util.oneOfGetter($oneOfFields = ["vehicleId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Vehicle _customerId.
             * @member {"customerId"|undefined} _customerId
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @instance
             */
            Object.defineProperty(Vehicle.prototype, "_customerId", {
                get: $util.oneOfGetter($oneOfFields = ["customerId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Vehicle _rego.
             * @member {"rego"|undefined} _rego
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @instance
             */
            Object.defineProperty(Vehicle.prototype, "_rego", {
                get: $util.oneOfGetter($oneOfFields = ["rego"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Vehicle _model.
             * @member {"model"|undefined} _model
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @instance
             */
            Object.defineProperty(Vehicle.prototype, "_model", {
                get: $util.oneOfGetter($oneOfFields = ["model"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Vehicle _ownersName.
             * @member {"ownersName"|undefined} _ownersName
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @instance
             */
            Object.defineProperty(Vehicle.prototype, "_ownersName", {
                get: $util.oneOfGetter($oneOfFields = ["ownersName"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Vehicle _totalRows.
             * @member {"totalRows"|undefined} _totalRows
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @instance
             */
            Object.defineProperty(Vehicle.prototype, "_totalRows", {
                get: $util.oneOfGetter($oneOfFields = ["totalRows"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified Vehicle message. Does not implicitly {@link QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle.verify|verify} messages.
             * @function encode
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @static
             * @param {QuoteVehicleDropdown.QuoteVehicleDropdownModel.IVehicle} m Vehicle message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Vehicle.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.rowNumber != null && Object.hasOwnProperty.call(m, "rowNumber"))
                    w.uint32(8).int64(m.rowNumber);
                if (m.vehicleId != null && Object.hasOwnProperty.call(m, "vehicleId"))
                    w.uint32(18).string(m.vehicleId);
                if (m.customerId != null && Object.hasOwnProperty.call(m, "customerId"))
                    w.uint32(26).string(m.customerId);
                if (m.rego != null && Object.hasOwnProperty.call(m, "rego"))
                    w.uint32(34).string(m.rego);
                if (m.model != null && Object.hasOwnProperty.call(m, "model"))
                    w.uint32(42).string(m.model);
                if (m.ownersName != null && Object.hasOwnProperty.call(m, "ownersName"))
                    w.uint32(50).string(m.ownersName);
                if (m.totalRows != null && Object.hasOwnProperty.call(m, "totalRows"))
                    w.uint32(56).int32(m.totalRows);
                return w;
            };

            /**
             * Encodes the specified Vehicle message, length delimited. Does not implicitly {@link QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle.verify|verify} messages.
             * @function encodeDelimited
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @static
             * @param {QuoteVehicleDropdown.QuoteVehicleDropdownModel.IVehicle} message Vehicle message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Vehicle.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Vehicle message from the specified reader or buffer.
             * @function decode
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle} Vehicle
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Vehicle.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.rowNumber = r.int64();
                        break;
                    case 2:
                        m.vehicleId = r.string();
                        break;
                    case 3:
                        m.customerId = r.string();
                        break;
                    case 4:
                        m.rego = r.string();
                        break;
                    case 5:
                        m.model = r.string();
                        break;
                    case 6:
                        m.ownersName = r.string();
                        break;
                    case 7:
                        m.totalRows = r.int32();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Decodes a Vehicle message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {QuoteVehicleDropdown.QuoteVehicleDropdownModel.Vehicle} Vehicle
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Vehicle.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            return Vehicle;
        })();

        return QuoteVehicleDropdownModel;
    })();

    return QuoteVehicleDropdown;
})();

module.exports = $root;
