import axios from 'axios'
import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_WCF_URI
})

service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    store.dispatch('$resetSpinner')
    return Promise.reject(error)
  }
)

export default service
