export default class PricePartsVehicleBodyStayle {
  constructor() {
    this.bodyStyle = '',
    this.quoteType='',
    this.supplierID=[],
    this.supplierType = '',
    this.supplyComment = '',
    this.supplyDate = '',
    this.timeRequired = ''
    this.claimNo='',
    this.selectedMargin=''
  }
}
