export default {
  AllocatedQuoteRequest: 10,
  AssessingQuoteRequest: 20,
  QuoteSubmitted: 30,
  InspectionRequestSubmitted: 40,
  QuoteAuthorised: 50,
  AuthorityWithheld: 60,
  AuthorityWithdrawn: 70,
  QuoteJobCancelled: 80,
  QuoteDeclinedSubmitted: 90,
  TotalLossSubmitted: 100,
  TotalLossDetermined: 110,
  CashSettlement: 120,
  QuoteInvoiceSubmitted: 130,
  ReactiveInvoiceSubmitted: 140,
  AdjustmentNoteSubmitted: 150,
  PaymentAuthorised: 160,
  PaymentDeclined: 170
}
