/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.MiscQuoteFilter = (function() {

    /**
     * Namespace MiscQuoteFilter.
     * @exports MiscQuoteFilter
     * @namespace
     */
    var MiscQuoteFilter = {};

    MiscQuoteFilter.MiscQuoteFilterModel = (function() {

        /**
         * Properties of a MiscQuoteFilterModel.
         * @memberof MiscQuoteFilter
         * @interface IMiscQuoteFilterModel
         * @property {string|null} [miscQuoteNo] MiscQuoteFilterModel miscQuoteNo
         * @property {string|null} [customerName] MiscQuoteFilterModel customerName
         * @property {string|null} [invoiceNo] MiscQuoteFilterModel invoiceNo
         * @property {number|null} [active] MiscQuoteFilterModel active
         * @property {string|null} [sortColumn] MiscQuoteFilterModel sortColumn
         * @property {string|null} [sortOrder] MiscQuoteFilterModel sortOrder
         * @property {number|null} [pageIndex] MiscQuoteFilterModel pageIndex
         * @property {number|null} [pageSize] MiscQuoteFilterModel pageSize
         */

        /**
         * Constructs a new MiscQuoteFilterModel.
         * @memberof MiscQuoteFilter
         * @classdesc Represents a MiscQuoteFilterModel.
         * @implements IMiscQuoteFilterModel
         * @constructor
         * @param {MiscQuoteFilter.IMiscQuoteFilterModel=} [p] Properties to set
         */
        function MiscQuoteFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * MiscQuoteFilterModel miscQuoteNo.
         * @member {string|null|undefined} miscQuoteNo
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        MiscQuoteFilterModel.prototype.miscQuoteNo = null;

        /**
         * MiscQuoteFilterModel customerName.
         * @member {string|null|undefined} customerName
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        MiscQuoteFilterModel.prototype.customerName = null;

        /**
         * MiscQuoteFilterModel invoiceNo.
         * @member {string|null|undefined} invoiceNo
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        MiscQuoteFilterModel.prototype.invoiceNo = null;

        /**
         * MiscQuoteFilterModel active.
         * @member {number|null|undefined} active
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        MiscQuoteFilterModel.prototype.active = null;

        /**
         * MiscQuoteFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        MiscQuoteFilterModel.prototype.sortColumn = null;

        /**
         * MiscQuoteFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        MiscQuoteFilterModel.prototype.sortOrder = null;

        /**
         * MiscQuoteFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        MiscQuoteFilterModel.prototype.pageIndex = null;

        /**
         * MiscQuoteFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        MiscQuoteFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * MiscQuoteFilterModel _miscQuoteNo.
         * @member {"miscQuoteNo"|undefined} _miscQuoteNo
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        Object.defineProperty(MiscQuoteFilterModel.prototype, "_miscQuoteNo", {
            get: $util.oneOfGetter($oneOfFields = ["miscQuoteNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MiscQuoteFilterModel _customerName.
         * @member {"customerName"|undefined} _customerName
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        Object.defineProperty(MiscQuoteFilterModel.prototype, "_customerName", {
            get: $util.oneOfGetter($oneOfFields = ["customerName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MiscQuoteFilterModel _invoiceNo.
         * @member {"invoiceNo"|undefined} _invoiceNo
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        Object.defineProperty(MiscQuoteFilterModel.prototype, "_invoiceNo", {
            get: $util.oneOfGetter($oneOfFields = ["invoiceNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MiscQuoteFilterModel _active.
         * @member {"active"|undefined} _active
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        Object.defineProperty(MiscQuoteFilterModel.prototype, "_active", {
            get: $util.oneOfGetter($oneOfFields = ["active"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MiscQuoteFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        Object.defineProperty(MiscQuoteFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MiscQuoteFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        Object.defineProperty(MiscQuoteFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MiscQuoteFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        Object.defineProperty(MiscQuoteFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MiscQuoteFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @instance
         */
        Object.defineProperty(MiscQuoteFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified MiscQuoteFilterModel message. Does not implicitly {@link MiscQuoteFilter.MiscQuoteFilterModel.verify|verify} messages.
         * @function encode
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @static
         * @param {MiscQuoteFilter.IMiscQuoteFilterModel} m MiscQuoteFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MiscQuoteFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.miscQuoteNo != null && Object.hasOwnProperty.call(m, "miscQuoteNo"))
                w.uint32(10).string(m.miscQuoteNo);
            if (m.customerName != null && Object.hasOwnProperty.call(m, "customerName"))
                w.uint32(18).string(m.customerName);
            if (m.invoiceNo != null && Object.hasOwnProperty.call(m, "invoiceNo"))
                w.uint32(26).string(m.invoiceNo);
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(32).int32(m.active);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(42).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(50).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(56).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(64).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified MiscQuoteFilterModel message, length delimited. Does not implicitly {@link MiscQuoteFilter.MiscQuoteFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @static
         * @param {MiscQuoteFilter.IMiscQuoteFilterModel} message MiscQuoteFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MiscQuoteFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MiscQuoteFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {MiscQuoteFilter.MiscQuoteFilterModel} MiscQuoteFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MiscQuoteFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.MiscQuoteFilter.MiscQuoteFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.miscQuoteNo = r.string();
                    break;
                case 2:
                    m.customerName = r.string();
                    break;
                case 3:
                    m.invoiceNo = r.string();
                    break;
                case 4:
                    m.active = r.int32();
                    break;
                case 5:
                    m.sortColumn = r.string();
                    break;
                case 6:
                    m.sortOrder = r.string();
                    break;
                case 7:
                    m.pageIndex = r.int32();
                    break;
                case 8:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a MiscQuoteFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MiscQuoteFilter.MiscQuoteFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MiscQuoteFilter.MiscQuoteFilterModel} MiscQuoteFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MiscQuoteFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return MiscQuoteFilterModel;
    })();

    return MiscQuoteFilter;
})();

module.exports = $root;
