import PrintPreviewRoutes from './route-types'

// Lazy-load components. Webpack will split each into individual file
const PrintPreview = r => require.ensure([], () => r(require('./PrintPreview.vue')), 'printpreview-chunk')
const PrintPreviewOld = r => require.ensure([], () => r(require('./PrintPreviewOld.vue')), 'printpreview-chunk')
const PrintPreviewWithSideView = r => require.ensure([], () => r(require('./PrintPreviewWithSideView.vue')), 'printpreview-chunk')

export default [
  {
    path: PrintPreviewRoutes.PrintPreview.path,
    name: PrintPreviewRoutes.PrintPreview.name,
    component: PrintPreview,
    // props: {
    //   reportName: ''
    // },
    props: true,
    meta: {
      id: '',
      route: '',
      title: 'Print Preview',
      subject: '',
      message: '',
      ekey: 'emailer',
      pkey: 'parameters'
    }
  },
  {
    path: PrintPreviewRoutes.PrintPreviewOld.path,
    name: PrintPreviewRoutes.PrintPreviewOld.name,
    component: PrintPreviewOld,
    props: true,
    meta: {
      id: '',
      route: '',
      title: 'Print Preview',
      subject: '',
      message: '',
      ekey: 'emailer',
      pkey: 'parameters'
    }
  },
  {
    path: '/PrintPreviewWithSideView/:reportName',
    name: PrintPreviewRoutes.PrintPreviewWithSideView.name,
    component: PrintPreviewWithSideView,
    props: {
      reportName: ''
    },
    //props: true,
    meta: {
      id: '',
      route: '',
      title: 'Print Preview',
      subject: '',
      message: '',
      ekey: 'emailer',
      pkey: 'parameters'
    }
  }
]
