import FqApi from '@/services/fq-api'
import ProtoBufApi from '@/services/protobuf-api'
import ProtoBuf from 'protobufjs'

const baseUrl = '/customers'

export default {
  async getEntity(id, hash = '') {
    const url = !hash ? `${baseUrl}/${id}` : `${baseUrl}/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getNewEntity() {
    const result = await FqApi.get(`${baseUrl}/new`)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseCustomerFilter(filter)
    const url = `${baseUrl}/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseCustomerFilter(filter) {
    const protoDefinition = require('@/assets/proto/customer/CustomerFilter.proto')
    const filterModel = protoDefinition.CustomerFilter.CustomerFilterModel
    var buffer = filterModel.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async putCustomer(entity, deepDiff) {
    if (deepDiff) {
      entity.deepDiff = JSON.stringify(deepDiff)
    }
    return await FqApi.put(`${baseUrl}/${entity.id}`, entity)
  },
  async postCustomer(customer) {
    return await FqApi.post(`${baseUrl}`, customer)
  },
  async getCustomerDropdownProto(filter) {
    const serialised = this.serialiseCustomerDropdownFilter(filter)
    const result = await ProtoBufApi(`${baseUrl}/protobuf/listcombo/${serialised}`)
    const proto = require('@/assets/proto/customer/CustomerDropdown.proto')
    const message = proto.CustomerDropdown.CustomerDropdownModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.assets
  },
  serialiseCustomerDropdownFilter(filter) {
    const entityProto = require('@/assets/proto/customer/CustomerDropdownFilter.proto')
    const filterMessage = entityProto.CustomerDropdownFilter.CustomerDropdownFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  }
}