<template>
  <div
    class="modal animated pulse"
    :class="{'is-active': hasErrors}">
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Please contact support</p>
        <button
          class="delete"
          aria-label="close"
          @click="exit" />
      </header>
      <section
        class="modal-card-body"
        v-if="hasErrors">
        <p class="title-3">There is an unhandled error:</p>
        <div v-if="errors[index].id > 0">
          <p class="title is-2">
            <span class="has-text-danger">EVENT ID: {{ errors[index].id }}</span>
          </p>
          <p>
            <span style="white-space: pre-wrap;">{{ errors[index].message }}</span>
          </p>
        </div>
        <div v-else-if="errors[index].statusCode">
          <p class="title is-2">
            <span class="has-text-danger">STATUS CODE: {{ errors[index].statusCode }}</span>
          </p>
          <div v-if="!showRaw">
            <span class="has-text-weight-semibold">Status Text: </span>
            <span style="white-space: pre-wrap;">{{ errors[index].message }}</span>
            <br>
            <span
              v-if="errors[index].url"
              class="has-text-weight-semibold">Url: </span>
            <div
              v-if="errors[index].url"
              class="box">{{ errors[index].url }}</div>
            <span class="has-text-weight-semibold">Error:</span>
            <div class="box">{{ errors[index].data }}</div>
            <!-- <pre>{{ errors[index].data }}</pre> -->
          </div>
          <div
            v-else
            class="box">
            <span v-html="errors[index].data.replace('font-family:', ':')" />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-primary"
          v-if="index > 0"
          @click="index--">Previous</button>
        <span class="filler" />
        <button
          class="button is-primary"
          v-if="index < errors.length -1"
          @click="index++">Next</button>
        <button
          class="button is-primary"
          v-if="index === errors.length -1"
          @click="exit">Dismiss</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppErrors',
  props: {
    showRaw: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      index: 0
    }
  },
  computed: {
    errors: function() {
      return this.$store.getters['errors/all']
    },
    hasErrors: function() {
      return this.$store.getters['errors/hasErrors']
    }
  },
  watch: {
    hasErrors(value) {
      if (value) {
        this.$resetSpinner()
      }
    }
  },
  mounted() {},
  methods: {
    exit() {
      this.$store.dispatch('errors/purge')
    }
  }
}
</script>
<style>
.filler {
  flex: 1 1 auto;
}
</style>
