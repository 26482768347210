import ContactDetailRoutes from './routes-detail'
import routeTypes from './route-types'
import store from '@/store'

const ContactLisView = r => require.ensure([], () => r(require('./ContactListView.vue')), 'contactlist-chunk')
const ContactView = r => require.ensure([], () => r(require('./ContactView.vue')), 'contact-chunk')
const ContactNew = r => require.ensure([], () => r(require('./ContactNew.vue')), 'contact-chunk')

const code = 'A049'

// Get sessionStorage
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|contact|${query}`)
}

export default [
  {
    path: routeTypes.ContactListView.path,
    component: ContactLisView,
    meta: {
      id: code,
      route: 'listing',
      title: 'Contacts',
      fkey: 'contacts-filter',
      report: 'rptTablesContactProfile',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx?`
    }
  },
  {
    path: '/contacts/new',
    name: 'ContactNew',
    component: ContactNew,
    meta: {
      id: code,
      route: 'detail',
      title: 'New'
    }
  },
  {
    path: routeTypes.ContactView.path,
    component: ContactView,
    props: route => ({
      isNew: route.query.mode === 'new' || route.meta.isNew || getStorage('isNew') === 'true',
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl'),
      params: route.query.params || route.meta.params || getStorage('params'),
      firstname: route.query.fname || route.meta.firstname || getStorage('firstname') || '',
      lastname: route.query.lname || route.meta.lastname || getStorage('lastname') || '',
      assetType: route.query.assetType || route.meta.assetType || getStorage('assetType'),
      assetId: route.query.assetId || route.meta.assetId || getStorage('assetId'),
      assetName: route.query.assetName || route.meta.assetName || getStorage('assetName')
    }),
    meta: {
      id: code,
      route: 'detail',
      title: 'Contact'
    },
    children: [...ContactDetailRoutes]
  }
]
