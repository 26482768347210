/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.ItemFilter = (function() {

    /**
     * Namespace ItemFilter.
     * @exports ItemFilter
     * @namespace
     */
    var ItemFilter = {};

    ItemFilter.ItemFilterModel = (function() {

        /**
         * Properties of an ItemFilterModel.
         * @memberof ItemFilter
         * @interface IItemFilterModel
         * @property {string|null} [itemNo] ItemFilterModel itemNo
         * @property {string|null} [itemDesc] ItemFilterModel itemDesc
         * @property {string|null} [type] ItemFilterModel type
         * @property {number|null} [status] ItemFilterModel status
         * @property {string|null} [sortColumn] ItemFilterModel sortColumn
         * @property {string|null} [sortOrder] ItemFilterModel sortOrder
         * @property {number|null} [pageIndex] ItemFilterModel pageIndex
         * @property {number|null} [pageSize] ItemFilterModel pageSize
         */

        /**
         * Constructs a new ItemFilterModel.
         * @memberof ItemFilter
         * @classdesc Represents an ItemFilterModel.
         * @implements IItemFilterModel
         * @constructor
         * @param {ItemFilter.IItemFilterModel=} [p] Properties to set
         */
        function ItemFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ItemFilterModel itemNo.
         * @member {string|null|undefined} itemNo
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        ItemFilterModel.prototype.itemNo = null;

        /**
         * ItemFilterModel itemDesc.
         * @member {string|null|undefined} itemDesc
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        ItemFilterModel.prototype.itemDesc = null;

        /**
         * ItemFilterModel type.
         * @member {string|null|undefined} type
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        ItemFilterModel.prototype.type = null;

        /**
         * ItemFilterModel status.
         * @member {number|null|undefined} status
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        ItemFilterModel.prototype.status = null;

        /**
         * ItemFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        ItemFilterModel.prototype.sortColumn = null;

        /**
         * ItemFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        ItemFilterModel.prototype.sortOrder = null;

        /**
         * ItemFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        ItemFilterModel.prototype.pageIndex = null;

        /**
         * ItemFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        ItemFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ItemFilterModel _itemNo.
         * @member {"itemNo"|undefined} _itemNo
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        Object.defineProperty(ItemFilterModel.prototype, "_itemNo", {
            get: $util.oneOfGetter($oneOfFields = ["itemNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ItemFilterModel _itemDesc.
         * @member {"itemDesc"|undefined} _itemDesc
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        Object.defineProperty(ItemFilterModel.prototype, "_itemDesc", {
            get: $util.oneOfGetter($oneOfFields = ["itemDesc"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ItemFilterModel _type.
         * @member {"type"|undefined} _type
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        Object.defineProperty(ItemFilterModel.prototype, "_type", {
            get: $util.oneOfGetter($oneOfFields = ["type"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ItemFilterModel _status.
         * @member {"status"|undefined} _status
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        Object.defineProperty(ItemFilterModel.prototype, "_status", {
            get: $util.oneOfGetter($oneOfFields = ["status"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ItemFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        Object.defineProperty(ItemFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ItemFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        Object.defineProperty(ItemFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ItemFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        Object.defineProperty(ItemFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ItemFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof ItemFilter.ItemFilterModel
         * @instance
         */
        Object.defineProperty(ItemFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified ItemFilterModel message. Does not implicitly {@link ItemFilter.ItemFilterModel.verify|verify} messages.
         * @function encode
         * @memberof ItemFilter.ItemFilterModel
         * @static
         * @param {ItemFilter.IItemFilterModel} m ItemFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ItemFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.itemNo != null && Object.hasOwnProperty.call(m, "itemNo"))
                w.uint32(10).string(m.itemNo);
            if (m.itemDesc != null && Object.hasOwnProperty.call(m, "itemDesc"))
                w.uint32(18).string(m.itemDesc);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(26).string(m.type);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(32).int32(m.status);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(42).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(50).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(56).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(64).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified ItemFilterModel message, length delimited. Does not implicitly {@link ItemFilter.ItemFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ItemFilter.ItemFilterModel
         * @static
         * @param {ItemFilter.IItemFilterModel} message ItemFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ItemFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ItemFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof ItemFilter.ItemFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ItemFilter.ItemFilterModel} ItemFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ItemFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ItemFilter.ItemFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.itemNo = r.string();
                    break;
                case 2:
                    m.itemDesc = r.string();
                    break;
                case 3:
                    m.type = r.string();
                    break;
                case 4:
                    m.status = r.int32();
                    break;
                case 5:
                    m.sortColumn = r.string();
                    break;
                case 6:
                    m.sortOrder = r.string();
                    break;
                case 7:
                    m.pageIndex = r.int32();
                    break;
                case 8:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes an ItemFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ItemFilter.ItemFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ItemFilter.ItemFilterModel} ItemFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ItemFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return ItemFilterModel;
    })();

    return ItemFilter;
})();

module.exports = $root;
