const userPrintOptionKey = 'uniq-user-print-option'

const state = {
  printOptions: {
    invoice: {
      showItemNo: true
    },
    quote: {}
  },
  showMoreVehicle: true,
  showMoreCustomer: true,
  showMoreInsurer: true
}

const getters = {
  printOptions: state => state.printOptions,
  showMoreVehicle: state => state.showMoreVehicle,
  showMoreCustomer: state => state.showMoreCustomer,
  showMoreInsurer: state => state.showMoreInsurer
}

const actions = {
  loadPrintOptions({ commit }) {
    commit('loadPrintOptions')
  },
  setInvoicePrintShowItemNo({ commit }, show) {
    commit('setInvoicePrintShowItemNo', show)
  },
  toggleShowMoreVehicle({ commit }) {
    commit('toggleShowMoreVehicle')
  },
  toggleShowMoreCustomer({ commit }) {
    commit('toggleShowMoreCustomer')
  },
  toggleShowMoreInsurer({ commit }) {
    commit('toggleShowMoreInsurer')
  }
}

const mutations = {
  loadPrintOptions(state) {
    const option = localStorage.getItem(userPrintOptionKey)
    if (option) {
      state.printOptions = JSON.parse(option)
    }
  },
  setInvoicePrintShowItemNo(state, show) {
    state.printOptions.invoice.showItemNo = show
    localStorage.setItem(userPrintOptionKey, JSON.stringify(state.printOptions))
  },
  toggleShowMoreVehicle(state, value) {
    state.showMoreVehicle = !state.showMoreVehicle
  },
  toggleShowMoreCustomer(state, value) {
    state.showMoreCustomer = !state.showMoreCustomer
  },
  toggleShowMoreInsurer(state, value) {
    state.showMoreInsurer = !state.showMoreInsurer
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}