import Guid from '@/components/Guid'

export default class QuoteLabourRateModel {
  constructor(quoteId, labourCodeId, labourTypeId = Guid.empty(), rate = 0) {
    this.quoteId = quoteId
    this.labourCodeId = labourCodeId
    this.labourTypeId = labourTypeId
    this.rate = rate
    this.modifiedBy = ''
    this.modifiedDate = null
    this.createdBy = ''
    this.createdDate = null
    this.isNew = true
    this.isDeleted = false
    this.quoteVersion = 0
    this.deleted = false
  }
}
