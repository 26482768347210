/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.QuickInvoiceAssetDropdownFilter = (function() {

    /**
     * Namespace QuickInvoiceAssetDropdownFilter.
     * @exports QuickInvoiceAssetDropdownFilter
     * @namespace
     */
    var QuickInvoiceAssetDropdownFilter = {};

    QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel = (function() {

        /**
         * Properties of a QuickInvoiceAssetDropdownFilterModel.
         * @memberof QuickInvoiceAssetDropdownFilter
         * @interface IQuickInvoiceAssetDropdownFilterModel
         * @property {string|null} [assetId] QuickInvoiceAssetDropdownFilterModel assetId
         * @property {string|null} [assetName] QuickInvoiceAssetDropdownFilterModel assetName
         * @property {boolean|null} [showCustomer] QuickInvoiceAssetDropdownFilterModel showCustomer
         * @property {boolean|null} [showInsurer] QuickInvoiceAssetDropdownFilterModel showInsurer
         * @property {number|null} [pageIndex] QuickInvoiceAssetDropdownFilterModel pageIndex
         * @property {number|null} [pageSize] QuickInvoiceAssetDropdownFilterModel pageSize
         */

        /**
         * Constructs a new QuickInvoiceAssetDropdownFilterModel.
         * @memberof QuickInvoiceAssetDropdownFilter
         * @classdesc Represents a QuickInvoiceAssetDropdownFilterModel.
         * @implements IQuickInvoiceAssetDropdownFilterModel
         * @constructor
         * @param {QuickInvoiceAssetDropdownFilter.IQuickInvoiceAssetDropdownFilterModel=} [p] Properties to set
         */
        function QuickInvoiceAssetDropdownFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QuickInvoiceAssetDropdownFilterModel assetId.
         * @member {string|null|undefined} assetId
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @instance
         */
        QuickInvoiceAssetDropdownFilterModel.prototype.assetId = null;

        /**
         * QuickInvoiceAssetDropdownFilterModel assetName.
         * @member {string|null|undefined} assetName
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @instance
         */
        QuickInvoiceAssetDropdownFilterModel.prototype.assetName = null;

        /**
         * QuickInvoiceAssetDropdownFilterModel showCustomer.
         * @member {boolean|null|undefined} showCustomer
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @instance
         */
        QuickInvoiceAssetDropdownFilterModel.prototype.showCustomer = null;

        /**
         * QuickInvoiceAssetDropdownFilterModel showInsurer.
         * @member {boolean|null|undefined} showInsurer
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @instance
         */
        QuickInvoiceAssetDropdownFilterModel.prototype.showInsurer = null;

        /**
         * QuickInvoiceAssetDropdownFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @instance
         */
        QuickInvoiceAssetDropdownFilterModel.prototype.pageIndex = null;

        /**
         * QuickInvoiceAssetDropdownFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @instance
         */
        QuickInvoiceAssetDropdownFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * QuickInvoiceAssetDropdownFilterModel _assetId.
         * @member {"assetId"|undefined} _assetId
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceAssetDropdownFilterModel.prototype, "_assetId", {
            get: $util.oneOfGetter($oneOfFields = ["assetId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickInvoiceAssetDropdownFilterModel _assetName.
         * @member {"assetName"|undefined} _assetName
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceAssetDropdownFilterModel.prototype, "_assetName", {
            get: $util.oneOfGetter($oneOfFields = ["assetName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickInvoiceAssetDropdownFilterModel _showCustomer.
         * @member {"showCustomer"|undefined} _showCustomer
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceAssetDropdownFilterModel.prototype, "_showCustomer", {
            get: $util.oneOfGetter($oneOfFields = ["showCustomer"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickInvoiceAssetDropdownFilterModel _showInsurer.
         * @member {"showInsurer"|undefined} _showInsurer
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceAssetDropdownFilterModel.prototype, "_showInsurer", {
            get: $util.oneOfGetter($oneOfFields = ["showInsurer"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickInvoiceAssetDropdownFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceAssetDropdownFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickInvoiceAssetDropdownFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceAssetDropdownFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified QuickInvoiceAssetDropdownFilterModel message. Does not implicitly {@link QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel.verify|verify} messages.
         * @function encode
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @static
         * @param {QuickInvoiceAssetDropdownFilter.IQuickInvoiceAssetDropdownFilterModel} m QuickInvoiceAssetDropdownFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuickInvoiceAssetDropdownFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.assetId != null && Object.hasOwnProperty.call(m, "assetId"))
                w.uint32(10).string(m.assetId);
            if (m.assetName != null && Object.hasOwnProperty.call(m, "assetName"))
                w.uint32(18).string(m.assetName);
            if (m.showCustomer != null && Object.hasOwnProperty.call(m, "showCustomer"))
                w.uint32(24).bool(m.showCustomer);
            if (m.showInsurer != null && Object.hasOwnProperty.call(m, "showInsurer"))
                w.uint32(32).bool(m.showInsurer);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(40).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(48).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified QuickInvoiceAssetDropdownFilterModel message, length delimited. Does not implicitly {@link QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @static
         * @param {QuickInvoiceAssetDropdownFilter.IQuickInvoiceAssetDropdownFilterModel} message QuickInvoiceAssetDropdownFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuickInvoiceAssetDropdownFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QuickInvoiceAssetDropdownFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel} QuickInvoiceAssetDropdownFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuickInvoiceAssetDropdownFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.assetId = r.string();
                    break;
                case 2:
                    m.assetName = r.string();
                    break;
                case 3:
                    m.showCustomer = r.bool();
                    break;
                case 4:
                    m.showInsurer = r.bool();
                    break;
                case 5:
                    m.pageIndex = r.int32();
                    break;
                case 6:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a QuickInvoiceAssetDropdownFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel} QuickInvoiceAssetDropdownFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuickInvoiceAssetDropdownFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return QuickInvoiceAssetDropdownFilterModel;
    })();

    return QuickInvoiceAssetDropdownFilter;
})();

module.exports = $root;
