import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
// import ProtoBufApi from '@/services/protobuf-api'
// import AuditTrailApi from '@/services/audittrail-api'
// import { GlAccountTypes } from '@/enums'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialisedDealerCreditEntryFilter(filter)
    const url = `/dealercreditentry/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getAttachments(vendorId) {
    return FqApi.get(`/attachments/list?objectId=${vendorId}`)
  },
  async postEntity(entity) {
    const result = await FqApi.post('/dealercreditentry', entity)
    return result.data
  },
  serialisedDealerCreditEntryFilter(filter) {
    const dealerCreditEntryProto = require('../../assets/proto/dealercreditentry/DealerCreditEntryFilter.proto')
    const filterMessage = dealerCreditEntryProto.DealerCreditEntryFilter.DealerCreditEntryFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  }
}