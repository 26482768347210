import FqApi from '@/services/fq-api'

const baseUrl = '/emails'

export default {
  async createRoute(serviceProviderEmail) {
    const url = (`/companies/createEmailRoute/${serviceProviderEmail}`)
    const result = await FqApi.post(url)
    return result.data
  },
  async deleteRoute() {
    const url = ('/companies/deleteEmailRoute')
    const result = await FqApi.post(url)
    return result.data
  },
  async getRoute() {
    const url = ('/companies/getEmailRoute')
    const result = await FqApi.get(url)
    return result.data
  },
  async getServiceProviderDomainName() {
    const url = ('/companies/getServiceProviderDomainName')
    const result = await FqApi.get(url)
    return result.data
  },
  async validateServiceProviderEmail(serviceProviderEmail) {
    const result = await FqApi.get(`${baseUrl}/validateServiceProviderEmail/${serviceProviderEmail}`)
    return result.data
  },
  async getMessages(quoteId) {
    const result = await FqApi.get(`${baseUrl}/messages/${quoteId}`)
    return result.data
  },
  async getUnreadCount(quoteId) {
    const result = await FqApi.get(`${baseUrl}/getunreadcount/${quoteId}`)
    return result.data
  },
  async setMessagesAsRead(quoteId) {
    const result = await FqApi.post(`${baseUrl}/setasread/${quoteId}`)
    return result.data
  },
  async getQuoteEmailAttachment(quoteId) {
    const result = await FqApi.post(`${baseUrl}/setasread/${quoteId}`)
    return result.data
  },
  async getQuoteEmailAttachments(quoteId, emailId, isIncoming) {
    const result = await FqApi.post(`${baseUrl}/attachments/${quoteId}/${emailId}?isIncoming=${isIncoming}`)
    return result.data
  },
  async downloadModifiedQuoteEmailAttachment(quoteId, quoteEmailAttachmentId, isIncoming) {
    const url = `${baseUrl}/download/${quoteId}/modified/${quoteEmailAttachmentId}?isIncoming=${isIncoming}`
    return await FqApi.get(url)
  },
  async copyAttachmentToQuote(sourceEmailAttachmentId, targetQuoteId, targetQuoteAttachmentCategoryId) {
    const url = `${baseUrl}/copy/${targetQuoteId}/${sourceEmailAttachmentId}/${targetQuoteAttachmentCategoryId}`
    return await FqApi.get(url)
  },
  async getEmailTemplateDropdown(filter) {
    const result = await FqApi.post(`${baseUrl}/template/dropdownlist`, filter)
    return result.data
  },
  async send(formData) {
    const result = await FqApi.post('/emails/send', formData)
    return result
  }
}