<template>
  <nav class="panel">
    <slot />
  </nav>
</template>

<script>
export default {
  name: 'DashboardPanel',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  }

}
</script>

<style scoped>

</style>