const ADD_ERROR = 'ADD_ERROR'
const REMOVE_ERROR = 'REMOVE_ERROR'
const PURGE_ERRORS = 'PURGE_ERRORS'

const state = {
  errors: []
}

const getters = {
  all: state => state.errors,
  hasErrors: state => state.errors.length && state.errors.length > 0
}

const actions = {
  add({ commit, state }, error) {
    commit(ADD_ERROR, error)
  },
  remove({ commit, state }, error) {
    commit(REMOVE_ERROR, error)
  },
  purge({ commit, state }) {
    commit(PURGE_ERRORS)
  }
}

const mutations = {
  [ADD_ERROR](state, data) {
    state.errors.push(data)
  },

  [REMOVE_ERROR](state, data) {
    let idx = -1
    for (let i = 0; i < state.errors.length; i++) {
      if (state.errors.id === data.id) {
        idx = i
        break
      }
    }
    if (idx > -1) {
      state.errors.splice(idx, 1)
    }
  },

  [PURGE_ERRORS](state) {
    state.errors = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
