import routeTypes from './route-types'
// import store from '@/store'

const OrmMessageListView = r => require.ensure([], () => r(require('./OrmMessageListView.vue')), 'ormmessagelist-chunk')
const OrmMessageView = r => require.ensure([], () => r(require('./OrmMessageView.vue')), 'ormmessageview-chunk')
const OrmMessageDetail = r => require.ensure([], () => r(require('./OrmMessageDetail.vue')), 'ormmessageview-chunk')
const OrmMessageNew = r => require.ensure([], ()=> r(require('./OrmMessageNew.vue')), 'ormmessage-chunk')
const menuCode = 'A116'

// Get sessionStorage
// const getStorage = function(query) {
//   const sessionId = store.getters['userInfo/info'].sessionId
//   return sessionStorage.getItem(`${sessionId}|ormmessage|${query}`)
// }

export default [
  {
    path: routeTypes.OrmMessageListView.path,
    name: routeTypes.OrmMessageListView.name,
    component: OrmMessageListView,
    meta: {
      id: menuCode,
      route: 'listing',
      title: 'ORM Messages',
      fkey: 'ormmessage|filter',
      report: '',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.OrmMessageView.path,
    name: '',
    component: OrmMessageView,
    meta: {
      id: menuCode,
      route: 'detail',
      title: 'ORM Messages',
      isNew: false,
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    },
    children: [
      {
        path: '',
        name: routeTypes.OrmMessageView.name,
        component: OrmMessageDetail,
        meta: {
          id: menuCode,
          route: 'detail',
          title: 'Detail',
          isNew: false
        }
      }
    ]
  },
  {
    path: routeTypes.OrmMessageNew.path,
    name: '',
    component: OrmMessageNew,
    meta: {
      id: menuCode,
      route: 'detail',
      isNew: true,
      title: 'New ORM Message'
    },
    children: [
      {
        path: '',
        name: routeTypes.OrmMessageNew.name,
        component: OrmMessageDetail,
        meta: {
          id: menuCode,
          route: 'detail',
          title: 'Detail',
          isNew: false
        }
      }
    ]
  }
]
