
const state = {
  returnRoute: {},
  emailers: [],
  parameters: []
}

const getters = {
  getEmailer: state =>
    function(id) {
      return state.emailers.find(e => e.id === id)
    },
  getParameters: state => state.parameters
}

const actions = {
  addEmailer({ commit }, emailer) {
    commit('addEmailer', emailer)
  },
  removeEmailer({ commit }, emailer) {
    commit('removeEmailer', emailer.id)
  },
  setEmailerAttachments({ commit }, payload) {
    commit('setEmailerAttachments', payload)
  },
  addParameters({ commit }, parameters) {
    commit('addParameters', parameters)
  },
  removeParameters({ commit }) {
    commit('removeParameters')
  },
  addParameter({ commit }, parameter) {
    commit('addParameter', parameter)
  },
  deleteParameter({ commit }, parameter) {
    commit('deleteParameters', parameter)
  }
}

const mutations = {
  addEmailer(state, emailer) {
    const index = state.emailers.findIndex(e => e.id === emailer.id)
    if (index !== -1) {
      state.emailers[index] = emailer
    }
    else {
      state.emailers.push(emailer)
    }
  },
  removeEmailer(state, id) {
    const index = state.emailers.findIndex(e => e.id === id)
    if (index !== -1) {
      state.emailers.splice(index, 1)
    }
  },
  setEmailerAttachments(state, { emailerId, attachments }) {
    const emailer = state.emailers.find(e => e.id === emailerId)
    if (emailer) {
      if (!emailer.attachments)
        emailer.attachments = []
      emailer.attachments.splice(0, emailer.attachments.length)
      for (var i = 0; i < attachments.length; i++) {
        emailer.attachments.push(attachments[i])
      }
    }
  },
  addParameters(state, parameters) {
    state.parameters = parameters
  },
  removeParameters(state) {
    state.parameters = []
  },
  addParameter(state, parameter) {
    const index = state.parameters.findIndex(i => i.quoteId === parameter.quoteId)
    if (index !== -1)
      state.parameters[index] = parameter
    else
      state.parameters.push(parameter)
  },
  removeParameter(state, parameter) {
    const index = state.parameters.findIndex(i => i.quoteId === parameter.quoteId)
    if (index !== -1)
      state.parameters.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
