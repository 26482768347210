exports.OrmMessageListView = {
  path: '/ormmessages',
  name: 'OrmMessageListView'
}
exports.OrmMessageView = {
  path: '/ormmessages/:messageId',
  name: 'OrmMessageView'
}
exports.OrmMessageNew = {
  path: '/ormmessages/new/:messageId',
  name: 'OrmMessageNew'
}
