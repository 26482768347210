exports.VehicleListView = {
  path: '/vehicles',
  name: 'VehicleListView'
}
exports.VehicleNew = {
  path: '/vehicles/new',
  name: 'VehicleNew'
}
exports.VehicleView = {
  path: '/vehicles/:vehicleId',
  name: 'VehicleView'
}
exports.VehicleDetail = {
  path: '/vehicles/:vehicleId',
  name: 'VehicleDetail'
}
