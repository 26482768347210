/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.RecurringRemarkSummary = (function() {

    /**
     * Namespace RecurringRemarkSummary.
     * @exports RecurringRemarkSummary
     * @namespace
     */
    var RecurringRemarkSummary = {};

    RecurringRemarkSummary.RecurringRemarkSummaryModel = (function() {

        /**
         * Properties of a RecurringRemarkSummaryModel.
         * @memberof RecurringRemarkSummary
         * @interface IRecurringRemarkSummaryModel
         * @property {Array.<RecurringRemarkSummary.RecurringRemarkSummaryModel.IRecurringRemark>|null} [remarks] RecurringRemarkSummaryModel remarks
         */

        /**
         * Constructs a new RecurringRemarkSummaryModel.
         * @memberof RecurringRemarkSummary
         * @classdesc Represents a RecurringRemarkSummaryModel.
         * @implements IRecurringRemarkSummaryModel
         * @constructor
         * @param {RecurringRemarkSummary.IRecurringRemarkSummaryModel=} [p] Properties to set
         */
        function RecurringRemarkSummaryModel(p) {
            this.remarks = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * RecurringRemarkSummaryModel remarks.
         * @member {Array.<RecurringRemarkSummary.RecurringRemarkSummaryModel.IRecurringRemark>} remarks
         * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel
         * @instance
         */
        RecurringRemarkSummaryModel.prototype.remarks = $util.emptyArray;

        /**
         * Encodes the specified RecurringRemarkSummaryModel message. Does not implicitly {@link RecurringRemarkSummary.RecurringRemarkSummaryModel.verify|verify} messages.
         * @function encode
         * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel
         * @static
         * @param {RecurringRemarkSummary.IRecurringRemarkSummaryModel} m RecurringRemarkSummaryModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecurringRemarkSummaryModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.remarks != null && m.remarks.length) {
                for (var i = 0; i < m.remarks.length; ++i)
                    $root.RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark.encode(m.remarks[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Encodes the specified RecurringRemarkSummaryModel message, length delimited. Does not implicitly {@link RecurringRemarkSummary.RecurringRemarkSummaryModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel
         * @static
         * @param {RecurringRemarkSummary.IRecurringRemarkSummaryModel} message RecurringRemarkSummaryModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecurringRemarkSummaryModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RecurringRemarkSummaryModel message from the specified reader or buffer.
         * @function decode
         * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {RecurringRemarkSummary.RecurringRemarkSummaryModel} RecurringRemarkSummaryModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecurringRemarkSummaryModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.RecurringRemarkSummary.RecurringRemarkSummaryModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.remarks && m.remarks.length))
                        m.remarks = [];
                    m.remarks.push($root.RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a RecurringRemarkSummaryModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RecurringRemarkSummary.RecurringRemarkSummaryModel} RecurringRemarkSummaryModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecurringRemarkSummaryModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RecurringRemarkSummaryModel.RecurringRemark = (function() {

            /**
             * Properties of a RecurringRemark.
             * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel
             * @interface IRecurringRemark
             * @property {string|null} [recurringRemarkId] RecurringRemark recurringRemarkId
             * @property {string|null} [remarkDescription] RecurringRemark remarkDescription
             * @property {boolean|null} [active] RecurringRemark active
             * @property {number|null} [totalRows] RecurringRemark totalRows
             */

            /**
             * Constructs a new RecurringRemark.
             * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel
             * @classdesc Represents a RecurringRemark.
             * @implements IRecurringRemark
             * @constructor
             * @param {RecurringRemarkSummary.RecurringRemarkSummaryModel.IRecurringRemark=} [p] Properties to set
             */
            function RecurringRemark(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * RecurringRemark recurringRemarkId.
             * @member {string|null|undefined} recurringRemarkId
             * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark
             * @instance
             */
            RecurringRemark.prototype.recurringRemarkId = null;

            /**
             * RecurringRemark remarkDescription.
             * @member {string|null|undefined} remarkDescription
             * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark
             * @instance
             */
            RecurringRemark.prototype.remarkDescription = null;

            /**
             * RecurringRemark active.
             * @member {boolean|null|undefined} active
             * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark
             * @instance
             */
            RecurringRemark.prototype.active = null;

            /**
             * RecurringRemark totalRows.
             * @member {number|null|undefined} totalRows
             * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark
             * @instance
             */
            RecurringRemark.prototype.totalRows = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * RecurringRemark _recurringRemarkId.
             * @member {"recurringRemarkId"|undefined} _recurringRemarkId
             * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark
             * @instance
             */
            Object.defineProperty(RecurringRemark.prototype, "_recurringRemarkId", {
                get: $util.oneOfGetter($oneOfFields = ["recurringRemarkId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * RecurringRemark _remarkDescription.
             * @member {"remarkDescription"|undefined} _remarkDescription
             * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark
             * @instance
             */
            Object.defineProperty(RecurringRemark.prototype, "_remarkDescription", {
                get: $util.oneOfGetter($oneOfFields = ["remarkDescription"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * RecurringRemark _active.
             * @member {"active"|undefined} _active
             * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark
             * @instance
             */
            Object.defineProperty(RecurringRemark.prototype, "_active", {
                get: $util.oneOfGetter($oneOfFields = ["active"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * RecurringRemark _totalRows.
             * @member {"totalRows"|undefined} _totalRows
             * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark
             * @instance
             */
            Object.defineProperty(RecurringRemark.prototype, "_totalRows", {
                get: $util.oneOfGetter($oneOfFields = ["totalRows"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified RecurringRemark message. Does not implicitly {@link RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark.verify|verify} messages.
             * @function encode
             * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark
             * @static
             * @param {RecurringRemarkSummary.RecurringRemarkSummaryModel.IRecurringRemark} m RecurringRemark message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecurringRemark.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.recurringRemarkId != null && Object.hasOwnProperty.call(m, "recurringRemarkId"))
                    w.uint32(10).string(m.recurringRemarkId);
                if (m.remarkDescription != null && Object.hasOwnProperty.call(m, "remarkDescription"))
                    w.uint32(18).string(m.remarkDescription);
                if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                    w.uint32(24).bool(m.active);
                if (m.totalRows != null && Object.hasOwnProperty.call(m, "totalRows"))
                    w.uint32(32).int32(m.totalRows);
                return w;
            };

            /**
             * Encodes the specified RecurringRemark message, length delimited. Does not implicitly {@link RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark.verify|verify} messages.
             * @function encodeDelimited
             * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark
             * @static
             * @param {RecurringRemarkSummary.RecurringRemarkSummaryModel.IRecurringRemark} message RecurringRemark message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecurringRemark.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RecurringRemark message from the specified reader or buffer.
             * @function decode
             * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark} RecurringRemark
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecurringRemark.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.recurringRemarkId = r.string();
                        break;
                    case 2:
                        m.remarkDescription = r.string();
                        break;
                    case 3:
                        m.active = r.bool();
                        break;
                    case 4:
                        m.totalRows = r.int32();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Decodes a RecurringRemark message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {RecurringRemarkSummary.RecurringRemarkSummaryModel.RecurringRemark} RecurringRemark
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecurringRemark.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            return RecurringRemark;
        })();

        return RecurringRemarkSummaryModel;
    })();

    return RecurringRemarkSummary;
})();

module.exports = $root;
