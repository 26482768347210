<template>
  <svg enable-background="new 0 0 20 20"
    version="1.1"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
    <circle :style="circleStyle"
      cx="10"
      cy="10"
      r="10" />
    <text x="50%"
      y="50%"
      :style="textStyle"
      text-anchor="middle"
      font-size="0.563em"
      dy=".35em">{{ text }}</text>
  </svg>
</template>

<script>
export default {
  name: 'SvgCircle',
  components: {},
  mixins: [],
  props: {
    width: {
      type: Number,
      default: 25
    },
    height: {
      type: Number,
      default: 25
    },
    fillColour: {
      type: String,
      default: '#5ba7f2'
    },
    textColour: {
      type: String,
      default: 'white'
    },
    text: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {}
  },
  computed: {
    circleStyle() {
      return {
        fill: this.fillColour
      }
    },
    textStyle() {
      return {
        fill: this.textColour
      }
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
svg {
  font-size: 1rem;
}

</style>
