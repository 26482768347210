/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.HeaderMenu = (function() {

    /**
     * Namespace HeaderMenu.
     * @exports HeaderMenu
     * @namespace
     */
    var HeaderMenu = {};

    HeaderMenu.HeaderMenuModel = (function() {

        /**
         * Properties of a HeaderMenuModel.
         * @memberof HeaderMenu
         * @interface IHeaderMenuModel
         * @property {Array.<HeaderMenu.HeaderMenuModel.IHeaderMenuItemModel>|null} [items] HeaderMenuModel items
         */

        /**
         * Constructs a new HeaderMenuModel.
         * @memberof HeaderMenu
         * @classdesc Represents a HeaderMenuModel.
         * @implements IHeaderMenuModel
         * @constructor
         * @param {HeaderMenu.IHeaderMenuModel=} [p] Properties to set
         */
        function HeaderMenuModel(p) {
            this.items = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * HeaderMenuModel items.
         * @member {Array.<HeaderMenu.HeaderMenuModel.IHeaderMenuItemModel>} items
         * @memberof HeaderMenu.HeaderMenuModel
         * @instance
         */
        HeaderMenuModel.prototype.items = $util.emptyArray;

        /**
         * Encodes the specified HeaderMenuModel message. Does not implicitly {@link HeaderMenu.HeaderMenuModel.verify|verify} messages.
         * @function encode
         * @memberof HeaderMenu.HeaderMenuModel
         * @static
         * @param {HeaderMenu.IHeaderMenuModel} m HeaderMenuModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HeaderMenuModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.items != null && m.items.length) {
                for (var i = 0; i < m.items.length; ++i)
                    $root.HeaderMenu.HeaderMenuModel.HeaderMenuItemModel.encode(m.items[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Encodes the specified HeaderMenuModel message, length delimited. Does not implicitly {@link HeaderMenu.HeaderMenuModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof HeaderMenu.HeaderMenuModel
         * @static
         * @param {HeaderMenu.IHeaderMenuModel} message HeaderMenuModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HeaderMenuModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HeaderMenuModel message from the specified reader or buffer.
         * @function decode
         * @memberof HeaderMenu.HeaderMenuModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {HeaderMenu.HeaderMenuModel} HeaderMenuModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HeaderMenuModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.HeaderMenu.HeaderMenuModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.items && m.items.length))
                        m.items = [];
                    m.items.push($root.HeaderMenu.HeaderMenuModel.HeaderMenuItemModel.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a HeaderMenuModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof HeaderMenu.HeaderMenuModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {HeaderMenu.HeaderMenuModel} HeaderMenuModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HeaderMenuModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        HeaderMenuModel.HeaderMenuItemModel = (function() {

            /**
             * Properties of a HeaderMenuItemModel.
             * @memberof HeaderMenu.HeaderMenuModel
             * @interface IHeaderMenuItemModel
             * @property {string|null} [name] HeaderMenuItemModel name
             * @property {string|null} [url] HeaderMenuItemModel url
             * @property {Array.<HeaderMenu.HeaderMenuModel.IHeaderMenuItemModel>|null} [items] HeaderMenuItemModel items
             * @property {string|null} [version] HeaderMenuItemModel version
             * @property {string|null} [code] HeaderMenuItemModel code
             * @property {string|null} [route] HeaderMenuItemModel route
             */

            /**
             * Constructs a new HeaderMenuItemModel.
             * @memberof HeaderMenu.HeaderMenuModel
             * @classdesc Represents a HeaderMenuItemModel.
             * @implements IHeaderMenuItemModel
             * @constructor
             * @param {HeaderMenu.HeaderMenuModel.IHeaderMenuItemModel=} [p] Properties to set
             */
            function HeaderMenuItemModel(p) {
                this.items = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * HeaderMenuItemModel name.
             * @member {string|null|undefined} name
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @instance
             */
            HeaderMenuItemModel.prototype.name = null;

            /**
             * HeaderMenuItemModel url.
             * @member {string|null|undefined} url
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @instance
             */
            HeaderMenuItemModel.prototype.url = null;

            /**
             * HeaderMenuItemModel items.
             * @member {Array.<HeaderMenu.HeaderMenuModel.IHeaderMenuItemModel>} items
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @instance
             */
            HeaderMenuItemModel.prototype.items = $util.emptyArray;

            /**
             * HeaderMenuItemModel version.
             * @member {string|null|undefined} version
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @instance
             */
            HeaderMenuItemModel.prototype.version = null;

            /**
             * HeaderMenuItemModel code.
             * @member {string|null|undefined} code
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @instance
             */
            HeaderMenuItemModel.prototype.code = null;

            /**
             * HeaderMenuItemModel route.
             * @member {string|null|undefined} route
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @instance
             */
            HeaderMenuItemModel.prototype.route = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * HeaderMenuItemModel _name.
             * @member {"name"|undefined} _name
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @instance
             */
            Object.defineProperty(HeaderMenuItemModel.prototype, "_name", {
                get: $util.oneOfGetter($oneOfFields = ["name"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * HeaderMenuItemModel _url.
             * @member {"url"|undefined} _url
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @instance
             */
            Object.defineProperty(HeaderMenuItemModel.prototype, "_url", {
                get: $util.oneOfGetter($oneOfFields = ["url"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * HeaderMenuItemModel _version.
             * @member {"version"|undefined} _version
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @instance
             */
            Object.defineProperty(HeaderMenuItemModel.prototype, "_version", {
                get: $util.oneOfGetter($oneOfFields = ["version"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * HeaderMenuItemModel _code.
             * @member {"code"|undefined} _code
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @instance
             */
            Object.defineProperty(HeaderMenuItemModel.prototype, "_code", {
                get: $util.oneOfGetter($oneOfFields = ["code"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * HeaderMenuItemModel _route.
             * @member {"route"|undefined} _route
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @instance
             */
            Object.defineProperty(HeaderMenuItemModel.prototype, "_route", {
                get: $util.oneOfGetter($oneOfFields = ["route"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified HeaderMenuItemModel message. Does not implicitly {@link HeaderMenu.HeaderMenuModel.HeaderMenuItemModel.verify|verify} messages.
             * @function encode
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @static
             * @param {HeaderMenu.HeaderMenuModel.IHeaderMenuItemModel} m HeaderMenuItemModel message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HeaderMenuItemModel.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(10).string(m.name);
                if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                    w.uint32(18).string(m.url);
                if (m.items != null && m.items.length) {
                    for (var i = 0; i < m.items.length; ++i)
                        $root.HeaderMenu.HeaderMenuModel.HeaderMenuItemModel.encode(m.items[i], w.uint32(26).fork()).ldelim();
                }
                if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                    w.uint32(34).string(m.version);
                if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                    w.uint32(42).string(m.code);
                if (m.route != null && Object.hasOwnProperty.call(m, "route"))
                    w.uint32(50).string(m.route);
                return w;
            };

            /**
             * Encodes the specified HeaderMenuItemModel message, length delimited. Does not implicitly {@link HeaderMenu.HeaderMenuModel.HeaderMenuItemModel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @static
             * @param {HeaderMenu.HeaderMenuModel.IHeaderMenuItemModel} message HeaderMenuItemModel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HeaderMenuItemModel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a HeaderMenuItemModel message from the specified reader or buffer.
             * @function decode
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {HeaderMenu.HeaderMenuModel.HeaderMenuItemModel} HeaderMenuItemModel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HeaderMenuItemModel.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.HeaderMenu.HeaderMenuModel.HeaderMenuItemModel();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.name = r.string();
                        break;
                    case 2:
                        m.url = r.string();
                        break;
                    case 3:
                        if (!(m.items && m.items.length))
                            m.items = [];
                        m.items.push($root.HeaderMenu.HeaderMenuModel.HeaderMenuItemModel.decode(r, r.uint32()));
                        break;
                    case 4:
                        m.version = r.string();
                        break;
                    case 5:
                        m.code = r.string();
                        break;
                    case 6:
                        m.route = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Decodes a HeaderMenuItemModel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof HeaderMenu.HeaderMenuModel.HeaderMenuItemModel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {HeaderMenu.HeaderMenuModel.HeaderMenuItemModel} HeaderMenuItemModel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HeaderMenuItemModel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            return HeaderMenuItemModel;
        })();

        return HeaderMenuModel;
    })();

    return HeaderMenu;
})();

module.exports = $root;
