export default {
  RepairerQuote: 'Repairer Quote',
  MiscQuote: 'MISC Quote',
  PdrQuote: 'PDR Quote',
  TowingQuote: 'Towing Quote',
  Accounting: 'Accounting',
  OnlineParts: 'Online Parts',
  Imaging: 'Imaging',
  Management: 'Management',
  BookingIn: 'Booking-In',
  Sms: 'SMS',
  OtherIndustries: 'Other Industries',
  MYOB: 'MYOB',
  MoneyWorks: 'MoneyWorks'
}
