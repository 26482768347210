import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
import ProtoBufApi from '@/services/protobuf-api'

export default {
  async getItem(isCustom, id, hash) {
    const route = (isCustom ? '/itemlabourcustoms/' : '/itemlabourstandards/') + id
    const url = !hash ? route : route + `?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getPart(isCustom, id, hash) {
    const route = (isCustom ? '/itempartcustoms/' : '/itempartstandards/') + id
    const url = !hash ? route : route + `?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getButterflyDropdownProto(filter) {
    const serialised = this.serialiseButterflyDropdownFilter(filter)
    const result = await ProtoBufApi(`/items/protobuf/butterfly/listcombo/${serialised}`)
    const proto = require('../../assets/proto/item/ItemButterflyDropdown.proto')
    const message = proto.ItemButterflyDropdown.ItemButterflyDropdownModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.assets
  },
  serialiseButterflyDropdownFilter(filter) {
    const entityProto = require('../../assets/proto/item/ItemButterflyDropdownFilter.proto')
    const filterMessage = entityProto.ItemButterflyDropdownFilter.ItemButterflyDropdownFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseItemFilter(filter)
    const url = `/itemlabourcustoms/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseItemFilter(filter) {
    const entityProto = require('../../assets/proto/item/ItemFilter.proto')
    const filterMessage = entityProto.ItemFilter.ItemFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  putItem(entity, deepDiff) {
    if (deepDiff) {
      entity.deepDiff = JSON.stringify(deepDiff)
    }
    const url = entity.itemType === 'PRT' ? `/itempartcustoms/${entity.itemPartCustomId}` : `/itemlabourcustoms/${entity.itemLabourCustomId}`
    return FqApi.put(url, entity)
  },
  // Create Brand New Item
  postItemNew(entity) {
    return FqApi.post(entity.itemType === 'PRT' ? '/itempartcustoms/new' : '/itemlabourcustoms/new', entity)
  },
  // Create Item from Template
  postItem(entity) {
    return FqApi.post(entity.itemType === 'PRT' ? '/itempartcustoms/' : '/itemlabourcustoms/', entity)
  },
  deleteItem(itemType, recordId) {
    return FqApi.delete((itemType === 'PRT' ? '/itempartcustoms/' : '/itemlabourcustoms/') + recordId)
  },
  async getNewEntity(itemType) {
    const result = itemType == 'PRT' ? await FqApi.get('/itempartcustoms/new') : await FqApi.get('/itemlabourcustoms/new')
    return result.data
  },

  async getAssetEmail(assetId, assetType) {
    const url = `/items/getassetemail/${assetId}/${assetType}`
    const result = await FqApi.get(url)
    return result.data
  }
}
