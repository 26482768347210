import FqApi from '@/services/fq-api'
// import ProtoBufApi from '@/services/protobuf-api'
// import AuditTrailApi from '@/services/audittrail-api'
// import { GlAccountTypes } from '@/enums'

export default {
  async getEntity(id) {
    const result = await FqApi.get(`/receiptpayment?id=${id}`)
    return result.data
  },
  // async getEntitySummaries(filter) {
  //   const serialised = this.serialiseReceiptEntryFilter(filter)
  //   const url = `/receiptentry/list/${serialised}`
  //   const result = await FqApi.get(url)
  //   return result.data
  // },
  async getEntityByPamentId(id) {
    const result = await FqApi.get(`/receiptpayment/${id}`)
    return result.data
  },
  async getGLBankAccountsDropdownProto(id) {
    const result = await FqApi.get(`/receiptentry/getGlBankAccount?id=${id}`)
    return result.data
  },
  putReceiptPayment(ReceiptPayments, deepdiff){
    if(ReceiptPayments.customerId != null){
      return FqApi.put(`/receiptpayment/${ReceiptPayments.customerId}`, ReceiptPayments)
    }
    else{
      return FqApi.put(`/receiptpayment/${ReceiptPayments.insurerId}`, ReceiptPayments)
    }
  }
}