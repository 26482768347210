import FactorInvoiceService from './FactorInvoiceService'
import StoreUtil from '@/store/utils'
import DeepDiff from 'deep-diff'
import { md5 } from '@/components/crypto'
import _cloneDeep from 'lodash/cloneDeep'
import ReceiptEntyRoutes from '../receiptentry/route-types'
import router from '@/router'

const tag = 'factorinvoice'

const state = {
  returnRoute: {},
  baseIndex: 0,
  currentIndex: 0,
  snapshots: []
}

const getters = {
  returnRoute: state => state.returnRoute,
  snapshots: state => state.snapshots,
  baseSnapshot: state => state.snapshots[state.baseIndex],
  currentSnapshot: state => state.snapshots[state.currentIndex],
  snapshotDiff: state => {
    if (state.snapshots[state.baseIndex] && state.snapshots[state.currentIndex]) {
      return DeepDiff.diff(state.snapshots[state.baseIndex], state.snapshots[state.currentIndex])
    } else {
      return null
    }
  }
}

const actions = {
  async getStoreItem({ commit }, id) {
    let entity = StoreUtil.getStorage(id, tag)
    if (!entity || (entity && !entity.isNew)) {
      const hash = entity ? md5(JSON.stringify(entity)) : ''
      const data = await FactorInvoiceService.getEntity(id, hash)
      if (data) {
        console.log('from WebApi')
        data.invoiceDate = data.invoiceDate.split('.')[1] ? data.invoiceDate.split('.')[0] + 'Z' : data.invoiceDate
        data.receipts = StoreUtil.getStorage(id, 'receiptentry')
        entity = data
      } else {
        console.log('from sessionStorage')
      }
    }
    //StoreUtil.setStorage(id, tag, entity)
    commit('saveInitialSnapshots', entity)
  },
  async editStoreItem({ commit }, id) {
    const entity = StoreUtil.getStorage(id, tag) || (await FactorInvoiceService.getEntity(id, ''))
    StoreUtil.setStorage(entity.invoiceId, tag, entity)
    commit('saveInitialSnapshots', entity)
    router.push({name: ReceiptEntyRoutes.FactorInvoiceDetail.name, params:{id: id, readOnly: true}})
  },
  async addStoreItem({ commit }, id) {
    let invoices = StoreUtil.getStorage(id, 'receiptentry')
    let invoiceIds
    invoiceIds = invoices.map(x => x.invoiceID)
    const entity = await FactorInvoiceService.GetNewCreditorInvoice(id, invoiceIds)
    const utcDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString()
    entity.invoiceDate = utcDate.split('.')[0] + 'Z'
    let receipts = StoreUtil.getStorage(id, 'receiptentry').filter(i => i.invoiceType !== 8)
    entity.receipts = receipts
    StoreUtil.setStorage(id, tag, entity)
    await StoreUtil.setStorage(id, 'receiptentry', _cloneDeep(receipts))
    commit('saveInitialSnapshots', entity)
  },
  saveSnapshot({ commit }, entity) {
    commit('saveSnapshot', entity)
  },
  clearSnapshots({ commit }, id) {
    StoreUtil.removeStorage(id, tag)
    commit('clearSnapshots')
  },
  setReturnRoute({ commit }, route) {
    commit('setReturnRoute', route)
  }
}

const mutations = {
  saveInitialSnapshots(state, entity) {
    state.snapshots = []
    state.snapshots.splice(0, 1, entity)
    state.snapshots.splice(1, 1, entity)
    state.currentIndex = state.snapshots.length - 1
  },
  saveSnapshot(state, entity) {
    state.snapshots.splice(state.currentIndex, 1, entity)
  },
  clearSnapshots(state) {
    state.snapshots = []
    state.baseIndex = 0
    state.currentIndex = 0
  },
  setReturnRoute(state, route) {
    state.returnRoute = route
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
