import ContactService from './ContactService'
import ContactRoutes from './route-types'
import StoreUtil from '@/store/utils'
import DeepDiff from 'deep-diff'
import Guid from '@/components/Guid'

const tag = 'contact'

const state = {
  returnRoute: {},
  baseIndex: 0,
  currentIndex: 0,
  snapshots: []
}

const getters = {
  returnRoute: state => state.returnRoute,
  snapshots: state => state.snapshots,
  baseSnapshot: state => state.snapshots[state.baseIndex],
  currentSnapshot: state => state.snapshots[state.currentIndex],
  snapshotDiff: state => {
    if (state.snapshots[state.baseIndex] && state.snapshots[state.currentIndex]) {
      return DeepDiff.diff(state.snapshots[state.baseIndex], state.snapshots[state.currentIndex])
    } else {
      return null
    }
  }
}

const actions = {
  async getStoreItem({ commit }, id) {
    const entity = StoreUtil.getStorage(id, tag) || (await ContactService.getEntity(id, ''))
    StoreUtil.setStorage(entity.id, tag, entity)
    commit('saveInitialSnapshots', entity)
  },
  async editStoreItem({ commit }, id) {
    const entity = StoreUtil.getStorage(id, tag) || (await ContactService.getEntity(id, ''))
    StoreUtil.setStorage(entity.id, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(ContactRoutes.ContactListView.path, entity.id, false)
  },
  async addStoreItem({ commit }, payload) {
    /**
     * @param {ContactPayload} payload payload for creating new contact
     */
    const entity = await ContactService.getNewEntity(payload.firstname, payload.lastname)
    if (payload && payload.assetId && payload.assetType && payload.assetName) {
      const asset = {
        assetId: payload.assetId,
        assetType: payload.assetType,
        isDeleted: false,
        isNew: true,
        id: Guid.newGuid(),
        name: payload.assetName
      }
      entity.assets.push(asset)
    }

    StoreUtil.setStorage(entity.id, tag, entity)
    commit('saveInitialSnapshots', entity)
    if (payload.gotoRoute) {
      StoreUtil.routeToDetail(ContactRoutes.ContactListView.path, entity.id, true)
    }
  },
  saveSnapshot({ commit }, entity) {
    commit('saveSnapshot', entity)
  },
  clearSnapshots({ commit }, id) {
    StoreUtil.removeStorage(id, tag)
    commit('clearSnapshots')
  },
  setReturnRoute({ commit }, route) {
    commit('setReturnRoute', route)
  }
}

const mutations = {
  saveInitialSnapshots(state, entity) {
    state.snapshots = []
    state.snapshots.splice(0, 1, entity)
    state.snapshots.splice(1, 1, entity)
    state.currentIndex = state.snapshots.length - 1
  },
  saveSnapshot(state, entity) {
    state.snapshots.splice(state.currentIndex, 1, entity)
  },
  clearSnapshots(state) {
    state.snapshots = []
    state.baseIndex = 0
    state.currentIndex = 0
  },
  setReturnRoute(state, route) {
    state.returnRoute = route
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
