import OrmBatchingRoutes from './route-types'

const OrmBatchingListView = () => import(/* webpackChunkName: "ormbatching-chunk" */ './OrmBatchingListView.vue')
const OrmBatchingView = () => import(/* webpackChunkName: "ormbatching-chunk" */ './OrmBatchingView2.vue')
const OrmBatchingDetail = () => import(/* webpackChunkName: "ormbatching-chunk" */ './OrmBatchingDetail.vue')
const OrmBatchingAvailableQuotes = () => import(/* webpackChunkName: "ormbatching-chunk" */ './OrmBatchingAvailableQuotes.vue')
const OrmBatchingQuotes = () => import(/* webpackChunkName: "ormbatching-chunk" */ './OrmBatchingQuotes.vue')
const menuCode = 'A130'

// Get sessionStorage
// const getStorage = function(query) {
//   const sessionId = store.getters['userInfo/info'].sessionId
//   return sessionStorage.getItem(`${sessionId}|ormmessage|${query}`)
// }

export default [
  {
    path: OrmBatchingRoutes.OrmBatchingListView.path,
    name: OrmBatchingRoutes.OrmBatchingListView.name,
    component: OrmBatchingListView,
    meta: {
      id: menuCode,
      route: 'listing',
      title: 'ORM Batching',
      fkey: 'ormbatching|filter',
      report: '',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: OrmBatchingRoutes.OrmBatchingQuotes.path,
    name: '',
    component: OrmBatchingView,
    meta: {
      id: menuCode,
      route: 'detail',
      title: 'ORM Batching',
      isNew: false,
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    },
    children: [
      {
        path: '',
        name: OrmBatchingRoutes.OrmBatchingQuotes.name,
        component: OrmBatchingQuotes,
        meta: {
          id: menuCode,
          route: 'detail',
          title: 'Detail',
          isNew: false
        }
      },
      {
        path: OrmBatchingRoutes.OrmBatchingAvailableQuotes.path,
        name: OrmBatchingRoutes.OrmBatchingAvailableQuotes.name,
        component: OrmBatchingAvailableQuotes,
        meta: {
          id: menuCode,
          route: 'detail',
          title: 'Detail',
          isNew: false
        }
      },
      {
        path: OrmBatchingRoutes.OrmBatchingDetail.path,
        name: OrmBatchingRoutes.OrmBatchingDetail.name,
        component: OrmBatchingDetail,
        meta: {
          id: menuCode,
          route: 'draggable',
          title: 'Detail',
          isNew: false
        }
      }
    ]
  }
]
