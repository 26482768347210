import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntity(id, hash) {
    const url = !hash ? `/ormbatches/${id}` : `/ormbatches/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseOrmBatchFilter(filter) {
    const proto = require('../../assets/proto/orm/OrmBatchFilter.proto')
    const filterMessage = proto.OrmBatchFilter.OrmBatchFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseOrmBatchFilter(filter)
    const url = `/ormbatches/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getBatchQuoteSummaries(id) {
    const url = `/ormbatches/quotes/${id}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getBatchQuoteSummariesByIds(batchId, quoteIds) {
    let url = `/ormbatches/quotesbyids/${batchId}`
    const result = await FqApi.post(url, quoteIds)
    return result.data
  },
  async getBatchQuoteAvailableSummaries(batchId) {
    const url = `/ormbatches/quotes/available/${batchId}`
    const result = await FqApi.get(url)
    return result.data
  },
  putEntity(entity, deepdiff) {
    if (deepdiff) {
      entity.deepDiff = JSON.stringify(deepdiff)
    }
    return FqApi.put(`/ormbatches/${entity.batchId}`, entity)
  },
  async postEntity(entity) {
    return FqApi.post('/ormbatches', entity)
  },
  async getNewEntity() {
    const url = '/ormbatches/new/'
    const result = await FqApi.get(url)
    return result.data
  },
  async getActiveBatchIds(batchId) {
    const url = `/ormbatches/activebatchids/${batchId}`
    const result = await FqApi.get(url)
    return result.data
  }
}