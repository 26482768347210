import QuoteService from '@/views/quote/QuoteService'
import StoreUtil from '@/store/utils'
import QuoteRoutes from '@/views/quote/route-types'

const actions = {
    async addStoreItem({ commit }, quoteNo) {
        const tag = 'quote'
        const entity = await QuoteService.getNewEntity(quoteNo, false)
        StoreUtil.setStorage(entity.quoteId, tag, entity)
        commit('saveInitialSnapshots', entity)
        StoreUtil.routeToDetail(QuoteRoutes.QuoteListView.path, entity.quoteId, entity.isNew)
    }
}

const mutations = {
    saveInitialSnapshots(state, entity) {
        state.snapshots = []
        state.snapshots.splice(0, 1, entity)
        state.snapshots.splice(1, 1, entity)
        state.currentIndex = state.snapshots.length - 1
    }
}

export default {
    namespaced: true,
    actions,
    mutations
}
