export default {
  /**
   * Get the error from a response.
   *
   * @param {Response} response The Vue-resource Response that we will try to get errors from.
   */
  getError: function(response) {
    var responseText
    if (response instanceof TypeError) {
      responseText = response.message
    } else if (response.request.status) {
      if (response.request.status === 401) {
        return 'Unauthorized'
      }
    } else if (response) {
      responseText = response.data['error_description'] ? response.data.error_description : response.statusText
    }
    return responseText
  }
  // Axios way of handling error
  // if (error.response) {
  //     // The request was made and the server responded with a status code
  //     // that falls out of the range of 2xx
  //     console.log(error.response.data);
  //     console.log(error.response.status);
  //     console.log(error.response.headers);
  //   } else if (error.request) {
  //     // The request was made but no response was received
  //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //     // http.ClientRequest in node.js
  //     console.log(error.request);
  //   } else {
  //     // Something happened in setting up the request that triggered an Error
  //     console.log('Error', error.message);
  //   }
  //   console.log(error.config);
}

// Global String prototypes
// String.prototype.toPascalCase = function() {
//   return this.match(/[a-z]+/gi)
//     .map(function(word) {
//       return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
//     })
//     .join('')
// }

String.prototype.toPascalCase = function() {
  return this.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase()
  })
}

String.prototype.escapeXml = function() {
  return this.replace(/[<>&'"]/g, function(c) {
    switch (c) {
      case '<':
        return '&lt;'
      case '>':
        return '&gt;'
      case '&':
        return '&amp;'
      case '\'':
        return '&apos;'
      case '"':
        return '&quot;'
    }
  })
}
