const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const getCurrentMonthIndex = ()=> {
   return new Date().getMonth()
}

export const getRelativeMonths = () => {
    const currentMonthIndex = getCurrentMonthIndex()
    const res = months.slice(currentMonthIndex+1).concat(months.slice(0, currentMonthIndex+1))
    return res.map(x => x.toUpperCase())
}
export const getCorrectStagedMonthlyJobs = (jobs) => {
    // jobs : list of correct staged jobs
    // create a list of 12 months with 0 values
    let res = Array(12).fill(0)
    jobs.forEach(job => {
        const index = months.indexOf(job.month)
        // increment the jobs
        res[index] += job.totalJobs
    })

    const currentMonthIndex = getCurrentMonthIndex()
    return res.slice(currentMonthIndex+1).concat(res.slice(0, currentMonthIndex+1))
}

export const getWronglyStagedMonthlyJobs = (totaljobs, correctJobs) => {
    // totaljobs : list of all jobs
    // correctJobs : list of correct staged jobs
    // create a list of 12 months with 0 values
    let res = Array(12).fill(0)
    totaljobs.forEach((job) => {
        const monthIndex = months.indexOf(job.month)
        // increment jobs
        res[monthIndex] += job.totalJobs
    })

    const currentMonthIndex = getCurrentMonthIndex()
    const wrongStatedJobs = res.slice(currentMonthIndex+1).concat(res.slice(0, currentMonthIndex+1))

    // subtract the correct jobs from the total jobs
     return  wrongStatedJobs.map((val, index) => val - correctJobs[index])
}

export const dashboardTabs = {
    Today:0,
    Yesterday:1,
    ThisWeek:2
}