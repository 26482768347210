/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.PaymentEntryFilter = (function() {

    /**
     * Namespace PaymentEntryFilter.
     * @exports PaymentEntryFilter
     * @namespace
     */
    var PaymentEntryFilter = {};

    PaymentEntryFilter.PaymentEntryFilterModel = (function() {

        /**
         * Properties of a PaymentEntryFilterModel.
         * @memberof PaymentEntryFilter
         * @interface IPaymentEntryFilterModel
         * @property {string|null} [invoiceNo] PaymentEntryFilterModel invoiceNo
         * @property {string|null} [venName] PaymentEntryFilterModel venName
         * @property {string|null} [quoteNo] PaymentEntryFilterModel quoteNo
         * @property {number|null} [filterType] PaymentEntryFilterModel filterType
         * @property {string|null} [sortColumn] PaymentEntryFilterModel sortColumn
         * @property {string|null} [sortOrder] PaymentEntryFilterModel sortOrder
         * @property {number|null} [pageIndex] PaymentEntryFilterModel pageIndex
         * @property {number|null} [pageSize] PaymentEntryFilterModel pageSize
         */

        /**
         * Constructs a new PaymentEntryFilterModel.
         * @memberof PaymentEntryFilter
         * @classdesc Represents a PaymentEntryFilterModel.
         * @implements IPaymentEntryFilterModel
         * @constructor
         * @param {PaymentEntryFilter.IPaymentEntryFilterModel=} [p] Properties to set
         */
        function PaymentEntryFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PaymentEntryFilterModel invoiceNo.
         * @member {string|null|undefined} invoiceNo
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        PaymentEntryFilterModel.prototype.invoiceNo = null;

        /**
         * PaymentEntryFilterModel venName.
         * @member {string|null|undefined} venName
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        PaymentEntryFilterModel.prototype.venName = null;

        /**
         * PaymentEntryFilterModel quoteNo.
         * @member {string|null|undefined} quoteNo
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        PaymentEntryFilterModel.prototype.quoteNo = null;

        /**
         * PaymentEntryFilterModel filterType.
         * @member {number|null|undefined} filterType
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        PaymentEntryFilterModel.prototype.filterType = null;

        /**
         * PaymentEntryFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        PaymentEntryFilterModel.prototype.sortColumn = null;

        /**
         * PaymentEntryFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        PaymentEntryFilterModel.prototype.sortOrder = null;

        /**
         * PaymentEntryFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        PaymentEntryFilterModel.prototype.pageIndex = null;

        /**
         * PaymentEntryFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        PaymentEntryFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * PaymentEntryFilterModel _invoiceNo.
         * @member {"invoiceNo"|undefined} _invoiceNo
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        Object.defineProperty(PaymentEntryFilterModel.prototype, "_invoiceNo", {
            get: $util.oneOfGetter($oneOfFields = ["invoiceNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentEntryFilterModel _venName.
         * @member {"venName"|undefined} _venName
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        Object.defineProperty(PaymentEntryFilterModel.prototype, "_venName", {
            get: $util.oneOfGetter($oneOfFields = ["venName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentEntryFilterModel _quoteNo.
         * @member {"quoteNo"|undefined} _quoteNo
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        Object.defineProperty(PaymentEntryFilterModel.prototype, "_quoteNo", {
            get: $util.oneOfGetter($oneOfFields = ["quoteNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentEntryFilterModel _filterType.
         * @member {"filterType"|undefined} _filterType
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        Object.defineProperty(PaymentEntryFilterModel.prototype, "_filterType", {
            get: $util.oneOfGetter($oneOfFields = ["filterType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentEntryFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        Object.defineProperty(PaymentEntryFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentEntryFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        Object.defineProperty(PaymentEntryFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentEntryFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        Object.defineProperty(PaymentEntryFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PaymentEntryFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @instance
         */
        Object.defineProperty(PaymentEntryFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified PaymentEntryFilterModel message. Does not implicitly {@link PaymentEntryFilter.PaymentEntryFilterModel.verify|verify} messages.
         * @function encode
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @static
         * @param {PaymentEntryFilter.IPaymentEntryFilterModel} m PaymentEntryFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentEntryFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.invoiceNo != null && Object.hasOwnProperty.call(m, "invoiceNo"))
                w.uint32(10).string(m.invoiceNo);
            if (m.venName != null && Object.hasOwnProperty.call(m, "venName"))
                w.uint32(18).string(m.venName);
            if (m.quoteNo != null && Object.hasOwnProperty.call(m, "quoteNo"))
                w.uint32(26).string(m.quoteNo);
            if (m.filterType != null && Object.hasOwnProperty.call(m, "filterType"))
                w.uint32(32).int32(m.filterType);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(42).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(50).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(56).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(64).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified PaymentEntryFilterModel message, length delimited. Does not implicitly {@link PaymentEntryFilter.PaymentEntryFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @static
         * @param {PaymentEntryFilter.IPaymentEntryFilterModel} message PaymentEntryFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentEntryFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PaymentEntryFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {PaymentEntryFilter.PaymentEntryFilterModel} PaymentEntryFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentEntryFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.PaymentEntryFilter.PaymentEntryFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.invoiceNo = r.string();
                    break;
                case 2:
                    m.venName = r.string();
                    break;
                case 3:
                    m.quoteNo = r.string();
                    break;
                case 4:
                    m.filterType = r.int32();
                    break;
                case 5:
                    m.sortColumn = r.string();
                    break;
                case 6:
                    m.sortOrder = r.string();
                    break;
                case 7:
                    m.pageIndex = r.int32();
                    break;
                case 8:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a PaymentEntryFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof PaymentEntryFilter.PaymentEntryFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {PaymentEntryFilter.PaymentEntryFilterModel} PaymentEntryFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentEntryFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return PaymentEntryFilterModel;
    })();

    return PaymentEntryFilter;
})();

module.exports = $root;
