const state = {
  agent: {
    isActive: false,
    isScannerReady: false
  }
}

const getters = {
  isActive: state => state.agent.isActive,
  isScannerReady: state => state.agent.isScannerReady
}

const actions = {
  async setIsActive({ commit }, value) {
    commit('setIsActive', value)
  },
  async setIsScannerReady({ commit }, value) {
    commit('setIsScannerReady', value)
  }
}

const mutations = {
  setIsActive(state, value) {
    state.agent.isActive = value
  },
  setIsScannerReady(state, value) {
    state.agent.isScannerReady = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
