import FqApi from '@/services/fq-api'

export default {
  async getListing(filters) {
    return FqApi.get(`/contacts/list?filter=${filters}`)
  },
  getContact(contactId) {
    return FqApi.get(`/contacts?id=${contactId}`)
  },
  async getEntity(contactId) {
    const result = await FqApi.get(`/contacts?id=${contactId}`)
    return result.data
  },
  getNewContact(fname, lname) {
    let uri = '/contacts/new?q'

    if (fname && fname !== '') {
      uri += `&firstName=${fname}`
    }

    if (lname && lname !== '') {
      uri += `&lastName=${lname}`
    }

    return FqApi.get(uri)
  },
  async getNewEntity(fname, lname) {
    let uri = '/contacts/new?q'
    if (fname && fname !== '') {
      uri += `&firstName=${fname}`
    }
    if (lname && lname !== '') {
      uri += `&lastName=${lname}`
    }
    const result = await FqApi.get(uri)
    return result.data
  },
  getNewAssetContact(assetType, assetId) {
    return FqApi.get(`/contacts/newasset?assetType=${assetType}&assetId=${assetId}`)
  },

  getAssetTypes() {
    return FqApi.get('/contacts/assetTypes')
  },

  putContact(contact) {
    return FqApi.put(`/contacts?id=${contact.id}`, contact)
  },

  postContact(contact) {
    return FqApi.post('/contacts', contact)
  },

  getSalutations() {
    return FqApi.get('/contacts/salutations')
  },

  getContactTypes() {
    return FqApi.get('/contacts/contactTypes')
  },

  searchContactByName(key) {
    return FqApi.get('/contacts/searchname?key=' + key)
  },

  getContactByObjectId(assetFilter) {
    return FqApi.post('/contacts/object', assetFilter)
  },

  async assertUsed(entityId) {
    const result = await FqApi.get(`/contacts/assertused/${entityId}`)
    return result.data
  }
}
