import QuoteTemplateRoutes from './route-types'

const moduleId = 'A073'

const QuoteTemplateListView = () => import('./QuoteTemplateListView')
const QuoteTemplateView = () => import('./QuoteTemplateView')
const QuoteTemplateLabourView = () => import('./QuoteTemplateLabourView')
const QuoteTemplateMiscView = () => import('./QuoteTemplateMiscView')
const QuoteTemplateSubletView = () => import('./QuoteTemplateSubletView')

export default [
  {
    path: QuoteTemplateRoutes.QuoteTemplateListView.path,
    name: QuoteTemplateRoutes.QuoteTemplateListView.name,
    component: QuoteTemplateListView,
    meta: {
      id: moduleId,
      route: 'listing',
      title: 'Quote Template',
      name: 'Quote Template'
    }
  },
  {
    path: QuoteTemplateRoutes.QuoteTemplateView.path,
    name: '',
    component: QuoteTemplateView,
    meta: {
      id: moduleId,
      route: 'detail',
      title: 'Quote Template'
    },
    children: [
      {
        path: '',
        name: QuoteTemplateRoutes.QuoteTemplateLabourView.name,
        component: QuoteTemplateLabourView,
        meta: {
          id: moduleId,
          route: 'labour',
          title: 'Labour'
        }
      },
      {
        path: QuoteTemplateRoutes.QuoteTemplateMiscView.path,
        name: QuoteTemplateRoutes.QuoteTemplateMiscView.name,
        component: QuoteTemplateMiscView,
        meta: {
          id: moduleId,
          route: 'misc',
          title: 'Misc'
        }
      },
      {
        path: QuoteTemplateRoutes.QuoteTemplateSubletView.path,
        name: QuoteTemplateRoutes.QuoteTemplateSubletView.name,
        component: QuoteTemplateSubletView,
        meta: {
          id: moduleId,
          route: 'sublet',
          title: 'Sublet'
        }
      }
    ]
  }
]
