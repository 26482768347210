import routerTypes from './route-types'
//import PrintPreviewRoutes from './route-types'

// Lazy-load components. Webpack will split each into individual file
const DebtorAgingReportView = r => require.ensure([], () => r(require('./DebtorAgingReportView.vue')), 'debtoragingreport-chunk')
//const PrintPreviewExtended = r => require.ensure([], () => r(require('@/components/printpreview/PrintPreviewExtended.vue')), 'printpreviewextended-chunk')

export default [
  {
    path: routerTypes.DebtorAgingReportView.path,
    name: routerTypes.DebtorAgingReportView.name,
    component: DebtorAgingReportView,
    meta: {
      id: 'A014',
      route: 'listing',
      title: 'Debtor Aging Report',
      fkey: 'debtoragingreport|filter',
      report: 'AT_ARDetailedAgingReportV2',
      reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
    }
  }
]
