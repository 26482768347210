import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {
  async getNewEntity() {
    const result = await FqApi.get('/paymententries/advancepayment/new')
    return result.data
  },
  async getNewEntityPaymentEntry(vendorId) {
    const url = `/paymententries/new/${vendorId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialisePaymentEntryFilter(filter)
    const url = `paymententries/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntity(id) {
    const url = `/paymententries/advancepayment/${id}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntityPaymentEntry(id) {
    const url = `/paymententries/${id}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getPaymentEntryByPaymentId(id) {
    const url = `/paymententries/invoicebypaymentid?paymentid=${id}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getAdvancePaymentByInvoiceId(id) {
    const url = `/paymententries/advancepayment/paymentbyinvoiceid?invoiceid=${id}`
    const result = await FqApi.get(url)
    return result.data
  },
  async GetTotalAdvancePaymentByVendor(id) {
    const result = await FqApi.get(`/paymententries/advancepayment/vendor?vendorId=${id}`)
    return result.data
  },
  async postEntity(entity) {
    return FqApi.post('/paymententries/advancepayment/advancepayment', entity)
  },
  async postEntityPaymentEntry(entity) {
    return FqApi.post('/paymententries', entity)
  },
  serialisePaymentEntryFilter(filter) {
    const entityProto = require('../../assets/proto/paymententry/PaymentEntryFilter.proto')
    const filterMessage = entityProto.PaymentEntryFilter.PaymentEntryFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getGlBankAccountsDropdown(companyId) {
    const result = await FqApi.get(`/paymententries/advancepayment/getGlBankAccount?id=${companyId}`)
    return result.data
  }
}
