import FqApi from '@/services/fq-api'

const baseUrl = '/insurers'

export default {
  async getEntity(insurerId, hash) {
    const url = !hash ? `/insurers/${insurerId}` : `${baseUrl}/${insurerId}?hash=${hash}`
    const response = await FqApi.get(url)
    return response.data
  },
  async getNewEntity() {
    const result = await FqApi.get(`${baseUrl}/new`)
    return result.data
  },
  async putInsurer(insurer, deepdiff) {
    if (deepdiff) {
      insurer.deepDiff = JSON.stringify(deepdiff)
    }
    return await FqApi.put(`/insurers/${insurer.insurerId}`, insurer)
  },
  async postInsurer(insurer) {
    return await FqApi.post('/insurers', insurer)
  },
  async getArnieBrand() {
    const result = await FqApi.get('/settings/arniebrands')
    return result.data.list
  }
}