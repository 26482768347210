import FqApi from '@/services/fq-api'
import ProtoBufApi from '@/services/protobuf-api'
import ProtoBuf from 'protobufjs'

export default {
  getRecurringRemark(searchKey) {
    const uri = '/recurringremarks/list'

    // if (searchKey && searchKey !== '') {
    //   uri = `${uri}?searchKey=${searchKey}`
    // }
    const filterObj = {
      remark: searchKey,
      active: 1,
      sortColumn: '',
      sortOrder: 'asc',
      pageIndex: 1,
      pageSize: 999
    }
    return FqApi.post(uri, filterObj)
  },
  async getEntity(id, hash) {
    const url = !hash ? `/recurringremarks/${id}` : `/recurringremarks/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = encodeURIComponent(this.serialiseRecurringRemarkFilter(filter))
    const url = `/recurringremarks/list?filter=${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getNewEntity() {
    const result = await FqApi.get('/recurringremarks/new')
    return result.data
  },
  postRecurringRemark(entity) {
    if (entity.id != null) {
      return FqApi.post('/recurringremarks', entity)
    } else {
      const newRemark = {
        remark: entity,
        isActive: true
      }
      return FqApi.post('/recurringremarks', newRemark)
    }
  },
  putRecurringRemark(entity, deepdiff) {
    if (deepdiff) {
      entity.deepDiff = JSON.stringify(deepdiff)
    }
    return FqApi.put(`/recurringremarks/${entity.id}`, entity)
  },
  deleteRecurringRemarks(id) {
    return FqApi.delete(`/recurringremarks?id=${id}`)
  },
  async getRemarks(searchKey) {
    const filter = {
      remark: searchKey,
      active: 1,
      sortColumn: '',
      sortOrder: 'asc',
      pageIndex: 1,
      pageSize: 999
    }
    const serialised = this.serialiseRecurringRemarkFilter(filter)
    const result = await ProtoBufApi.get(`/recurringremarks/protobuf/list/${serialised}`)
    const proto = require('../../assets/proto/recurringremark/RecurringRemarkSummary.proto')
    const message = proto.RecurringRemarkSummary.RecurringRemarkSummaryModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.remarks
  },
  serialiseRecurringRemarkFilter(filter) {
    const entityProto = require('../../assets/proto/recurringremark/RecurringRemarkFilter.proto')
    const filterMessage = entityProto.RecurringRemarkFilter.RecurringRemarkFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async assertRemarkExists(id, name) {
    if (name) {
      const result = await FqApi.get(`/recurringremarks/assertexists/${id}?name=${name}`)
      return result.data
    } else {
      return false
    }
  }
}
