import * as classHelper from '../../classHelper'

export default class JobInvoicedYTDModel {
  constructor(jobInvoicedYTDModel = null) {
    this.monthName = null
		this.month = 0
    this.year = 0
		this.fiscalMonthOrder = 0
		this.workingDays = 0
		this.monthlyTarget = 0
		this.totalJobs = 0
		this.totalInvoices = 0
		this.rrTotal = 0
		this.repairTotal = 0
		this.paintTotal = 0
		this.partTotal = 0
		this.miscTotal = 0
		this.subletTotal = 0
		this.otherTotal = 0
		this.totalExGst = 0
		this.totalIncGst = 0
		this.rowNumber = 0
		this.totalRows = 0
    if (jobInvoicedYTDModel !== null) {
      classHelper.shallowCopyValues(this, jobInvoicedYTDModel)
    }
  }
}