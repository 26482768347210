/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.OtherLabourFilter = (function() {

    /**
     * Namespace OtherLabourFilter.
     * @exports OtherLabourFilter
     * @namespace
     */
    var OtherLabourFilter = {};

    OtherLabourFilter.OtherLabourFilterModel = (function() {

        /**
         * Properties of an OtherLabourFilterModel.
         * @memberof OtherLabourFilter
         * @interface IOtherLabourFilterModel
         * @property {string|null} [itemNumber] OtherLabourFilterModel itemNumber
         * @property {string|null} [description] OtherLabourFilterModel description
         * @property {string|null} [category] OtherLabourFilterModel category
         * @property {number|null} [active] OtherLabourFilterModel active
         * @property {string|null} [sortColumn] OtherLabourFilterModel sortColumn
         * @property {string|null} [sortOrder] OtherLabourFilterModel sortOrder
         * @property {number|null} [pageIndex] OtherLabourFilterModel pageIndex
         * @property {number|null} [pageSize] OtherLabourFilterModel pageSize
         */

        /**
         * Constructs a new OtherLabourFilterModel.
         * @memberof OtherLabourFilter
         * @classdesc Represents an OtherLabourFilterModel.
         * @implements IOtherLabourFilterModel
         * @constructor
         * @param {OtherLabourFilter.IOtherLabourFilterModel=} [p] Properties to set
         */
        function OtherLabourFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * OtherLabourFilterModel itemNumber.
         * @member {string|null|undefined} itemNumber
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        OtherLabourFilterModel.prototype.itemNumber = null;

        /**
         * OtherLabourFilterModel description.
         * @member {string|null|undefined} description
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        OtherLabourFilterModel.prototype.description = null;

        /**
         * OtherLabourFilterModel category.
         * @member {string|null|undefined} category
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        OtherLabourFilterModel.prototype.category = null;

        /**
         * OtherLabourFilterModel active.
         * @member {number|null|undefined} active
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        OtherLabourFilterModel.prototype.active = null;

        /**
         * OtherLabourFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        OtherLabourFilterModel.prototype.sortColumn = null;

        /**
         * OtherLabourFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        OtherLabourFilterModel.prototype.sortOrder = null;

        /**
         * OtherLabourFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        OtherLabourFilterModel.prototype.pageIndex = null;

        /**
         * OtherLabourFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        OtherLabourFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * OtherLabourFilterModel _itemNumber.
         * @member {"itemNumber"|undefined} _itemNumber
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        Object.defineProperty(OtherLabourFilterModel.prototype, "_itemNumber", {
            get: $util.oneOfGetter($oneOfFields = ["itemNumber"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OtherLabourFilterModel _description.
         * @member {"description"|undefined} _description
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        Object.defineProperty(OtherLabourFilterModel.prototype, "_description", {
            get: $util.oneOfGetter($oneOfFields = ["description"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OtherLabourFilterModel _category.
         * @member {"category"|undefined} _category
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        Object.defineProperty(OtherLabourFilterModel.prototype, "_category", {
            get: $util.oneOfGetter($oneOfFields = ["category"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OtherLabourFilterModel _active.
         * @member {"active"|undefined} _active
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        Object.defineProperty(OtherLabourFilterModel.prototype, "_active", {
            get: $util.oneOfGetter($oneOfFields = ["active"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OtherLabourFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        Object.defineProperty(OtherLabourFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OtherLabourFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        Object.defineProperty(OtherLabourFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OtherLabourFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        Object.defineProperty(OtherLabourFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OtherLabourFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @instance
         */
        Object.defineProperty(OtherLabourFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified OtherLabourFilterModel message. Does not implicitly {@link OtherLabourFilter.OtherLabourFilterModel.verify|verify} messages.
         * @function encode
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @static
         * @param {OtherLabourFilter.IOtherLabourFilterModel} m OtherLabourFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OtherLabourFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.itemNumber != null && Object.hasOwnProperty.call(m, "itemNumber"))
                w.uint32(10).string(m.itemNumber);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(18).string(m.description);
            if (m.category != null && Object.hasOwnProperty.call(m, "category"))
                w.uint32(26).string(m.category);
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(32).int32(m.active);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(42).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(50).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(56).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(64).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified OtherLabourFilterModel message, length delimited. Does not implicitly {@link OtherLabourFilter.OtherLabourFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @static
         * @param {OtherLabourFilter.IOtherLabourFilterModel} message OtherLabourFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OtherLabourFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OtherLabourFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {OtherLabourFilter.OtherLabourFilterModel} OtherLabourFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OtherLabourFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.OtherLabourFilter.OtherLabourFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.itemNumber = r.string();
                    break;
                case 2:
                    m.description = r.string();
                    break;
                case 3:
                    m.category = r.string();
                    break;
                case 4:
                    m.active = r.int32();
                    break;
                case 5:
                    m.sortColumn = r.string();
                    break;
                case 6:
                    m.sortOrder = r.string();
                    break;
                case 7:
                    m.pageIndex = r.int32();
                    break;
                case 8:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes an OtherLabourFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof OtherLabourFilter.OtherLabourFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {OtherLabourFilter.OtherLabourFilterModel} OtherLabourFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OtherLabourFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return OtherLabourFilterModel;
    })();

    return OtherLabourFilter;
})();

module.exports = $root;
