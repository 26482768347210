import { ComponentRoute } from '@/classes'

const InsurerRoutes = {
  InsurerListView: new ComponentRoute('/insurers', 'InsurerListView'),
  InsurerView: new ComponentRoute('/insurers/:insurerId', 'InsurerView'),
  InsurerDetail: new ComponentRoute('/insurers/:insurerId', 'InsurerDetail'),
  InsurerRatesMarkups: new ComponentRoute('rates', 'InsurerRatesMarkups'),
  InsurerAddress: new ComponentRoute('address', 'InsurerAddress'),
  InsurerPhoneEmail: new ComponentRoute('phoneemail', 'InsurerPhoneEmail'),
  InsurerConsumables: new ComponentRoute('consumables', 'InsurerConsumables'),
  InsurerLoadingValues: new ComponentRoute('loadingvalues', 'InsurerLoadingValues'),
  InsurerPaintMaterials: new ComponentRoute('paintmaterials', 'InsurerPaintMaterials'),
  InsurerSpecialRates: new ComponentRoute('specialrates', 'InsurerSpecialRates'),
  InsurerAutoItems: new ComponentRoute('autoitems', 'InsurerAutoItems'),
  InsurerContacts: new ComponentRoute('contacts', 'InsurerContacts')
}

export default InsurerRoutes
