import WcfApi from '@/services/fq-wcf-api'
import ProtoBufApi from '@/services/protobuf-api'

export default {
  async getNav() {
    var protoDefinition = require('../assets/proto/HeaderMenu.proto')
    var headerMessage = protoDefinition.HeaderMenu.HeaderMenuModel
    const result = await ProtoBufApi.get('/access/header')
    const rs = headerMessage.decode(new Uint8Array(result.data))
    return rs.items
  },
  async getCachedUserInfo() {
    var protoDefinition = require('../assets/proto/UserInfoModel.proto')
    var message = protoDefinition.User.UserInfoModel
    const result = await ProtoBufApi.get('/access/userinfo')
    const rs = message.decode(new Uint8Array(result.data))
    return rs
  },
  async getLicenceData() {
    var protoDefinition = require('../assets/proto/LicenseDataModel.proto')
    var message = protoDefinition.Access.LicenseDataModel
    const result = await ProtoBufApi.get('/access/licensedata')
    const rs = message.decode(new Uint8Array(result.data))
    return rs
  },
  async getAccess(accessId, route) {
    var protoDefinition = require('../assets/proto/UserAccessModel.proto')
    var message = protoDefinition.UserAccess.UserAccessModel
    const result = await ProtoBufApi.get(`/access/${accessId}/${route}`)
    const rs = message.decode(new Uint8Array(result.data))
    return rs
  },
  signOut() {
    return WcfApi.post('/AuthenticationService/AuthenticationService.svc/rh/SignOut')
  },
  getRedirectProto(returnUrl) {
    const protoDefinition = require('../assets/proto/NavParams.proto')
    const navParamsModel = protoDefinition.NavParams.NavParamsModel
    var navProto = navParamsModel.encode({ returnUrl: returnUrl }).finish()

    return btoa(navProto)
  }
}
