import FqApi from '@/services/fq-api'
import ProtoBufApi from '@/services/protobuf-api'
import { GlAccountTypes } from '@/enums'
import ProtoBuf from 'protobufjs'

export default {
  async getListing(filters) {
    return FqApi.get(`/vendors/list/${filters}`)
  },

  async getEntity(id) {
    const rs = await FqApi.get(`/vendors?id=${id}`)
    return rs.data
  },

  async postEntity(entity) {
    return FqApi.post('/vendors', entity)
  },

  async putEntity(entity, deepdiff) {
    if (deepdiff) {
      entity.deepdif = JSON.stringify(deepdiff)
    }

    return FqApi.put(`/vendors?id=${entity.id}`, entity)
  },

  async getNewEntity() {
    const res = await FqApi.get('/vendors/new')
    return res.data
  },

  async searchVendorByName(key) {
    const result = await FqApi.get('/vendors/searchname?key=' + key)
    return result
  },

  async getVendorDropdownProto(filter) {
    const serialised = this.serialiseVendorDropdownFilter(filter)
    const result = await ProtoBufApi(`/vendors/protobuf/listcombo/${serialised}`)
    const proto = require('../../assets/proto/vendor/VendorDropdown.proto')
    const message = proto.VendorDropdown.VendorDropdownModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.assets
  },

  serialiseVendorDropdownFilter(filter) {
    const entityProto = require('../../assets/proto/vendor/VendorDropdownFilter.proto')
    const filterMessage = entityProto.VendorDropdownFilter.VendorDropdownFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },

  async assertUsed(entityId) {
    const result = await FqApi.get(`/vendors/assertused/${entityId}`)
    return result.data
  },

  async assertAbnUsed(entityId, abn) {
    const result = await FqApi.get(`/vendors/assertabnused/${entityId}?abn=${abn}`)
    return result.data
  },
  async getGlAccountListComboProto() {
    const result = await ProtoBufApi(
      `/protobuf/glaccounts/listcombo?glTypes=${GlAccountTypes.CostOfSales}&glTypes=${GlAccountTypes.Expenses}&glTypes=${GlAccountTypes.Assets}`
    )
    const glAccountProto = require('../../assets/proto/listcombo/GlAccount.proto')
    const accountMessage = await glAccountProto.GlAccount.GlAccountListComboModel
    const entity = accountMessage.decode(new Uint8Array(result.data))
    return entity.glAccounts
  },
  async getAbn(entityId) {
    const result = await FqApi.get(`/vendors/getAbn?id=${entityId}`)
    return result.data
  },
  async getGlAccount(entityId) {
    const result = await FqApi.get(`/vendors/getGlAccount?id=${entityId}`)
    return result.data
  },
  async getVendorDropdownProto2(filter) {
    const serialised = this.serialiseVendorDropdownFilter2(filter)
    const result = await ProtoBufApi(`/vendors/protobuf/listcombo2/${serialised}`)
    const proto = require('../../assets/proto/vendor/VendorDropdown2.proto')
    const message = proto.VendorDropdown2.VendorDropdownModel2
    const entity = message.decode(new Uint8Array(result.data))
    return entity.vendors
  },
  serialiseVendorDropdownFilter2(filter) {
    const entityProto = require('../../assets/proto/vendor/VendorDropdownFilter2.proto')
    const filterMessage = entityProto.VendorDropdownFilter2.VendorDropdownFilterModel2
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  }
}
