export default class QuoteImageMetaModel {
  constructor() {
    this.quoteImageId = null
    this.quoteId = null
    this.quoteVersion = null
    this.deleted = false
    this.title = ''
    this.fileName = ''
    this.annotation = ''
    this.statusId = 1
    this.referenceId = ''
    this.isSelected = false
  }

  static createFromQuoteImage(quoteImage) {
    const newMeta = new QuoteImageMetaModel()
    newMeta.quoteImageId = quoteImage.quoteImageId
    newMeta.quoteId = quoteImage.quoteId
    newMeta.quoteVersion = quoteImage.quoteVersion
    newMeta.deleted = quoteImage.deleted
    newMeta.title = quoteImage.title
    newMeta.fileName = quoteImage.fileName
    newMeta.annotation = quoteImage.annotation
    newMeta.statusId = quoteImage.statusId
    newMeta.referenceId = quoteImage.referenceId
    newMeta.isSelected = quoteImage.isSelected
    return newMeta
  }
}