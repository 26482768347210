import Circle9 from './Circle9'
import DoubleBounce from './DoubleBounce'
import LetterCube from './LetterCube'
import Circle8 from './Circle8'
import Socket from './Socket'
import Spinner from './Spinner'

const SpinnerList = ['Circle9', 'DoubleBounce', 'LetterCube', 'Circle8', 'Socket', 'Spinner']

// To be used in <component></component> template
const RandomSpinner = SpinnerList[Math.floor(Math.random() * SpinnerList.length)]

export { Circle9, DoubleBounce, LetterCube, Circle8, Socket, Spinner, RandomSpinner }
