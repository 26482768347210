import routeTypes from './route-types'
import store from '@/store'
import ContactComponentListing from '@/views/contact'

const CustomerView = r => require.ensure([], () => r(require('./CustomerView.vue')), 'customer-chunk')
const CustomerDetail = r => require.ensure([], () => r(require('./CustomerDetail.vue')), 'customer-chunk')
const CustomerPhoneEmail = r => require.ensure([], () => r(require('./CustomerPhoneEmail.vue')), 'customer-chunk')
const CustomerAddress = r => require.ensure([], () => r(require('./CustomerAddress.vue')), 'customer-chunk')
const CustomerListView = r => require.ensure([], () => r(require('./CustomerListView.vue')), 'customerlist-chunk')

// Get sessionStorage
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|customer|${query}`)
}

export default [
  {
    path: routeTypes.CustomerListView.path,
    name: routeTypes.CustomerListView.name,
    component: CustomerListView,
    meta: {
      id: 'A016',
      title: 'Customers',
      route: 'listing',
      fkey: 'customer|filter',
      report: 'rptCustomerList',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.CustomerDetail.path,
    component: CustomerView,
    props: route => ({
      isNew: route.query.mode === 'new' || route.meta.isNew || getStorage('isNew') === 'true',
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl'),
      name: route.query.name || route.meta.name || getStorage('name')
    }),
    meta: {
      id: 'A016',
      title: 'Customer',
      route: 'detail'
    },
    query: {
      filter: ''
    },
    children: [
      {
        path: '',
        name: routeTypes.CustomerDetail.name,
        component: CustomerDetail,
        meta: {
          id: 'A016',
          title: 'Detail',
          report: 'rptTableCustomerDetails',
          reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
        }
      },
      {
        path: routeTypes.CustomerPhoneEmail.path,
        name: routeTypes.CustomerPhoneEmail.name,
        component: CustomerPhoneEmail,
        props: {
          isTiled: true
        },
        meta: {
          id: 'A016',
          title: 'Phones / Emails'
        }
      },
      {
        path: routeTypes.CustomerAddress.path,
        name: routeTypes.CustomerAddress.name,
        component: CustomerAddress,
        props: {
          isTiled: true
        },
        meta: {
          id: 'A016',
          title: 'Addresses'
        }
      },
      {
        path: routeTypes.CustomerContact.path,
        name: routeTypes.CustomerContact.name,
        component: ContactComponentListing,
        props: {
          isTiled: true
        },
        meta: {
          id: 'A016',
          title: 'Contacts'
        }
      }
    ]
  }
]
