import FqApi from '@/services/fq-api'

export default {
  async getEntity(id, hash) {
    const url = !hash ? `/factorinvoices/${id}` : `/factorinvoice/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getGLBankAccountsDropdownProto(id) {
    const result = await FqApi.get(`/receiptentry/getGlBankAccount?id=${id}`)
    return result.data
  },
  async GetNewCreditorInvoice(id,invoiceIds) {
    const result = await FqApi.get(`/factorinvoices/new/${id}?invoiceIds=${invoiceIds}`)
    return result.data
  },
  async postEntity(entity) {
    return FqApi.post('/factorinvoices', entity)
  }
}