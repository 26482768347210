import router from '@/router'
import store from '@/store'

const routeToDetail = function(path, id, isNew = false, query) {
  const detailRoute = router.resolve(`${path}/${id}`)
  const newMeta = isNew ? Object.assign(detailRoute.route.meta, { isNew: true }) : detailRoute.route.meta
  router.push({
    path: detailRoute.route.path,
    query: query,
    meta: newMeta
  })
}

// Set sessionStorage
const setStorage = function(id, tag, entity) {
  const sessionId = store.getters['userInfo/info'].sessionId
  sessionStorage.setItem(`${sessionId}|${tag}|${id}`, JSON.stringify(entity))
}

// Get sessionStorage
const getStorage = function(id, tag) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return JSON.parse(sessionStorage.getItem(`${sessionId}|${tag}|${id}`))
}

// Remove sessionStorage
const removeStorage = function(id, tag) {
  const sessionId = store.getters['userInfo/info'].sessionId
  sessionStorage.removeItem(`${sessionId}|${tag}|${id}`)
}

// Set localStorage
const setLocalStorage = function(id, tag, entity) {
  const sessionId = store.getters['userInfo/info'].sessionId
  localStorage.setItem(`${sessionId}|${tag}|${id}`, JSON.stringify(entity))
}

// Get localStorage
const getLocalStorage = function(id, tag) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return JSON.parse(localStorage.getItem(`${sessionId}|${tag}|${id}`))
}

// Remove localStorage
const removeLocalStorage = function(id, tag) {
  const sessionId = store.getters['userInfo/info'].sessionId
  localStorage.removeItem(`${sessionId}|${tag}|${id}`)
}

export default {
  routeToDetail,
  setStorage,
  getStorage,
  removeStorage,
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage
}
