export default class CompanyModel {
  constructor() {
    this.itemCategories = null
    this.setting = null
    this.info = null
    this.currency = {
      currency: '',
      symbol: ''
    }
  }

  isCategoryEnabled(category) {
    let enabled = false
    if (this.itemCategories) {
      const itemCategory = this.itemCategories.find(c => c.itemCategoryType === category)
      enabled = itemCategory ? itemCategory.enabled : false
    }
    return enabled
  }
}