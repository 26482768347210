<template>
  <div class="page-body">
    <capacity-filter-bar :active-time-period="activeTimePeriod"
      :date-string="dateString"
      :hidden-weekdays="hiddenWeekDays"
      :selected-due-in-out="selectedDueInOut"
      :theme="theme"
      @arrowClick="handleArrowClick"
      @changeActive="handleReportChange"
      @changeShowWeekend="handleChangeShowWeekend" />

    <div class="is-flex"
      style="height: 93%;">
      <calendar-component :calendar-arr="calendarArr"
        :is-monthly="isMonthly"
        :active-time-period="activeTimePeriod"
        :weekly-side-time-bar="weeklySideTimeBar"
        :number-of-days="7 - hiddenWeekDaysFiltered.length"
        :min-time="minTime"
        :max-time="maxTime"
        :hidden-weekdays="hiddenWeekDaysFiltered"
        :is-side-panel-open="sidePanelObj.isSidePanelOpen"
        :selected-cell="sidePanelObj.selectedCell"
        :selected-cell-date-str="sidePanelObj.sidePanelDateStr"
        :is-loading="isDataLoading"
        @openModal="openModal"
        @handleCellClick="handleCellClick" />
    </div>

    <aside class="booking-panel"
      :class="{'closed' : !sidePanelObj.isSidePanelOpen, 'open' : sidePanelObj.isSidePanelOpen}">
      <booking-page-side-panel :quote-data="quoteData"
        :is-side-panel-open="sidePanelObj.isSidePanelOpen"
        :selected-cell="sidePanelObj.selectedCell"
        @handleDragEvent="handleDragEvent"
        @handleQuoteDag="handleQuoteDag"
        @handleSidePanelToggle="handleSidePanelToggle"
        @clearSelectedCell="selectedCell = null"
        @handleQuoteRedirect="handleQuoteRedirect" />
    </aside>

  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { Interval } from 'luxon'
import CapacityFilterBar from './CapacityComponentFilterBar.vue'
import BookingPageSidePanel from '../CalendarSidePanel.vue'
import CalendarComponent from '../CalendarComponent.vue'
import CapacityService from './CapacityService'
import StoreUtil from '@/store/utils'

export default {
  name: 'CapacityComponent',
  components: { CapacityFilterBar, BookingPageSidePanel, CalendarComponent },
  props: {
    value: null,
    updateData: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'dark'
    }
  },
  data() {
    return {
      sidePanelObj: {
        selectedDate: null,
        isSidePanelOpen: false,
        selectedCell: null,
        sidePanelDateStr: null
      },
      isDataLoading: false,
      // index of days that need to be hidden from the calendar
      hiddenWeekDays: [],
      // index of days that need to be blocked from the calendar
      blockedWeekDays: [6, 7],
      selectedDueInOut: 0,
      dataArray: [],
      calendarArr: [],
      dates: {
        start: DateTime.local().startOf('month'),
        end: DateTime.local().endOf('month')
      },
      timePeriods: {
        day: {
          key: 'day',
          start: DateTime.local().startOf('day'),
          end: DateTime.local().endOf('day'),
          format: 'ccc, dd LLL',
          // when clicking on the left arrow / right arrow
          changeValue: 'day'
        },
        week: {
          key: 'week',
          start: DateTime.local().startOf('week'),
          end: DateTime.local().endOf('week'),
          format: 'ccc dd, LLL',
          changeValue: 'week'
        },
        month: {
          key: 'month',
          start: DateTime.local().startOf('month'),
          end: DateTime.local().endOf('month'),
          format: 'LLLL yyyy',
          changeValue: 'month'
        }
      },
      activeTimePeriod: 'month',
      // ideally this would be a prop/api setting, but for now it is hardcoded
      minTime: { hour: 5, minutes: 0 },
      maxTime: { hour: 23, minute: 0 },
      intervalDuration: 15, // minutes,
      quoteData: {
        quoteList: [],
        isLoading: false
      }
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    timePeriod() {
      return this.timePeriods[this.activeTimePeriod]
    },
    dateString() {
      if (this.activeTimePeriod === this.timePeriods.week.key) {
        // have to and from for week, as cosfusing otherwise
        return `${this.dates.start.toFormat(this.timePeriod.format)} - ${this.dates.end.toFormat(this.timePeriod.format)}`
      } else return this.dates.start.toFormat(this.timePeriod.format)
    },
    isMonthly() {
      return this.activeTimePeriod === this.timePeriods.month.key
    },
    weeklyHeader() {
      return Interval.fromDateTimes(this.dates.start.startOf('week'), this.dates.end.endOf('week'))
        .splitBy({ days: 1 })
        .map((timePeriod) => {
          return {
            dateObj: timePeriod.start,
            week_dayNo: timePeriod.start.toFormat('d'),
            week_day: timePeriod.start.toFormat('ccc')
          }
        })
    },
    weeklySideTimeBar() {
      // dont need this data for monthly mode
      if (this.activeTimePeriod === this.timePeriods.month.key) return []

      return Interval.fromDateTimes(
        this.dates.start.set({ hour: this.minTime.hour, minute: this.minTime.minutes }),
        this.dates.start.set({ hour: this.maxTime.hour, minute: this.maxTime.minutes })
      )
        .splitBy({ minutes: this.intervalDuration })
        .map((timePeriod) => {
          return {
            //show time if it is the first time period of the hour
            showTime: timePeriod.start.minute === 0,
            dateObj: timePeriod.start,
            hour: timePeriod.start.toFormat('HH'),
            minutes: timePeriod.start.toFormat('mm')
          }
        })
    },
    hiddenWeekDaysFiltered() {
      /// grab day index of each job
      const dayIndex = this.dataArray.map((item) => {
        return DateTime.fromISO(item[this.selectedDueInOut === 0 ? 'jobStart' : 'jobEnd']).weekday
      })

      /// return days that are in the hiddenWeekDays array and not in the dayIndex array
      return this.hiddenWeekDays.filter((day) => {
        return !dayIndex.includes(day)
      })
    },
    // account for the first week of the month starting in the previous month
    // account for sunday being the first day of the week etc.
    computedDates() {
      // account for the first week of the month starting in the previous month
      // minus one day to set the start of the week to be sunbday and end to be saturday
      let dates = {
        start: this.dates.start.startOf('week').minus({ days: 1 }),
        end: this.dates.end.endOf('week').minus({ days: 1 })
      }

      if (this.activeTimePeriod === this.timePeriods.month.key) {
        /// since we are setting the date to the end of the week, and not end of the month
        /// there is a possibility that the this end date could be before the month actually ends
        /// i.e 31st is a sunday, since we want to end the week on saturday, we subtract a day from the end date
        /// this would make the end date 30th, which is not the end of the month, so we add a week to the end date
        /// if the end date is less than the original end date, this is done to ensure that the calendar is able
        /// to show the 31st/last day as the last day of the month in the next week
        if (dates.end < this.dates.end) {
          dates.end = dates.end.plus({ weeks: 1 })
        }
        /// do the complete opposite for the start date, if the start date of the week is greater than
        /// the start date of the month, then subtract a week from the start date so we dont miss
        /// any days in the month
        if (dates.start > this.dates.start) {
          dates.start = dates.start.minus({ weeks: 1 })
        }
      }
      return dates
    }
  },
  watch: {
    updateData() {
      this.getCapacityDate()
    },
    dates: {
      handler() {
        this.getCalendarDateArray()
        this.getCapacityDate()
      },
      deep: true
    },
    dataArray: {
      handler() {
        this.getCalendarDateArray()
      },
      deep: true
    },
    hiddenWeekDays: {
      handler() {
        this.getCalendarDateArray()
        this.getCapacityDate()
      },
      deep: true
    },
    selectedDueInOut() {
      this.getCapacityDate()
      // close side panel and clear selected cell when the report is changed
      this.sidePanelObj.isSidePanelOpen = false
      this.sidePanelObj.selectedCell = null
    },
    sidePanelObj: {
      handler() {
        this.persistFilter()
      },
      deep: true
    }
  },
  mounted() {
    this.getCalendarDateArray()
    this.getCapacityDate()
    const capacitySettings = StoreUtil.getLocalStorage('capacitySettings', 'showWeekends')
    this.handleChangeShowWeekend(capacitySettings)

    this.retrieveFilter()
  },
  methods: {
    handleQuoteRedirect(quote) {
      this.$emit('handleQuoteRedirect', quote)
    },
    handleReportChange(index) {
      /// close side panel and clear selected cell if the report is changed

      this.selectedDueInOut = index
    },
    async getCapacityDate() {
      this.isDataLoading = true
      const data = await CapacityService.getCapacityDueInOut(
        this.selectedDueInOut,
        this.computedDates.start.toISODate(),
        this.computedDates.end.toISODate(),
        this.selectedDueInOut === 0 ? 'jobStart' : 'jobEnd',
        'ASC'
      )
      this.dataArray = data
      this.isDataLoading = false
      this.$emit('setUpdateData', false)
    },
    handleChangeShowWeekend(bool) {
      if (!!bool) {
        this.hiddenWeekDays = this.hiddenWeekDays.filter((day) => day !== 6 && day !== 7)
        StoreUtil.setLocalStorage('capacitySettings', 'showWeekends', true)
      } else {
        this.hiddenWeekDays.push(6, 7)
        StoreUtil.setLocalStorage('capacitySettings', 'showWeekends', false)
      }
    },

    handleDateChange(key) {
      this.activeTimePeriod = key
      this.dates = {
        start: this.timePeriods[key].start,
        end: this.timePeriods[key].end
      }
    },
    handleArrowClick(direction) {
      const value = direction === 'left' ? -1 : 1
      this.dates = {
        start: this.dates.start.plus({ [this.timePeriod.changeValue]: value }).startOf(this.timePeriod.changeValue), // start/end of the day / week / month
        end: this.dates.end.plus({ [this.timePeriod.changeValue]: value }).endOf(this.timePeriod.changeValue)
      }
    },

    getCalendarDateArray() {
      const minTime = this.minTime
      const maxTime = this.maxTime
      const intervalDuration = this.intervalDuration

      const isMonthly = this.activeTimePeriod === this.timePeriods.month.key

      const splitBy = isMonthly ? { weeks: 1 } : { days: 1 }
      const nestedSplitBy = isMonthly ? { days: 1 } : { minutes: intervalDuration }
      const dateFormatString = isMonthly ? 'd' : 'ccc, dd MMM HH:mm'

      // define how current time period is defined (day / hour)
      const currentTimeFrame = isMonthly ? 'day' : 'hour'

      let dates = this.computedDates

      let wklyArr = Interval.fromDateTimes(dates.start, dates.end)
        .splitBy(splitBy)
        .map((timePeriod, index) => {
          //// get all days in the week (monthly mode) / hours in the day (weekly mode)
          const startime = isMonthly ? timePeriod.start : timePeriod.start.set({ hour: minTime.hour, minute: minTime.minutes })
          const endtime = isMonthly ? timePeriod.end : timePeriod.start.set({ hour: maxTime.hour, minute: maxTime.minutes })

          const days = Interval.fromDateTimes(startime, endtime)
            .splitBy(nestedSplitBy)
            .filter((day) => {
              return !this.hiddenWeekDaysFiltered.includes(day.start.weekday)
            })

          const dayObj = days.map((split, subIndex) => {
            /// time now rounded to the closest interval (30 mins in this case)
            const now = DateTime.local().set({
              minute: Math.round(DateTime.local().minute / intervalDuration) * intervalDuration
            })

            /// as per the design this code checks when month changes, and adds a header with month to the calendar
            let isNewMonth = false
            if (isMonthly && subIndex > 0) {
              isNewMonth = !split.start.hasSame(days[subIndex - 1]?.start, 'month')
            }

            /// check if time falls within the current time period
            let isNow = false
            if (isMonthly) isNow = split.start.hasSame(now, currentTimeFrame)
            else {
              // check is the time is within the current time period (15 or 30 mins)
              isNow = split.start.hasSame(now, currentTimeFrame) && split.start.hasSame(now, 'minute')
            }

            // set the scroll to time to be 2 intervals before the current time
            // to allow some buffer space at the top of the calendar
            if (!!isNow && !isMonthly) {
              this.scrollToTime = split.start.minus({ minutes: intervalDuration * 2 }).toFormat(dateFormatString)
            }

            const isThisMonth = !isMonthly || split.start.hasSame(this.dates.start, 'month')
            const isCurrentOrFuture = split.start.startOf(currentTimeFrame) >= DateTime.local().startOf(currentTimeFrame)

            let dateString = split.start.toFormat(dateFormatString)

            if (isNewMonth) dateString += split.start.toFormat(' LLL')

            let bookingsArr = this.dataArray.filter((item) => {
              return DateTime.fromISO(item[this.selectedDueInOut === 0 ? 'jobStart' : 'jobEnd']).hasSame(split.start, 'day')
            })

            return {
              dateObj: split.start,
              dateString: split.start.toFormat('cccc, d MMMM y'),
              date: dateString,
              // only want weekname for the first week (index 0) of the month in monthly mode
              weekDayName: isMonthly && index === 0 ? split.start.toFormat('ccc') : null,
              isNewMonth: isNewMonth,
              isToday: isNow,
              bookings: bookingsArr,
              isNonWorkingDay: !this.blockedWeekDays.includes(split.start.weekday),
              booked: bookingsArr.length,
              isThisMonth: isThisMonth,
              isCurrentOrFuture: isCurrentOrFuture
            }
          })

          return {
            dateObj: timePeriod.start,
            week_dayNo: isMonthly ? timePeriod.start.toFormat('W') : timePeriod.start.toFormat('d'),
            // only used for weekly mode
            isToday: timePeriod.start.hasSame(DateTime.local(), 'day'),
            days: dayObj
          }
        })

      if (!this.isMonthly) {
        this.calendarArr = wklyArr
      } else {
        // in monthly mode, there may be cases where the first week of the month starts in the previous month,
        // and may not end in the previous month, but once we hide weekends, it may end in the previous month
        // so we need to handle this case by completely removing the first week of the month if not a single
        // day in the week is in the current month
        if (wklyArr[0].days.every((day) => !day.isThisMonth)) {
          wklyArr.shift()
        }

        // do same for last week of the month
        if (wklyArr[wklyArr.length - 1].days.every((day) => !day.isThisMonth)) {
          wklyArr.pop()
        }

        this.calendarArr = wklyArr
      }
    },
    handleSidePanelToggle() {
      this.sidePanelObj.isSidePanelOpen = !this.sidePanelObj.isSidePanelOpen
    },
    handleDragEvent(e, quote) {
      this.selectedQuote = e
    },
    handleQuoteDag(bool) {
      this.isQuoteDragging = bool
    },
    openModal() {
      this.modalOpen = true
    },
    closeModal() {
      this.modalOpen = false
      this.selectedQuote = null
    },
    handleModalToggle() {
      this.showModal = !this.showModal
    },
    handleNewBookingModalToggle() {
      this.newBookingOpen = !this.newBookingOpen
    },
    handleCellClick(cell) {
      this.sidePanelObj.isSidePanelOpen = true
      this.sidePanelObj.selectedCell = cell
      if (cell.length == 1) {
        this.sidePanelObj.sidePanelDateStr = cell[0].dateString
      } else {
        this.sidePanelObj.sidePanelDateStr = cell[0].dateString + ' - ' + cell[cell.length - 1].dateString
      }

      this.sidePanelObj.selectedDate = this.dates.start.toFormat('yyyy-MM-dd')
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.sidePanelObj))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      // if the side was open when the page was last visited, open it again
      if (!!filter && filter.isSidePanelOpen) {
        // get the date from side panel and set it to current month
        if (!!filter.selectedDate) {
          this.dates = {
            start: DateTime.fromFormat(filter.selectedDate, 'yyyy-MM-dd').startOf('month'),
            end: DateTime.fromFormat(filter.selectedDate, 'yyyy-MM-dd').endOf('month')
          }
        }
        // open side panel and set selected cell
        this.sidePanelObj.selectedCell = filter.selectedCell
        this.sidePanelObj.isSidePanelOpen = true
        this.sidePanelObj.sidePanelDateStr = filter.sidePanelDateStr
      }
    }
  }
}
</script>

<style lang="scss">
.page-body {
  height: 100%;
  background: #f8f9fa;
  position: relative;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 0.6em;
}

.thin-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  position: relative;
}

.thin-scrollbar::-webkit-scrollbar-track::before,
.thin-scrollbar::-webkit-scrollbar-track::after {
  content: '▲';
  position: absolute;
  width: 100%;
  text-align: center;
}

.thin-scrollbar::-webkit-scrollbar-track::before {
  top: 0;
}

.thin-scrollbar::-webkit-scrollbar-track::after {
  content: '▼';
  bottom: 0;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #7e7e7e;
  color: #7e7e7e;
}

.booking-panel {
  width: 24%;
  height: 100%;
  background: #343a40;
  padding: 1em;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  &.closed {
    transform: translateX(100%) !important;
  }
  &.open {
    transform: translateX(0%) !important;
  }
}
</style>