import ProtoBufApi from '@/services/protobuf-api'

export default {
  async getLabourTypes() {
    const url = '/quotes/protobuf/labourtypes'
    const result = await ProtoBufApi(url)
    const proto = require('@/assets/proto/quote/LabourTypes.proto')
    const message = await proto.LabourTypes.LabourTypesModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.labourTypes
  }
}
