import { TableColumn } from '@/classes'

const startColumns = [
  new TableColumn('Job No.', 'quoteNoRef', true, 'desc', 'left'),
  new TableColumn('Time', 'jobInOutDate', false, 'desc', 'left'),
  new TableColumn('Job Stage', 'jobStage', true, 'desc', 'left'),
  new TableColumn('Owner.', 'owner', true, 'desc', 'left'),
  new TableColumn('Rego', 'rego', true, 'desc', 'left'),
  new TableColumn('Vehicle', 'makeModel', true, 'desc', 'left'),
  new TableColumn('Insurer', 'insurer', true, 'desc', 'left')
]

const inColumns = [
  new TableColumn('Due Out', 'jobEnd', true, 'desc', 'left'),
  new TableColumn('Days Booked', 'daysBooked', true, 'desc', 'centered')
]

const outColumns = [
  new TableColumn('Owner Payable', 'ownersContribution', true, 'asc', 'right')
]

const endColumns = [
  new TableColumn('Job Total', 'totalIncGst', true, 'asc', 'right'),
  new TableColumn('Notes', 'notes', true, 'asc', 'centered')
  // new TableColumn('Arrival', 'Arrived', true, 'asc', 'centered')
]

let DueInColumns = [...startColumns, ...inColumns, ...endColumns]
let DueOutColumns = [...startColumns, ...outColumns, ...endColumns]

export { DueInColumns, DueOutColumns }
