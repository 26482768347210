import routeTypes from './route-types'

const AdvanceReceiptModal = r => require.ensure([], () => r(require('./AdvanceReceiptModal.vue')), 'advancereceipt-chunk')

export default [
  {
    path: routeTypes.AdvanceReceiptModal.path,
    name: '',
    component: AdvanceReceiptModal,
    props: route => ({
      isNew: route.query.mode === 'new' || route.meta.isNew || getStorage('isNew') === 'true',
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl'),
      name: route.query.name || route.meta.name || getStorage('name')
    }),
    meta: {
      id: 'A013',
      route: 'detail',
      title: 'Advance Receipt',
      report: 'AT_Invoice',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  }
]