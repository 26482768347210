/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.OrmBatchFilter = (function() {

    /**
     * Namespace OrmBatchFilter.
     * @exports OrmBatchFilter
     * @namespace
     */
    var OrmBatchFilter = {};

    OrmBatchFilter.OrmBatchFilterModel = (function() {

        /**
         * Properties of an OrmBatchFilterModel.
         * @memberof OrmBatchFilter
         * @interface IOrmBatchFilterModel
         * @property {number|null} [batchNo] OrmBatchFilterModel batchNo
         * @property {string|null} [batchDate] OrmBatchFilterModel batchDate
         * @property {number|null} [batchStatus] OrmBatchFilterModel batchStatus
         * @property {string|null} [sortColumn] OrmBatchFilterModel sortColumn
         * @property {string|null} [sortOrder] OrmBatchFilterModel sortOrder
         * @property {number|null} [pageIndex] OrmBatchFilterModel pageIndex
         * @property {number|null} [pageSize] OrmBatchFilterModel pageSize
         */

        /**
         * Constructs a new OrmBatchFilterModel.
         * @memberof OrmBatchFilter
         * @classdesc Represents an OrmBatchFilterModel.
         * @implements IOrmBatchFilterModel
         * @constructor
         * @param {OrmBatchFilter.IOrmBatchFilterModel=} [p] Properties to set
         */
        function OrmBatchFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * OrmBatchFilterModel batchNo.
         * @member {number|null|undefined} batchNo
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @instance
         */
        OrmBatchFilterModel.prototype.batchNo = null;

        /**
         * OrmBatchFilterModel batchDate.
         * @member {string|null|undefined} batchDate
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @instance
         */
        OrmBatchFilterModel.prototype.batchDate = null;

        /**
         * OrmBatchFilterModel batchStatus.
         * @member {number|null|undefined} batchStatus
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @instance
         */
        OrmBatchFilterModel.prototype.batchStatus = null;

        /**
         * OrmBatchFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @instance
         */
        OrmBatchFilterModel.prototype.sortColumn = null;

        /**
         * OrmBatchFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @instance
         */
        OrmBatchFilterModel.prototype.sortOrder = null;

        /**
         * OrmBatchFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @instance
         */
        OrmBatchFilterModel.prototype.pageIndex = null;

        /**
         * OrmBatchFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @instance
         */
        OrmBatchFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * OrmBatchFilterModel _batchNo.
         * @member {"batchNo"|undefined} _batchNo
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @instance
         */
        Object.defineProperty(OrmBatchFilterModel.prototype, "_batchNo", {
            get: $util.oneOfGetter($oneOfFields = ["batchNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmBatchFilterModel _batchDate.
         * @member {"batchDate"|undefined} _batchDate
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @instance
         */
        Object.defineProperty(OrmBatchFilterModel.prototype, "_batchDate", {
            get: $util.oneOfGetter($oneOfFields = ["batchDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmBatchFilterModel _batchStatus.
         * @member {"batchStatus"|undefined} _batchStatus
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @instance
         */
        Object.defineProperty(OrmBatchFilterModel.prototype, "_batchStatus", {
            get: $util.oneOfGetter($oneOfFields = ["batchStatus"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmBatchFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @instance
         */
        Object.defineProperty(OrmBatchFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmBatchFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @instance
         */
        Object.defineProperty(OrmBatchFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmBatchFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @instance
         */
        Object.defineProperty(OrmBatchFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * OrmBatchFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @instance
         */
        Object.defineProperty(OrmBatchFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified OrmBatchFilterModel message. Does not implicitly {@link OrmBatchFilter.OrmBatchFilterModel.verify|verify} messages.
         * @function encode
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @static
         * @param {OrmBatchFilter.IOrmBatchFilterModel} m OrmBatchFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrmBatchFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.batchNo != null && Object.hasOwnProperty.call(m, "batchNo"))
                w.uint32(8).int32(m.batchNo);
            if (m.batchDate != null && Object.hasOwnProperty.call(m, "batchDate"))
                w.uint32(18).string(m.batchDate);
            if (m.batchStatus != null && Object.hasOwnProperty.call(m, "batchStatus"))
                w.uint32(24).int32(m.batchStatus);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(34).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(42).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(48).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(56).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified OrmBatchFilterModel message, length delimited. Does not implicitly {@link OrmBatchFilter.OrmBatchFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @static
         * @param {OrmBatchFilter.IOrmBatchFilterModel} message OrmBatchFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrmBatchFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OrmBatchFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {OrmBatchFilter.OrmBatchFilterModel} OrmBatchFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrmBatchFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.OrmBatchFilter.OrmBatchFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.batchNo = r.int32();
                    break;
                case 2:
                    m.batchDate = r.string();
                    break;
                case 3:
                    m.batchStatus = r.int32();
                    break;
                case 4:
                    m.sortColumn = r.string();
                    break;
                case 5:
                    m.sortOrder = r.string();
                    break;
                case 6:
                    m.pageIndex = r.int32();
                    break;
                case 7:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes an OrmBatchFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof OrmBatchFilter.OrmBatchFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {OrmBatchFilter.OrmBatchFilterModel} OrmBatchFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrmBatchFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return OrmBatchFilterModel;
    })();

    return OrmBatchFilter;
})();

module.exports = $root;
