import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntity(id, hash) {
    const url = !hash ? `/otherlabours/${id}` : `/otherlabours/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseOtherLabourFilter(filter)
    const url = `/otherlabours/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseOtherLabourFilter(filter) {
    const entityProto = require('../../assets/proto/otherlabour/OtherLabourFilter.proto')
    const filterMessage = entityProto.OtherLabourFilter.OtherLabourFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  putOtherLabour(entity, deepDiff) {
    if (deepDiff) {
      entity.deepDiff = JSON.stringify(deepDiff)
    }
    return FqApi.put(`/otherlabours/${entity.itemOtherId}`, entity)
  },
  async postOtherLabour(entity) {
    return FqApi.post('/otherlabours', entity)
  },
  async getNewEntity() {
    const result = await FqApi.get('/otherlabours/new')
    return result.data
  },
  async getAssetEmail(assetId, assetType) {
    const url = `/otherlabours/getassetemail/${assetId}/${assetType}`
    const result = await FqApi.get(url)
    return result.data
  }
}
