import axios from 'axios'

// function getCookie(name) {
//   var value = '; ' + document.cookie
//   var parts = value.split('; ' + name + '=')
//   if (parts.length === 2) return parts.pop().split(';').shift()
// }

const service = axios.create({
  baseURL: process.env.VUE_APP_AUDITTRAILAPI_URI
  // headers: { 'Authorization': 'Bearer ' + getCookie('UniqApi.Token') }
})

service.interceptors.request.use(
  async function(config) {
    const token = await getToken()
    config.headers.common['Authorization'] = `Bearer ${token}`
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

async function getToken() {
  const secretToken = '7eebTEusd5GcfzPpAfcgiwyni9hLICm3'
  const result = await axios.get(`${process.env.VUE_APP_AUDITTRAILAPI_URI}/v1/auth/token?authToken=${secretToken}`)
  // console.log(result.data)
  // console.log(result.status)
  return result.data
}

export default service
