import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntity(auditTrailFieldId, hash) {
    const url = !hash ? `/audittrail/${auditTrailFieldId}` : `/audittrail/${auditTrailFieldId}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseAuditTrailFilter(filter)
    const url = `/audittrail/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseAuditTrailFilter(filter) {
    const entityProto = require('../../assets/proto/audittrail/AuditTrailFilter.proto')
    const filterMessage = entityProto.AuditTrailFilter.AuditTrailFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getFieldNameDropdown(entityShortName) {
    const url = `/audittrail/fieldname/${entityShortName}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntityNameDropdownProto() {
    const url = '/audittrail/entityname'
    const result = await FqApi.get(url)
    return result.data
  },
  async getPerformedByDropdownProto() {
    const url = '/audittrail/performedby'
    const result = await FqApi.get(url)
    return result.data
  }
}
