import routeTypes from './route-types'

const VehicleListing = r => require.ensure([], () => r(require('./VehicleListing.vue')), 'vehiclelist-chunk')
const VehicleView = r => require.ensure([], () => r(require('./VehicleView.vue')), 'vehicle-chunk')
const VehicleDetail = r => require.ensure([], () => r(require('./VehicleDetail.vue')), 'vehicle-chunk')
const VehicleNew = r => require.ensure([], () => r(require('./VehicleNew.vue')), 'vehicle-chunk')

const id = 'A082'

export default [
  {
    path: routeTypes.VehicleListView.path,
    name: routeTypes.VehicleListView.name,
    component: VehicleListing,
    meta: {
      id: id,
      route: 'listing',
      title: 'Vehicles',
      fkey: 'vehicles-filter',
      report: 'rptTablesCar',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.VehicleNew.path,
    name: routeTypes.VehicleNew.name,
    component: VehicleNew,
    meta: {
      id: id,
      route: 'detail',
      title: 'New Vehicle'
    }
  },
  {
    path: routeTypes.VehicleView.path,
    component: VehicleView,
    meta: {
      id: id,
      route: 'detail',
      title: 'Vehicle'
    },
    children: [
      {
        path: '',
        name: routeTypes.VehicleDetail.name,
        component: VehicleDetail,
        meta: {
          title: 'Detail',
          report: 'rptTableVehicleDetails',
          reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
        }
      }
    ]
  }
]
