import * as classHelper from '@/classes/classHelper'

export default class QuoteTemplateItemModel {
  constructor(quoteTemplateItem = null) {
    this.itemId = null
    this.tempateId = null
    this.itemNo = null
    this.itemDesc = null
    this.itemType = ''
    this.sortNo = 0
    this.partNo = null
    this.labourType = null
    this.quantity = 0
    this.allowTick = null
    this.reportOnly = false
    this.timesType = null
    this.value = 0
    this.isBlend = false
    this.isDeleted = false
    this.isDirty = false
    this.isNew = true
    this.overwriteOnConflict = false
    this.mergeOnConflict = false

    if (quoteTemplateItem) {
      classHelper.shallowCopyValues(this, quoteTemplateItem)
    }
  }
}