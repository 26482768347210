/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.State = (function() {

    /**
     * Namespace State.
     * @exports State
     * @namespace
     */
    var State = {};

    State.StateListComboModel = (function() {

        /**
         * Properties of a StateListComboModel.
         * @memberof State
         * @interface IStateListComboModel
         * @property {Array.<State.StateListComboModel.IState>|null} [states] StateListComboModel states
         */

        /**
         * Constructs a new StateListComboModel.
         * @memberof State
         * @classdesc Represents a StateListComboModel.
         * @implements IStateListComboModel
         * @constructor
         * @param {State.IStateListComboModel=} [p] Properties to set
         */
        function StateListComboModel(p) {
            this.states = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StateListComboModel states.
         * @member {Array.<State.StateListComboModel.IState>} states
         * @memberof State.StateListComboModel
         * @instance
         */
        StateListComboModel.prototype.states = $util.emptyArray;

        /**
         * Encodes the specified StateListComboModel message. Does not implicitly {@link State.StateListComboModel.verify|verify} messages.
         * @function encode
         * @memberof State.StateListComboModel
         * @static
         * @param {State.IStateListComboModel} m StateListComboModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StateListComboModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.states != null && m.states.length) {
                for (var i = 0; i < m.states.length; ++i)
                    $root.State.StateListComboModel.State.encode(m.states[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Encodes the specified StateListComboModel message, length delimited. Does not implicitly {@link State.StateListComboModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof State.StateListComboModel
         * @static
         * @param {State.IStateListComboModel} message StateListComboModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StateListComboModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StateListComboModel message from the specified reader or buffer.
         * @function decode
         * @memberof State.StateListComboModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {State.StateListComboModel} StateListComboModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StateListComboModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.State.StateListComboModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.states && m.states.length))
                        m.states = [];
                    m.states.push($root.State.StateListComboModel.State.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a StateListComboModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof State.StateListComboModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {State.StateListComboModel} StateListComboModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StateListComboModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        StateListComboModel.State = (function() {

            /**
             * Properties of a State.
             * @memberof State.StateListComboModel
             * @interface IState
             * @property {string|null} [id] State id
             * @property {string|null} [code] State code
             * @property {string|null} [name] State name
             * @property {string|null} [areaCode] State areaCode
             */

            /**
             * Constructs a new State.
             * @memberof State.StateListComboModel
             * @classdesc Represents a State.
             * @implements IState
             * @constructor
             * @param {State.StateListComboModel.IState=} [p] Properties to set
             */
            function State(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * State id.
             * @member {string|null|undefined} id
             * @memberof State.StateListComboModel.State
             * @instance
             */
            State.prototype.id = null;

            /**
             * State code.
             * @member {string|null|undefined} code
             * @memberof State.StateListComboModel.State
             * @instance
             */
            State.prototype.code = null;

            /**
             * State name.
             * @member {string|null|undefined} name
             * @memberof State.StateListComboModel.State
             * @instance
             */
            State.prototype.name = null;

            /**
             * State areaCode.
             * @member {string|null|undefined} areaCode
             * @memberof State.StateListComboModel.State
             * @instance
             */
            State.prototype.areaCode = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * State _id.
             * @member {"id"|undefined} _id
             * @memberof State.StateListComboModel.State
             * @instance
             */
            Object.defineProperty(State.prototype, "_id", {
                get: $util.oneOfGetter($oneOfFields = ["id"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * State _code.
             * @member {"code"|undefined} _code
             * @memberof State.StateListComboModel.State
             * @instance
             */
            Object.defineProperty(State.prototype, "_code", {
                get: $util.oneOfGetter($oneOfFields = ["code"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * State _name.
             * @member {"name"|undefined} _name
             * @memberof State.StateListComboModel.State
             * @instance
             */
            Object.defineProperty(State.prototype, "_name", {
                get: $util.oneOfGetter($oneOfFields = ["name"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * State _areaCode.
             * @member {"areaCode"|undefined} _areaCode
             * @memberof State.StateListComboModel.State
             * @instance
             */
            Object.defineProperty(State.prototype, "_areaCode", {
                get: $util.oneOfGetter($oneOfFields = ["areaCode"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified State message. Does not implicitly {@link State.StateListComboModel.State.verify|verify} messages.
             * @function encode
             * @memberof State.StateListComboModel.State
             * @static
             * @param {State.StateListComboModel.IState} m State message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            State.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                    w.uint32(10).string(m.id);
                if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                    w.uint32(18).string(m.code);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(26).string(m.name);
                if (m.areaCode != null && Object.hasOwnProperty.call(m, "areaCode"))
                    w.uint32(34).string(m.areaCode);
                return w;
            };

            /**
             * Encodes the specified State message, length delimited. Does not implicitly {@link State.StateListComboModel.State.verify|verify} messages.
             * @function encodeDelimited
             * @memberof State.StateListComboModel.State
             * @static
             * @param {State.StateListComboModel.IState} message State message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            State.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a State message from the specified reader or buffer.
             * @function decode
             * @memberof State.StateListComboModel.State
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {State.StateListComboModel.State} State
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            State.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.State.StateListComboModel.State();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.id = r.string();
                        break;
                    case 2:
                        m.code = r.string();
                        break;
                    case 3:
                        m.name = r.string();
                        break;
                    case 4:
                        m.areaCode = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Decodes a State message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof State.StateListComboModel.State
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {State.StateListComboModel.State} State
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            State.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            return State;
        })();

        return StateListComboModel;
    })();

    return State;
})();

module.exports = $root;
