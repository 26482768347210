import PaymentEntryRoutes from './route-types'
import store from '@/store'

const PaymentEntryListView = r => require.ensure([], () => r(require('./PaymentEntryListView.vue')), 'paymententrylist-chunk')
const PaymentEntryView = r => require.ensure([], () => r(require('./PaymentEntryView.vue')), 'paymententry-chunk')
const PaymentEntryDetail = r => require.ensure([], () => r(require('./PaymentEntryDetail.vue')), 'paymententry-chunk')

const tag = 'paymententry'
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|${tag}|${query}`)
}

const moduleId = 'A048'

export default [
  {
    path: PaymentEntryRoutes.PaymentEntryListView.path,
    name: PaymentEntryRoutes.PaymentEntryListView.name,
    component: PaymentEntryListView,
    meta: {
      id: moduleId,
      route: 'listing',
      title: 'Payment Entry',
      fkey: 'paymententry|filter',
      report: 'rptCreditorPaymentEntryListV2',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: PaymentEntryRoutes.PaymentEntryDetail.path,
    name: '',
    component: PaymentEntryView,
    props: route => ({
      isNew: route.meta.isNew,
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl')
    }),
    meta: {
      id: moduleId,
      route: 'detail',
      title: 'Payment Entry',
      isNew: false,
      ekey: 'emailer'
    },
    children: [
      {
        path: '',
        name: PaymentEntryRoutes.PaymentEntryDetail.name,
        component: PaymentEntryDetail,
        meta: {
          id: moduleId,
          route: 'detail',
          title: 'Detail',
          fkey: 'paymententry|filter',
          isNew: false,
          ekey: 'emailer'
        }
      }
    ]
  }
]
