<template>
  <div id="app">
    <app-errors />
    <app-nav />
    <router-view v-if="$userInfo && $user && $user.permissions && $company"
      class="app-content" />
  </div>
</template>

<script>
import AppNav from './AppNav.vue'
import AppErrors from './AppErrors.vue'
import Vue from 'vue'
import VueTheMask from 'vue-the-mask'
import Vuelidate from 'vuelidate'
import GlobalSpinnerMixin from '@/components/mixins/global/GlobalSpinnerMixin'
import GlobalUserInfoMixin from '@/components/mixins/global/GlobalUserInfoMixin'
import GlobalCompanyMixin from '@/components/mixins/global/GlobalCompanyMixin'
import GlobalLicenceInfoMixin from '@/components/mixins/global/GlobalLicenceInfoMixin'
import GlobalCommonMixin from '@/components/mixins/global/GlobalCommonMixin'
import GlobalUserSettingMixin from '@/components/mixins/global/GlobalUserSettingMixin'
import GlobalNotificationMixin from '@/components/mixins/global/GlobalNotificationMixin'
import BulmaSnackbar from '@/components/BulmaSnackbar'
import BulmaToast from '@/components/BulmaToast'
import UnsavedChangesModalPlugin from '@/components/BulmaModal/UnsavedChangesModalPlugin'
import VCalendar from 'v-calendar'
import filters from '@/filters'
import Tippy, { TippyComponent } from '@/components/Tippy'
// import 'v-calendar/lib/v-calendar.min.css'
// import { setupCalendar } from 'v-calendar'

// Use v-calendar, v-date-picker & v-popover components
Vue.use(VCalendar, {
  locale: 'en-AU',
  locales: {
    'en-MY': {
      firstDayOfWeek: 1,
      masks: {
        L: 'DD/MM/YYYY'
        // ...optional `title`, `weekdays`, `navMonths`, etc
      }
    }
  },
  datePicker: {
    popover: {
      visibility: 'focus'
    }
  },
  masks: {
    title: 'MMMM YYYY',
    weekdays: 'W',
    navMonths: 'MMM',
    input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
    data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
    dayPopover: 'L'
  }
})

Vue.use(VueTheMask) // Added to fix error when used in lazy-loaded components
Vue.use(Vuelidate)
Vue.use(BulmaSnackbar)
Vue.use(BulmaToast)
Vue.use(UnsavedChangesModalPlugin)
Vue.use(Tippy)
Vue.component('tippy', TippyComponent)

Vue.mixin(GlobalSpinnerMixin)
Vue.mixin(GlobalUserInfoMixin)
Vue.mixin(GlobalLicenceInfoMixin)
Vue.mixin(GlobalCommonMixin)
Vue.mixin(GlobalUserSettingMixin)
Vue.mixin(GlobalCompanyMixin)
Vue.mixin(GlobalNotificationMixin)

Vue.prototype.$eventHub = new Vue()
// For Vue 3.x compatibilty. Filters are removed in 3.x
Vue.prototype.$filters = filters

export default {
  name: 'App',
  components: {
    AppNav,
    AppErrors
  },
  data() {
    return {}
  },
  async created() {
    // from GlobalUserInfoMixin
    await Promise.all([this.getUserInfo(), this.getUserPermissions(), this.getCompanyItemCategories(), this.getCompanySetting(), this.getCompanyInfo()])
    // setupCalendar({
    //   firstDayOfWeek: 2,
    //   locale: this.$userInfo.locale
    // })
    this.getLabourTypes() // from GlobalCommonMixin
    this.getNotifications()
    this.startNotificationPoll()
    this.$store.dispatch('vehicleOptions/load')
  },
  beforeDestroy() {
    this.stopNotificationPoll()
  }
}
</script>

<style lang="scss">
#app {
  overflow: hidden;
  min-height: 100vh; // minimum height to prevent truncation of dropdown menu. Previous value 550px
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(rgb(218, 221, 223), rgb(255, 255, 255));
  /* Fixed top AppNav */
  > header {
    &.is-fixed-top {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 32;
    }
  }
  > div.app-content {
    top: 3.25rem;
    position: fixed;
    width: 100%;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    bottom: 0;
  }
}
#view-container {
  padding: 10px;
}
</style>
