// const tag = 'printpreview'

const state = {
  returnRoute: {},
  emailers: [],
  parameters: null
}

const getters = {
  returnRoute: state => state.returnRoute,
  getEmailer: state =>
    function(id) {
      return state.emailers.find(e => e.id === id)
    },
  reportParameters: state => state.parameters
}

const actions = {
  setReturnRoute({ commit }, route) {
    commit('setReturnRoute', route)
  },
  addEmailer({ commit }, emailer) {
    commit('addEmailer', emailer)
  },
  removeEmailer({ commit }, emailer) {
    commit('removeEmailer', emailer)
  },
  addParameters({ commit }, parameters) {
    commit('addParameters', parameters)
  },
  removeParameters({ commit }) {
    commit('removeParameters')
  }
}

const mutations = {
  setReturnRoute(state, route) {
    state.returnRoute = route
  },
  addEmailer(state, emailer) {
    state.emailers.push(emailer)
  },
  removeEmailer(state, id) {
    const index = state.emailers.findIndex(e => e.id === id)
    if (index !== -1) {
      state.emailers.splice(index, 1)
    }
  },
  addParameters(state, parameters) {
    state.parameters = parameters
  },
  removeParameters(state) {
    state.parameters = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
