import routerTypes from './route-types'

// Lazy-load components. Webpack will split each into individual file
const UsedPartsInfoListView = r => require.ensure([], () => r(require('./UsedPartsInfoListView.vue')), 'usedpartsinfolist-chunk')

export default [
  {
    path: routerTypes.UsedPartsInfoListView.path,
    name: routerTypes.UsedPartsInfoListView.name,
    component: UsedPartsInfoListView,
    meta: {
      id: 'A112',
      route: 'listing',
      title: 'Used Parts Information',
      fkey: 'usedpartsinfo|filter',
      report: 'rptUsedPartsList',
      reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
    }
  }
]
