import router from './router'
import store from './store'
import utils from '@/utils'
import FqApi from '@/services/fq-api'
import NavService from './components/NavService'

/**
 * Auth Plugin
 *
 * (see https://vuejs.org/v2/guide/plugins.html for more info on Vue.js plugins)
 *
 * Handles login and token authentication using OAuth2.
 */
export default {
  /**
   * Install the Auth class.
   *
   * Creates a Axios http interceptor to handle automatically adding auth headers
   * and refreshing tokens. Then attaches this object to the global Vue (as Vue.auth).
   *
   * @param {Object} Vue The global Vue.
   * @param {Object} options Any options we want to have in our plugin.
   * @return {void}
   */
  install(Vue, options) {
    Vue.prototype.$auth = Vue.auth = this
  },

  isLoggedIn() {
    return FqApi.get('/auth/isLoggedIn')
      .then(response => {
        return true
      })
      .catch(err => {
        console.log(err)
        return false
      })
  },

  /**
   * Login
   *
   * @param {Object.<string>} creds The username and password for logging in.
   * @param {string|null} redirect The name of the Route to redirect to.
   * @return {Promise}
   */
  login(creds, redirect) {
    // const params = { 'grant_type': 'password', 'username': creds.username, 'password': creds.password }
    // return Vue.http.post(LOGIN_URL, params, AUTH_BASIC_HEADERS)
    //   .then((response) => {
    //     this._storeToken(response)

    //     if (redirect) {
    //       router.push({ name: redirect })
    //     }

    //     return response
    //   })
    //   .catch((errorResponse) => {
    //     return errorResponse
    //   })
    // console.log(LOGIN_URL)

    // temporarily set the header
    FqApi.defaults.headers.common['Authorization'] = `Bearer ${creds.username}`

    return FqApi.get('/access/header')
      .then(response => {
        const fakeResponse = {
          data: {
            access_token: creds.username, // eslint-disable-line camelcase
            refresh_token: creds.password, // eslint-disable-line camelcase
            items: response.data.items
          }
        }

        this._storeToken(fakeResponse)

        if (redirect) {
          router.push({ name: redirect })
        }

        return fakeResponse
      })
      .catch(error => {
        alert(utils.getError(error))
      })
  },

  /**
   * Logout
   *
   * Clear all data in our Vuex store (which resets logged-in status) and redirect back
   * to login form.
   *
   * @return {void}
   */
  logout() {
    // store.commit('CLEAR_ALL_DATA')
    // router.push({ name: '/login' })
    //Signout Wcf
    NavService.signOut()
    const user = store.getters['userInfo/user']
    let rootUrl = `${process.env.VUE_APP_ROOT_URI}`
    if (user && user.info && user.info.isSupportUser) {
      rootUrl = `${rootUrl}/SignInCsr.aspx`
    } else {
      rootUrl = `${rootUrl}/SignIn.aspx`
    }
    window.location.href = rootUrl
  },

  /**
   * Store tokens
   *
   * Update the Vuex store with the access/refresh tokens received from the response from
   * the Oauth2 server.
   *
   * @private
   * @param {Response} response Vue-resource Response instance from an OAuth2 server.
   *      that contains our tokens.
   * @return {void}
   */
  _storeToken(response) {
    const auth = store.state.auth
    const user = store.state.user

    auth.isLoggedIn = true
    auth.accessToken = response.data.access_token
    auth.refreshToken = response.data.refresh_token
    // TODO: get user's name from response from Oauth server.
    user.name = 'John Smith'

    store.commit('UPDATE_AUTH', auth)
    store.commit('UPDATE_USER', user)
  }
}
