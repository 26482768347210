import routeTypes from './route-types'
// import store from '@/store'

const EstImageMessageListView = (r) => require.ensure([], () => r(require('./EstImageMessageListView.vue')), 'estimagemessagelist-chunk')
const EstImageMessageView = (r) => require.ensure([], () => r(require('./EstImageMessageView.vue')), 'estimagemessageview-chunk')
const EstImageMessageDetail = (r) => require.ensure([], () => r(require('./EstImageMessageDetail.vue')), 'estimagemessageview-chunk')
const EstImageMessageNew = (r) => require.ensure([], () => r(require('./EstImageMessageNew.vue')), 'estimagemessage-chunk')
const menuCode = 'A200'

export default [
  {
    path: routeTypes.EstImageMessageListView.path,
    name: routeTypes.EstImageMessageListView.name,
    component: EstImageMessageListView,
    meta: {
      id: menuCode,
      route: 'listing',
      title: 'EstImage Messages',
      fkey: 'estimagemessage|filter',
      report: '',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.EstImageMessageView.path,
    name: '',
    component: EstImageMessageView,
    meta: {
      id: menuCode,
      route: 'detail',
      title: 'EstImage Messages',
      isNew: false,
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    },
    children: [
      {
        path: '',
        name: routeTypes.EstImageMessageView.name,
        component: EstImageMessageDetail,
        meta: {
          id: menuCode,
          route: 'detail',
          title: 'Detail',
          isNew: false
        }
      }
    ]
  },
  {
    path: routeTypes.EstImageMessageNew.path,
    name: '',
    component: EstImageMessageNew,
    meta: {
      id: menuCode,
      route: 'detail',
      isNew: true,
      title: 'New EstImage Message'
    },
    children: [
      {
        path: '',
        name: routeTypes.EstImageMessageNew.name,
        component: EstImageMessageDetail,
        meta: {
          id: menuCode,
          route: 'detail',
          title: 'Detail',
          isNew: false
        }
      }
    ]
  }
]
