import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
import axios from 'axios'
import AuditTrailApi from '@/services/audittrail-api'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialiseMessageFilter(filter)
    const url = `/ormmessages/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseMessageFilter(filter) {
    const proto = require('../../assets/proto/orm/OrmFilter.proto')
    const filterMessage = proto.OrmFilter.OrmFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getCountSummaries() {
    const url = '/ormmessages/list/counts'
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntity(id) {
    const url = `/ormmessages/${id}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getNewEntity() {
    const res = await FqApi.get('/ormmessages/new')
    return res.data
  },
  putEntity(entity, deepdiff) {
    if (deepdiff) {
      entity.deepDiff = JSON.stringify(deepdiff)
    }
    return FqApi.put(`/ormmessages/${entity.messageId}`, entity)
  },
  updateMessageStatus(messageId, statusId) {
    return FqApi.put(`/ormmessages/${messageId}?statusId=${statusId}`)
  },
  async postEntity(entity) {
    return FqApi.post(`/ormmessages/${entity.messageId}`, entity)
  },
  async getInsurerSummaries() {
    const url = '/ormmessages/insurerlist'
    const result = await FqApi.get(url)
    return result.data
  },
  loadMessage(options) {
    return FqApi.put(`/ormmessages/load/${options.messageId}`, options)
  },
  async getQuoteIdByNo(quoteNo) {
    const result = await FqApi.get(`quotes/quoteidbyno?quoteNo=${quoteNo}`)
    return result.data
  },
  async assertCustomerExists(name) {
    const url = `/ormmessages/load/customerexists?name=${encodeURIComponent(name)}`
    const result = await FqApi.get(url)
    return result.data
  },
  async assertVehicleExists(rego) {
    const url = `/ormmessages/load/vehicleexists?rego=${encodeURIComponent(rego)}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteNo(quoteId) {
    const url = `/ormmessages/load/getquoteno/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteEntity(quoteId) {
    const url = `/quotes/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getToken() {
    const secretToken = '7eebTEusd5GcfzPpAfcgiwyni9hLICm3'
    const result = await axios.get(`/audittrail/v1/auth/token?authToken=${secretToken}`)
    console.log(result.data)
    console.log(result.status)
  },
  async auditTrailTest() {
    const result = await AuditTrailApi.get('/v1/audittrails/test')
    console.log(result.data)
  },
  async postAuditTrailItem(audtiTrailItem) {
    // const audtiTrailItem = {
    //   auditTrailId: null,
    //   performedAt: '',
    //   performedBy: userId,
    //   userRole: userRole,
    //   userAgent: '',
    //   userHostAddress: '',
    //   userHostName: '',
    //   url: '',
    //   companyId: companyId,
    //   entityId: entityId,
    //   entityType: entityType,
    //   entityReference: '',
    //   operationType: '',
    //   deepdiff: JSON.stringify(deepdiff),
    //   entity: ''
    // }
    return AuditTrailApi.post('/v1/audittrails/audittrailitem', audtiTrailItem)
  },
  async hasAnyPendingOrmMessage(quoteId) {
    const url = `/ormmessages/quote/${quoteId}/checkForPendingMessage`
    const result = await FqApi.get(url)
    return result?.data
  }
}
