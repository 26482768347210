import { getCurrency } from '@/components/currency'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

function pad(value, length) {
  if (value === undefined && value === null) {
    return ''
  }
  const len = !length ? 2 : length
  var n = value + ''
  while (n.length < len) {
    n = '0' + n
  }
  return n
}
function formatNumber(value, locale, precision = 2) {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: precision
  }).format(value)
}
function formatNumberWithRounding(value, locale, precision = 2) {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: precision
  }).format(roundAwayFromZero(value, precision))
}
function formatPercentage(value, locale, precision = 2) {
  return new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: precision
  }).format(value)
}
function formatCurrency(value, locale, whiteSpace = false, precision = 2) {
  if (whiteSpace) {
    const formatted = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: getCurrency(locale),
      minimumFractionDigits: precision
    }).format(value).replace(/^(\D+)/, '$1 ')
    return formatted
  } else {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: getCurrency(locale),
      minimumFractionDigits: precision
    }).format(value)
  }
}

function randomDecimal(min, max, places = 2) {
  return roundAwayFromZero(Math.random() * (max - min) + min, places)
}

function randomInt(min, max) {
  return Math.floor(Math.random() * (max - min) + min)
}

export {
  pad,
  formatNumber,
  formatNumberWithRounding,
  formatPercentage,
  formatCurrency,
  randomDecimal,
  randomInt
}
