import auth from '@/auth'
import HttpStatus from '@/components/http-status'

function isInvalidToken(error) {
  const status = error && error.response ? error.response.status : ''
  // const statusText = error.response.statusText
  // return (status === HttpStatus.UNAUTHORIZED && statusText === 'Invalid token')
  return status === HttpStatus.UNAUTHORIZED
}

function isExpiredToken(error) {
  const status = error && error.response ? error.response.status : ''
  const statusText = error && error.response ? error.response.statusText : ''

  return status === HttpStatus.UNAUTHORIZED && statusText === 'Token expired'
}

function refreshToken(request) {
  auth.logout()
}

function getCookie(name) {
  var value = '; ' + document.cookie
  var parts = value.split('; ' + name + '=')
  if (parts.length === 2)
    return parts
      .pop()
      .split(';')
      .shift()
}

function getAuthToken() {
  return getCookie('UniqApi.Token')
}

function parseArrayBufferErrorResponse(data) {
  let response = ''
  if ('TextDecoder' in window) {
    // Decode as UTF-8
    const dataView = new DataView(data)
    const decoder = new TextDecoder('utf8')
    response = JSON.parse(decoder.decode(dataView))
  } else {
    // Fallback decode as ASCII
    const decodedString = String.fromCharCode.apply(null, new Uint8Array(data))
    response = JSON.parse(decodedString)
  }
  return response
}

export default {
  isInvalidToken,
  isExpiredToken,
  refreshToken,
  getAuthToken,
  parseArrayBufferErrorResponse
}
