import CompanySettingRoutes from './route-types'

const CompanySettingView = () => import('./CompanySettingView')
const CompanyDetailSetting = () => import('./CompanyDetailSetting')
const CompanyEmailSetting = () => import('./CompanyEmailSetting')
const CompanyLicenseSetting = () => import('./CompanyLicenseSetting')
const CompanyIntegrationSetting = () => import('./CompanyIntegrationSetting')
const CompanyItemCategorySetting = () => import('./CompanyItemCategorySetting')
const CompanyModulesSetting = () => import('./CompanyModulesSetting')
const CompanyPreferencesSetting = () => import('./CompanyPreferencesSetting')

const CompanyShopTargetsSetting = () => import('./CompanyShopTargetsSetting')

export default [
  {
    path: CompanySettingRoutes.CompanySettingView.path,
    component: CompanySettingView,
    meta: {
      id: 'A190',
      route: 'Company Setting',
      title: 'Company Setting',
      name: 'Company Setting'
    },
    children: [
      {
        path: CompanySettingRoutes.CompanyMonthlySetting.path,
        name: CompanySettingRoutes.CompanyMonthlySetting.name,
        component: CompanyShopTargetsSetting,
        meta: {
          id: 'A190',
          title: 'Shop Targets',
          name: 'Shop Targets'
        }
      },
      {
        path: CompanySettingRoutes.CompanyDetailSetting.path,
        name: CompanySettingRoutes.CompanyDetailSetting.name,
        component: CompanyDetailSetting,
        meta: {
          title: 'Detail',
          name: 'Detail'
        }
      },
      {
        path: CompanySettingRoutes.CompanyLicenseSetting.path,
        name: CompanySettingRoutes.CompanyLicenseSetting.name,
        component: CompanyLicenseSetting,
        meta: {
          title: 'License',
          name: 'License'
        }
      },
      {
        path: CompanySettingRoutes.CompanyModulesSetting.path,
        name: CompanySettingRoutes.CompanyModulesSetting.name,
        component: CompanyModulesSetting,
        meta: {
          title: 'Modules',
          name: 'Modules'
        }
      },
      {
        path: CompanySettingRoutes.CompanyInternalSetting.path,
        name: CompanySettingRoutes.CompanyInternalSetting.name,
        component: CompanyPreferencesSetting,
        meta: {
          title: 'Preferences',
          name: 'Preferences'
        }
      },
      {
        path: CompanySettingRoutes.ItemCategorySetting.path,
        name: CompanySettingRoutes.ItemCategorySetting.name,
        component: CompanyItemCategorySetting,
        meta: {
          title: 'Item Category',
          name: 'Item Category'
        }
      },
      {
        path: CompanySettingRoutes.CompanyExternalSetting.path,
        name: CompanySettingRoutes.CompanyExternalSetting.name,
        component: CompanyIntegrationSetting,
        meta: {
          title: 'Integration',
          name: 'Integration'
        }
      },
      {
        path: CompanySettingRoutes.CompanyEmailSetting.path,
        name: CompanySettingRoutes.CompanyEmailSetting.name,
        component: CompanyEmailSetting,
        meta: {
          title: 'Email',
          name: 'Email'
        }
      }
    ]
  }
]
