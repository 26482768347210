import routeTypes from './route-types'

const ClientToolView = () => import(/* webpackChunkName: "client-tool-chunk" */ './ClientToolView.vue')

export default [
  {
    path: routeTypes.ClientToolView.path,
    name: routeTypes.ClientToolView.name,
    component: ClientToolView,
    meta: {
      id: 'A119',
      route: 'listing',
      title: 'Client Tools'
    }
  }
]