import FqApi from '@/services/fq-api'

export default {
  async getEntity(id, hash) {
    const url = !hash ? `/invoices/${id}` : `/invoices/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getNewEntity() {
    const result = await FqApi.get('/invoices/new')
    return result.data
  },
  async getInvoiceIdByNo(invoiceNo) {
    const result = await FqApi.get(`invoices/invoiceidbyno?invoiceNo=${encodeURIComponent(invoiceNo)}`)
    return result.data
  }
  ,
  async assertInvoiceNoAvailable(invoiceNo, invoiceType) {
    const result = await FqApi.get(`invoices/invoiceno/assertavailable/${invoiceNo}/${invoiceType}`)
    return result.data
  }
}
