export default {
  Quote: 1,
  Excess: 2,
  OwnerContribution: 3,
  Gst: 4,
  Quick: 5,
  Miscellaneous: 6,
  Adjustment: 7,
  Advance: 8,
  getDisplayName(invoiceType) {
    switch (invoiceType) {
      case 1:
        return 'Quote'
      case 2:
        return 'Excess'
      case 3:
        return 'Owner Contribution'
      case 4:
        return 'GST'
      case 5:
        return 'Quick'
      case 6:
        return 'Miscellaneous'
      case 7:
        return 'Adjustment'
      case 8:
        return 'Advance'
      default:
        return 'Unknown'
    }
  }
}
