import Guid from '@/components/Guid'

export default class PurchaseOrderReceiptItemModel {
  constructor(companyId, creditorInvoiceId, vendorId, purchaseOrderItemId,
    receivedDate = '', description = '', itemNo = '', unitCost = 0, received = 0, account = null, gstAmount = 0, totalAmount = 0, dealerCost = 0, quote2ItemId = null,
    itemType = null, // beforeGst = 0, afterGst = 0,
    side = '', orderQty = 0, totalReceivedQty = 0, returnedQty = 0, partNo = '' ) {
    const owning = totalReceivedQty - returnedQty
    this.account = account
    // this.afterGst = afterGst
    // this.beforeGst = beforeGst
    this.companyId = companyId
    this.creditorInvoiceId = creditorInvoiceId
    this.creditReceiptId = Guid.newGuid(),
    this.dealerCost = dealerCost
    this.deleted = false
    this.description = description
    this.gstAmount = gstAmount
    this.isDeleted = false
    this.isNew = true
    this.isDirty = false
    this.itemNo = itemNo
    this.itemType = itemType
    this.orderQty = orderQty
    this.owning = owning
    this.partNo = partNo
    this.purchaseOrderItemId = purchaseOrderItemId
    this.quote2ItemId = quote2ItemId
    this.vendorId = vendorId
    this.received = received
    this.receivedDate = receivedDate
    this.side = side
    this.totalAmount = totalAmount
    this.totalReceivedQty = totalReceivedQty
    this.returnedQty = returnedQty
    this.unitCost = unitCost
    this.createdBy = ''
    this.createdDate = ''
    this.modifiedBy = ''
    this.modifiedDate = ''
    this.isDeleted = false
    this.isNew = true
  }
}

// export default class PurchaseOrderReceiptItemModel {
//   constructor(companyId, creditorInvoiceId, vendorId, purchaseOrderItemId,
//     receivedDate = null, description = '', itemNo = '', unitCost = 0, received = 0, account = null, gstAmount = 0, totalAmount = 0, dealerCost = 0, quote2ItemId = null ) {
//     // const dealerCost = unitPrice * quantity
//     this.companyId = companyId
//     this.creditorInvoiceId = creditorInvoiceId
//     this.creditReceiptId = Guid.newGuid(),

//     this.vendorId = vendorId
//     this.purchaseOrderItemId = purchaseOrderItemId
//     this.receivedDate = receivedDate
//     this.description = description
//     // this.purchaseOrderItemId = purchaseOrderItemId
//     this.itemNo = itemNo
//     this.unitCost = unitCost
//     this.received = received
//     this.account = account
//     this.gstAmount = gstAmount
//     this.totalAmount = totalAmount
//     this.dealerCost = dealerCost
//     this.quote2ItemId = quote2ItemId
//     this.createdBy = ''
//     this.createdDate = ''
//     this.modifiedBy = ''
//     this.modifiedDate = ''
//     this.deleted = false
//     this.isDeleted = false
//     this.isNew = true
//   }
// }