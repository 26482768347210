import DashboardRoutes from './route-types'

import DashboardView from './DashboardView'

export default [
  {
    path: DashboardRoutes.DashboardView.path,
    name: DashboardRoutes.DashboardView.name,
    component: DashboardView,
    meta: {
      title: 'FlexiQuote Dashboard'
    }
  }
]
