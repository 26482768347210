import UtilitiesRoutes from './route-types'

const OrmUtilitiesView = (r) => require.ensure([], () => r(require('./orm/OrmUtilitiesView.vue')), 'support-utilities-chunk')
const OrmUtilitiesDetail = (r) => require.ensure([], () => r(require('./orm/OrmUtilitiesDetail.vue')), 'support-utilities-chunk')
const PnetUtilitiesView = r => require.ensure([], () => r(require('./pnet/PnetUtilitiesView.vue')), 'support-utilities-chunk')
const PnetUtilitiesDetail = r => require.ensure([], () => r(require('./pnet/PnetUtilitiesDetail.vue')), 'support-utilities-chunk')

export default [
  {
    path: UtilitiesRoutes.OrmUtilitiesDetail.path,
    name: '',
    component: OrmUtilitiesView,
    // props: true,
    props: route => ({
      isNew: route.meta.isNew,
      returnUrl: route.query.returnUrl || route.meta.returnUrl
    }),
    meta: {
      route: 'detail',
      title: 'ORM Utilities',
      isNew: false
    },
    children: [
      {
        path: '',
        name: UtilitiesRoutes.OrmUtilitiesDetail.name,
        component: OrmUtilitiesDetail,
        // props: true,
        meta: {
          route: 'detail',
          title: 'Detail'
        }
      }
    ]
  },
  {
    path: UtilitiesRoutes.PnetUtilitiesDetail.path,
    name: '',
    component: PnetUtilitiesView,
    // props: true,
    props: route => ({
      isNew: route.meta.isNew,
      returnUrl: route.query.returnUrl || route.meta.returnUrl
    }),
    meta: {
      route: 'detail',
      title: 'PNET Utilities',
      isNew: false
    },
    children: [
      {
        path: '',
        name: UtilitiesRoutes.PnetUtilitiesDetail.name,
        component: PnetUtilitiesDetail,
        // props: true,
        meta: {
          route: 'detail',
          title: 'Detail'
        }
      }
    ]
  }
]
