export default {
  // 0: 'Company',
  Customer: 1,
  Contact: 2,
  Vendor: 3,
  Insurer: 4
  // 5: 'UserProfile',
  // 6: 'Superannuation',
  // 7: 'CustomerCompany'
}
