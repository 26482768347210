/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.QuickInvoiceFilter = (function() {

    /**
     * Namespace QuickInvoiceFilter.
     * @exports QuickInvoiceFilter
     * @namespace
     */
    var QuickInvoiceFilter = {};

    QuickInvoiceFilter.QuickInvoiceFilterModel = (function() {

        /**
         * Properties of a QuickInvoiceFilterModel.
         * @memberof QuickInvoiceFilter
         * @interface IQuickInvoiceFilterModel
         * @property {string|null} [invoiceNo] QuickInvoiceFilterModel invoiceNo
         * @property {string|null} [assetName] QuickInvoiceFilterModel assetName
         * @property {string|null} [reference] QuickInvoiceFilterModel reference
         * @property {string|null} [dateFrom] QuickInvoiceFilterModel dateFrom
         * @property {string|null} [dateTo] QuickInvoiceFilterModel dateTo
         * @property {string|null} [sortColumn] QuickInvoiceFilterModel sortColumn
         * @property {string|null} [sortOrder] QuickInvoiceFilterModel sortOrder
         * @property {number|null} [pageIndex] QuickInvoiceFilterModel pageIndex
         * @property {number|null} [pageSize] QuickInvoiceFilterModel pageSize
         */

        /**
         * Constructs a new QuickInvoiceFilterModel.
         * @memberof QuickInvoiceFilter
         * @classdesc Represents a QuickInvoiceFilterModel.
         * @implements IQuickInvoiceFilterModel
         * @constructor
         * @param {QuickInvoiceFilter.IQuickInvoiceFilterModel=} [p] Properties to set
         */
        function QuickInvoiceFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QuickInvoiceFilterModel invoiceNo.
         * @member {string|null|undefined} invoiceNo
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        QuickInvoiceFilterModel.prototype.invoiceNo = null;

        /**
         * QuickInvoiceFilterModel assetName.
         * @member {string|null|undefined} assetName
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        QuickInvoiceFilterModel.prototype.assetName = null;

        /**
         * QuickInvoiceFilterModel reference.
         * @member {string|null|undefined} reference
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        QuickInvoiceFilterModel.prototype.reference = null;

        /**
         * QuickInvoiceFilterModel dateFrom.
         * @member {string|null|undefined} dateFrom
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        QuickInvoiceFilterModel.prototype.dateFrom = null;

        /**
         * QuickInvoiceFilterModel dateTo.
         * @member {string|null|undefined} dateTo
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        QuickInvoiceFilterModel.prototype.dateTo = null;

        /**
         * QuickInvoiceFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        QuickInvoiceFilterModel.prototype.sortColumn = null;

        /**
         * QuickInvoiceFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        QuickInvoiceFilterModel.prototype.sortOrder = null;

        /**
         * QuickInvoiceFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        QuickInvoiceFilterModel.prototype.pageIndex = null;

        /**
         * QuickInvoiceFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        QuickInvoiceFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * QuickInvoiceFilterModel _invoiceNo.
         * @member {"invoiceNo"|undefined} _invoiceNo
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceFilterModel.prototype, "_invoiceNo", {
            get: $util.oneOfGetter($oneOfFields = ["invoiceNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickInvoiceFilterModel _assetName.
         * @member {"assetName"|undefined} _assetName
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceFilterModel.prototype, "_assetName", {
            get: $util.oneOfGetter($oneOfFields = ["assetName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickInvoiceFilterModel _reference.
         * @member {"reference"|undefined} _reference
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceFilterModel.prototype, "_reference", {
            get: $util.oneOfGetter($oneOfFields = ["reference"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickInvoiceFilterModel _dateFrom.
         * @member {"dateFrom"|undefined} _dateFrom
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceFilterModel.prototype, "_dateFrom", {
            get: $util.oneOfGetter($oneOfFields = ["dateFrom"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickInvoiceFilterModel _dateTo.
         * @member {"dateTo"|undefined} _dateTo
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceFilterModel.prototype, "_dateTo", {
            get: $util.oneOfGetter($oneOfFields = ["dateTo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickInvoiceFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickInvoiceFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickInvoiceFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuickInvoiceFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @instance
         */
        Object.defineProperty(QuickInvoiceFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified QuickInvoiceFilterModel message. Does not implicitly {@link QuickInvoiceFilter.QuickInvoiceFilterModel.verify|verify} messages.
         * @function encode
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @static
         * @param {QuickInvoiceFilter.IQuickInvoiceFilterModel} m QuickInvoiceFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuickInvoiceFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.invoiceNo != null && Object.hasOwnProperty.call(m, "invoiceNo"))
                w.uint32(10).string(m.invoiceNo);
            if (m.assetName != null && Object.hasOwnProperty.call(m, "assetName"))
                w.uint32(18).string(m.assetName);
            if (m.reference != null && Object.hasOwnProperty.call(m, "reference"))
                w.uint32(26).string(m.reference);
            if (m.dateFrom != null && Object.hasOwnProperty.call(m, "dateFrom"))
                w.uint32(34).string(m.dateFrom);
            if (m.dateTo != null && Object.hasOwnProperty.call(m, "dateTo"))
                w.uint32(42).string(m.dateTo);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(50).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(58).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(64).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(72).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified QuickInvoiceFilterModel message, length delimited. Does not implicitly {@link QuickInvoiceFilter.QuickInvoiceFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @static
         * @param {QuickInvoiceFilter.IQuickInvoiceFilterModel} message QuickInvoiceFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuickInvoiceFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QuickInvoiceFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {QuickInvoiceFilter.QuickInvoiceFilterModel} QuickInvoiceFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuickInvoiceFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.QuickInvoiceFilter.QuickInvoiceFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.invoiceNo = r.string();
                    break;
                case 2:
                    m.assetName = r.string();
                    break;
                case 3:
                    m.reference = r.string();
                    break;
                case 4:
                    m.dateFrom = r.string();
                    break;
                case 5:
                    m.dateTo = r.string();
                    break;
                case 6:
                    m.sortColumn = r.string();
                    break;
                case 7:
                    m.sortOrder = r.string();
                    break;
                case 8:
                    m.pageIndex = r.int32();
                    break;
                case 9:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a QuickInvoiceFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof QuickInvoiceFilter.QuickInvoiceFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {QuickInvoiceFilter.QuickInvoiceFilterModel} QuickInvoiceFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuickInvoiceFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return QuickInvoiceFilterModel;
    })();

    return QuickInvoiceFilter;
})();

module.exports = $root;
