const map = require('./locale-currency-map')
const currencySymbolMap = require('./currency-symbol-map')

/**
 * Get country code from locale
 * @param {String} locale e.g. 'en-AU'
 * @return {String} country code
 */
const getCountryCode = function(localeString) {
  let components = localeString.split('_')
  if (components.length === 2) {
    return components.pop()
  }
  components = localeString.split('-')
  if (components.length === 2) {
    return components.pop()
  }
  return localeString
}

/**
 * Get currency symbol
 * @param {String} currencyCode e.g. 'AU'
 * @return {String} currency symbol e.g. '$', '£'
 */
const getSymbolFromCurrency = function(currencyCode) {
  if (typeof currencyCode !== 'string') return undefined
  var code = currencyCode.toUpperCase()
  if (!currencySymbolMap.hasOwnProperty(code)) return undefined
  return currencySymbolMap[code]
}

/**
 * Get currency code
 * @param {String} locale e.g. 'en-AU'
 * @return {String} currency code e.g. 'AUD', 'USD'
 */
const getCurrency = function(locale) {
  const countryCode = getCountryCode(locale).toUpperCase()
  if (countryCode in map) {
    return map[countryCode]
  }
  return null
}

// module.exports.getCurrency = getCurrency

/**
 * Get locales
 * @param {String} currencyCode e.g. 'AUD', 'USD'
 * @return {Array}
 */
const getLocales = function(currencyCode) {
  currencyCode = currencyCode.toUpperCase()
  var locales = []
  for (const countryCode in map) {
    if (map[countryCode] === currencyCode) {
      locales.push(countryCode)
    }
  }
  return locales
}

/**
 * Get currency symbol
 * @param {String} locale e.g. 'en-AU'
 * @return {String} currency code '$','£'
 */
const getCurrencySymbol = function(locale) {
  return getSymbolFromCurrency(getCurrency(locale))
}

export { getCountryCode, getSymbolFromCurrency, getCurrency, getLocales, getCurrencySymbol }
