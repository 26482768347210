export default class PricePartsDetailInfo {
  constructor() {
    this.MarginId = '',
    this.SelectedDate='',
    this.SelectedTime='',
    this.SendPhotos = true,
    this.SelectedPhotos = null,
    this.Notes = '',
    this.editDefaultOption=null
  }
}
