import FqApi from '@/services/fq-api'
import ProtoBufApi from '@/services/protobuf-api'
import { GlAccountTypes } from '@/enums'
import ProtoBuf from 'protobufjs'

export default {
  async getEntity(id, hash = '') {
    const url = !hash ? `/customers/${id}` : `/customers/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getNewEntity() {
    const result = await FqApi.get('/customers/new')
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseCustomerFilter(filter)
    const url = `/customers/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseCustomerFilter(filter) {
    const protoDefinition = require('../../assets/proto/customer/CustomerFilter.proto')
    const filterModel = protoDefinition.CustomerFilter.CustomerFilterModel
    var buffer = filterModel.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  putCustomer(entity, deepDiff) {
    if (deepDiff) {
      entity.deepDiff = JSON.stringify(deepDiff)
    }
    return FqApi.put(`/customers/${entity.id}`, entity)
  },
  async postCustomer(customer) {
    try {
      await FqApi.post('/customers', customer)
      return true
    } catch (e) {
      // do the error handling etc
      throw e
    }
  },
  searchCustomerByName(key) {
    return FqApi.get('/customers/searchname?key=' + key)
  },
  async getCustomerDropdown(filter) {
    const serialised = this.serialiseCustomerDropdownFilter(filter)
    const result = await FqApi.get(`/customers/listcombo/${serialised}`)
    return result.data
  },
  async getCustomerDropdownProto(filter) {
    const serialised = this.serialiseCustomerDropdownFilter(filter)
    const result = await ProtoBufApi(`/customers/protobuf/listcombo/${serialised}`)
    const proto = require('../../assets/proto/customer/CustomerDropdown.proto')
    const message = proto.CustomerDropdown.CustomerDropdownModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.assets
  },
  serialiseCustomerDropdownFilter(filter) {
    const entityProto = require('../../assets/proto/customer/CustomerDropdownFilter.proto')
    const filterMessage = entityProto.CustomerDropdownFilter.CustomerDropdownFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  searchCustomerById(customerId) {
    var result = FqApi.get('/customers/searchid?customerId=' + customerId)
    return result
  },
  async getGlAccountListComboProto() {
    const result = await ProtoBufApi(`/protobuf/glaccounts/listcombo?glTypes=${GlAccountTypes.Income}`)
    const glAccountProto = require('../../assets/proto/listcombo/GlAccount.proto')
    const accountMessage = await glAccountProto.GlAccount.GlAccountListComboModel
    const entity = accountMessage.decode(new Uint8Array(result.data))
    return entity.glAccounts
  },
  async assertUsed(entityId) {
    const result = await FqApi.get(`/customers/assertused/${entityId}`)
    return result.data
  },
  async assertNameExists(id, name) {
    if (name) {
      const result = await FqApi.get(`/customers/assertexists/${id}?name=${name}`)
      return result.data
    } else {
      return false
    }
  },
  async getVehicles(customerId) {
    const result = await FqApi.get(`/customers/vehicles/${customerId}`)
    return result.data
  }
}
