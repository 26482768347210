import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({ $showSpinnerRequestCount: 'showSpinnerRequestCount' })
  },
  methods: {
    ...mapActions(['$showSpinner', '$hideSpinner', '$resetSpinner'])
  }
}
