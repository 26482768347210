import FqApi from '@/services/fq-api'
import ProtoBufApi from '@/services/protobuf-api'

export default {
  async getItemLabourStandardByItemNos(itemNos) {
    let url = 'itemlabourstandards/byitemnos'
    const requestQuery = itemNos.join('&itemNos=')
    if (requestQuery) {
      url = `${url}?itemNos=${requestQuery}`
    }
    const result = await FqApi.get(url)
    return result.data
  },
  async getNtarLoadingItemsProto() {
    const result = await ProtoBufApi('/items/protobuf/ntarloadingitems')
    const proto = require('../../../assets/proto/item/NtarLoadingItems.proto')
    const message = proto.NtarLoadingItems.NtarLoadingItems
    const entity = message.decode(new Uint8Array(result.data))
    return entity.items
  },
  async getNtarOtherLoadingItemsProto() {
    const result = await ProtoBufApi('/items/protobuf/ntarotherloadingitems')
    const proto = require('../../../assets/proto/item/NtarOtherLoadingItems.proto')
    const message = proto.NtarOtherLoadingItems.NtarOtherLoadingItems
    const entity = message.decode(new Uint8Array(result.data))
    return entity.items
  },
  async getNtarLoadingValuesProto() {
    const result = await ProtoBufApi('/items/protobuf/ntarloadingvalues')
    const proto = require('../../../assets/proto/item/NtarLoadingValues.proto')
    const message = proto.NtarLoadingValues.NtarLoadingValues
    const entity = message.decode(new Uint8Array(result.data))
    return entity.items
  }
}
