/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.QuoteAvailableLabourFilter = (function() {

    /**
     * Namespace QuoteAvailableLabourFilter.
     * @exports QuoteAvailableLabourFilter
     * @namespace
     */
    var QuoteAvailableLabourFilter = {};

    QuoteAvailableLabourFilter.QuoteAvailableLabourFilter = (function() {

        /**
         * Properties of a QuoteAvailableLabourFilter.
         * @memberof QuoteAvailableLabourFilter
         * @interface IQuoteAvailableLabourFilter
         * @property {string|null} [countryId] QuoteAvailableLabourFilter countryId
         * @property {string|null} [companyId] QuoteAvailableLabourFilter companyId
         * @property {string|null} [query] QuoteAvailableLabourFilter query
         * @property {string|null} [butterflyCode] QuoteAvailableLabourFilter butterflyCode
         * @property {string|null} [scheduleId] QuoteAvailableLabourFilter scheduleId
         * @property {string|null} [bodyId] QuoteAvailableLabourFilter bodyId
         * @property {string|null} [bodySize] QuoteAvailableLabourFilter bodySize
         * @property {string|null} [paintGroup] QuoteAvailableLabourFilter paintGroup
         * @property {number|null} [variantId] QuoteAvailableLabourFilter variantId
         * @property {string|null} [nvic] QuoteAvailableLabourFilter nvic
         * @property {string|null} [sortColumn] QuoteAvailableLabourFilter sortColumn
         * @property {string|null} [sortOrder] QuoteAvailableLabourFilter sortOrder
         * @property {number|null} [pageIndex] QuoteAvailableLabourFilter pageIndex
         * @property {number|null} [pageSize] QuoteAvailableLabourFilter pageSize
         */

        /**
         * Constructs a new QuoteAvailableLabourFilter.
         * @memberof QuoteAvailableLabourFilter
         * @classdesc Represents a QuoteAvailableLabourFilter.
         * @implements IQuoteAvailableLabourFilter
         * @constructor
         * @param {QuoteAvailableLabourFilter.IQuoteAvailableLabourFilter=} [p] Properties to set
         */
        function QuoteAvailableLabourFilter(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QuoteAvailableLabourFilter countryId.
         * @member {string|null|undefined} countryId
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        QuoteAvailableLabourFilter.prototype.countryId = null;

        /**
         * QuoteAvailableLabourFilter companyId.
         * @member {string|null|undefined} companyId
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        QuoteAvailableLabourFilter.prototype.companyId = null;

        /**
         * QuoteAvailableLabourFilter query.
         * @member {string|null|undefined} query
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        QuoteAvailableLabourFilter.prototype.query = null;

        /**
         * QuoteAvailableLabourFilter butterflyCode.
         * @member {string|null|undefined} butterflyCode
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        QuoteAvailableLabourFilter.prototype.butterflyCode = null;

        /**
         * QuoteAvailableLabourFilter scheduleId.
         * @member {string|null|undefined} scheduleId
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        QuoteAvailableLabourFilter.prototype.scheduleId = null;

        /**
         * QuoteAvailableLabourFilter bodyId.
         * @member {string|null|undefined} bodyId
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        QuoteAvailableLabourFilter.prototype.bodyId = null;

        /**
         * QuoteAvailableLabourFilter bodySize.
         * @member {string|null|undefined} bodySize
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        QuoteAvailableLabourFilter.prototype.bodySize = null;

        /**
         * QuoteAvailableLabourFilter paintGroup.
         * @member {string|null|undefined} paintGroup
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        QuoteAvailableLabourFilter.prototype.paintGroup = null;

        /**
         * QuoteAvailableLabourFilter variantId.
         * @member {number|null|undefined} variantId
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        QuoteAvailableLabourFilter.prototype.variantId = null;

        /**
         * QuoteAvailableLabourFilter nvic.
         * @member {string|null|undefined} nvic
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        QuoteAvailableLabourFilter.prototype.nvic = null;

        /**
         * QuoteAvailableLabourFilter sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        QuoteAvailableLabourFilter.prototype.sortColumn = null;

        /**
         * QuoteAvailableLabourFilter sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        QuoteAvailableLabourFilter.prototype.sortOrder = null;

        /**
         * QuoteAvailableLabourFilter pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        QuoteAvailableLabourFilter.prototype.pageIndex = null;

        /**
         * QuoteAvailableLabourFilter pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        QuoteAvailableLabourFilter.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * QuoteAvailableLabourFilter _countryId.
         * @member {"countryId"|undefined} _countryId
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableLabourFilter.prototype, "_countryId", {
            get: $util.oneOfGetter($oneOfFields = ["countryId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableLabourFilter _companyId.
         * @member {"companyId"|undefined} _companyId
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableLabourFilter.prototype, "_companyId", {
            get: $util.oneOfGetter($oneOfFields = ["companyId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableLabourFilter _query.
         * @member {"query"|undefined} _query
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableLabourFilter.prototype, "_query", {
            get: $util.oneOfGetter($oneOfFields = ["query"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableLabourFilter _butterflyCode.
         * @member {"butterflyCode"|undefined} _butterflyCode
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableLabourFilter.prototype, "_butterflyCode", {
            get: $util.oneOfGetter($oneOfFields = ["butterflyCode"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableLabourFilter _scheduleId.
         * @member {"scheduleId"|undefined} _scheduleId
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableLabourFilter.prototype, "_scheduleId", {
            get: $util.oneOfGetter($oneOfFields = ["scheduleId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableLabourFilter _bodyId.
         * @member {"bodyId"|undefined} _bodyId
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableLabourFilter.prototype, "_bodyId", {
            get: $util.oneOfGetter($oneOfFields = ["bodyId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableLabourFilter _bodySize.
         * @member {"bodySize"|undefined} _bodySize
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableLabourFilter.prototype, "_bodySize", {
            get: $util.oneOfGetter($oneOfFields = ["bodySize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableLabourFilter _paintGroup.
         * @member {"paintGroup"|undefined} _paintGroup
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableLabourFilter.prototype, "_paintGroup", {
            get: $util.oneOfGetter($oneOfFields = ["paintGroup"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableLabourFilter _variantId.
         * @member {"variantId"|undefined} _variantId
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableLabourFilter.prototype, "_variantId", {
            get: $util.oneOfGetter($oneOfFields = ["variantId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableLabourFilter _nvic.
         * @member {"nvic"|undefined} _nvic
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableLabourFilter.prototype, "_nvic", {
            get: $util.oneOfGetter($oneOfFields = ["nvic"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableLabourFilter _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableLabourFilter.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableLabourFilter _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableLabourFilter.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableLabourFilter _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableLabourFilter.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableLabourFilter _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableLabourFilter.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified QuoteAvailableLabourFilter message. Does not implicitly {@link QuoteAvailableLabourFilter.QuoteAvailableLabourFilter.verify|verify} messages.
         * @function encode
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @static
         * @param {QuoteAvailableLabourFilter.IQuoteAvailableLabourFilter} m QuoteAvailableLabourFilter message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuoteAvailableLabourFilter.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.countryId != null && Object.hasOwnProperty.call(m, "countryId"))
                w.uint32(10).string(m.countryId);
            if (m.companyId != null && Object.hasOwnProperty.call(m, "companyId"))
                w.uint32(18).string(m.companyId);
            if (m.query != null && Object.hasOwnProperty.call(m, "query"))
                w.uint32(26).string(m.query);
            if (m.butterflyCode != null && Object.hasOwnProperty.call(m, "butterflyCode"))
                w.uint32(34).string(m.butterflyCode);
            if (m.scheduleId != null && Object.hasOwnProperty.call(m, "scheduleId"))
                w.uint32(42).string(m.scheduleId);
            if (m.bodyId != null && Object.hasOwnProperty.call(m, "bodyId"))
                w.uint32(50).string(m.bodyId);
            if (m.bodySize != null && Object.hasOwnProperty.call(m, "bodySize"))
                w.uint32(58).string(m.bodySize);
            if (m.paintGroup != null && Object.hasOwnProperty.call(m, "paintGroup"))
                w.uint32(66).string(m.paintGroup);
            if (m.variantId != null && Object.hasOwnProperty.call(m, "variantId"))
                w.uint32(72).int32(m.variantId);
            if (m.nvic != null && Object.hasOwnProperty.call(m, "nvic"))
                w.uint32(82).string(m.nvic);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(90).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(98).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(104).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(112).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified QuoteAvailableLabourFilter message, length delimited. Does not implicitly {@link QuoteAvailableLabourFilter.QuoteAvailableLabourFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @static
         * @param {QuoteAvailableLabourFilter.IQuoteAvailableLabourFilter} message QuoteAvailableLabourFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuoteAvailableLabourFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QuoteAvailableLabourFilter message from the specified reader or buffer.
         * @function decode
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {QuoteAvailableLabourFilter.QuoteAvailableLabourFilter} QuoteAvailableLabourFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuoteAvailableLabourFilter.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.QuoteAvailableLabourFilter.QuoteAvailableLabourFilter();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.countryId = r.string();
                    break;
                case 2:
                    m.companyId = r.string();
                    break;
                case 3:
                    m.query = r.string();
                    break;
                case 4:
                    m.butterflyCode = r.string();
                    break;
                case 5:
                    m.scheduleId = r.string();
                    break;
                case 6:
                    m.bodyId = r.string();
                    break;
                case 7:
                    m.bodySize = r.string();
                    break;
                case 8:
                    m.paintGroup = r.string();
                    break;
                case 9:
                    m.variantId = r.int32();
                    break;
                case 10:
                    m.nvic = r.string();
                    break;
                case 11:
                    m.sortColumn = r.string();
                    break;
                case 12:
                    m.sortOrder = r.string();
                    break;
                case 13:
                    m.pageIndex = r.int32();
                    break;
                case 14:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a QuoteAvailableLabourFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof QuoteAvailableLabourFilter.QuoteAvailableLabourFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {QuoteAvailableLabourFilter.QuoteAvailableLabourFilter} QuoteAvailableLabourFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuoteAvailableLabourFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return QuoteAvailableLabourFilter;
    })();

    return QuoteAvailableLabourFilter;
})();

module.exports = $root;
