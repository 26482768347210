import FqApi from '@/services/fq-api'

export default {
  async hasModule(moduleName) {
    const url = `companies/modules/${moduleName}/active`
    const result = await FqApi.get(url)
    return result.data
  },
  async getExternalSetting(settingName) {
    const url = `companyexternalsettings/${settingName}`
    const result = await FqApi.get(url)
    return result.data
  },
  async externalSettingExists(settingName) {
    const url = `companyexternalsettings/exists/${settingName}`
    const result = await FqApi.get(url)
    return result.data
  }
}
