/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.ReturnPartsItemFilter = (function() {

    /**
     * Namespace ReturnPartsItemFilter.
     * @exports ReturnPartsItemFilter
     * @namespace
     */
    var ReturnPartsItemFilter = {};

    ReturnPartsItemFilter.ReturnPartsItemFilterModel = (function() {

        /**
         * Properties of a ReturnPartsItemFilterModel.
         * @memberof ReturnPartsItemFilter
         * @interface IReturnPartsItemFilterModel
         * @property {string|null} [query] ReturnPartsItemFilterModel query
         * @property {boolean|null} [isReceiptDate] ReturnPartsItemFilterModel isReceiptDate
         * @property {string|null} [receiptDate] ReturnPartsItemFilterModel receiptDate
         * @property {string|null} [vendorId] ReturnPartsItemFilterModel vendorId
         * @property {string|null} [crReturnNo] ReturnPartsItemFilterModel crReturnNo
         * @property {string|null} [sortColumn] ReturnPartsItemFilterModel sortColumn
         * @property {string|null} [sortOrder] ReturnPartsItemFilterModel sortOrder
         * @property {number|null} [pageIndex] ReturnPartsItemFilterModel pageIndex
         * @property {number|null} [pageSize] ReturnPartsItemFilterModel pageSize
         */

        /**
         * Constructs a new ReturnPartsItemFilterModel.
         * @memberof ReturnPartsItemFilter
         * @classdesc Represents a ReturnPartsItemFilterModel.
         * @implements IReturnPartsItemFilterModel
         * @constructor
         * @param {ReturnPartsItemFilter.IReturnPartsItemFilterModel=} [p] Properties to set
         */
        function ReturnPartsItemFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReturnPartsItemFilterModel query.
         * @member {string|null|undefined} query
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        ReturnPartsItemFilterModel.prototype.query = null;

        /**
         * ReturnPartsItemFilterModel isReceiptDate.
         * @member {boolean|null|undefined} isReceiptDate
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        ReturnPartsItemFilterModel.prototype.isReceiptDate = null;

        /**
         * ReturnPartsItemFilterModel receiptDate.
         * @member {string|null|undefined} receiptDate
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        ReturnPartsItemFilterModel.prototype.receiptDate = null;

        /**
         * ReturnPartsItemFilterModel vendorId.
         * @member {string|null|undefined} vendorId
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        ReturnPartsItemFilterModel.prototype.vendorId = null;

        /**
         * ReturnPartsItemFilterModel crReturnNo.
         * @member {string|null|undefined} crReturnNo
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        ReturnPartsItemFilterModel.prototype.crReturnNo = null;

        /**
         * ReturnPartsItemFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        ReturnPartsItemFilterModel.prototype.sortColumn = null;

        /**
         * ReturnPartsItemFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        ReturnPartsItemFilterModel.prototype.sortOrder = null;

        /**
         * ReturnPartsItemFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        ReturnPartsItemFilterModel.prototype.pageIndex = null;

        /**
         * ReturnPartsItemFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        ReturnPartsItemFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ReturnPartsItemFilterModel _query.
         * @member {"query"|undefined} _query
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsItemFilterModel.prototype, "_query", {
            get: $util.oneOfGetter($oneOfFields = ["query"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReturnPartsItemFilterModel _isReceiptDate.
         * @member {"isReceiptDate"|undefined} _isReceiptDate
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsItemFilterModel.prototype, "_isReceiptDate", {
            get: $util.oneOfGetter($oneOfFields = ["isReceiptDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReturnPartsItemFilterModel _receiptDate.
         * @member {"receiptDate"|undefined} _receiptDate
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsItemFilterModel.prototype, "_receiptDate", {
            get: $util.oneOfGetter($oneOfFields = ["receiptDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReturnPartsItemFilterModel _vendorId.
         * @member {"vendorId"|undefined} _vendorId
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsItemFilterModel.prototype, "_vendorId", {
            get: $util.oneOfGetter($oneOfFields = ["vendorId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReturnPartsItemFilterModel _crReturnNo.
         * @member {"crReturnNo"|undefined} _crReturnNo
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsItemFilterModel.prototype, "_crReturnNo", {
            get: $util.oneOfGetter($oneOfFields = ["crReturnNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReturnPartsItemFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsItemFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReturnPartsItemFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsItemFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReturnPartsItemFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsItemFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReturnPartsItemFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @instance
         */
        Object.defineProperty(ReturnPartsItemFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified ReturnPartsItemFilterModel message. Does not implicitly {@link ReturnPartsItemFilter.ReturnPartsItemFilterModel.verify|verify} messages.
         * @function encode
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @static
         * @param {ReturnPartsItemFilter.IReturnPartsItemFilterModel} m ReturnPartsItemFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReturnPartsItemFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.query != null && Object.hasOwnProperty.call(m, "query"))
                w.uint32(10).string(m.query);
            if (m.isReceiptDate != null && Object.hasOwnProperty.call(m, "isReceiptDate"))
                w.uint32(16).bool(m.isReceiptDate);
            if (m.receiptDate != null && Object.hasOwnProperty.call(m, "receiptDate"))
                w.uint32(26).string(m.receiptDate);
            if (m.vendorId != null && Object.hasOwnProperty.call(m, "vendorId"))
                w.uint32(34).string(m.vendorId);
            if (m.crReturnNo != null && Object.hasOwnProperty.call(m, "crReturnNo"))
                w.uint32(50).string(m.crReturnNo);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(58).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(66).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(72).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(80).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified ReturnPartsItemFilterModel message, length delimited. Does not implicitly {@link ReturnPartsItemFilter.ReturnPartsItemFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @static
         * @param {ReturnPartsItemFilter.IReturnPartsItemFilterModel} message ReturnPartsItemFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReturnPartsItemFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReturnPartsItemFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ReturnPartsItemFilter.ReturnPartsItemFilterModel} ReturnPartsItemFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReturnPartsItemFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ReturnPartsItemFilter.ReturnPartsItemFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.query = r.string();
                    break;
                case 2:
                    m.isReceiptDate = r.bool();
                    break;
                case 3:
                    m.receiptDate = r.string();
                    break;
                case 4:
                    m.vendorId = r.string();
                    break;
                case 6:
                    m.crReturnNo = r.string();
                    break;
                case 7:
                    m.sortColumn = r.string();
                    break;
                case 8:
                    m.sortOrder = r.string();
                    break;
                case 9:
                    m.pageIndex = r.int32();
                    break;
                case 10:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a ReturnPartsItemFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ReturnPartsItemFilter.ReturnPartsItemFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ReturnPartsItemFilter.ReturnPartsItemFilterModel} ReturnPartsItemFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReturnPartsItemFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return ReturnPartsItemFilterModel;
    })();

    return ReturnPartsItemFilter;
})();

module.exports = $root;
