import * as types from './mutation-types'

export const updateLicenceExpired = ({ commit }, value) => commit(types.UPDATE_LICENCEEXPIRED, value)

// Global mixin version
export const $showSpinner = function({ commit }, value) {
  commit(types.UPDATE_SPINNER_MESSAGE, value || 'Loading...')
  commit(types.SHOW_SPINNER, true)
}

// Global mixin version
export const $hideSpinner = function({ commit }) {
  // commit(types.UPDATE_SPINNER_MESSAGE, 'Loading...')
  commit(types.SHOW_SPINNER, false)
}

// Global mixin version
export const $resetSpinner = function({ commit }) {
  commit(types.RESET_SPINNER)
}
