// import Vue from 'vue'

import { pad, formatNumber, formatNumberWithRounding, formatPercentage, formatCurrency } from '@/utils/NumberFunctions'
import {
  formatDateLocale,
  formatWeekDayDateLocale,
  formatTimeLocale,
  formatDateTimeLocale,
  formatDateUtc,
  formatDateLong,
  DATE_FORMAT,
  firstDayOfMonth,
  lastDayOfMonth,
  getTimezoneOffset,
  getIanaTimezone,
  formatDateTimezone,
  getSiteTimezoneOffset,
  fromISOWithCurrentTime
} from '@/utils/DateFunctions'
import { getCurrencySymbol } from '@/components/currency'
import { formatPascalToDescription } from '@/utils/StringFunctions'

const filters = {
  pad,
  formatNumber,
  formatNumberWithRounding,
  formatPercentage,
  formatCurrency,
  formatDateLocale,
  formatWeekDayDateLocale,
  formatTimeLocale,
  formatDateTimeLocale,
  formatDateUtc,
  formatDateLong,
  getCurrencySymbol,
  DATE_FORMAT,
  firstDayOfMonth,
  lastDayOfMonth,
  getTimezoneOffset,
  getIanaTimezone,
  formatDateTimezone,
  getSiteTimezoneOffset,
  fromISOWithCurrentTime,
  formatPascalToDescription
}

export default filters
