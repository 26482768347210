/**
 * Constants enumerating the HTTP status codes.
 *
 * All status codes defined in RFC1945 (HTTP/1.0, RFC2616 (HTTP/1.1),
 * RFC2518 (WebDAV), RFC6585 (Additional HTTP Status Codes), and
 * RFC7538 (Permanent Redirect) are supported.
 *
 * Based on the org.apache.commons.httpclient.HttpStatus Java API.
 *
 * Ported by Bryce Neal.
 */

var statusCodes = {}

const HttpStatus = {
  ACCEPTED: 202,
  BAD_GATEWAY: 502,
  BAD_REQUEST: 400,
  CONFLICT: 409,
  CONTINUE: 100,
  CREATED: 201,
  EXPECTATION_FAILED: 417,
  FAILED_DEPENDENCY: 424,
  FORBIDDEN: 403,
  GATEWAY_TIMEOUT: 504,
  GONE: 410,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  INSUFFICIENT_SPACE_ON_RESOURCE: 419,
  INSUFFICIENT_STORAGE: 507,
  INTERNAL_SERVER_ERROR: 500,
  LENGTH_REQUIRED: 411,
  LOCKED: 423,
  METHOD_FAILURE: 420,
  METHOD_NOT_ALLOWED: 405,
  MOVED_PERMANENTLY: 301,
  MOVED_TEMPORARILY: 302,
  MULTI_STATUS: 207,
  MULTIPLE_CHOICES: 300,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
  NO_CONTENT: 204,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NOT_ACCEPTABLE: 406,
  NOT_FOUND: 404,
  NOT_IMPLEMENTED: 501,
  NOT_MODIFIED: 304,
  OK: 200,
  PARTIAL_CONTENT: 206,
  PAYMENT_REQUIRED: 402,
  PERMANENT_REDIRECT: 308,
  PRECONDITION_FAILED: 412,
  PRECONDITION_REQUIRED: 428,
  PROCESSING: 102,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  REQUEST_TIMEOUT: 408,
  REQUEST_TOO_LONG: 413,
  REQUEST_URI_TOO_LONG: 414,
  REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  RESET_CONTENT: 205,
  SEE_OTHER: 303,
  SERVICE_UNAVAILABLE: 503,
  SWITCHING_PROTOCOLS: 101,
  TEMPORARY_REDIRECT: 307,
  TOO_MANY_REQUESTS: 429,
  UNAUTHORIZED: 401,
  UNPROCESSABLE_ENTITY: 422,
  UNSUPPORTED_MEDIA_TYPE: 415,
  USE_PROXY: 305
}

statusCodes[HttpStatus.ACCEPTED] = 'Accepted'
statusCodes[HttpStatus.BAD_GATEWAY] = 'Bad Gateway'
statusCodes[HttpStatus.BAD_REQUEST] = 'Bad Request'
statusCodes[HttpStatus.CONFLICT] = 'Conflict'
statusCodes[HttpStatus.CONTINUE] = 'Continue'
statusCodes[HttpStatus.CREATED] = 'Created'
statusCodes[HttpStatus.EXPECTATION_FAILED] = 'Expectation Failed'
statusCodes[HttpStatus.FAILED_DEPENDENCY] = 'Failed Dependency'
statusCodes[HttpStatus.FORBIDDEN] = 'Forbidden'
statusCodes[HttpStatus.GATEWAY_TIMEOUT] = 'Gateway Timeout'
statusCodes[HttpStatus.GONE] = 'Gone'
statusCodes[HttpStatus.HTTP_VERSION_NOT_SUPPORTED] = 'HTTP Version Not Supported'
statusCodes[HttpStatus.INSUFFICIENT_SPACE_ON_RESOURCE] = 'Insufficient Space on Resource'
statusCodes[HttpStatus.INSUFFICIENT_STORAGE] = 'Insufficient Storage'
statusCodes[HttpStatus.INTERNAL_SERVER_ERROR] = 'Server Error'
statusCodes[HttpStatus.LENGTH_REQUIRED] = 'Length Required'
statusCodes[HttpStatus.LOCKED] = 'Locked'
statusCodes[HttpStatus.METHOD_FAILURE] = 'Method Failure'
statusCodes[HttpStatus.METHOD_NOT_ALLOWED] = 'Method Not Allowed'
statusCodes[HttpStatus.MOVED_PERMANENTLY] = 'Moved Permanently'
statusCodes[HttpStatus.MOVED_TEMPORARILY] = 'Moved Temporarily'
statusCodes[HttpStatus.MULTI_STATUS] = 'Multi-Status'
statusCodes[HttpStatus.MULTIPLE_CHOICES] = 'Multiple Choices'
statusCodes[HttpStatus.NETWORK_AUTHENTICATION_REQUIRED] = 'Network Authentication Required'
statusCodes[HttpStatus.NO_CONTENT] = 'No Content'
statusCodes[HttpStatus.NON_AUTHORITATIVE_INFORMATION] = 'Non Authoritative Information'
statusCodes[HttpStatus.NOT_ACCEPTABLE] = 'Not Acceptable'
statusCodes[HttpStatus.NOT_FOUND] = 'Not Found'
statusCodes[HttpStatus.NOT_IMPLEMENTED] = 'Not Implemented'
statusCodes[HttpStatus.NOT_MODIFIED] = 'Not Modified'
statusCodes[HttpStatus.OK] = 'OK'
statusCodes[HttpStatus.PARTIAL_CONTENT] = 'Partial Content'
statusCodes[HttpStatus.PAYMENT_REQUIRED] = 'Payment Required'
statusCodes[HttpStatus.PERMANENT_REDIRECT] = 'Permanent Redirect'
statusCodes[HttpStatus.PRECONDITION_FAILED] = 'Precondition Failed'
statusCodes[HttpStatus.PRECONDITION_REQUIRED] = 'Precondition Required'
statusCodes[HttpStatus.PROCESSING] = 'Processing'
statusCodes[HttpStatus.PROXY_AUTHENTICATION_REQUIRED] = 'Proxy Authentication Required'
statusCodes[HttpStatus.REQUEST_HEADER_FIELDS_TOO_LARGE] = 'Request Header Fields Too Large'
statusCodes[HttpStatus.REQUEST_TIMEOUT] = 'Request Timeout'
statusCodes[HttpStatus.REQUEST_TOO_LONG] = 'Request Entity Too Large'
statusCodes[HttpStatus.REQUEST_URI_TOO_LONG] = 'Request-URI Too Long'
statusCodes[HttpStatus.REQUESTED_RANGE_NOT_SATISFIABLE] = 'Requested Range Not Satisfiable'
statusCodes[HttpStatus.RESET_CONTENT] = 'Reset Content'
statusCodes[HttpStatus.SEE_OTHER] = 'See Other'
statusCodes[HttpStatus.SERVICE_UNAVAILABLE] = 'Service Unavailable'
statusCodes[HttpStatus.SWITCHING_PROTOCOLS] = 'Switching Protocols'
statusCodes[HttpStatus.TEMPORARY_REDIRECT] = 'Temporary Redirect'
statusCodes[HttpStatus.TOO_MANY_REQUESTS] = 'Too Many Requests'
statusCodes[HttpStatus.UNAUTHORIZED] = 'Unauthorized'
statusCodes[HttpStatus.UNPROCESSABLE_ENTITY] = 'Unprocessable Entity'
statusCodes[HttpStatus.UNSUPPORTED_MEDIA_TYPE] = 'Unsupported Media Type'
statusCodes[HttpStatus.USE_PROXY] = 'Use Proxy'

export default HttpStatus

export function getStatusText(statusCode) {
  if (statusCodes.hasOwnProperty(statusCode)) {
    return statusCodes[statusCode]
  } else {
    throw new Error('Status code does not exist: ' + statusCode)
  }
}
