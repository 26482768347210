const booleanProps = {
  allowHTML: true,
  animateFill: true,
  arrow: false,
  flip: true,
  followCursor: false,
  hideOnClick: true,
  ignoreAttributes: false,
  inertia: false,
  inlinePositioning: false,
  interactive: false,
  showOnCreate: false,
  sticky: false,
  touch: true
}
export default {
  animation: 'fade',
  appendTo: () => document.body,
  aria: 'describedby',
  arrowType: 'sharp',
  boundary: 'scrollParent',
  content: '',
  delay: 0,
  distance: 10,
  duration: [300, 250],
  flipBehavior: 'flip',
  getReferenceClientRect: null,
  interactiveBorder: 2,
  interactiveDebounce: 0,
  maxWidth: 350,
  moveTransition: '', // 'transform 0.2s ease-out'
  offset: 0,
  onAfterUpdate() {},
  onBeforeUpdate() {},
  onClickOutside() {},
  onCreate() {},
  onDestroy() {},
  onHidden() {},
  onHide() {},
  onMount() {},
  onShow() {},
  onShown() {},
  onTrigger() {},
  onUntrigger() {},
  placement: 'top',
  plugins: [],
  popperOptions: {},
  role: 'tooltip',
  theme: '',
  trigger: 'mouseenter focus',
  triggerTarget: null,
  zIndex: 9999,
  ...booleanProps
}

export { booleanProps }
