import { ComponentRoute } from '@/classes'

const ReceiptEntryRoutes = {
  ReceiptEntryContainerView: new ComponentRoute('/receiptentry', 'ReceiptEntryContainerView'),
  ReceiptEntryListView: new ComponentRoute('/receiptentry', 'ReceiptEntryListView'),
  FactorInvoiceView: new ComponentRoute('/receiptentry/factorinvoices/:id', 'FactorInvoiceView'),
  FactorInvoiceDetail: new ComponentRoute('/receiptentry/factorinvoices/:id', 'FactorInvoiceDetail'),
  BankUndepositedFundView: new ComponentRoute('/receiptentry/bankundepositedfund/:CompanyId', 'BankUndepositedFundView'),
  BankUndepositedFundDetail: new ComponentRoute('/receiptentry/bankundepositedfund/:CompanyId', 'BankUndepositedFundDetail'),
  ReceiptPaymentView: new ComponentRoute('/receiptentry/receiptpayment/:AssetId', 'ReceiptPaymentView'),
  ReceiptPaymentDetail: new ComponentRoute('/receiptentry/receiptpayment/:AssetId', 'ReceiptPaymentDetail')
}

export default ReceiptEntryRoutes