import routeTypes from './route-types'
import store from '@/store'

const ReceiptEntryContainerView = r => require.ensure([], () => r(require('./ReceiptEntryContainerView.vue')), 'receiptentrylist-chunk')
const ReceiptEntryListView = r => require.ensure([], () => r(require('./ReceiptEntryListView.vue')), 'receiptentrylist-chunk')
const FactorInvoiceView = r => require.ensure([], () => r(require('../factorinvoice/FactorInvoiceView.vue')), 'factorinvoice-chunk')
const FactorInvoiceDetail = r => require.ensure([], () => r(require('../factorinvoice/FactorInvoiceDetail.vue')), 'factorinvoice-chunk')
const BankUndepositedFundView = r => require.ensure([], () => r(require('../bankundepositedfund/BankUndepositedFundView.vue')), 'bankundepositedfund-chunk')
const BankUndepositedFundDetail = r => require.ensure([], () => r(require('../bankundepositedfund/BankUndepositedFundDetail.vue')), 'bankundepositedfund-chunk')
const ReceiptPaymentView = r => require.ensure([], () => r(require('../receiptpayment/ReceiptPaymentView.vue')), 'receiptpayment-chunk')
const ReceiptPaymentDetail = r => require.ensure([], () => r(require('../receiptpayment/ReceiptPaymentDetail.vue')), 'receiptpayment-chunk')

// Get sessionStorage
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|factorinvoice|${query}`)
}
export default [
  {
    path: routeTypes.ReceiptEntryContainerView.path,
    name: routeTypes.ReceiptEntryContainerView.name,
    component: ReceiptEntryContainerView,
    meta: {
      id: 'A013',
      route: 'listing',
      title: 'Receipt Entry',
      fkey: 'receiptentry|filter',
      mkey: 'receiptentry|modules',
      ckey: 'receiptentry|consumables'
      // report: 'RPTDEBTORRECEIPTENTRY',
      // reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    },
    children: [
      {
        path: routeTypes.ReceiptEntryListView.path,
        name: routeTypes.ReceiptEntryListView.name,
        component: ReceiptEntryListView,
        meta: {
          id: 'A013',
          route: 'listing',
          title: '',
          fkey: 'receiptentry|filter',
          mkey: 'receiptentry|modules',
          ckey: 'receiptentry|consumables',
          report: 'RPTDEBTORRECEIPTENTRY',
          reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
        }
      },
      {
        path: routeTypes.FactorInvoiceDetail.path,
        name: '',
        component: FactorInvoiceView,
        props: route => ({
          isNew: route.query.mode === 'new' || route.meta.isNew || getStorage('isNew') === 'true',
          returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl'),
          name: route.query.name || route.meta.name || getStorage('name')
        }),
        meta: {
          id: 'A013',
          route: 'detail',
          title: 'Factor Invoice',
          report: 'AT_CreditorInvoice',
          reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`

        },
        children: [
          {
            path: '',
            name: routeTypes.FactorInvoiceDetail.name,
            component: FactorInvoiceDetail,
            meta: {
              route: 'detail',
              title: 'Detail',
              fkey: 'factorinvoice|filter'
            }
          }
        ]
      },
      {
        path: routeTypes.BankUndepositedFundView.path,
        name: '',
        component: BankUndepositedFundView,
        meta: {
          id: 'A013',
          route: 'detail',
          title: 'Bank Un-Deposited Funds',
          report: 'AT_BankUndeposited',
          reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
        },
        children: [
          {
            path: '',
            name: routeTypes.BankUndepositedFundDetail.name,
            component: BankUndepositedFundDetail,
            meta: {
              route: 'detail',
              title: 'Detail',
              fkey: 'bankundepositedfund|filter'
            }
          }
        ]
      },
      {
        path: routeTypes.ReceiptPaymentView.path,
        name: '',
        component: ReceiptPaymentView,
        meta: {
          id: 'A013',
          route: 'detail',
          title: 'Payment',
          report: 'AT_OfficialReceiptList',
          reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
        },
        children: [
          {
            path: '',
            name: routeTypes.ReceiptPaymentDetail.name,
            component: ReceiptPaymentDetail,
            meta: {
              route: 'detail',
              title: 'Detail',
              fkey: 'receiptpayment|filter'
            }
          }
        ]
      }
    ]
  }
]
