// directives as directives option

// Working & use "import { FocusInserted } from './FocusInsertedDirective"
// export const FocusInserted = {
//   inserted(el, binding, vnode) {
//     el.focus()
//   }
// }

// Working & & use "import FocusInserted from './FocusInsertedDirective"
export default {
  inserted(el, binding, vnode) {
    // const shouldFocus = binding.value === undefined || (binding.value && binding.value.focus)
    // if (!shouldFocus) return
    if (binding.value === undefined || (binding.value && binding.value.focus)) {
      setTimeout(() => {
        el.focus()
      }, 20)
    }
  }
}

// Has UI tabbing issues & not working for all
// export default function (el, binding, vnode) {
//   if (el) {
//     el.focus()
//   }
// }
