/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.QuoteFilter = (function() {

    /**
     * Namespace QuoteFilter.
     * @exports QuoteFilter
     * @namespace
     */
    var QuoteFilter = {};

    QuoteFilter.QuoteFilterModel = (function() {

        /**
         * Properties of a QuoteFilterModel.
         * @memberof QuoteFilter
         * @interface IQuoteFilterModel
         * @property {string|null} [dateFrom] QuoteFilterModel dateFrom
         * @property {string|null} [dateTo] QuoteFilterModel dateTo
         * @property {string|null} [insurerId] QuoteFilterModel insurerId
         * @property {Array.<number>|null} [jobStage] QuoteFilterModel jobStage
         * @property {number|null} [active] QuoteFilterModel active
         * @property {string|null} [invoiceStatus] QuoteFilterModel invoiceStatus
         * @property {string|null} [authorisedStatus] QuoteFilterModel authorisedStatus
         * @property {string|null} [quoteType] QuoteFilterModel quoteType
         * @property {string|null} [sortColumn] QuoteFilterModel sortColumn
         * @property {string|null} [sortOrder] QuoteFilterModel sortOrder
         * @property {number|null} [pageIndex] QuoteFilterModel pageIndex
         * @property {number|null} [pageSize] QuoteFilterModel pageSize
         * @property {string|null} [search] QuoteFilterModel search
         * @property {string|null} [claimsTypeKey] QuoteFilterModel claimsTypeKey
         */

        /**
         * Constructs a new QuoteFilterModel.
         * @memberof QuoteFilter
         * @classdesc Represents a QuoteFilterModel.
         * @implements IQuoteFilterModel
         * @constructor
         * @param {QuoteFilter.IQuoteFilterModel=} [p] Properties to set
         */
        function QuoteFilterModel(p) {
            this.jobStage = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QuoteFilterModel dateFrom.
         * @member {string|null|undefined} dateFrom
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        QuoteFilterModel.prototype.dateFrom = null;

        /**
         * QuoteFilterModel dateTo.
         * @member {string|null|undefined} dateTo
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        QuoteFilterModel.prototype.dateTo = null;

        /**
         * QuoteFilterModel insurerId.
         * @member {string|null|undefined} insurerId
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        QuoteFilterModel.prototype.insurerId = null;

        /**
         * QuoteFilterModel jobStage.
         * @member {Array.<number>} jobStage
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        QuoteFilterModel.prototype.jobStage = $util.emptyArray;

        /**
         * QuoteFilterModel active.
         * @member {number|null|undefined} active
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        QuoteFilterModel.prototype.active = null;

        /**
         * QuoteFilterModel invoiceStatus.
         * @member {string|null|undefined} invoiceStatus
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        QuoteFilterModel.prototype.invoiceStatus = null;

        /**
         * QuoteFilterModel authorisedStatus.
         * @member {string|null|undefined} authorisedStatus
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        QuoteFilterModel.prototype.authorisedStatus = null;

        /**
         * QuoteFilterModel quoteType.
         * @member {string|null|undefined} quoteType
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        QuoteFilterModel.prototype.quoteType = null;

        /**
         * QuoteFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        QuoteFilterModel.prototype.sortColumn = null;

        /**
         * QuoteFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        QuoteFilterModel.prototype.sortOrder = null;

        /**
         * QuoteFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        QuoteFilterModel.prototype.pageIndex = null;

        /**
         * QuoteFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        QuoteFilterModel.prototype.pageSize = null;

        /**
         * QuoteFilterModel search.
         * @member {string|null|undefined} search
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        QuoteFilterModel.prototype.search = null;

        /**
         * QuoteFilterModel claimsTypeKey.
         * @member {string|null|undefined} claimsTypeKey
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        QuoteFilterModel.prototype.claimsTypeKey = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * QuoteFilterModel _dateFrom.
         * @member {"dateFrom"|undefined} _dateFrom
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        Object.defineProperty(QuoteFilterModel.prototype, "_dateFrom", {
            get: $util.oneOfGetter($oneOfFields = ["dateFrom"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteFilterModel _dateTo.
         * @member {"dateTo"|undefined} _dateTo
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        Object.defineProperty(QuoteFilterModel.prototype, "_dateTo", {
            get: $util.oneOfGetter($oneOfFields = ["dateTo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteFilterModel _insurerId.
         * @member {"insurerId"|undefined} _insurerId
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        Object.defineProperty(QuoteFilterModel.prototype, "_insurerId", {
            get: $util.oneOfGetter($oneOfFields = ["insurerId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteFilterModel _active.
         * @member {"active"|undefined} _active
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        Object.defineProperty(QuoteFilterModel.prototype, "_active", {
            get: $util.oneOfGetter($oneOfFields = ["active"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteFilterModel _invoiceStatus.
         * @member {"invoiceStatus"|undefined} _invoiceStatus
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        Object.defineProperty(QuoteFilterModel.prototype, "_invoiceStatus", {
            get: $util.oneOfGetter($oneOfFields = ["invoiceStatus"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteFilterModel _authorisedStatus.
         * @member {"authorisedStatus"|undefined} _authorisedStatus
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        Object.defineProperty(QuoteFilterModel.prototype, "_authorisedStatus", {
            get: $util.oneOfGetter($oneOfFields = ["authorisedStatus"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteFilterModel _quoteType.
         * @member {"quoteType"|undefined} _quoteType
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        Object.defineProperty(QuoteFilterModel.prototype, "_quoteType", {
            get: $util.oneOfGetter($oneOfFields = ["quoteType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        Object.defineProperty(QuoteFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        Object.defineProperty(QuoteFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        Object.defineProperty(QuoteFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        Object.defineProperty(QuoteFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteFilterModel _search.
         * @member {"search"|undefined} _search
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        Object.defineProperty(QuoteFilterModel.prototype, "_search", {
            get: $util.oneOfGetter($oneOfFields = ["search"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteFilterModel _claimsTypeKey.
         * @member {"claimsTypeKey"|undefined} _claimsTypeKey
         * @memberof QuoteFilter.QuoteFilterModel
         * @instance
         */
        Object.defineProperty(QuoteFilterModel.prototype, "_claimsTypeKey", {
            get: $util.oneOfGetter($oneOfFields = ["claimsTypeKey"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified QuoteFilterModel message. Does not implicitly {@link QuoteFilter.QuoteFilterModel.verify|verify} messages.
         * @function encode
         * @memberof QuoteFilter.QuoteFilterModel
         * @static
         * @param {QuoteFilter.IQuoteFilterModel} m QuoteFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuoteFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.dateFrom != null && Object.hasOwnProperty.call(m, "dateFrom"))
                w.uint32(10).string(m.dateFrom);
            if (m.dateTo != null && Object.hasOwnProperty.call(m, "dateTo"))
                w.uint32(18).string(m.dateTo);
            if (m.insurerId != null && Object.hasOwnProperty.call(m, "insurerId"))
                w.uint32(26).string(m.insurerId);
            if (m.jobStage != null && m.jobStage.length) {
                w.uint32(34).fork();
                for (var i = 0; i < m.jobStage.length; ++i)
                    w.int32(m.jobStage[i]);
                w.ldelim();
            }
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(40).int32(m.active);
            if (m.invoiceStatus != null && Object.hasOwnProperty.call(m, "invoiceStatus"))
                w.uint32(50).string(m.invoiceStatus);
            if (m.authorisedStatus != null && Object.hasOwnProperty.call(m, "authorisedStatus"))
                w.uint32(58).string(m.authorisedStatus);
            if (m.quoteType != null && Object.hasOwnProperty.call(m, "quoteType"))
                w.uint32(66).string(m.quoteType);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(74).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(82).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(88).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(96).int32(m.pageSize);
            if (m.search != null && Object.hasOwnProperty.call(m, "search"))
                w.uint32(106).string(m.search);
            if (m.claimsTypeKey != null && Object.hasOwnProperty.call(m, "claimsTypeKey"))
                w.uint32(114).string(m.claimsTypeKey);
            return w;
        };

        /**
         * Encodes the specified QuoteFilterModel message, length delimited. Does not implicitly {@link QuoteFilter.QuoteFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof QuoteFilter.QuoteFilterModel
         * @static
         * @param {QuoteFilter.IQuoteFilterModel} message QuoteFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuoteFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QuoteFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof QuoteFilter.QuoteFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {QuoteFilter.QuoteFilterModel} QuoteFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuoteFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.QuoteFilter.QuoteFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.dateFrom = r.string();
                    break;
                case 2:
                    m.dateTo = r.string();
                    break;
                case 3:
                    m.insurerId = r.string();
                    break;
                case 4:
                    if (!(m.jobStage && m.jobStage.length))
                        m.jobStage = [];
                    if ((t & 7) === 2) {
                        var c2 = r.uint32() + r.pos;
                        while (r.pos < c2)
                            m.jobStage.push(r.int32());
                    } else
                        m.jobStage.push(r.int32());
                    break;
                case 5:
                    m.active = r.int32();
                    break;
                case 6:
                    m.invoiceStatus = r.string();
                    break;
                case 7:
                    m.authorisedStatus = r.string();
                    break;
                case 8:
                    m.quoteType = r.string();
                    break;
                case 9:
                    m.sortColumn = r.string();
                    break;
                case 10:
                    m.sortOrder = r.string();
                    break;
                case 11:
                    m.pageIndex = r.int32();
                    break;
                case 12:
                    m.pageSize = r.int32();
                    break;
                case 13:
                    m.search = r.string();
                    break;
                case 14:
                    m.claimsTypeKey = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a QuoteFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof QuoteFilter.QuoteFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {QuoteFilter.QuoteFilterModel} QuoteFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuoteFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return QuoteFilterModel;
    })();

    return QuoteFilter;
})();

module.exports = $root;
