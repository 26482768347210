import { GstModel } from '@/classes'

export function calcTotalExGst(qty, unitPrice) {
  return qty * unitPrice
}

export function calcGst(qty, unitPrice, gstRate) {
  const value = calcTotalExGst(qty, unitPrice) * (gstRate / 100)
  return Math.roundAwayFromZero(value, 2)
}

export function calcGstFromInclusive(includeGstAmount, gstRate) {
  const gstModel = new GstModel(gstRate)
  // console.log(gstModel)
  const value = gstModel.divisor ? includeGstAmount / gstModel.divisor : 0
  return Math.roundAwayFromZero(value, 2)
}

export function calcBeforeGst(afterGst, gstRate) {
  const gstModel = new GstModel(gstRate)
  const value = afterGst / (gstModel.multiplier)
  return Math.roundAwayFromZero(value, 2)
}

export function roundAwayFromZero(number, precision = 2) {
  return Math.roundAwayFromZero(number, precision)
}

export function roundUp(number, precision = 2) {
  const factor = Math.pow(10, precision)
  const rounded = Math.ceil((number * factor) + Number.EPSILON) / factor
  return rounded
}

// This works as well
// export function roundAwayFromZero(number, precision) {
//   const factor = Math.pow(10, precision)
//   const rounded = Math.round(number * factor) / factor
//   return rounded
// }

// Source from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
// eslint-disable-next-line
;(function() {
  /**
   * Decimal adjustment of a number.
   *
   * @param {String}  type  The type of adjustment.
   * @param {Number}  value The number.
   * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
   * @returns {Number} The adjusted value.
   */
  function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value)
    }
    value = +value
    exp = +exp
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN
    }
    // Shift
    value = value.toString().split('e')
    value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)))
    // Shift back
    value = value.toString().split('e')
    return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp))
  }

  // Decimal round
  if (!Math.roundAwayFromZero) {
    Math.roundAwayFromZero = function (number, precision) {
      const factor = Math.pow(10, precision)
      const rounded = Math.round((number * factor) + (Number.EPSILON * Math.pow(10, 8))) / factor
      return rounded
    }
  }

  // Decimal round
  if (!Math.round10) {
    Math.round10 = function(value, exp) {
      return decimalAdjust('round', value, exp)
    }
  }
  // Decimal floor
  if (!Math.floor10) {
    Math.floor10 = function(value, exp) {
      return decimalAdjust('floor', value, exp)
    }
  }
  // Decimal ceil
  if (!Math.ceil10) {
    Math.ceil10 = function(value, exp) {
      return decimalAdjust('ceil', value, exp)
    }
  }
})()
