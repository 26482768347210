export default {
  AQUA: 'Aqua',
  BEIG: 'Beige',
  BLAK: 'Black',
  BLUE: 'Blue',
  BROW: 'Brown',
  CHAM: 'Champagne',
  GOLD: 'Gold',
  GREN: 'Green',
  GREY: 'Grey',
  MARN: 'Maroon',
  ORAG: 'Orange',
  PINK: 'Pink',
  PURP: 'Purple',
  RED: 'Red',
  SILV: 'Silver',
  WHITE: 'White',
  YELW: 'Yellow',
  Item: ''
}
