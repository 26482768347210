import * as classHelper from '@/classes/classHelper'

export default class QuoteTemplateModel {
  constructor(template = null) {
    this.templateId = null
    this.bodyId = null
    this.companyId = null
    this.modelId = null
    this.name = null
    this.quoteType = null
    this.timesType = null

    if (template) {
      classHelper.shallowCopyValues(this, template)
    }
  }
}