// Unsaved modal box with animate.css
<template>
  <base-modal-ex v-show="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    has-modal-card>
    <div class="model-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <div class="modal-card-title"
            :class="{ 'is-flex-items-center' : showIcon}">
            <span v-if="showIcon"
              class="icon is-medium"
              :class="`has-text-${iconType}`"
              style="margin-right: 0.5rem">
              <i class="mdi mdi-36px"
                :class="`${iconName}`" />
            </span>
            <div>{{ title }}</div>
          </div>
        </header>
        <section class="modal-card-body">
          <div>{{ message }}</div>
        </section>
        <footer class="modal-card-foot"
          :style="`justify-content: flex-${buttonLocation}`">
          <button v-if="!licenceExpired"
            class="button is-success tooltip"
            @click="saveContinue()"
            data-tooltip="Save &amp; continue">Save &amp; Continue</button>
          <button class="button is-warning tooltip"
            @click="skipSave()"
            data-tooltip="Continue without save"
            :disabled="skipDisabled">Skip</button>
          <button class="button tooltip is-tooltip-topright"
            @click="close()"
            data-tooltip="Don't continue">Close</button>
        </footer>
      </div>
    </div>
  </base-modal-ex>
</template>
<script>
import BaseModalEx from '../BaseModalEx'

export default {
  name: 'UnsavedModalPlugin',
  components: {
    BaseModalEx
  },
  props: {
    title: {
      type: String,
      default: 'Unsaved Changes'
    },
    message: {
      type: String,
      default: 'There are unsaved changes. Please select action below'
    },
    licenceExpired: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    },
    skipDisabled: {
      type: Boolean,
      default: false
    },
    onSaveContinue: {
      type: Function,
      default: () => {}
    },
    onSkipSave: {
      type: Function,
      default: () => {}
    },
    onCancel: {
      type: Function,
      default: () => {}
    },
    container: String
  },
  data() {
    return {
      isActive: false,
      parentElement: null
    }
  },
  computed: {},
  watch: {},
  created() {},
  beforeMount() {
    this.setupContainer()
  },
  mounted() {
    this.showModal()
  },
  methods: {
    setupContainer() {
      const container = document.querySelector(this.container) || document.body
      this.parentElement = document.querySelector('#unsaved-modal')

      if (!this.parentElement) {
        this.parentElement = document.createElement('div')
        this.parentElement.setAttribute('id', 'unsaved-modal')
      }
      container.appendChild(this.parentElement)
    },
    showModal() {
      this.parentElement.appendChild(this.$el)
      // this.parentElement.insertAdjacentElement('afterbegin', this.$el)
      this.isActive = true
    },
    skipSave() {
      this.onSkipSave()
      this.close()
    },
    saveContinue() {
      this.onSaveContinue()
      this.close()
    },
    close() {
      this.isActive = false
      // Timeout for the animation complete before destroying
      setTimeout(() => {
        this.$destroy()
        if (typeof this.$el.remove !== 'undefined') {
          this.$el.remove()
        } else if (typeof this.$el.parentNode !== 'undefined') {
          this.$el.parentNode.removeChild(this.$el)
          // this.parentElement.parentNode.removeChild(this.parentElement)
        }
      }, 150)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
