/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.QuoteAvailableMiscFilter = (function() {

    /**
     * Namespace QuoteAvailableMiscFilter.
     * @exports QuoteAvailableMiscFilter
     * @namespace
     */
    var QuoteAvailableMiscFilter = {};

    QuoteAvailableMiscFilter.QuoteAvailableMiscFilter = (function() {

        /**
         * Properties of a QuoteAvailableMiscFilter.
         * @memberof QuoteAvailableMiscFilter
         * @interface IQuoteAvailableMiscFilter
         * @property {string|null} [countryId] QuoteAvailableMiscFilter countryId
         * @property {string|null} [query] QuoteAvailableMiscFilter query
         * @property {string|null} [type] QuoteAvailableMiscFilter type
         * @property {string|null} [sortColumn] QuoteAvailableMiscFilter sortColumn
         * @property {string|null} [sortOrder] QuoteAvailableMiscFilter sortOrder
         * @property {number|null} [pageIndex] QuoteAvailableMiscFilter pageIndex
         * @property {number|null} [pageSize] QuoteAvailableMiscFilter pageSize
         */

        /**
         * Constructs a new QuoteAvailableMiscFilter.
         * @memberof QuoteAvailableMiscFilter
         * @classdesc Represents a QuoteAvailableMiscFilter.
         * @implements IQuoteAvailableMiscFilter
         * @constructor
         * @param {QuoteAvailableMiscFilter.IQuoteAvailableMiscFilter=} [p] Properties to set
         */
        function QuoteAvailableMiscFilter(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QuoteAvailableMiscFilter countryId.
         * @member {string|null|undefined} countryId
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @instance
         */
        QuoteAvailableMiscFilter.prototype.countryId = null;

        /**
         * QuoteAvailableMiscFilter query.
         * @member {string|null|undefined} query
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @instance
         */
        QuoteAvailableMiscFilter.prototype.query = null;

        /**
         * QuoteAvailableMiscFilter type.
         * @member {string|null|undefined} type
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @instance
         */
        QuoteAvailableMiscFilter.prototype.type = null;

        /**
         * QuoteAvailableMiscFilter sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @instance
         */
        QuoteAvailableMiscFilter.prototype.sortColumn = null;

        /**
         * QuoteAvailableMiscFilter sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @instance
         */
        QuoteAvailableMiscFilter.prototype.sortOrder = null;

        /**
         * QuoteAvailableMiscFilter pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @instance
         */
        QuoteAvailableMiscFilter.prototype.pageIndex = null;

        /**
         * QuoteAvailableMiscFilter pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @instance
         */
        QuoteAvailableMiscFilter.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * QuoteAvailableMiscFilter _countryId.
         * @member {"countryId"|undefined} _countryId
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableMiscFilter.prototype, "_countryId", {
            get: $util.oneOfGetter($oneOfFields = ["countryId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableMiscFilter _query.
         * @member {"query"|undefined} _query
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableMiscFilter.prototype, "_query", {
            get: $util.oneOfGetter($oneOfFields = ["query"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableMiscFilter _type.
         * @member {"type"|undefined} _type
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableMiscFilter.prototype, "_type", {
            get: $util.oneOfGetter($oneOfFields = ["type"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableMiscFilter _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableMiscFilter.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableMiscFilter _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableMiscFilter.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableMiscFilter _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableMiscFilter.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * QuoteAvailableMiscFilter _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @instance
         */
        Object.defineProperty(QuoteAvailableMiscFilter.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified QuoteAvailableMiscFilter message. Does not implicitly {@link QuoteAvailableMiscFilter.QuoteAvailableMiscFilter.verify|verify} messages.
         * @function encode
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @static
         * @param {QuoteAvailableMiscFilter.IQuoteAvailableMiscFilter} m QuoteAvailableMiscFilter message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuoteAvailableMiscFilter.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.countryId != null && Object.hasOwnProperty.call(m, "countryId"))
                w.uint32(10).string(m.countryId);
            if (m.query != null && Object.hasOwnProperty.call(m, "query"))
                w.uint32(18).string(m.query);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(26).string(m.type);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(34).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(42).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(48).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(56).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified QuoteAvailableMiscFilter message, length delimited. Does not implicitly {@link QuoteAvailableMiscFilter.QuoteAvailableMiscFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @static
         * @param {QuoteAvailableMiscFilter.IQuoteAvailableMiscFilter} message QuoteAvailableMiscFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuoteAvailableMiscFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QuoteAvailableMiscFilter message from the specified reader or buffer.
         * @function decode
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {QuoteAvailableMiscFilter.QuoteAvailableMiscFilter} QuoteAvailableMiscFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuoteAvailableMiscFilter.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.QuoteAvailableMiscFilter.QuoteAvailableMiscFilter();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.countryId = r.string();
                    break;
                case 2:
                    m.query = r.string();
                    break;
                case 3:
                    m.type = r.string();
                    break;
                case 4:
                    m.sortColumn = r.string();
                    break;
                case 5:
                    m.sortOrder = r.string();
                    break;
                case 6:
                    m.pageIndex = r.int32();
                    break;
                case 7:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a QuoteAvailableMiscFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof QuoteAvailableMiscFilter.QuoteAvailableMiscFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {QuoteAvailableMiscFilter.QuoteAvailableMiscFilter} QuoteAvailableMiscFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuoteAvailableMiscFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return QuoteAvailableMiscFilter;
    })();

    return QuoteAvailableMiscFilter;
})();

module.exports = $root;
