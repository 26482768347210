import routerTypes from './route-types'

const CreditorListListView = r => require.ensure([], () => r(require('./CreditorListListView.vue')), 'creditorlist-chunk')
export default [
  {
    path: routerTypes.CreditorListListView.path,
    name: routerTypes.CreditorListListView.name,
    component: CreditorListListView,
    meta: {
      id: 'A019',
      route: 'listing',
      title: 'Creditor List',
      fkey: 'creditorlist|filter',
      report: 'AT_CreditorListV2',
      reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
    }
  }
]
