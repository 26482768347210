<template>
  <div>
    <div v-if="$userInfo.isSystemAdministrator">
      <app-detail-header :show-spinner="false"
        ref="sectionHeader" />
      <section class="container"
        style="margin-top:1em;">
        <div class="columns">
          <div class="field has-addons column">
            <p class="control has-icons-left">
              <input class="input is-primary"
                id="filter"
                type="text"
                v-model="filterKeyword"
                placeholder="Filter">
              <span class="icon is-left">
                <i class="mdi mdi-magnify mdi-20px" />
              </span>
            </p>
            <p class="control">
              <span class="select is-primary">
                <select v-model="active">
                  <option :value="true">Active</option>
                  <option :value="false">Inactive</option>
                  <option :value="null">All</option>
                </select>
              </span>
            </p>
          </div>

          <div class="field has-addons column is-narrow">
            <p class="control">
              <button class="button is-warning"
                @click="sortData(sort, sortDesc)">
                Sort
              </button>
            </p>
            <p class="control">
              <span class="select is-warning">
                <select v-model="sort"
                  @change="sortData(sort, sortDesc)">
                  <option value="name">Name</option>
                  <option value="code">Code Name</option>
                </select>
              </span>
            </p>
            <p class="control">
              <button class="button is-warning"
                @click="sortData(sort, !sortDesc)">
                <span class="icon">
                  <i class="mdi"
                    :class="{ 'mid-arrow-down-circle': sortDesc, 'mdi-arrow-up-circle': !sortDesc }" />
                </span>
              </button>
            </p>
          </div>
        </div>
        <ul>
          <li v-for="(p, idx) in filteredPermissionRecords"
            class="boxy list-complete-item"
            :key="idx">
            <div class="columns box-head"
              style="border-bottom: 1px dotted #c3c3c3;">
              <div class="column">
                <h2 class="subtitle is-5">
                  <input class="input"
                    type="text"
                    v-model="p.name"
                    placeholder="Module Name"
                    required
                    @change="updateAccess(p)">
                </h2>
              </div>
              <div class="column is-narrow">
                <div class="field has-addons">
                  <p class="control">
                    <input class="input"
                      type="text"
                      v-model="p.codeName"
                      placeholder="Code Name"
                      required
                      @change="updateAccess(p)">
                  </p>
                  <p class="control">
                    <a class="button"
                      @click="p.isActive = !p.isActive">
                      <span class="icon">
                        <i class="mdi mdi-20px"
                          style="color:#c3c3c3"
                          :class="[p.isActive ? 'mdi-trash-can-outline': 'mdi-lifebuoy']" />
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <permission-record-versions :permission-record-id="p.id"
              :versions="p.versions"
              @accessChanged="updateAccess(p)" />
          </li>
        </ul>
      </section>
    </div>
    <div v-else
      class="is-flex is-justify-content-center pt-5 ">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
import PermissionRecordService from './PermissionRecordService'
import PermissionRecordVersions from './PermissionRecordVersions'
import AppDetailHeader from '@/components/AppDetailHeader'
import _sortBy from 'lodash.sortby'
import _debounce from 'lodash.debounce'

export default {
  name: 'PermissionRecordListing',
  components: { PermissionRecordVersions, AppDetailHeader },
  data() {
    return {
      isLoading: true,
      permissionRecords: [],
      filterKeyword: '',
      active: true,
      sort: 'name',
      sortDesc: 'false'
    }
  },

  computed: {
    filteredPermissionRecords: function () {
      const self = this
      let rs = []

      if (this.filterKeyword.length > 0) {
        rs = this.permissionRecords.filter(function (p) {
          return p.name && p.name.toLowerCase().indexOf(self.filterKeyword.toLowerCase()) > -1
        })
      } else {
        rs = this.permissionRecords
      }

      if (self.active !== null) {
        rs = rs.filter(function (p) {
          return p.isActive === self.active
        })
      }

      return rs
    }
  },
  mounted() {
    PermissionRecordService.getPermissionRecords().then(
      (response) => {
        this.permissionRecords = response.data.list
        this.isLoading = false
      },
      () => {
        this.isLoading = false
      }
    )
  },

  methods: {
    getVersions: function (permissionRecord) {
      var rs = []

      for (var i = 1; i <= permissionRecord.versions.length; i++) {
        rs.push(i)
      }

      return rs
    },

    updateAccess: _debounce(async function (access) {
      try {
        await PermissionRecordService.updateAccess(access.id, access)
        this.$notification.success('Success', 'Access updated')
      } catch (e) {
        this.$notification.error('Error', e)
      }
    }, 500),

    sortData: function (col, order) {
      this.sortDesc = order
      const self = this
      let rs = this.permissionRecords

      rs = _sortBy(rs, [
        function (o) {
          if (self.sort === 'name') {
            return o.name
          } else {
            return o.codeName
          }
        }
      ])

      if (this.sortDesc) {
        rs = rs.reverse()
      }

      this.permissionRecords = rs
    }
  }
}
</script>

<style lang="scss" scoped>
.boxy {
  margin-bottom: 1.5em;
  padding: 0.8em;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
}

.box-head {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f6f8f9+0,e5ebee+50,d7dee3+51,f5f7f9+100;White+Gloss */
  background: #f6f8f9;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f8f9', endColorstr='#f5f7f9', GradientType=0);
  /* IE6-9 */
}
</style>
