<template>
  <p>
    Your license
    <span v-if="licenceData.isExpired">expired</span>
    <span v-if="licenceData.isExpiring && !licenceData.isExpired">will expire</span>
    on
    <strong>{{ $filters.formatDateLocale(licenceData.expiryDate, this.$userInfo.locale, 'short') }}</strong>.
    <span v-if="!licenceData.isGracePeriod"> Editing is disabled</span>
    <span v-if="licenceData.isGracePeriod"> Editing will be disabled on
      <strong>{{ $filters.formatDateLocale(graceExpiryDate, this.$userInfo.locale, 'short') }}</strong>.
    </span>
  </p>
</template>

<script>
import { DateTimeFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'ExpiryMessage',
  mixins: [DateTimeFiltersMixin],
  props: {
    licenceData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  computed: {
    // expiryDate() {
    //   const localDate = new Date(this.licenceData.expiryDate)
    //   if (Intl) {
    //     return Intl.DateTimeFormat(this.$userInfo.locale).format(localDate)
    //   } else {
    //     return localDate.toLocaleDateString(this.$userInfo.locale)
    //   }
    // },
    graceExpiryDate() {
      const localDate = new Date(this.licenceData.expiryDate)
      const graceDate = localDate
      graceDate.setDate(localDate.getDate() + this.licenceData.gracePeriod)
      return graceDate
      // if (Intl) {
      //   return Intl.DateTimeFormat(this.$userInfo.locale).format(graceDate)
      // } else {
      //   return graceDate.toLocaleDateString(this.$userInfo.locale)
      // }
    }
  }
}
</script>
