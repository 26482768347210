import routerTypes from './route-types'
import store from '@/store'

// Lazy-load components. Webpack will split each into individual file
const AuditTrailListView = r => require.ensure([], () => r(require('./AuditTrailListView.vue')), 'audittraillist-chunk')
const AuditTrailView = r => require.ensure([], () => r(require('./AuditTrailView.vue')), 'audittrail-chunk')
const AuditTrailDetail = r => require.ensure([], () => r(require('./AuditTrailDetail.vue')), 'audittrail-chunk')

 const menuCode = 'A101'
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|audittrtail|${query}`)
}

export default [
  {
    path: routerTypes.AuditTrailListView.path,
    name: routerTypes.AuditTrailListView.name,
    component: AuditTrailListView,
    meta: {
      id: menuCode,
      route: 'listing',
      title: 'Audittrail',
      fkey: 'audittrail|filter'
    }
   },
   {
    path: '/audittrail/:auditTrailFieldId',
    component: AuditTrailView,
    props: route => ({
      isNew: route.query.mode === 'new' || route.meta.isNew || getStorage('isNew') === 'true',
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl'),
      name: route.query.name || route.meta.name || getStorage('name')
    }),
    meta: {
      id: 'A101',
      route: 'detail',
      title: 'Audittrail'
    },
    query: {
      filter: ''
    },
   children: [
    {
      path: '',
      name: 'AuditTrailDetail',
      component: AuditTrailDetail,
      meta: {
        title: 'Detail',
        fkey: 'audittrail|filter'
      }
    }
  ]
}
]