export const STORE = 'STORE'
export const REMOVE = 'REMOVE'
export const UPDATE_LICENCEEXPIRED = 'UPDATE_LICENCEEXPIRED'
export const SHOW_SPINNER = 'SHOW_SPINNER'
export const RESET_SPINNER = 'RESET_SPINNER'
export const UPDATE_SPINNER_MESSAGE = 'UPDATE_SPINNER_MESSAGE'

// mutation types for entity
export const SetItem = 'setItem'
export const RemoveItem = 'removeItem'
