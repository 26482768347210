import routeTypes from './route-types'
// import store from '@/store'

const PnetMessageListView = r => require.ensure([], () => r(require('./PnetMessageListView.vue')), 'pnetmessagelist-chunk')
const PnetMessageView = r => require.ensure([], () => r(require('./PnetMessageView.vue')), 'pnetmessageview-chunk')
const PnetMessageDetail = r => require.ensure([], () => r(require('./PnetMessageDetail.vue')), 'pnetmessageview-chunk')
const PnetMessageNew = r => require.ensure([], ()=> r(require('./PnetMessageNew.vue')), 'pnetmessage-chunk')
const menuCode = 'A115'

// Get sessionStorage
// const getStorage = function(query) {
//   const sessionId = store.getters['userInfo/info'].sessionId
//   return sessionStorage.getItem(`${sessionId}|pnetmessage|${query}`)
// }

export default [
  {
    path: routeTypes.PnetMessageListView.path,
    name: routeTypes.PnetMessageListView.name,
    component: PnetMessageListView,
    meta: {
      id: menuCode,
      route: 'listing',
      title: 'PNET Messages',
      fkey: 'pnetmessage|filter',
      report: '',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.PnetMessageView.path,
    name: '',
    component: PnetMessageView,
    meta: {
      id: menuCode,
      route: 'detail',
      title: 'PNET Messages',
      isNew: false
    },
    children: [
      {
        path: '',
        name: routeTypes.PnetMessageView.name,
        component: PnetMessageDetail,
        meta: {
          id: menuCode,
          route: 'detail',
          title: 'Detail',
          isNew: false
        }
      }
    ]
  },
  {
    path: routeTypes.PnetMessageNew.path,
    name: routeTypes.PnetMessageNew.name,
    component: PnetMessageNew,
    meta: {
      id: menuCode,
      isNew: false,
      title: 'New Pnet Message'
    }
  }
]
