// import * as types from '../../store/mutation-types'
// const SundryCreditorLocalStorageKey = 'uniq-sundrycreditor-items'

import SundryCreditorService from './SundryCreditorService'
import SundryCreditorRoutes from './route-types'
import StoreUtil from '@/store/utils'
import DeepDiff from 'deep-diff'

const tag = 'sundrycreditor'

const state = {
  // items: [],
  returnRoute: {},
  baseIndex: 0,
  currentIndex: 0,
  snapshots: [],
  readOnlyView: false
}

const getters = {
  returnRoute: state => state.returnRoute,
  snapshots: state => state.snapshots,
  baseSnapshot: state => state.snapshots[state.baseIndex],
  currentSnapshot: state => state.snapshots[state.currentIndex],
  snapshotDiff: state => {
    if (state.snapshots[state.baseIndex] && state.snapshots[state.currentIndex]) {
      return DeepDiff.diff(state.snapshots[state.baseIndex], state.snapshots[state.currentIndex])
    } else {
      return null
    }
  },
  readOnlyView: state => state.readOnlyView
}

const actions = {
  async getStoreItem({ commit }, id) {
    const entity = StoreUtil.getStorage(id, tag) || (await SundryCreditorService.getEntity(id, ''))
    if(entity.exported)
      commit('setReadOnlyView', true)
    StoreUtil.setStorage(entity.id, tag, entity)
    commit('saveInitialSnapshots', entity)
  },
  async editStoreItem({ commit }, id) {
    const entity = StoreUtil.getStorage(id, tag) || (await SundryCreditorService.getEntity(id, ''))
    StoreUtil.setStorage(entity.id, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(SundryCreditorRoutes.SundryCreditorListView.path, entity.id, false)
  },
  async addStoreItem({ commit }) {
    const entity = await SundryCreditorService.getNewEntity()
    const utcDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString()
    entity.invoiceDate = utcDate.split('.')[0] + 'Z'
    StoreUtil.setStorage(entity.id, tag, entity)
    commit('saveInitialSnapshots', entity)
    StoreUtil.routeToDetail(SundryCreditorRoutes.SundryCreditorListView.path, entity.id, true)
  },
  saveSnapshot({ commit }, entity) {
    commit('saveSnapshot', entity)
  },
  clearSnapshots({ commit }, id) {
    StoreUtil.removeStorage(id, tag)
    commit('clearSnapshots')
  },
  setVendor({ commit, state }, updateModel) {
    commit('setVendor', updateModel)
  },
  setContact({ commit, state }, updateModel) {
    commit('setContact', updateModel)
  },
  setReturnRoute({ commit }, route) {
    commit('setReturnRoute', route)
  },
  setReadOnlyView({ commit }, value) {
    commit('setReadOnlyView', value)
  }
}

const mutations = {
  saveInitialSnapshots(state, entity) {
    state.snapshots = []
    state.snapshots.splice(0, 1, entity)
    state.snapshots.splice(1, 1, entity)
    state.currentIndex = state.snapshots.length - 1
  },
  saveSnapshot(state, entity) {
    state.snapshots.splice(state.currentIndex, 1, entity)
  },
  clearSnapshots(state) {
    state.snapshots = []
    state.baseIndex = 0
    state.currentIndex = 0
  },
  setVendor(state, updateModel) {
    const curSundry = state.snapshots[state.currentIndex]
    curSundry.vendor = updateModel.vendor
    curSundry.abn = updateModel.abn
    curSundry.glCode = updateModel.glAccount
  },
  setContact(state, updateModel) {
    const curSundry = state.snapshots[state.currentIndex]
    curSundry.contact = updateModel.contact
  },
  setReturnRoute(state, route) {
    state.returnRoute = route
  },
  setReadOnlyView(state, value) {
    state.readOnlyView = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
