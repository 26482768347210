import ProductionInOutRoutes from './route-types'
import ProductionInOutView from './ProductionInOutView'

// const ProductionInOutView = r => require.ensure([], () => r(require('./ProductionInOutView.vue')), 'productioninoutlist-chunk')

const moduleId = 'A160'

export default [
  {
    path: ProductionInOutRoutes.ProductionInOutView.path,
    name: ProductionInOutRoutes.ProductionInOutView.name,
    component: ProductionInOutView,
    meta: {
      id: moduleId,
      route: 'listing',
      title: 'Due In & Due Out',
      fkey: 'productioninout|filter',
      report: 'rptProductionInOut',
      reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
    }
  }
]
