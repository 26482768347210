import { STORAGE_KEY } from './state'
import * as types from './mutation-types'

const localStoragePlugin = store => {
  store.subscribe((mutation, state) => {
    const syncedData = { auth: state.auth, user: state.user }
    localStorage.setItem(STORAGE_KEY, JSON.stringify(syncedData))

    // Persist entity changes to localStorage
    const setEntityState = (mutationType, getter) => {
      const key = `uniq-${mutationType.split('/')[0]}-${getter}`
      const getterKey = mutationType.replace(types.SetItem, getter)
      const object = store.getters[getterKey]
      if (object !== undefined) {
        localStorage.setItem(key, JSON.stringify(object))
      } else {
        localStorage.removeItem(key)
      }
    }
    const removeEntityState = (mutationType, getter) => {
      const key = `uniq-${mutationType.split('/')[0]}-${getter}`
      localStorage.removeItem(key)
    }

    if (mutation.type.includes(`/${types.SetItem}`)) {
      setEntityState(mutation.type, 'items')
    } else if (mutation.type.includes(`/${types.RemoveItem}`)) {
      removeEntityState(mutation.type, 'items')
    }

    if (mutation.type === 'CLEAR_ALL_DATA') {
      localStorage.removeItem(STORAGE_KEY)
    }
  })
}

// TODO: setup env
// export default process.env.NODE_ENV !== 'production' ? [localStoragePlugin] : [localStoragePlugin]
export default [localStoragePlugin]
