import axios from 'axios'
import auth from '@/auth'
import store from '@/store'
import common from './common'
import HttpStatus from '@/components/http-status'
import { UniqError } from '@/classes'

function getCookie(name) {
  let nameValues = {}
  let cookies = document.cookie.split(';')
  for (let cookie of cookies) {
    let [name, value] = cookie.split('=', 2)
    if (value && name) {
      nameValues[name.trim()] = value.trim()
    }
  }

  return nameValues[name]
}

const service = axios.create({
  baseURL: process.env.VUE_APP_WEBAPI_URI,
  responseType: 'arraybuffer',
  headers: {
    Authorization: 'Bearer ' + getCookie('UniqApi.Token')
  }
})

service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    store.dispatch('$resetSpinner')
    if (common.isExpiredToken(error)) {
      return common.refreshToken(error)
    } else if (common.isInvalidToken(error)) {
      auth.logout()
    } else if (error && error.response && error.response.status && error.response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
      if (error.response.data instanceof ArrayBuffer) {
        const result = common.parseArrayBufferErrorResponse(error.response.data)
        store.dispatch('errors/add', result)
      } else {
        store.dispatch('errors/add', error.response.data)
      }
      return Promise.reject(error)
    } else {
      const status = error.response ? error.response.status : ''
      const statusText = error.response ? error.response.statusText : ''
      const url = error.response ? error.response.config.url : ''
      const e = new UniqError(0, statusText, status, url, error.response.data)
      store.dispatch('errors/add', e)
      return Promise.reject(error)
    }
  }
)

export default service
