import FqApi from '@/services/fq-api'

export default {
  async getJobsCompletedNotInvoiced() {
    const result = await FqApi.get('/jcni/toBeInvoiced')
    return result.data
  },
  async getJobsOverdueToBeInvoiced() {
    const result = await FqApi.get('/jcni/overdueToBeInvoiced')
    return result.data
  },
  async getJobsPastDueDate() {
    const result = await FqApi.get('/jcni/pastDueDate')
    return result.data
  }
}