/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.LabourTypes = (function() {

    /**
     * Namespace LabourTypes.
     * @exports LabourTypes
     * @namespace
     */
    var LabourTypes = {};

    LabourTypes.LabourTypesModel = (function() {

        /**
         * Properties of a LabourTypesModel.
         * @memberof LabourTypes
         * @interface ILabourTypesModel
         * @property {Array.<LabourTypes.LabourTypesModel.ILabourType>|null} [labourTypes] LabourTypesModel labourTypes
         */

        /**
         * Constructs a new LabourTypesModel.
         * @memberof LabourTypes
         * @classdesc Represents a LabourTypesModel.
         * @implements ILabourTypesModel
         * @constructor
         * @param {LabourTypes.ILabourTypesModel=} [p] Properties to set
         */
        function LabourTypesModel(p) {
            this.labourTypes = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * LabourTypesModel labourTypes.
         * @member {Array.<LabourTypes.LabourTypesModel.ILabourType>} labourTypes
         * @memberof LabourTypes.LabourTypesModel
         * @instance
         */
        LabourTypesModel.prototype.labourTypes = $util.emptyArray;

        /**
         * Encodes the specified LabourTypesModel message. Does not implicitly {@link LabourTypes.LabourTypesModel.verify|verify} messages.
         * @function encode
         * @memberof LabourTypes.LabourTypesModel
         * @static
         * @param {LabourTypes.ILabourTypesModel} m LabourTypesModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LabourTypesModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.labourTypes != null && m.labourTypes.length) {
                for (var i = 0; i < m.labourTypes.length; ++i)
                    $root.LabourTypes.LabourTypesModel.LabourType.encode(m.labourTypes[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Encodes the specified LabourTypesModel message, length delimited. Does not implicitly {@link LabourTypes.LabourTypesModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof LabourTypes.LabourTypesModel
         * @static
         * @param {LabourTypes.ILabourTypesModel} message LabourTypesModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LabourTypesModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LabourTypesModel message from the specified reader or buffer.
         * @function decode
         * @memberof LabourTypes.LabourTypesModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {LabourTypes.LabourTypesModel} LabourTypesModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LabourTypesModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.LabourTypes.LabourTypesModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.labourTypes && m.labourTypes.length))
                        m.labourTypes = [];
                    m.labourTypes.push($root.LabourTypes.LabourTypesModel.LabourType.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a LabourTypesModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof LabourTypes.LabourTypesModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {LabourTypes.LabourTypesModel} LabourTypesModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LabourTypesModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        LabourTypesModel.LabourType = (function() {

            /**
             * Properties of a LabourType.
             * @memberof LabourTypes.LabourTypesModel
             * @interface ILabourType
             * @property {string|null} [labourTypeId] LabourType labourTypeId
             * @property {string|null} [labourTypeCode] LabourType labourTypeCode
             * @property {string|null} [labourTypeDesc] LabourType labourTypeDesc
             */

            /**
             * Constructs a new LabourType.
             * @memberof LabourTypes.LabourTypesModel
             * @classdesc Represents a LabourType.
             * @implements ILabourType
             * @constructor
             * @param {LabourTypes.LabourTypesModel.ILabourType=} [p] Properties to set
             */
            function LabourType(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * LabourType labourTypeId.
             * @member {string|null|undefined} labourTypeId
             * @memberof LabourTypes.LabourTypesModel.LabourType
             * @instance
             */
            LabourType.prototype.labourTypeId = null;

            /**
             * LabourType labourTypeCode.
             * @member {string|null|undefined} labourTypeCode
             * @memberof LabourTypes.LabourTypesModel.LabourType
             * @instance
             */
            LabourType.prototype.labourTypeCode = null;

            /**
             * LabourType labourTypeDesc.
             * @member {string|null|undefined} labourTypeDesc
             * @memberof LabourTypes.LabourTypesModel.LabourType
             * @instance
             */
            LabourType.prototype.labourTypeDesc = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * LabourType _labourTypeId.
             * @member {"labourTypeId"|undefined} _labourTypeId
             * @memberof LabourTypes.LabourTypesModel.LabourType
             * @instance
             */
            Object.defineProperty(LabourType.prototype, "_labourTypeId", {
                get: $util.oneOfGetter($oneOfFields = ["labourTypeId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * LabourType _labourTypeCode.
             * @member {"labourTypeCode"|undefined} _labourTypeCode
             * @memberof LabourTypes.LabourTypesModel.LabourType
             * @instance
             */
            Object.defineProperty(LabourType.prototype, "_labourTypeCode", {
                get: $util.oneOfGetter($oneOfFields = ["labourTypeCode"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * LabourType _labourTypeDesc.
             * @member {"labourTypeDesc"|undefined} _labourTypeDesc
             * @memberof LabourTypes.LabourTypesModel.LabourType
             * @instance
             */
            Object.defineProperty(LabourType.prototype, "_labourTypeDesc", {
                get: $util.oneOfGetter($oneOfFields = ["labourTypeDesc"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified LabourType message. Does not implicitly {@link LabourTypes.LabourTypesModel.LabourType.verify|verify} messages.
             * @function encode
             * @memberof LabourTypes.LabourTypesModel.LabourType
             * @static
             * @param {LabourTypes.LabourTypesModel.ILabourType} m LabourType message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LabourType.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.labourTypeId != null && Object.hasOwnProperty.call(m, "labourTypeId"))
                    w.uint32(10).string(m.labourTypeId);
                if (m.labourTypeCode != null && Object.hasOwnProperty.call(m, "labourTypeCode"))
                    w.uint32(18).string(m.labourTypeCode);
                if (m.labourTypeDesc != null && Object.hasOwnProperty.call(m, "labourTypeDesc"))
                    w.uint32(26).string(m.labourTypeDesc);
                return w;
            };

            /**
             * Encodes the specified LabourType message, length delimited. Does not implicitly {@link LabourTypes.LabourTypesModel.LabourType.verify|verify} messages.
             * @function encodeDelimited
             * @memberof LabourTypes.LabourTypesModel.LabourType
             * @static
             * @param {LabourTypes.LabourTypesModel.ILabourType} message LabourType message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LabourType.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LabourType message from the specified reader or buffer.
             * @function decode
             * @memberof LabourTypes.LabourTypesModel.LabourType
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {LabourTypes.LabourTypesModel.LabourType} LabourType
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LabourType.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.LabourTypes.LabourTypesModel.LabourType();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.labourTypeId = r.string();
                        break;
                    case 2:
                        m.labourTypeCode = r.string();
                        break;
                    case 3:
                        m.labourTypeDesc = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Decodes a LabourType message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof LabourTypes.LabourTypesModel.LabourType
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {LabourTypes.LabourTypesModel.LabourType} LabourType
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LabourType.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            return LabourType;
        })();

        return LabourTypesModel;
    })();

    return LabourTypes;
})();

module.exports = $root;
