import routeTypes from './route-types'
import store from '@/store'

// Lazy-load components. Webpack will split each into individual file
const QuickInvoiceListView = r => require.ensure([], () => r(require('./QuickInvoiceListView.vue')), 'quickinvoicelist-chunk')
const QuickInvoiceView = r => require.ensure([], () => r(require('./QuickInvoiceView.vue')), 'quickinvoice-chunk')
const QuickInvoiceDetail = r => require.ensure([], () => r(require('./QuickInvoiceDetail.vue')), 'quickinvoice-chunk')
const QuickInvoiceItems = r => require.ensure([], () => r(require('./QuickInvoiceItems.vue')), 'quickinvoice-item-chunk')

// Get sessionStorage
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|quickinvoice|${query}`)
}

const moduleId = 'A036'

export default [
  {
    path: routeTypes.QuickInvoiceListView.path,
    name: routeTypes.QuickInvoiceListView.name,
    component: QuickInvoiceListView,
    meta: {
      id: moduleId,
      route: 'listing',
      title: 'Quick Invoices',
      fkey: 'quickinvoice|filter',
      report: 'rptDebtorQuickInvoice',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.QuickInvoiceDetail.path,
    name: '',
    component: QuickInvoiceView,
    props: route => ({
      isNew: route.meta.isNew,
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl')
    }),
    meta: {
      id: moduleId,
      route: 'detail',
      title: 'Quick Invoice',
      isNew: false
    },
    children: [
      {
        path: '',
        name: routeTypes.QuickInvoiceDetail.name,
        component: QuickInvoiceDetail,
        // props: (route) => ({
        //   isNew: route.meta.isNew
        // }),
        // props: (route) => ({
        //   isNew: route.meta.isNew
        // }),
        // props: (route) => ({
        //   customerId: route.meta.customerId,
        //   contactId: route.meta.contactId
        // }),
        meta: {
          id: moduleId,
          route: 'detail',
          title: 'Detail',
          isNew: false
        }
      },
      {
        path: routeTypes.QuickInvoiceItems.path,
        name: routeTypes.QuickInvoiceItems.name,
        component: QuickInvoiceItems,
        meta: {
          id: moduleId,
          title: 'Items'
        }
      }
    ]
  }
]
