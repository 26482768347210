import routerTypes from './route-types'

// Lazy-load components. Webpack will split each into individual file
const OutstandingPartListView = r => require.ensure([], () => r(require('./OutstandingPartListView.vue')), 'outstandingpartlist-chunk')

export default [
  {
    path: routerTypes.OutstandingPartListView.path,
    name: routerTypes.OutstandingPartListView.name,
    component: OutstandingPartListView,
    meta: {
      id: 'A066',
      route: 'listing',
      title: 'Outstanding Parts List',
      fkey: 'outstandingpart|filter',
      report: 'AT_OutStandingParts',
      reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
    }
  }
]
