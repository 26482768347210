import routeTypes from './route-types'
import store from '@/store'

// Lazy-load components. Webpack will split each into individual file
const OtherLabourListView = r => require.ensure([], () => r(require('./OtherLabourListView.vue')), 'otherlabourlist-chunk')
const OtherLabourView = r => require.ensure([], () => r(require('./OtherLabourView.vue')), 'otherlabour-chunk')
const OtherLabourDetail = r => require.ensure([], () => r(require('./OtherLabourDetail.vue')), 'otherlabour-chunk')

// Get sessionStorage
const getStorage = function(query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|otherlabour|${query}`)
}

export default [
  {
    path: routeTypes.OtherLabourListView.path,
    name: routeTypes.OtherLabourListView.name,
    component: OtherLabourListView,
    meta: {
      id: 'A028',
      route: 'listing',
      title: 'Other Labours',
      fkey: 'otherlabour|filter',
      report: 'rptOtherLabourTablemaintenance',
      reportUrl: `${process.env.ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.OtherLabourDetail.path,
    name: '',
    component: OtherLabourView,
    props: route => ({
      isNew: route.query.mode === 'new' || route.meta.isNew || getStorage('isNew') === 'true',
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl'),
      name: route.query.name || route.meta.name || getStorage('name')
    }),
    meta: {
      id: 'A028',
      route: 'detail',
      title: 'Other Labour'
    },
    query: {
      filter: ''
    },
    children: [
      {
        path: '',
        name: routeTypes.OtherLabourDetail.name,
        component: OtherLabourDetail,
        meta: {
          title: 'Detail',
          fkey: 'otherlabour|filter',
          report: 'rptTableOtherLabourDetails',
          reportUrl: `${process.env.ROOT_URI}/modules/reports/printpreview.aspx`
        }
      }
    ]
  }
]
