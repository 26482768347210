import { ComponentRoute } from '@/classes'

const SuperadminVehicleLookupRoutes = {
  VehicleLookupView: new ComponentRoute('/superadmin/vehiclelookup', 'VehicleLookupView'),
  PricingView: new ComponentRoute('/superadmin/vehiclelookup/pricing', 'PricingView'),
  PricingDetail: new ComponentRoute('/superadmin/vehiclelookup/pricing', 'PricingDetail'),
  SubscriptionView: new ComponentRoute('/superadmin/vehiclelookup/subscription', 'SubscriptionView'),
  SubscriptionListing: new ComponentRoute('/superadmin/vehiclelookup/subscription/list', 'SubscriptionListing'),
  SubscriptionDetail: new ComponentRoute('/superadmin/vehiclelookup/subscription/detail', 'SubscriptionDetail')
}

export default SuperadminVehicleLookupRoutes
