import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
import ProtoBufApi from '@/services/protobuf-api'

const baseUrl = '/vehicles'

export default {
  async getEntity(id) {
    const res = await FqApi.get(`${baseUrl}?id=${id}`)
    return res.data
  },
  async postEntity(entity) {
    return FqApi.post(baseUrl, entity)
  },
  async putEntity(entity, deepdiff) {
    if (deepdiff) {
      entity.deepDiff = JSON.stringify(deepdiff)
    }
    return await FqApi.put(`${baseUrl}?id=${entity.id}`, entity)
  },
  async getNewEntity(rego) {
    if (rego) {
      const res = await FqApi.get(`${baseUrl}/new?rego=${rego}`)
      return res.data
    } else {
      const res = await FqApi.get(`${baseUrl}/new`)
      return res.data
    }
  },
  searchModelByName(key) {
    return FqApi.get(`/carmodels/searchname?key=${key}`)
  },
  serialiseVehicleDropdownFilter(filter) {
    const entityProto = require('@/assets/proto/quote/QuoteVehicleDropdownFilter.proto')
    const filterMessage = entityProto.QuoteVehicleDropdownFilter.QuoteVehicleDropdownFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getVehicleDropdownProto(filter) {
    const serialised = this.serialiseVehicleDropdownFilter(filter)
    const result = await ProtoBufApi(`${baseUrl}/protobuf/quotevehicles/listcombo/${serialised}`)
    const proto = require('@/assets/proto/quote/QuoteVehicleDropdown.proto')
    const message = proto.QuoteVehicleDropdown.QuoteVehicleDropdownModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.vehicles
  },
  async getNtarLtarStatusById(vehicleId) {
    const results = await FqApi.get(`/vehicles/ntarltarstatus/${vehicleId}`)
    return results.data
  },
  async getNtarLtarStatusByScheduleBody(scheduleId, bodyId) {
    const results = await FqApi.get(`/vehicles/ntarltarstatus/${scheduleId}/${bodyId}`)
    return results.data
  }
}