/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.Access = (function() {

    /**
     * Namespace Access.
     * @exports Access
     * @namespace
     */
    var Access = {};

    Access.LicenseDataModel = (function() {

        /**
         * Properties of a LicenseDataModel.
         * @memberof Access
         * @interface ILicenseDataModel
         * @property {string|null} [expiryDate] LicenseDataModel expiryDate
         * @property {boolean|null} [isExpired] LicenseDataModel isExpired
         * @property {boolean|null} [isExpiring] LicenseDataModel isExpiring
         * @property {boolean|null} [isGracePeriod] LicenseDataModel isGracePeriod
         * @property {number|null} [gracePeriod] LicenseDataModel gracePeriod
         */

        /**
         * Constructs a new LicenseDataModel.
         * @memberof Access
         * @classdesc Represents a LicenseDataModel.
         * @implements ILicenseDataModel
         * @constructor
         * @param {Access.ILicenseDataModel=} [p] Properties to set
         */
        function LicenseDataModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * LicenseDataModel expiryDate.
         * @member {string|null|undefined} expiryDate
         * @memberof Access.LicenseDataModel
         * @instance
         */
        LicenseDataModel.prototype.expiryDate = null;

        /**
         * LicenseDataModel isExpired.
         * @member {boolean|null|undefined} isExpired
         * @memberof Access.LicenseDataModel
         * @instance
         */
        LicenseDataModel.prototype.isExpired = null;

        /**
         * LicenseDataModel isExpiring.
         * @member {boolean|null|undefined} isExpiring
         * @memberof Access.LicenseDataModel
         * @instance
         */
        LicenseDataModel.prototype.isExpiring = null;

        /**
         * LicenseDataModel isGracePeriod.
         * @member {boolean|null|undefined} isGracePeriod
         * @memberof Access.LicenseDataModel
         * @instance
         */
        LicenseDataModel.prototype.isGracePeriod = null;

        /**
         * LicenseDataModel gracePeriod.
         * @member {number|null|undefined} gracePeriod
         * @memberof Access.LicenseDataModel
         * @instance
         */
        LicenseDataModel.prototype.gracePeriod = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * LicenseDataModel _expiryDate.
         * @member {"expiryDate"|undefined} _expiryDate
         * @memberof Access.LicenseDataModel
         * @instance
         */
        Object.defineProperty(LicenseDataModel.prototype, "_expiryDate", {
            get: $util.oneOfGetter($oneOfFields = ["expiryDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * LicenseDataModel _isExpired.
         * @member {"isExpired"|undefined} _isExpired
         * @memberof Access.LicenseDataModel
         * @instance
         */
        Object.defineProperty(LicenseDataModel.prototype, "_isExpired", {
            get: $util.oneOfGetter($oneOfFields = ["isExpired"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * LicenseDataModel _isExpiring.
         * @member {"isExpiring"|undefined} _isExpiring
         * @memberof Access.LicenseDataModel
         * @instance
         */
        Object.defineProperty(LicenseDataModel.prototype, "_isExpiring", {
            get: $util.oneOfGetter($oneOfFields = ["isExpiring"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * LicenseDataModel _isGracePeriod.
         * @member {"isGracePeriod"|undefined} _isGracePeriod
         * @memberof Access.LicenseDataModel
         * @instance
         */
        Object.defineProperty(LicenseDataModel.prototype, "_isGracePeriod", {
            get: $util.oneOfGetter($oneOfFields = ["isGracePeriod"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * LicenseDataModel _gracePeriod.
         * @member {"gracePeriod"|undefined} _gracePeriod
         * @memberof Access.LicenseDataModel
         * @instance
         */
        Object.defineProperty(LicenseDataModel.prototype, "_gracePeriod", {
            get: $util.oneOfGetter($oneOfFields = ["gracePeriod"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified LicenseDataModel message. Does not implicitly {@link Access.LicenseDataModel.verify|verify} messages.
         * @function encode
         * @memberof Access.LicenseDataModel
         * @static
         * @param {Access.ILicenseDataModel} m LicenseDataModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LicenseDataModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.expiryDate != null && Object.hasOwnProperty.call(m, "expiryDate"))
                w.uint32(10).string(m.expiryDate);
            if (m.isExpired != null && Object.hasOwnProperty.call(m, "isExpired"))
                w.uint32(16).bool(m.isExpired);
            if (m.isExpiring != null && Object.hasOwnProperty.call(m, "isExpiring"))
                w.uint32(24).bool(m.isExpiring);
            if (m.isGracePeriod != null && Object.hasOwnProperty.call(m, "isGracePeriod"))
                w.uint32(32).bool(m.isGracePeriod);
            if (m.gracePeriod != null && Object.hasOwnProperty.call(m, "gracePeriod"))
                w.uint32(40).int32(m.gracePeriod);
            return w;
        };

        /**
         * Encodes the specified LicenseDataModel message, length delimited. Does not implicitly {@link Access.LicenseDataModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Access.LicenseDataModel
         * @static
         * @param {Access.ILicenseDataModel} message LicenseDataModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LicenseDataModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LicenseDataModel message from the specified reader or buffer.
         * @function decode
         * @memberof Access.LicenseDataModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Access.LicenseDataModel} LicenseDataModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LicenseDataModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Access.LicenseDataModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.expiryDate = r.string();
                    break;
                case 2:
                    m.isExpired = r.bool();
                    break;
                case 3:
                    m.isExpiring = r.bool();
                    break;
                case 4:
                    m.isGracePeriod = r.bool();
                    break;
                case 5:
                    m.gracePeriod = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a LicenseDataModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Access.LicenseDataModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Access.LicenseDataModel} LicenseDataModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LicenseDataModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return LicenseDataModel;
    })();

    return Access;
})();

$root.bcl = (function() {

    /**
     * Namespace bcl.
     * @exports bcl
     * @namespace
     */
    var bcl = {};

    bcl.TimeSpan = (function() {

        /**
         * Properties of a TimeSpan.
         * @memberof bcl
         * @interface ITimeSpan
         * @property {number|Long|null} [value] TimeSpan value
         * @property {bcl.TimeSpan.TimeSpanScale|null} [scale] TimeSpan scale
         */

        /**
         * Constructs a new TimeSpan.
         * @memberof bcl
         * @classdesc Represents a TimeSpan.
         * @implements ITimeSpan
         * @constructor
         * @param {bcl.ITimeSpan=} [p] Properties to set
         */
        function TimeSpan(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TimeSpan value.
         * @member {number|Long} value
         * @memberof bcl.TimeSpan
         * @instance
         */
        TimeSpan.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TimeSpan scale.
         * @member {bcl.TimeSpan.TimeSpanScale} scale
         * @memberof bcl.TimeSpan
         * @instance
         */
        TimeSpan.prototype.scale = 0;

        /**
         * Encodes the specified TimeSpan message. Does not implicitly {@link bcl.TimeSpan.verify|verify} messages.
         * @function encode
         * @memberof bcl.TimeSpan
         * @static
         * @param {bcl.ITimeSpan} m TimeSpan message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeSpan.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(8).sint64(m.value);
            if (m.scale != null && Object.hasOwnProperty.call(m, "scale"))
                w.uint32(16).int32(m.scale);
            return w;
        };

        /**
         * Encodes the specified TimeSpan message, length delimited. Does not implicitly {@link bcl.TimeSpan.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.TimeSpan
         * @static
         * @param {bcl.ITimeSpan} message TimeSpan message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeSpan.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TimeSpan message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.TimeSpan
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.TimeSpan} TimeSpan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeSpan.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.TimeSpan();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.value = r.sint64();
                    break;
                case 2:
                    m.scale = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a TimeSpan message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.TimeSpan
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.TimeSpan} TimeSpan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeSpan.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * TimeSpanScale enum.
         * @name bcl.TimeSpan.TimeSpanScale
         * @enum {number}
         * @property {number} DAYS=0 DAYS value
         * @property {number} HOURS=1 HOURS value
         * @property {number} MINUTES=2 MINUTES value
         * @property {number} SECONDS=3 SECONDS value
         * @property {number} MILLISECONDS=4 MILLISECONDS value
         * @property {number} MINMAX=15 MINMAX value
         */
        TimeSpan.TimeSpanScale = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DAYS"] = 0;
            values[valuesById[1] = "HOURS"] = 1;
            values[valuesById[2] = "MINUTES"] = 2;
            values[valuesById[3] = "SECONDS"] = 3;
            values[valuesById[4] = "MILLISECONDS"] = 4;
            values[valuesById[15] = "MINMAX"] = 15;
            return values;
        })();

        return TimeSpan;
    })();

    bcl.DateTime = (function() {

        /**
         * Properties of a DateTime.
         * @memberof bcl
         * @interface IDateTime
         * @property {number|Long|null} [value] DateTime value
         * @property {bcl.DateTime.TimeSpanScale|null} [scale] DateTime scale
         */

        /**
         * Constructs a new DateTime.
         * @memberof bcl
         * @classdesc Represents a DateTime.
         * @implements IDateTime
         * @constructor
         * @param {bcl.IDateTime=} [p] Properties to set
         */
        function DateTime(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DateTime value.
         * @member {number|Long} value
         * @memberof bcl.DateTime
         * @instance
         */
        DateTime.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DateTime scale.
         * @member {bcl.DateTime.TimeSpanScale} scale
         * @memberof bcl.DateTime
         * @instance
         */
        DateTime.prototype.scale = 0;

        /**
         * Encodes the specified DateTime message. Does not implicitly {@link bcl.DateTime.verify|verify} messages.
         * @function encode
         * @memberof bcl.DateTime
         * @static
         * @param {bcl.IDateTime} m DateTime message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateTime.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(8).sint64(m.value);
            if (m.scale != null && Object.hasOwnProperty.call(m, "scale"))
                w.uint32(16).int32(m.scale);
            return w;
        };

        /**
         * Encodes the specified DateTime message, length delimited. Does not implicitly {@link bcl.DateTime.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.DateTime
         * @static
         * @param {bcl.IDateTime} message DateTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateTime.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DateTime message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.DateTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.DateTime} DateTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateTime.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.DateTime();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.value = r.sint64();
                    break;
                case 2:
                    m.scale = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a DateTime message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.DateTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.DateTime} DateTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateTime.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * TimeSpanScale enum.
         * @name bcl.DateTime.TimeSpanScale
         * @enum {number}
         * @property {number} DAYS=0 DAYS value
         * @property {number} HOURS=1 HOURS value
         * @property {number} MINUTES=2 MINUTES value
         * @property {number} SECONDS=3 SECONDS value
         * @property {number} MILLISECONDS=4 MILLISECONDS value
         * @property {number} MINMAX=15 MINMAX value
         */
        DateTime.TimeSpanScale = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DAYS"] = 0;
            values[valuesById[1] = "HOURS"] = 1;
            values[valuesById[2] = "MINUTES"] = 2;
            values[valuesById[3] = "SECONDS"] = 3;
            values[valuesById[4] = "MILLISECONDS"] = 4;
            values[valuesById[15] = "MINMAX"] = 15;
            return values;
        })();

        return DateTime;
    })();

    bcl.Guid = (function() {

        /**
         * Properties of a Guid.
         * @memberof bcl
         * @interface IGuid
         * @property {number|Long|null} [lo] Guid lo
         * @property {number|Long|null} [hi] Guid hi
         */

        /**
         * Constructs a new Guid.
         * @memberof bcl
         * @classdesc Represents a Guid.
         * @implements IGuid
         * @constructor
         * @param {bcl.IGuid=} [p] Properties to set
         */
        function Guid(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Guid lo.
         * @member {number|Long} lo
         * @memberof bcl.Guid
         * @instance
         */
        Guid.prototype.lo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Guid hi.
         * @member {number|Long} hi
         * @memberof bcl.Guid
         * @instance
         */
        Guid.prototype.hi = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Encodes the specified Guid message. Does not implicitly {@link bcl.Guid.verify|verify} messages.
         * @function encode
         * @memberof bcl.Guid
         * @static
         * @param {bcl.IGuid} m Guid message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Guid.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.lo != null && Object.hasOwnProperty.call(m, "lo"))
                w.uint32(9).fixed64(m.lo);
            if (m.hi != null && Object.hasOwnProperty.call(m, "hi"))
                w.uint32(17).fixed64(m.hi);
            return w;
        };

        /**
         * Encodes the specified Guid message, length delimited. Does not implicitly {@link bcl.Guid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.Guid
         * @static
         * @param {bcl.IGuid} message Guid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Guid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Guid message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.Guid
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.Guid} Guid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Guid.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.Guid();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.lo = r.fixed64();
                    break;
                case 2:
                    m.hi = r.fixed64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a Guid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.Guid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.Guid} Guid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Guid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return Guid;
    })();

    bcl.Decimal = (function() {

        /**
         * Properties of a Decimal.
         * @memberof bcl
         * @interface IDecimal
         * @property {number|Long|null} [lo] Decimal lo
         * @property {number|null} [hi] Decimal hi
         * @property {number|null} [signScale] Decimal signScale
         */

        /**
         * Constructs a new Decimal.
         * @memberof bcl
         * @classdesc Represents a Decimal.
         * @implements IDecimal
         * @constructor
         * @param {bcl.IDecimal=} [p] Properties to set
         */
        function Decimal(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Decimal lo.
         * @member {number|Long} lo
         * @memberof bcl.Decimal
         * @instance
         */
        Decimal.prototype.lo = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Decimal hi.
         * @member {number} hi
         * @memberof bcl.Decimal
         * @instance
         */
        Decimal.prototype.hi = 0;

        /**
         * Decimal signScale.
         * @member {number} signScale
         * @memberof bcl.Decimal
         * @instance
         */
        Decimal.prototype.signScale = 0;

        /**
         * Encodes the specified Decimal message. Does not implicitly {@link bcl.Decimal.verify|verify} messages.
         * @function encode
         * @memberof bcl.Decimal
         * @static
         * @param {bcl.IDecimal} m Decimal message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Decimal.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.lo != null && Object.hasOwnProperty.call(m, "lo"))
                w.uint32(8).uint64(m.lo);
            if (m.hi != null && Object.hasOwnProperty.call(m, "hi"))
                w.uint32(16).uint32(m.hi);
            if (m.signScale != null && Object.hasOwnProperty.call(m, "signScale"))
                w.uint32(24).sint32(m.signScale);
            return w;
        };

        /**
         * Encodes the specified Decimal message, length delimited. Does not implicitly {@link bcl.Decimal.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bcl.Decimal
         * @static
         * @param {bcl.IDecimal} message Decimal message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Decimal.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Decimal message from the specified reader or buffer.
         * @function decode
         * @memberof bcl.Decimal
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {bcl.Decimal} Decimal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Decimal.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.bcl.Decimal();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.lo = r.uint64();
                    break;
                case 2:
                    m.hi = r.uint32();
                    break;
                case 3:
                    m.signScale = r.sint32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a Decimal message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bcl.Decimal
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bcl.Decimal} Decimal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Decimal.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return Decimal;
    })();

    return bcl;
})();

module.exports = $root;
