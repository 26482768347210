import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('notification', { $notifications: 'notifications' })
  },
  methods: {
    ...mapActions('notification', ['startNotificationPoll', 'stopNotificationPoll', 'getNotifications'])
  }
}
